import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, List, Typography, Button, Image } from "antd";
import UserActions from "../../actions";
import Actions from "../../common/ActionsStrings";
import { getFormattedDt, getFormattedDtNew } from "../../common/util";
import Loader from "../../components/Loading/Loader";
import Edit from "./Edit";
import { GrAnnounce } from "react-icons/gr";
import { AiOutlineEdit } from "react-icons/ai";
import ContainerWrapper from "../../components/ContainerWrapper";
import "../../sass/styles/fyndr/_campaign.scss";
import Expired from "../../assets/images/expired2.png";
import ExpiringSoon from "../../assets/images/Expiring soon.png";
import Featured from "../../assets/images/Featured.png";
import featureLogo from "../../assets/images/featuredLogo.png";
import NeverPromoted from "../../assets/images/NeverPromoted.png";
import HelmetFyndr from "../../components/HelmetFyndr";
import getEnvVars from "../../environment";
import Tour from "reactour";
import blueAuto from "../../assets/images/blueAuto.svg"
import yellowAuto from "../../assets/images/yellowAuto.svg"
import redAuto from "../../assets/images/redAuto.svg"
import grayAuto from "../../assets/images/grayAuto.svg"
import offerImage from '../../assets/images/offerCampaign.svg'
import couponImage from '../../assets/images/couponCampaign.svg'
import eventImage from '../../assets/images/eventCampaign.svg'

const { Text } = Typography;
const web = getEnvVars();
const CampaignCenter = (props) => {
  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);
  const { history } = props;
  const dispatch = useDispatch();
  const { CMPN_SELECTED, CMPN_INIT, TOGGLE_CREATE_CAMPAIGN } = Actions;
  const [started, setStarted] = useState(false);
  const [current, setCurrent] = useState(0);
  const [visitedTill, setVisitedTill] = useState(0);
  const [sortedCmpns, setSortedCmpns] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("all");
  const {
    indv: { bizid },
  } = useSelector(({ auth }) => auth);
  const { cmpns } = useSelector(({ cmpn }) => cmpn);
  const { collapsedCreateCampaign } = useSelector(({ settings }) => settings);
  const [isOpen, setIsOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const tourConfig = [
    {
      selector: '[Indicators="featured"]',
      content: `Click on indicators to see only featured camapigns.`,
    },
    {
      selector: '[Indicators="expired"]',
      content: `Click on indicators to see only expired campaigns.`,
    },
    {
      selector: '[Indicators="expiringSoon"]',
      content: `Click on indicators to see campaigns which are going to expire soon.`,
    },
    {
      selector: '[Indicators="neverPromoted"]',
      content: `Click on indicators to see only those campaigns which are never featured.`,
    },
  ];
  useEffect(() => {
    localStorage.setItem("tourOpenIndicators", JSON.stringify(isOpen));
  }, [isOpen]);


  useEffect(() => {
    getCampaigns();

    if (collapsedCreateCampaign === "createCampaign") {
      startCampaign();
    }
    if (collapsedCreateCampaign === "campaignList") {
      setStarted(false);
      getCampaigns();
    }
  }, [started, collapsedCreateCampaign]);

  useEffect(() => {
    let tmp = [];
    let featuredCmpns = [];
    let nonFeaturedCmpns = [];
    cmpns &&
      Object.keys(cmpns).forEach((key) => {
        if (cmpns[key].isFeatured) {
          featuredCmpns.push(cmpns[key]);
        } else {
          nonFeaturedCmpns.push(cmpns[key]);
        }
      });
    featuredCmpns.sort(
      (a, b) => new Date(a.featuredEndDt) - new Date(b.featuredEndDt)
    );
    nonFeaturedCmpns.sort((a, b) => new Date(b.endDt) - new Date(a.endDt));
    tmp = featuredCmpns.concat(nonFeaturedCmpns);
    setSortedCmpns(tmp);
  }, [cmpns]);

  const editCampaign = async (campaign) => {
    await dispatch({ type: TOGGLE_CREATE_CAMPAIGN, value: "editCampaign" });
    await dispatch({ type: CMPN_SELECTED, payload: campaign });
    setIsEdit(true);
    setCurrent(0);
    setStarted(true);
  };

  const startCampaign = () => {
    setIsEdit(false);
    setCurrent(0);
    dispatch({ type: CMPN_INIT });
    setStarted(true);
  };

  const getCampaigns = async () => {
    setIsLoading(true);
    await UserActions.fetchCampaigns(bizid);
    setIsLoading(false);
  };

  const onChange = async (value) => {
    if (isEdit) {
      setCurrent(value);
      setVisitedTill(value);
    } else {
      if (value <= visitedTill) {
        setCurrent(value);
        setVisitedTill(value);
      }
    }
  };

  const handleFilterClick = (filterType) => {
    setFilter((prevFilter) => (prevFilter === filterType ? "all" : filterType));
  };

  const filteredCmpns = sortedCmpns.filter((campaign) => {
    const currDate = new Date();
    const featuredEndDate = new Date(campaign.featuredEndDt);
  
    if (filter === "all") return true;

    if (filter === "featured" && campaign.isFeatured) {
      const timeDiff = featuredEndDate - currDate;
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (daysDiff > 10) return true;
    }

    if (filter === "expired") {
      return featuredEndDate < currDate && campaign.isFeatured;
    }

    if (filter === "expiringSoon" && campaign.isFeatured) {
      const timeDiff = featuredEndDate - currDate;
      const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      return daysDiff > 0 && daysDiff <= 10;
    }

    if (filter === "neverPromoted" && !campaign.isFeatured) return true;

    return false;
  });

  useEffect(() => {
    const isTourOpen = JSON.parse(localStorage.getItem("tourOpenIndicators"));
    if (isTourOpen !== null) {
      setIsOpen(isTourOpen);
    } else {
      localStorage.setItem("tourOpenIndicators", JSON.stringify(true));
      setIsOpen(true);
    }
  }, []);

  const closeTour = () => {
    setIsOpen(false);
  };

  const determineStylesAndImage = (row, currDate) => {
    let featuredEndDate = new Date(row.featuredEndDt);
    let image = grayAuto;
    if (featuredEndDate < currDate && row.isFeatured) {
      image = redAuto;
    }
    if (featuredEndDate > currDate && row.isFeatured) {
      image = blueAuto;
      let timeDiff = featuredEndDate - currDate;
      let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (daysDiff <= 10) {
        image = yellowAuto;
      }
    }
    return image;
  }


  const skipTour = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Tour
        steps={tourConfig}
        isOpen={isOpen}
        onRequestClose={closeTour}
        showCloseButton={true}
        showSkipButton={true}
        onAfterOpen={() => setIsOpen(true)}
        onRequestSkip={skipTour}
        getCurrentStep={() => currentStep}
      />
      {!started && (
        <ContainerWrapper
          title={"Campaign Center"}
          headerOption={<Row justify={"end"}>{isLoading && <Loader />}</Row>}
        >

          <HelmetFyndr
            title="Campaign"
            imgUrl={""}
            url={`${web}/campaign`}
            descr="campaign"
          />

          <Card className="outer-card">
            <div className="sticky-div">
              <Row
                style={{
                  background: "#fff",
                  padding: "0px",
                  height: "24px",
                }}
              >
                <Col
                  Indicators="featured"
                  lg={2}
                  md={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleFilterClick("featured")}
                >
                  <div
                    className={
                      filter !== "featured" && filter !== "all"
                        ? "dots-filter blurred"
                        : "dots-filter"
                    }
                    style={{ background: "#257CDB" }}
                  />
                  <Text
                    className={
                      filter !== "featured" && filter !== "all"
                        ? "adminCmpn--status blurred"
                        : "adminCmpn--status"
                    }
                  >
                    Featured
                  </Text>
                </Col>
                <Col
                  Indicators="expired"
                  lg={2}
                  md={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleFilterClick("expired")}
                >
                  <div
                    className={
                      filter !== "expired" && filter !== "all"
                        ? "dots-filter blurred"
                        : "dots-filter"
                    }
                    style={{ background: "#ED0C10" }}
                  />
                  <Text
                    className={
                      filter !== "expired" && filter !== "all"
                        ? "adminCmpn--status blurred"
                        : "adminCmpn--status"
                    }
                  >
                    Expired
                  </Text>
                </Col>
                <Col
                  Indicators="expiringSoon"
                  lg={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleFilterClick("expiringSoon")}
                >
                  <div
                    className={
                      filter !== "expiringSoon" && filter !== "all"
                        ? "dots-filter blurred"
                        : "dots-filter"
                    }
                    style={{ background: "#FFC700" }}
                  />
                  <Text
                    className={
                      filter !== "expiringSoon" && filter !== "all"
                        ? "adminCmpn--status blurred"
                        : "adminCmpn--status"
                    }
                  >
                    Expiring Soon
                  </Text>
                </Col>
                <Col
                  Indicators="neverPromoted"
                  lg={3}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => handleFilterClick("neverPromoted")}
                >
                  <div
                    className={
                      filter !== "neverPromoted" && filter !== "all"
                        ? "dots-filter blurred"
                        : "dots-filter"
                    }
                    style={{ background: "#E6E6E6" }}
                  />
                  <Text
                    // className="adminCmpn--status"
                    className={
                      filter !== "neverPromoted" && filter !== "all"
                        ? "adminCmpn--status blurred"
                        : "adminCmpn--status"
                    }
                  >
                    Never Promoted
                  </Text>
                </Col>
              </Row>
            </div>
            <List
              itemLayout="horizontal"
              split={false}
              dataSource={filteredCmpns}
              renderItem={(row) => {
                if (row.cmpnType === "brochure") {
                  return null;
                }
                let currDate = new Date();
                let featuredEndDate = new Date(row.featuredEndDt);
                // grey
                let backgroundColor =
                  "linear-gradient(90deg, rgba(227, 227, 227, 0.92) 0%, #FFFFFF 100%)";
                let textColor = "#666666";
                let image = NeverPromoted;
                let imageHeigth = "70%";
                let imageWidth = "60%";
                // red
                if (featuredEndDate < currDate && row.isFeatured) {
                  backgroundColor =
                    "linear-gradient(90deg, #FFDADC 0%, #FFFFFF 100%, #FFFFFF 100.01%)";
                  textColor = "#ED0C10";
                  image = Expired;
                  imageHeigth = "80%";
                  imageWidth = "50%";
                }

                //blue
                if (featuredEndDate > currDate && row.isFeatured) {
                  backgroundColor =
                    "linear-gradient(90deg, #DEECFF 0%, #FFFFFF 100%)";
                  textColor = "#257CDB";
                  image = Featured;
                  let timeDiff = featuredEndDate - currDate;
                  let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
                  //yellow
                  if (daysDiff <= 10) {
                    backgroundColor =
                      "linear-gradient(90deg, #FFFCDE 0%, #FFFFFF 100%)";
                    textColor = "#FFC700";
                    image = ExpiringSoon;
                  }
                }
                return (
                  <List.Item style={{padding:"4px 0"}}>
                    <Row
                      style={{
                        background: backgroundColor,
                        width: "100%",
                        border: "1px solid #d3d6e1",
                        height: "auto",
                        borderRadius: "9px",
                        boxShadow: "0px 4px 4px 0px #0000000F",

                      }}
                    >
                      <Col
                      span={6} 
                      // sm={6} md={6} lg={6} xl={4} xxl={4}
                      >
                        <div className="offerImg">
                          <img
                            alt=""
                            src={row.cmpnImgs[0]?.thumbnailUri}
                            className="offerImg-img"
                          />
                        </div>
                      </Col>
                      <Col
                        span={10}
                        className="offerTitle-container"
                        style={{ color: textColor }}
                      >
                        <Row>
                          <Col span={24} className="offerTitle">
                            {row.title}<span style={{ marginLeft: "24px" }}> <img src={row?.paymentSubscription?.status === "ACTIVE" && determineStylesAndImage(row, currDate)} />
                            </span>
                          </Col>
                          <Col
                            className="item-text"
                            sm={13}
                            md={13}
                            lg={12}
                            xl={14}
                            xxl={12}
                          >
                            <span>
                              Start date : {getFormattedDtNew(row.startDt, userTimeZone)}
                            </span>
                          </Col>
                          <Col
                            className="item-text"
                            sm={10}
                            md={9}
                            lg={10}
                            xl={8}
                            xxl={6}
                            offset={2}
                          >
                            <span>Type: {row.cmpnType} </span>
                          </Col>
                          <Col
                            className="item-text"
                            sm={12}
                            md={13}
                            lg={12}
                            xl={14}
                            xxl={12}
                          >
                            End date: {getFormattedDtNew(row.endDt, userTimeZone)}
                          </Col>
                          <Col
                            className="item-text"
                            sm={6}
                            md={9}
                            lg={10}
                            xl={8}
                            xxl={6}
                            offset={2}
                          >
                            Sold :{" "}
                            {`${row.offers.reduce(function (sum, current) {
                              return sum + current.offerSold;
                            }, 0)}`}{" "}
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        span={3}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          style={{
                            height: imageHeigth,
                            width: imageWidth,
                            color: "red",
                          }}
                          src={image}
                          alt=""
                        />
                        
                      </Col>
                      <Col
                        span={5}
                        className="featuredBtn"
                      >
                        {row.isFeatured && (
                          <div>
                            <img
                              alt="featured Logo"
                              src={featureLogo}
                              style={{ height: "2rem", width: "8rem" }}
                            />
                          </div>
                        )}

                        <div style={{ paddingTop: "8px" }}>
                          {new Date().toISOString() < row.endDt && (
                            <Button
                              style={{
                                width: "9rem",
                                padding: "3px",
                                borderRadius: "8px",
                              }}
                              type="primary"
                              onClick={() =>
                                history.push("campaign/promo", {
                                  cmpn_id: row.objid,
                                  title: row.title,
                                  featured: row.isFeatured,
                                  paymentSubscription: row.paymentSubscription
                                })
                              }
                            >
                              <span style={{ marginRight: "3px" }}>
                                Promote
                              </span>

                            <GrAnnounce style={{ marginTop: "3px" }} />
                          </Button>
                          )}
                        </div>
                        <div style={{ paddingTop: "8px" }}>
                          <Button
                            style={{
                              width: "9rem",
                              padding: "3px",
                              borderRadius: "8px",
                            }}
                            type="default"
                            onClick={() => editCampaign(row)}
                          >
                            <span style={{ marginRight: "3px" }}>Edit</span>{" "}
                            <AiOutlineEdit style={{ marginTop: "3px" }} />
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </List.Item>
                );
              }}
            />
          </Card>
        </ContainerWrapper>
      )}
      {started && (
        <Edit
          current={current}
          onChange={onChange}
          nextStep={(value) => setCurrent(value + 1)}
          isEdit={isEdit}
          callback={(value) => {
            setStarted(value);
            dispatch({
              type: TOGGLE_CREATE_CAMPAIGN,
              value: "campaignList",
            });
            !value && getCampaigns();
          }}
          {...props}
        />
      )}
    </>
  );
};

export default CampaignCenter;
