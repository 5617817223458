import React, { useState, useEffect } from 'react'
import Dispute from '../../components/Dispute';
import DropdownComponent from '../../components/Dashboard/DropdownComponent';
import { Button, Space, message } from 'antd';
import actions from '../../actions';
import RefundModal from '../../components/Dispute/RefundModal';
import Comments from '../../components/Dispute/Comments';
import { useSelector } from 'react-redux';
import moment from 'moment';
import PulseLoader from "react-spinners/PulseLoader";
export default function DisputeForBiz() {
    const { indv } = useSelector(({ auth }) => auth);
    const [paymentId, setPaymentId] = useState();
    const [actualAmount, setActualAmount] = useState();
    const [amount, setAmount] = useState(null);
    const [disputeId, setDisputeId] = useState();
    const [refundApplicationFee, setRefundApplicationFee] = useState(false);
    const [reverseTransfer, setReverseTransfer] = useState(true);
    const [visible, setVisible] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [bgColor, setBgColor] = useState("");
    const [color, setColor] = useState("");
    const [statusDropdown, setStatusDropdown] = useState("");
    const [reason, setReason] = useState("");
    const [CommentsVisible, setCommentsVisible] = useState(false);
    const [comments, setComments] = useState("");
    const [selectedMerchant, setSelectedMerchant] = useState({});
    const [startDate, setStartDate] = useState("");
    const [commentslist, setCommenstList] = useState([]);
    const [status, setStatus] = useState([]);
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [data, setData] = useState([]);
    const statusColumn = [
        { value: "INITIATED", label: "Initiated" },
        { value: "CANCELED", label: "Canceled" },
        { value: "DISPUTED", label: "Disputed" },
        {
            value: "SETTLED_WITH_CUSTOMER_PAYMENT",
            label: "Settled With Customer Payment",
            disabled: true,
        },
        { value: "SETTLED_WITHOUT_PAYMENT", label: "Settled Without Payment" },
    ];
    const Reason = [
        { value: "duplicate", label: "Duplicate" },
        { value: "fraudulent", label: "Fraudulent" },
        { value: "requested_by_customer", label: "Requested By Customer" },
        { value: "Other", label: "Other" }
    ]
    const getColor = (status) => {
        switch (status) {
            case "INITIATED":
                return "#07C603";
            case "CANCELED":
                return "#C63D03";
            case "DISPUTED":
                return "#6F6F6F";
            case "SETTLED_WITH_CUSTOMER_PAYMENT":
                return "#0000FF";
            case "SETTLED_WITHOUT_PAYMENT":
                return "#9E0F99";
            default:
                return null;
        }
    };
    const getBgColor = (status) => {
        switch (status) {
            case "INITIATED":
                return "#E2FFE5";
            case "CANCELED":
                return "#FFD6D6";
            case "DISPUTED":
                return "#E8E8E8";
            case "SETTLED_WITH_CUSTOMER_PAYMENT":
                return "#E2E2FF";
            case "SETTLED_WITHOUT_PAYMENT":
                return "#FFCBFD";
            default:
                return null;
        }
    };
    const getLabels = (item) => {
        switch (item) {
            case "INITIATED":
                return "Initiated";
            case "SETTLED_WITH_CUSTOMER_PAYMENT":
                return "Settled With Customer Payment";
            case "SETTLED_WITHOUT_PAYMENT":
                return "Settled Without Payment";
            case "CANCELED":
                return "Canceled";
            case "DISPUTED":
                return "Disputed";
        }
    }
    const disputeListingsApiBiz = async () => {
        setLoading(true)
        let response = await actions.disputeListingsForBiz({
            status: status,
            startDate: startDate,
            endDate: endDate,
            pgstart: pagination.current,
            pgSize: pagination.pageSize
        })
        setPagination({
            ...pagination,
            total: response?.count,
        });
        if (response?.success === true) {
            setLoading(false);
            setData(response?.disputeDetails);
        }
    }
    const refundProcess = async () => {
        let response = await actions.RefundApi({
            disputeId: disputeId,
            refundAmt: amount,
            paymentId: paymentId,
            reason: reason,
            refundApplicationFee: refundApplicationFee,
            reverseTransfer: reverseTransfer,
            remarks: remarks
        })
        if (response?.success === true) {
            message.success(response?.msg)
            setVisible(false)
        } else {
            message.error(response?.msg)
            setVisible(false)
        }
    }
    const updateDisputeStatus = async (value, disputeId) => {
        let payload = {
            status: value
        }
        let resp = await actions.disputeStatusUpdate(payload, disputeId);
        if (resp.message) {
            disputeListingsApiBiz()
            message.success(resp.message)
        } else {
            message.error(resp.msg)
        }

    }
    const saveCommentApi = async () => {
        let response = await actions.saveCommentsforBiz({
            disputeId: disputeId,
            userId: indv.indvid,
            comment: comments
        })
        if (response) {
            message.success(response.message);
            listingOfComments(disputeId);
            setComments(" ")
        } else {
            message.error(response.message);
            setCommentsVisible(false);
        }
    }
    useEffect(() => {
        disputeListingsApiBiz();
    }, [status,
        endDate,
        pagination.current,
        pagination.pageSize,
        pagination.total]);

    const handleRefundbtnClick = (item) => {
        if (item === "CANCELED" || item === "SETTLED_WITH_CUSTOMER_PAYMENT" || item === "SETTLED_WITHOUT_PAYMENT") {
            message.error("Dispute is already settled or canceled. Please contact Fyndr Support for more information.");
        } else {
            setVisible(true);
        }
    }
    const handleTableChange = (pagination) => {
        setPagination({
            ...pagination
        })
    };
    const handleOnRefundClick = () => {
        if (amount === null) {
            message.error("Amount can't be empty")
        }
        else if (amount <= 0) {
            message.error("Amount must be greater than 0")
        }
        else if (amount > actualAmount) {
            message.error("Amount must not be greater than the actual amount")
        }
        else if (reason === "") {
            message.error("Please select any one of the reasons to proceed")
        } else {
            refundProcess()
        }
    }
    const handleOnClick = () => {
        if (comments === "") {
            message.error("Comments can't be empty or null")
        } else {
            saveCommentApi();
        }
    }
    const listingOfComments = async (disputeId) => {
        setIsloading(true)
        let response = await actions.fetchComments(disputeId)
        if (response?.success === true) {
            setIsloading(false);
            setCommenstList(response.payload);
        }
    }
    const tableColumn = [
        {
            title: "Invoice ID",
            dataIndex: "invoiceId",
            key: "invoiceId",

        },
        {
            title: "Dispute ID",
            dataIndex: "disputeId",
            key: "disputeId",


        },
        {
            title: "Client Email",
            dataIndex: "buyerEmail",
            key: "buyerEmail",

        },
        {
            title: "Dispute Reason",
            dataIndex: "disputeReason",
            key: "disputeReason",
            width: 200,
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (data, key) => {
                return <p>{data ? `$${data}` : "-"}</p>;
            },
        },
        {
            title: "Status",
            dataIndex: "disputeStatus",
            key: "disputeStatus",
            width: 180,
            render: (item, record) => (
                <DropdownComponent
                    bordered={false}
                    options={statusColumn}
                    disabled={
                        item === "SETTLED_WITH_CUSTOMER_PAYMENT" ||
                        item === "SETTLED_WITHOUT_PAYMENT"
                    }
                    value={getLabels(item)}
                    style={{
                        color: getColor(item),
                        width: "100%",
                        borderRadius: "1rem",
                        backgroundColor: getBgColor(item),
                    }}
                    onChange={(option) => {
                        setColor(getColor(option));
                        setBgColor(getBgColor(option));
                        setStatusDropdown(option);
                        updateDisputeStatus(option, record.disputeId);
                    }}
                />
            )
        },
        {
            title: "Actions",
            key: "action",
            render: (item) => (
                <Space>
                    <Button
                        type='primary'
                        style={{
                            borderRadius: "2rem",
                            padding: ".4rem .5rem .4rem .5rem",
                        }}
                        onClick={() => {
                            handleRefundbtnClick(item.status);
                            setPaymentId(item.paymentId);
                            setDisputeId(item.disputeId);
                            setAmount(item.amount);
                            setReason("");
                            setReverseTransfer(true);
                            setRefundApplicationFee(false);
                            setActualAmount(item.amount);
                        }}
                        key={item}
                        disabled={
                            item?.disputeStatus === "SETTLED_WITH_CUSTOMER_PAYMENT" ||
                            item?.disputeStatus === "SETTLED_WITHOUT_PAYMENT"
                        }
                    >
                        Refund
                    </Button>
                    <Button
                        type='link'
                        style={{
                            borderRadius: "2rem",
                            padding: ".4rem .5rem .4rem .5rem",
                        }}
                        onClick={() => {
                            setDisputeId(item.disputeId);
                            setCommentsVisible(true)
                            setSelectedMerchant(item);
                            listingOfComments(item.disputeId);

                        }}
                        key={item}
                    >
                        Comment
                    </Button>
                </Space>
            ),
        },
    ];
    return (
        <div>
            <div className="quickLoader">
                <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
            </div>
            <Dispute
                columns={tableColumn}
                pagination={pagination}
                dataSource={data}
                visible={visible}
                handleTableChange={handleTableChange}
                onChange={(e) => {
                    setStatus(e);
                }}
                handleOnChange={(e) => {
                    if (e === null) {
                        setStartDate("");
                        setEndDate("");
                    } else {
                        setPagination({
                            ...pagination,
                            current: 1,
                        });
                        setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
                        setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
                    }
                }}
            />
            <RefundModal
                visible={visible}
                options={Reason}
                isChecked={reverseTransfer}
                onCancel={() => setVisible(false)}
                actualAmount={actualAmount}
                handleInputChange={(e) => {
                    setAmount(e.target.value)
                }}
                onChange={(value) => {
                    setReason(value)
                }}
                handleOnClick={() => handleOnRefundClick()}
                handleTextChange={(e) => {
                    setRemarks(e.target.value)
                }}
                handleOnChange={(e) => {
                    setReverseTransfer(e.target.checked);
                }}
                handleValueChange={(e) => {
                    setRefundApplicationFee(e.target.checked)
                }}
            />
            <Comments
                visible={CommentsVisible}
                title={disputeId}
                loading={isLoading}
                merchantName={indv.fname}
                commentslist={commentslist}
                value={comments}
                onChange={(e) => setComments(e.target.value)}
                onCancel={() => {
                    setCommentsVisible(false);
                    setCommenstList([])
                  }}
                onClick={() => {
                    handleOnClick();
                }}
            />
        </div>
    )
}

