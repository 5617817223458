/* eslint-disable no-redeclare */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import ReviewStars from "../../components/ReviewStars/ReviewStars";
import AllReviewPopUp from "../../components/AllReviewPopUP/AllReviewPopUp";
import ReviewComments from "../../components/ReviewComments/ReviewComments";
import UserAction from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import uploadImage from "../../assets/images/uploadImage.svg";
import backbtn from "../../assets/images/backbtn.svg";
import cancelImg from "../../assets/images/cancel-img.svg";
import Placeholder from "../../assets/images/placeholder-image.jpg";
import GoogleMapNew from "../../components/GoogleMap";
import { Marker } from "react-google-maps";
import actions from "../../actions";
import Logo from "../../components/Logo";
import success from "../../assets/images/success.svg"
import "../../sass/styles/fyndr/_viewstoremodal.scss";
import {
  Typography,
  Row,
  Col,
  Radio,
  Divider,
  Checkbox,
  Button,
  InputNumber,
  Badge,
  message,
  Select,
  Input,
  Table,
  Tag,
  Modal,
  Space,
  Popconfirm,
  Image,
  Card,
} from "antd";
import MobileRow from "./MobileRow";
import { FiChevronLeft, FiShoppingCart } from "react-icons/fi";
import Images from "../../assets/images/Images";
import { ConfirmationModal, RichTextViewer, TopMenu } from "../../components";
import SkeletonLoader from "./SkeletonLoader";
import CardPay from "../Payment/CardPay";
import Sliders from "../../assets/images/sliders.svg";
import { Actions, Theme } from "../../common";
import Phone from "../../assets/images/Phone.svg";
import Location from "../../assets/images/Location.svg";
import Clock from "../../assets/images/Clock.svg";
import Share from "../../assets/images/Share.svg";
import copyShare from "../../assets/images/copyShare.svg";
import Store from "../../assets/images/Store.svg";
import Van from "../../assets/images/Van.svg";
import Website from "../../assets/images/Website.svg";
import Heart from "../../assets/images/Heart.svg";
import viewless from "../../assets/images/viewless.svg";
import viewmore from "../../assets/images/viewmore.svg";
import cartImg from "../../assets/images/cart.svg";
import minus from "../../assets/images/minus.svg";
import plus from "../../assets/images/plus.svg";
import store_blue from "../../assets/images/store_blue-icon.svg";
import dropdown_icon from "../../assets/images/dropdown_icon.svg";
import addIcon from "../../assets/images/addIcon.svg";
import { AiFillHeart } from "react-icons/ai";
import minusIcon from "../../assets/images/minusIcon.svg";
import edit_btn from "../../assets/images/edit_btn.svg"
import {
  getClosingTime,
  getToday,
  getTomorrow,
  isTab,
  isMobile,
  parseAddress,
  getTiming12,
} from "../../common/util";
import UserActions from "../../actions";
import { DeliveryTypes, WholeUnits } from "../../constants/lists";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import ModalVerifyMobileUI from "../../components/ModalVerifyPhone/ModalVerifyMobileUI";
import closeBtn from "../../assets/images/closeBtn.svg";
import CustomizedModal from "../../components/CustomizedModal/CustomizedModal";
import OverAllReview from "../../components/OverAllReview/OverAllReview";
import moment from "moment";
import UserCommentText from "../../components/ReviewComments/UserCommentText";
import getEnvVars from "../../environment";
import SlotBooking from "../../components/SlotBooking/SlotBooking"
import { object } from "yup";
import PurchasePopup from "../Offer/PurchasePopup";
const { Meta } = Card;
const { CART_UPDATED, CART_DELETED, CART_INVOICED, CHECKOUT_STARTED } = Actions;
const ITEM = "ITEM";
const { web } = getEnvVars();

function AddToCart({ addToCart, style, biz }) {
  return (
    <Button
      icon={<img src={cartImg} alt="cart" style={{ marginRight: "8px" }} />}
      type="primary"
      size="large"
      style={{
        borderRadius: "5px",
        ...style,
      }}
      disabled={biz !== undefined && !biz.merchantId}
      onClick={() => addToCart()}
    >
      Add to Cart
    </Button>
  );
}



const CataloguePageUrl = (props) => {
    const dispatch = useDispatch();
  const history = useHistory();
  const searchRef = useRef(null);
  const specialRef = useRef(null);
  const currentRoute = useLocation().pathname;
  const { SET_ROUTE, ROLE_MANAGER, ROLE_SUPPORT, CMPN_LIKED } = Actions;
  const closeIcon = <img src={closeBtn} alt="closeBtn" />;
  const { Option } = Select;
  const topContainer = useRef();
  // const {
  //   location: {
  //     state: { catalogueDetail = null, isPreview, loc, fromBizAccount },
  //   },
  // } = props;

  const { location = {} } = props;
  const { state = {} } = location;

  const {
    catalogueDetail = null,
    isPreview = false,
    loc = null,
    fromBizAccount = false,
    locationId = null
  } = state;
  const {
    roleType,
    indv,
    indv: { biz, bizid, locations, bizName, currency, qrid, indvid },
  } = useSelector(({ auth }) => auth);
  const { isMasquerade } = useSelector(({ admin }) => admin);
  const {
    catalogue: { objid, name, currencySymbol },
    cart,
    buyerQrId,
    cartModified,
    catalogueLocation,
    invoiceBeingEdited,
    catalogueLocation: { parentLocation },
  } = useSelector(({ catalogue }) => catalogue);
  // const { bizLikes } = useSelector(({ cmpn }) => cmpn);
  const [showOffer, setShowOffer] = useState({ value: false, count: 0 });
  const [catalogueDetails, setCatalogueDetails] = useState(catalogueDetail);
  const [bizID, setBizID] = useState(
    isPreview ? bizid : catalogueDetail?.biz?.bizid
  );
  const [bizDetails, setBizDetails] = useState(catalogueDetail?.biz);
  const [catalogueinfo, setCatalogueInfo] = useState(
    catalogueDetail?.catalogue
  );

  const [appointmentData, setAppointmentData] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemWorkingHours, setSelectedItemWorkingHours] =
    useState(null);
  const [catalogueCategories, setCatalogueCategories] = useState(null);
  const [step, setStep] = useState(1);
  const [instr, setInstr] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [catalogueItemsList, setCatalogueItemsList] = useState([]);
  const [wholeModifiers, setWholeModifier] = useState([]);
  const [addOnModifiers, setAddOnModifier] = useState([]);
  const [Modifiers, setModifier] = useState(false);
  const [selectedWholeModifier, setSelectedWholeModifier] = useState(
    selectedItem?.objid
  );
  const [slider, setSlider] = useState(1)
  const [selectedCatalogues, setSelectedCatalogues] = useState({});
  const [value, setValue] = useState();
  const [replyComment, setReplyComment] = useState("");
  const [replyData, setReplyData] = useState({});
  const [reportComment, setReportComment] = useState("");
  const [reportModalVisble, setReportModalVisible] = useState(false);
  const [reportedCommentVisible, setReportedCommentVisible] = useState(false);
  const [reportData, setReportData] = useState();
  const [seeAllComments, setSeeAllComments] = useState(false);
  const [title, setTitle] = useState("");
  const [paraVisible, setParaVisible] = useState(false);
  const [selectedPlatformUser, setSelectedPlatformUser] = useState({});
  const [replyModalVisible, setReplyModalVisible] = useState(false);
  const [businessId, setBusinessId] = useState("");
  const [reviewsOverview, setReviewsOverview] = useState(null);
  const [bizIdUser, setBizId] = useState(null);
  const [itemName, setItemName] = useState("")
  const [addClicked, setAddClicked] = useState(false);
  const [indexSelected, setIndexSelected] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });
  const [commentForReview, setCommentForReview] = useState("");
  const [appointVisible, setAppointVisible] = useState(false);
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };
  const [userComment, setUserComment] = useState(false);
  const [selectedImages, setSelectedImages] = useState(Array(6).fill(null));
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState(null);
  const [sortByData, setSortByData] = useState({
    orderBy: "RATING",
    orderType: "DESC",
  });
  const [date, setDate] = useState([]);
  const [dataLength, setDataLength] = useState()
  const [selectedAddonModifiers, setSelectedAddonModifier] = useState([]);
  const [totalAddonPrice, setTotalAddonPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [modalVisible, setModalVisible] = useState(false);
  const [itemId, setItemId] = useState("");
  const [itemSelectedData, setSelectedItemData] = useState({});
  const [fetchLocation, setFetchLocation] = useState(false);
  const [locationsData, setLocationsData] = useState([]);
  const [locId, setLocId] = useState(locationId);
  const [expandedComments, setExpandedComments] = useState([]);
  const [expandedReplies, setExpandedReplies] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCopied, setIsCopied] = useState(false);
  const [itemkey, setItemKey] = useState()
  const [unit, setUnit] = useState()
  const [isBookingEnabled, setIsBookingEnabled] = useState(history?.location?.state?.catalogueDetail?.isBookingEnabled);
  const [qty, setQty] = useState(0);
  const [later, setlater] = useState(false);
  const [createdInvoice, setCreatedInvoice] = useState(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [payCard, setpayCard] = useState(null);
  // const [liked, setLiked] = useState(props.history.location.state?.liked);
  // console.log(
  //   "props.history.location.state?.liked",
  //   props.history.location.state?.liked
  // );
  const [currentLocation, setCurrentLocation] = useState(
    // catalogueDetails?.locations
    //   ? locations?.filter(
    //       (row) => row.objid === catalogueDetails?.locations[0]
    //     )[0]
    //   : catalogueDetails?.locId
    catalogueDetails?.locName
  );
  const { cmpnsLiked, bizLikes } = useSelector(({ cmpn }) => cmpn);
  const liked = cmpnsLiked && cmpnsLiked[objid && objid];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(()=>{
    localStorage.removeItem("googleAccessToken")
  
  },[])
  useEffect(() => {
    if (bizID) {
      getReviewList(bizID);
      getReviewOverViews(bizID);
    }
    console.log("steps>>", step, isPreview);
  }, [
    bizID,
    sortByData,
    pagination.current,
    pagination.total,
    pagination.pageSize,
  ]);

  useEffect(() => {
    setSelectedItem(selectedItem)
  }, [selectedItem, cart])

  let reviwdata = [
    {
      star: 5,
      count: 2000,
    },
    {
      star: 4,
      count: 1500,
    },
    {
      star: 3,
      count: 100,
    },
    {
      star: 2,
      count: 50,
    },
    {
      star: 1,
      count: 20,
    },
  ];

  const doLike = async (cmpnId, liked) => {
    let payload = {
      cmpnId,
      indvId: indvid,
      bizId: bizID,
    };
    if (liked) {
      payload.objid = liked.likedId;
      payload.isDeleted = !liked.isDeleted;
    }
    const resp = await UserActions.likeCmpn(payload);

    if (resp.success) {
      dispatch({ type: CMPN_LIKED, payload: resp.payload });
    } else {
      message.error("Please login first");
    }
  };

  useEffect(() => {
    topContainer.current.scrollIntoView({ block: "start", behavior: "smooth" });

  }, []);
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  const copyTextToClipboard = () => {
    const urlWithReferralCode = `${web}/catalogue/store/${props.match.params.url}`;
    navigator.clipboard
      .writeText(urlWithReferralCode)
      .then(() => {
        message.success("Copied");
      })
      .catch((error) => {
        console.error("Error copying text:", error);
      });
    setIsCopied(true);
  };

  // const {
  //   indv,
  //   indv: { indvid },
  // } = useSelector(({ auth }) => auth);

  const columns = [
    {
      title: "Datatype",

      render: (data, key) => {
        return (
          <Col span={24}>
            <ReviewComments
              reportModalVisibleClick={() => {
                if (data?.isReportingAllowed === true) {
                  setReplyData(data);
                  setReportComment("");
                  setReportModalVisible(true);
                } else {
                  setReportModalVisible(false);
                  setReportData(data?.reportedComments);
                  setReportedCommentVisible(true);
                }
              }}
              openParagraph={(data) => {
                setSeeAllComments(false);
                setTitle("Comment");
                setParaVisible(true);
                setSelectedPlatformUser(data);
              }}
              indv={bizid}
              businessId={businessId}
              setModalVisible={() => {
                setReplyData(data);
                setReplyModalVisible(true);
                
              }}
              data={data}
            />
          </Col>
        );
      },
    },
  ];

  const toggleExpandComment = (index) => {
    setExpandedComments((prevExpandedComments) => {
      const newExpandedComments = [...prevExpandedComments];
      newExpandedComments[index] = !newExpandedComments[index];
      return newExpandedComments;
    });
  };

  const toggleExpandReply = (index) => {
    setExpandedReplies((prevExpandedReplies) => {
      const newExpandedReplies = [...prevExpandedReplies];
      newExpandedReplies[index] = !newExpandedReplies[index];
      return newExpandedReplies;
    });
  };
  const LongText = (text, index, isReply) => {
    const MAX_LENGTH = 400;
    const isLongText = text?.length > MAX_LENGTH;
    const displayText = isLongText
      ? `${text.substring(0, MAX_LENGTH)}...`
      : text;

    const isExpanded = isReply
      ? expandedReplies[index]
      : expandedComments[index];

    const toggleExpand = isReply ? toggleExpandReply : toggleExpandComment;

    return (
      <>
        <RichTextViewer content={isExpanded ? text : displayText} />
        {isLongText && (
          <Text
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={() => toggleExpand(index)}
          >
            {isExpanded ? " Read Less" : "Read More"}
          </Text>
        )}
      </>
    );
  };
  const reportToTheComment = async (commentId) => {
    let response = await UserActions.addReport({
      commentId: commentId,
      businessId: businessId,
      reportMessage: reportComment,
    });
    if (response.success) {
      message.success(response?.msg);
      getReviewList(bizID);
      setReportComment("");
      setReportModalVisible(false);
    }
  };
  const replyToTheComment = async (commentId) => {
    setIsLoading(true);
    let res = await UserActions.addReply({
      commentId: commentId,
      businessId: businessId,
      reply: replyComment,
    });
    if (res.success) {
      setIsLoading(false);
      message.success(res.msg);
      getReviewList(bizID);
      setReplyComment("");
      setReplyModalVisible(false);
    }
  };
  const onReportHandler = (value) => {
    setReportComment(value.target.value);
  };
  const onReportSubmit = () => {
    if (reportComment === "") {
      message.error("Please add text to report the comment");
    } else {
      reportToTheComment(replyData?.commentId);
    }
  };
  const onHandleSubmit = () => {
    if (replyComment === "") {
      message.error("Please enter your reply");
    } else {
      replyToTheComment(replyData?.commentId);
    }
  };
  const onChangeHandler = (value) => {
    setReplyComment(value.target.value);
  };

  const getReviewOverViews = async (cmpn) => {
    let res = await UserActions.fetchReviewsOverview(cmpn);
    setReviewsOverview(res.cmpn);
    setBusinessId(res.cmpn?.bizId);
  };

  const getReviewList = async (cmpn) => {
    let pgStart = pagination.current;
    let pgSize = pagination.pageSize;
    let res = await UserActions.fetchReviewsratings(
      cmpn,
      sortByData,
      pgStart,
      pgSize
    );
    setPagination({
      ...pagination,
      total: res?.cmpn?.count,
    });
    setComments(res.cmpn);
  };

  //step 4
  const [cartWithTax, setCartWithTax] = useState(cart);
  const [grandTotal, setGrandTotal] = useState(0);
  const [taxRate, setTaxRate] = useState(null);
  const [totalTax, setTotalTax] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState({
    value: false,
    id: null,
  });
  const [tip, setTip] = useState(0);
  const [viewWorkingHourModal, setViewWorkingHourModal] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [tipError, setTipError] = useState(null);
  const [searchTxt, setSearchTxt] = useState(null);
  const [items, setItems] = useState([]);
  const [checkoutCartView, setCheckoutCartView] = useState(true);
  const [delType, setDelType] = useState("instore");
  const [delTypesAllowed, setDelTypesAllowed] = useState([
    { key: "instore", val: "In Store" },
  ]);
  const [custMessage, setCustMessage] = useState(null);
  const [delTime, setDelTime] = useState("ASAP");
  const [slots1, setSlots1] = useState([
    { value: "ASAP", label: "As soon as possible" },
  ]);
  const [slots2, setSlots2] = useState([]);
  const [delDay, setDelDay] = useState(1);
  const [showMapModal, setShowMapModal] = useState(false);
  const [closureInfo, setClosureInfo] = useState(
    parentLocation &&
      parentLocation.workingHours !== null &&
      parentLocation.workingHours.length > 5
      ? getClosingTime(parentLocation.workingHours)
      : null
  );
  const { Text } = Typography;
  const days = [1, 2];

  const getBizDetails = async () => {
    await UserActions.fetchBizinfo({
      bizId: [bizID],
    });
  };

  function addObject(newObject) {
    const index = appointmentData.findIndex(obj => obj.objid === newObject.objid);
    console.log("on later addobj ", newObject, appointmentData, index);

    if (index !== -1) {
      if (newObject.qty !== 0) {
        appointmentData[index] = newObject;
      } else {
        appointmentData.splice(index, 1)
        setIsLoading(true);
      }
      if (newObject.qty === 0) {
        delete newObject.appointment
      }
      if (appointmentData[index]?.appointment?.length > newObject?.qty && appointmentData[index]?.objid === newObject?.objid) {
        appointmentData[index].appointment.pop()
        setIsLoading(true);
      }

    } else {
      appointmentData.push(newObject);
    }
    setAppointmentData(appointmentData)
    setIsLoading(false);
    console.log("on later appointmentData ", appointmentData);
  }
  const buildSlots = (str) => {
    const today = new Date();
    const day1 = today.getDay();
    const day2 = new Date(today.getTime() + 86400 * 1000).getDay();

    try {
      const arr = str.split(";");
      const days_index = {
        Mo: 1,
        Tu: 2,
        We: 3,
        Th: 4,
        Fr: 5,
        Sa: 6,
        Su: 0,
      };
      let s1 = [{ value: "ASAP", label: "As soon as possible" }];
      let s2 = [{ value: "ASAP", label: "As soon as possible" }];

      arr.forEach((part) => {
        const pair = part.trim().split(" ");
        const day = days_index[pair[0]];

        pair[1].split(",").forEach((row) => {
          const period = row.split("-");
          //start time
          let tmp = period[0].split(":");
          const start_hr = Number(tmp[0]);
          const start_min = Number(tmp[1]);

          //end time
          tmp = period[1].split(":");
          const end_hr = Number(tmp[0]);
          const end_min = Number(tmp[1]);
          let hr1, min1;
          if (day == day1) {
            for (
              var i = Math.max(
                start_hr,
                today.getMinutes() < 30
                  ? today.getHours()
                  : today.getHours() + 1
              );
              i <= Math.min(end_hr, 23);
              i++
            ) {
              if (start_hr < end_hr) {
                if (
                  (i > start_hr || (i === start_hr && start_min === 0)) &&
                  (i > today.getHours() + 1 ||
                    (i === today.getHours() + 1 && today.getMinutes() < 30))
                ) {
                  hr1 = i;
                  min1 = "00";
                  s1 = addToSlot(s1, hr1, min1);
                }
                if (
                  (i < end_hr || (i === end_hr && end_min === 30)) &&
                  (i !== today.getHours() ||
                    (i == today.getHours() && today.getMinutes() === 0))
                ) {
                  hr1 = i;
                  min1 = "30";
                  s1 = addToSlot(s1, hr1, min1);
                }
              }
            }
          } else if (day == day2) {
            for (var i = start_hr; i <= Math.min(end_hr, 23); i++) {
              if (start_hr < end_hr) {
                if (i > start_hr || (i === start_hr && start_min === 0)) {
                  hr1 = i;
                  min1 = "00";
                  s2 = addToSlot(s2, hr1, min1);
                }
                if (i < end_hr || (i === end_hr && end_min === 30)) {
                  hr1 = i;
                  min1 = "30";
                  s2 = addToSlot(s2, hr1, min1);
                }
              }
            }
          }
        });
      });
      setSlots1(s1);
      setSlots2(s2);
    } catch (err) {
      console.log(err);
    }
  };
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const addToSlot = (slot, hr, min) => {
    let suffix = "am";
    if (hr == 12) suffix = min == 0 ? "noon" : "pm";
    if (hr > 12) {
      hr -= 12;
      suffix = "pm";
    }
    const str = `${(hr + "").padStart(2, "0")}:${min} ${suffix}`;
    slot.push({ value: str, label: str });
    return slot;
  };
  const catalogueCategoriesDetails = async (id) => {
    setIsLoading(true);
    let categoriesInTheCatalogue = await UserAction.fetchCatalogueCategories({
      bizid: bizID,
      catalogueId: catalogueDetails?.objid
        ? catalogueDetails?.objid
        : catalogueDetails?.catalogue?.objid,
    });
    if (categoriesInTheCatalogue?.success) {
      let payload = categoriesInTheCatalogue?.payload;
      payload?.sort((a, b) => a.name.localeCompare(b.name));

      setCatalogueCategories(payload);
    } else {
      message.error(categoriesInTheCatalogue?.message);
    }
    setIsLoading(false);
  };
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const { TextArea } = Input;

  const handleButtonClick = (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (event) => handleFileChange(event, index);
    fileInput.click();
  };
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024;
    if (!/^image\//.test(file.type)) {
      message.error(`Please upload image type files only.`);
    } else if (file.type === "image/svg+xml") {
      message.error("SVG files are not allowed.");
    } else if (file.size <= maxFileSize) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const URL = reader.result.replace(/^data:image\/[a-z]+;base64,/, "");
        const imageType = file.type.replace(/^image\//, "");
        const updatedSelectedImages = selectedImages.map((image, i) => {
          if (i === index) {
            if (imageType === "svg+xml") {
              return {
                url: { Placeholder },
                imageType: "jpg",
                image: "",
              };
            } else {
              return {
                url: reader.result,
                imageType: imageType,
                image: URL,
              };
            }
          }

          return image;
        });

        setSelectedImages(updatedSelectedImages);
      };
    } else {
      message.error("The image file size should be less than or equal to 5MB.");
    }
  };
  const areAppointmentsAvailable = Object.values(cartWithTax) !== undefined && Object.values(cartWithTax).some(item => item?.mitem?.appointment && item?.mitem?.appointment?.length > 0);
  const handleOnSubmit = () => {
    if (rating === 0) {
      message.error("Minimum rating should be 1");
    } else if (commentForReview === "") {
      message.error("Please add the comment to proceed");
    } else {
      AddReviewComments();
    }
  };
  const AddReviewComments = async () => {
    setIsLoading(true);
    let newImgs = selectedImages
      .filter(
        (item) =>
          item !== undefined && item !== null && Object.keys(item).length !== 0
      )
      .map((item) => {
        return {
          imageType: item?.imageType,
          image: item?.image,
        };
      });
    let response = await actions.addReview({
      businessId: businessId,
      userId: indvid,
      text: commentForReview,
      rating: rating,
      images: newImgs,
    });
    if (response?.success === true) {
      setIsLoading(false);
      message.success(response?.msg);
      setUserComment(false);
      setRating(0);
      getReviewList(businessId);
      getReviewOverViews(businessId);
    } else {
      setIsLoading(false);
      message.error(response?.msg);
      setUserComment(false);
    }
  };

  const handleCancelClick = (index) => {
    let selectedImagesArr = [...selectedImages];
    selectedImagesArr[index] = null;
    setSelectedImages(selectedImagesArr);
  };

  const getDetailsOfCatalogue = async (ID = null) => {
    if (
      (catalogueDetails?.objid && ID !== null) ||
      (catalogueDetails?.objid && selectedCategory?.objid) ||
      (catalogueDetails?.catalogue?.objid && selectedCategory?.objid)
    ) {
      setIsLoading(true);

      let response = await UserAction.fetchCatalogueDetails({
        bizid: bizID,
        catalogueId:
          catalogueDetails?.catalogue?.objid || catalogueDetails?.objid,
        categoryId: ID || selectedCategory?.objid,
      });

      if (response.success) {
        catalogueCategoriesDetails();

        setCatalogueDetails(response.payload);

        let listItems = response.payload.catalogueItems;
        listItems.map((item, key) => (item.id = item.objid));
        listItems.sort((a, b) => a.slno - b.slno);
        setCatalogueItemsList(listItems);
      } else {
        message.error(response.message);
      }
      setIsLoading(false);
    }
  };
  const handleModifiers = async () => {
    let wholeArray = selectedItem?.catalogueModifiers.filter(
      (row, key) => row.modifier.modType === "whole"
    );
    let addOn = selectedItem?.catalogueModifiers.filter(
      (row, key) => row.modifier.modType !== "whole"
    );
    setWholeModifier(wholeArray);
    setAddOnModifier(addOn);
  };

  const handleAddOnModifier = (event) => {
    let temp = 0;
    event.map((row) => {
      temp = +row.price;
    });

    setTotalAddonPrice(temp);
    setSelectedAddonModifier(event);
  };
  const calculateTotal = async () => {
    if (selectedItem) {
      let tot = 0;
      tot = Number(selectedItem?.price);
      if (selectedItem?.objid !== selectedWholeModifier?.objid) {
        tot += selectedWholeModifier?.price;
      }
      selectedAddonModifiers.forEach((item) => {
        tot += Number(item.price);
      });
      setUnitPrice(tot);

      tot *= Number(quantity);
      setTotal(tot);
    }
  };
  const takeToLogin = () => {
    history.push("/login", {
      locId: catalogueDetail?.locId,
      route: currentRoute,
      catalogueDetail: catalogueDetail,
    });
  };
  function monthToNumber(month) {
    const months = {
      Jan: '01',
      Feb: '02',
      Mar: '03',
      Apr: '04',
      May: '05',
      Jun: '06',
      Jul: '07',
      Aug: '08',
      Sep: '09',
      Oct: '10',
      Nov: '11',
      Dec: '12'
    };
    return months[month];
  }

  function getFullDayName(abbreviatedDay) {
    abbreviatedDay = abbreviatedDay?.toUpperCase();
    const dayMap = {
      'SUN': 'Sunday',
      'MON': 'Monday',
      'TUE': 'Tuesday',
      'WED': 'Wednesday',
      'THU': 'Thursday',
      'FRI': 'Friday',
      'SAT': 'Saturday'
    };
    if (dayMap.hasOwnProperty(abbreviatedDay)) {
      return dayMap[abbreviatedDay];
    } else {
      return 'Invalid day abbreviation';
    }
  }
  const output = (data) => {
    let dataArray = Object.values(data);
    let lData = dataArray?.filter((item) => item !== null)

    let flatArray = data.length > 1 ? lData.flat() : dataArray
    let results = flatArray.map((item) => {
      const dateKey = Object.keys(item)[0];
      const appointmentInfo = item[dateKey];
      const [month, day, dayOfWeek] = dateKey.split(" ");
      const paddedDay = day.padStart(2, '0');

      console.log("month>>>",month);

      return {
        [`${new Date().getFullYear()}-${monthToNumber(month)}-${paddedDay}`]: {
          bookingDay: getFullDayName(dayOfWeek?.slice(1, -1)).toUpperCase(),
          startTime: appointmentInfo.startTime,
          endTime: appointmentInfo.endTime,
          locId: appointmentInfo.locId,
          objId: appointmentInfo.objId
        }
      };
    });
    return results;
  }
  const addToCart = (data) => {
    let key =
      selectedItem?.objid !== selectedWholeModifier.objid
        ? `${selectedItem?.objid}-${selectedWholeModifier.objid}`
        : `${selectedItem?.objid}`;
    if (selectedAddonModifiers.length > 0) {
      let addonKey = selectedAddonModifiers.map((item) => item.objid).join("-");
      key += "-" + addonKey;
    }
    let newQty = quantity;
    let newTotal = total;
    let newData = data
    console.log("on later quantity", quantity, data, cart, appointmentData)
    if (cart.hasOwnProperty(key)) {
      newQty += Number(cart[key]["qty"]);
      newTotal += Number(cart[key]["total"]);
      const cartItem = cart[key];
      newData = isBookingEnabled && cartItem?.mitem?.appointment?.concat(data)
      // newData = cartItem.push(data)
    }
    console.log("some condition", (areAppointmentsAvailable || data) && "appointment")
    let addonModifiers = selectedAddonModifiers.map((item) => item.objid)
    console.log("on later cart", appointmentData.some((item) => item?.appointment && item?.appointment?.length > 0), areAppointmentsAvailable, cartWithTax)
    const info = {
      key,
      objid: selectedItem?.objid,
      price: selectedItem?.price,
      unitPrice,
      qty: newQty,
      total: newTotal,
      unit: selectedItem?.item.unit,
      whole:
        selectedWholeModifier &&
          selectedWholeModifier.objid !== selectedItem?.objid
          ? selectedWholeModifier.objid
          : null,
      addon: addonModifiers,
      mitem: {
        objid: selectedItem?.item?.objid,
        name: selectedItem?.item?.name,
        stdTax: selectedItem?.item?.stdTax,
        taxPercent: selectedItem?.item?.taxPercent,
        img_url: Array.isArray(selectedItem?.item?.images)
          ? selectedItem?.item?.images[0]?.img_url
          : "",
        // appointment: isBookingEnabled && newData,
        appointment: (isBookingEnabled && newData !== undefined) ? newData : [],
      },
      currencySymbol: currencySymbol || "$",
      currency,
      instruction: instr,
    };
    if (
      selectedWholeModifier &&
      selectedItem &&
      selectedWholeModifier.objid !== selectedItem?.objid
    ) {
      let {
        objid,
        price,
        modifier: { modName },
      } = selectedWholeModifier;
      info["wholeDetails"] = { objid: objid, modName, price };
    } else {
      info["wholeDetails"] = {};
    }
    if (selectedAddonModifiers.length > 0) {
      info["addonDetails"] = selectedAddonModifiers.map((row) => ({
        objid: row.objid,
        price: row.price,
        modName: row.modifier.modName,
      }));
    } else {
      info["addonDetails"] = [];
    }
    dispatch({ type: CART_UPDATED, payload: { [key]: info } });
    setSelectedAddonModifier([]);
    message.success("Item added to cart");
  };

  const calcTax = async () => {
    let stdRate = taxRate;
    if (
      taxRate === null &&
      Object.values(cart).filter((row) => row.mitem.stdTax == true).length > 0
    ) {
      stdRate = await getTaxRate();
    }

    let newCart = {};
    let totTax = 0;
    for (const [key, value] of Object.entries(cart)) {
      const {
        qty,
        unitPrice,
        mitem: { stdTax, taxPercent },
      } = value;
      const rate = stdTax ? stdRate : taxPercent / 100;
      const unitTax = rate * unitPrice;
      newCart[key] = value;
      newCart[key]["taxRate"] = rate;
      newCart[key]["tax"] = unitTax.toFixed(2);
      totTax += qty * unitTax;

    }

    setCartWithTax(newCart);
    setTotalTax(totTax);
  };

  const getTaxRate = async () => {
    if (catalogueLocation?.parentLocation) {
      const {
        parentLocation: { postalCode, country },
      } = catalogueLocation;

      const { success, data } = await UserActions.getTax({
        postalCode,
        country,
      });

      if (success) {
        setTaxRate(data?.taxRate);
        return data?.taxRate;
      } else {
        message.warning("Taxes could not be obtained,Please try again");
        // props.history.replace(`/qr/${props.location?.state?.loc}`);
      }
    } else {
      // message.warning("Taxes could not be obtained,Please try again");
      // props.history.replace(`/qr/${props.location?.state?.loc}`);
    }
  };
  React.useEffect(() => {
    catalogueDetails && bizID && catalogueCategoriesDetails();
  }, [catalogueDetails, bizID]);
  React.useEffect(() => {
    selectedCategory && getDetailsOfCatalogue();
  }, [selectedCategory]);
  React.useEffect(() => {
    handleModifiers();
    if (selectedItem) {
      // setTotal();
      setSelectedWholeModifier(selectedItem);
    }
  }, [selectedItem]);
  React.useEffect(() => {
    bizID && getBizDetails();
    getLocationOffer();
  }, [bizLikes[bizID]]);
  React.useEffect(() => {
    calculateTotal();
  }, [
    quantity,
    selectedWholeModifier,
    selectedAddonModifiers,
    totalAddonPrice,
  ]);
  useEffect(() => {
    locId !== null && onCatalogue(locId);
  }, [])

  React.useEffect(() => {
    (catalogueDetails == null || catalogueDetails == undefined) &&
      (bizID == null || bizID == undefined) &&
      fetchStorelocations(props.match.params.url);
    setLocId(catalogueDetail?.locId)
    // fetchStorelocations(props.match.params.url)
  }, []);

  const fetchStorelocations = async (url) => {
    let resp = await UserActions.fetchStoreLoc(url);
    if (resp.success) {
      setBizDetails(resp?.data?.biz);
      setLocationsData(resp?.data?.locations);
      if (resp?.data?.locations?.length === 0) {
        message.error("No store found");
        props.history.push("/");
      }
      console.log("id setLocId", resp?.data?.locations[0]?.objid);

      // setLocId(resp?.data?.locations[0]?.objid)
      if (resp.data.locations.length === 1) {
        onCatalogue(resp?.data?.locations[0]?.objid);
        setLocId(resp?.data?.locations[0]?.objid)
      } else {
        setFetchLocation(true);
      }
    }
  };

  const onCatalogue = async (id = null) => {
    if (value !== null) {
      const resp = await UserActions.getCatalogueByLoc({
        locId: id || value,
      });
      if (!resp.success) {
        message.error("Error retrieving catalogue");
      } else {
        //  onCart(details.locId);

        //  props.history.push(`/catalogue/store/${selectedCatalogues.url}`, {
        //    catalogueDetail: resp.payload,
        //    noPopup:true
        //    // fromBizAccount: true,
        //  });
        setCatalogueDetails(resp?.payload);
        setCatalogueInfo(resp?.payload?.catalogue);
        setBizDetails(resp?.payload?.biz);
        setBizID(resp?.payload?.biz?.bizid);
        setFetchLocation(false);
        setIsBookingEnabled(resp?.payload?.isBookingEnabled)
      }
    }
  };

  React.useEffect(() => {
    if (step === 4) {
      let gtot = 0;
      calcTax();
      Object.values(cart).forEach((row) => (gtot += parseFloat(row.total)));
      setGrandTotal(gtot);
      if (invoiceBeingEdited !== undefined) {
        try {
          setDelType(invoiceBeingEdited?.invoiceDetails?.deliveryType);
          // setDelTime(invoiceBeingEdited.invoiceDetails.deliveryTime);
        } catch (err) { }
      }
    }
    const opHrs = parentLocation?.workingHours
      ? parentLocation?.workingHours
      : "Mo 00:00-23:00; Tu 00:00-23:00; We 00:00-23:00; Th 00:00-23:00; Fr 00:00-23:00; Sa 00:00-23:00; Su 00:00-23:00";

    buildSlots(opHrs);

    if (parentLocation !== undefined && parentLocation?.deliveryOptions) {
      setDelTypesAllowed(
        DeliveryTypes.filter((row) =>
          parentLocation?.deliveryOptions?.split(",").includes(row.key)
        )
      );
    }
    setSelectedAddonModifier([]);
  }, [cartModified, step]);

  const handleBack = (id) => {
    if (selectedCategory) {
      if (step === 4) {
        // dispatch({ type: CART_DELETED, payload: id });
        setStep(2);
        setTotal(0);
      } else {
        setStep(step - 1);
        setTotal(0);
      }
    } else {
      dispatch({ type: CART_DELETED, payload: id });
      setStep(1);
    }
    setlater(false);
    setUnitPrice(0);
    setQuantity(1);
  };
  const handlePreviewBack = () => {
    history.goBack();
  };
  const getItemAsModifier = () => {
    return {
      objid: selectedItem?.objid,
      catitemId: null,
      modifier: {
        objid: selectedItem?.item?.objid,
        images: selectedItem?.item?.images,
        modName: selectedItem?.item?.name,
        modType: "whole",
        createDt: selectedItem?.item?.createDt,
        description: selectedItem?.item?.description,
        updateDt: selectedItem?.item?.updateDt,
      },
      price: 0,
      unit: selectedItem?.item?.unit,
    };
  };
  const OptionRow = ({ row, unit }) => {
    const dispPrice =
      wholeModifiers?.length > 0 || row?.modifier?.modType !== "whole"
        ? `+ ${currencySymbol || "$"}${row?.price}`
        : `${currencySymbol || "$"}${selectedItem?.price}`;

    return (
      <Row gutter={[10, 0]} style={{ margin: "1.5rem 0" }}>
        <Col style={{ minHeight: "10vh" }} align="center" span={7}>
          <img
            // className="catalogue_detail_page_inner_image"
            className="catalogue_row"
            alt="modifier"
            src={
              row?.modifier?.images
                ? row?.modifier?.images[0]?.img_url
                : Images.PlaceHolder
            }
          />
        </Col>
        <Col align="left" span={10}>
          <h5>{row.modifier.modName} </h5>
          <h3 className="catalogue_price_title">{dispPrice} </h3>
          {unit && <Text>Unit:{row.unit}</Text>}
        </Col>
        <Col span={7}>
          {row.modifier.modType === "whole" ? (
            <Radio
              style={{
                borderRadius: "1rem",
                background: "#f8f8f8",
                padding: "1rem",
                fontWeight: "bold",
              }}
              value={row}
              checked={
                selectedWholeModifier?.objid === row.objid ? true : false
              }
              onClick={(e) => {
                setSelectedWholeModifier(row);
                setCheckoutCartView(true);
              }}
            >
              {" "}
              Select
            </Radio>
          ) : (
            <Checkbox
              style={{
                borderRadius: "1rem",
                background: "#f8f8f8",
                padding: "1rem",
                fontWeight: "bold",
              }}
              value={row}
            >
              {" "}
              Select
            </Checkbox>
          )}
        </Col>
      </Row>
    );
  };
  const OptionRowModal = ({ row, unit }) => {
    const dispPrice =
      wholeModifiers?.length > 0 || row.modifier.modType !== "whole"
        ? `${currencySymbol || "$"}${row.price}`
        : `${currencySymbol || "$"}${selectedItem?.price}`;

    return (
      <Row>
        <Col className="viewStoreModal_items-list" span={24}>
          <Col>
            <Image
              preview={false}
              className="viewStoreModal_circle-img"
              style={{
                height: "3.75rem",
                width: "3.75rem",
                borderRadius: "50%",
                objectfit: "cover",
                margin: "16px 0px 16px 20px",
              }}
              alt="modifier"
              src={
                row?.modifier?.images
                  ? row?.modifier?.images[0]?.img_url
                  : Images.PlaceHolder
              }
            />
          </Col>
          <Col
            span={14}
            xxl={12}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              className="viewStoreModal_pricelist"
              style={{ fontWeight: "400", paddingBottom: "4px" }}
            >
              {row?.modifier?.modName}{" "}
            </Text>
            <Text
              className="viewStoreModal_pricelist"
              style={{ fontWeight: "700" }}
            >
              {dispPrice}{" "}
            </Text>
          </Col>

          <Col span={4}>
            {row?.modifier?.modType === "whole" ? (
              <Col className="viewStoreModal_item-price-check">
                <Radio
                  style={{
                    padding: "1rem",
                    fontWeight: "bold",
                  }}
                  value={row}
                  checked={
                    selectedWholeModifier?.objid === row?.objid ? true : false
                  }
                  onClick={(e) => {
                    setSelectedWholeModifier(row);
                    setCheckoutCartView(true);
                  }}
                ></Radio>
              </Col>
            ) : (
              <Col className="viewStoreModal_item-price-check">
                <Checkbox
                  style={{
                    padding: "1.5rem",
                    fontWeight: "bold",
                  }}
                  value={row}
                ></Checkbox>
              </Col>
            )}
          </Col>
        </Col>
      </Row>
    );
  };
  const handleChgQty = (key, num) => {
    const cartItem = cart[key];
    cartItem.qty = num;
    cartItem.total = Number(cartItem.unitPrice).toFixed(2) * num;
    console.log("dataStruct chngeqty", cartItem, key)
    if (cartItem.qty === 0) {
      dispatch({ type: CART_DELETED, payload: cartItem.objid });
    } else {
      dispatch({ type: CART_UPDATED, payload: { [key]: cartItem } });
    }
  };

  const getAppData = () => {
    const cartItem = cart[itemkey];
    console.log("cartItem>>>", itemkey, cartItem?.mitem?.appointment);
    return cartItem?.mitem?.appointment;

  }
  const handleSaveButtonClick = (key, selectedData) => {
    let filteredData = selectedData.filter((item) => item !== undefined)
    const groupedByObjId = filteredData.reduce((acc, item) => {
      const objId = Object.values(item)[0].objId;
      acc[objId] = acc[objId] || [];
      acc[objId].push(item);
      return acc;
    }, {});

    const filteredSelectedData = Object.values(groupedByObjId).flat();

    console.log("filteredSelectedData", filteredSelectedData, groupedByObjId)
    const updatedItemSelectedData = { ...itemSelectedData };
    updatedItemSelectedData.qty = updatedItemSelectedData.qty + 1;
    updatedItemSelectedData.mitem.appointment = [...filteredSelectedData];
    addObject(updatedItemSelectedData)
    const cartItem = cart[key];
    cartItem.mitem.appointment = [...groupedByObjId[key]];
    setDate((prevData) => [...prevData, ...filteredSelectedData]);
    console.log("handleSaveButtonClick cartItem", cartItem, cart[key])
    dispatch({ type: CART_UPDATED, payload: { [key]: cartItem } });


    if (addClicked) {
      console.log("handleSaveButtonClick in if")

      addClicked && handleChgQty(
        itemkey,
        (qty - 1) + (WholeUnits?.includes(unit) ? 1 : 0.1)
      )
    } else if (cartItem.mitem.appointment.length >= qty) {
      console.log("handleSaveButtonClick in elseif")

      const cartItem = cart[key];
      cartItem.mitem.appointment = filteredData;
      setQty(0)
    } else {
      console.log("handleSaveButtonClick in else")

      setQty(qty - cartItem.mitem.appointment.length)
    }
    setAppointVisible(false);

  };
  const handleEditButtonClick = (key, selectedData) => {
    let dataNew = itemSelectedData && itemSelectedData;
    const last = selectedData[selectedData.length - 1]
    // dataNew.appointment[indexSelected] = last 
    addObject(dataNew)
    let lastElement = selectedData[selectedData.length - 1];
    console.log("lastElement", lastElement)
    const cartItem = cart[key];
    cartItem.mitem.appointment[indexSelected] = lastElement;
    setAppointVisible(false);

  };
  const uiView = (mitem) => {
    if (mitem?.length > 0) {
      let filteredData = mitem?.filter((item) => item !== undefined)
      const data = filteredData?.map((item) => {
        const [date] = Object?.keys(item);
        const currentDate = new Date(date);

        let formattedDate;
        const isAlreadyFormatted = /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s\d{1,2}\s\(\w{3}\)/.test(date);

        if (isAlreadyFormatted) {
          formattedDate = date;
        } else if (currentDate.getMonth() === 1 && currentDate.getDate() === 29 && currentDate.getFullYear() === 2024) {
          formattedDate = `${new Intl.DateTimeFormat("en-US", { month: 'short', day: 'numeric' }).format(currentDate)} (${new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(currentDate)})`;
        } else {
          formattedDate = `${new Date(date).toLocaleDateString("en-US", { month: 'short', day: 'numeric' })} (${new Date(date).toLocaleDateString("en-US", { weekday: "short" })})`;
        }

        return {
          [formattedDate]: { startTime: item[date]?.startTime, endTime: item[date]?.endTime, locId: item[date]?.locId, objId: item[date]?.objId }
        };
      });
      return data;
    }
  }
  const startCheckout = async (isBusiness) => {
    const items = [];
    console.log("value cartWithTa", cartWithTax)
    for (const [key, value] of Object.entries(cartWithTax)) {
      if (value?.mitem?.appointment?.length > 0) {
        console.log("value inside if", value?.mitem?.appointment)
        const modifiedItem = {
          catalogue_item_id: key,
          details: value,
        };
        const dataStruc = value?.mitem?.appointment && uiView(value?.mitem?.appointment);
        console.log("value dataStruc", dataStruc)

        const structuredAppointment = output(dataStruc);
        console.log("value structuredAppointment", structuredAppointment)

        delete modifiedItem.details.appointment;
        modifiedItem.details.mitem.appointment = structuredAppointment;
        console.log("value modifiedItem", modifiedItem, value)
        items.push(modifiedItem);
      }
      else {
        console.log("value else")
        delete cartWithTax[key].mitem.appointment;
        items.push({
          catalogue_item_id: key,
          details: value,
        });
      }
    }

    const { locId, locName, parentLocation } = catalogueLocation;

    const invoiceDetails = {
      business_name: catalogueLocation?.biz?.bizName,
      business_country: catalogueLocation?.biz?.country,
      locName,
      locId,
      parentLocation,
      catalogueId: objid,
      catalogueName: name,
      title: `Invoice for ${name} from ${catalogueLocation.biz?.bizName}`,
      invoice_nbr: `${locId}/${Date.now()}`,
      cust_message: custMessage,

      server_name: "",
      items,
      deliveryType: delType,
      deliveryTime: `${delDay === 1 ? getToday() : getTomorrow()} ${delTime}`,
    };

    const cartInfo = {
      bizid: catalogueLocation.biz?.bizid,
      merchantId: catalogueLocation.biz?.merchantId,
      baseAmount: grandTotal.toFixed(2),
      taxAmount: totalTax.toFixed(2),
      tipAmount: Number(tip).toFixed(2),
      currency,
      currencySymbol,
      invoiceDetails,
    };
    if (isBusiness) {
      setIsLoading(true);
      cartInfo.buyerQRId = buyerQrId;
      cartInfo.channel = areAppointmentsAvailable ? "catalog_appointment" : "catalog";

      //update mode
      if (invoiceBeingEdited != undefined) {
        const props = ["objid", "createdDt", "updatedDt", "invoiceDt"];
        props.forEach((prop) => {
          cartInfo[prop] = invoiceBeingEdited[prop];
        });
      }

      const resp = await UserActions.saveInvoice(cartInfo, "rcpt");
      setIsLoading(false);
      if (resp.success) {
        message.success("Invoice Sent");
        console.log("invoiced", invoiceDetails)
        dispatch({ type: CART_INVOICED });
        history.goBack();
        // if (callback !== undefined) {
        //   callback(null);
        // } else {
        //   navigation.dispatch(StackActions.popToTop());
        //   navigation.navigate("Fin");
        // }
      } else {
        message.error(resp.msg);
        // if (callback !== undefined) {
        //   callback(resp.msg);
        // }
      }
    } else {
      // const updatedCartInfo = { ...cartInfo };

      // updatedCartInfo.invoiceDetails.items.forEach((item) => {
      //   const matchedAppointments = item.details.mitem.appointment.filter((appointment) => {
      //     const appointmentDate = Object.keys(appointment)[0];
      //     const appointmentData = appointment[appointmentDate];
      //     return appointmentData.objId.toString() === item.catalogue_item_id;
      //   });

      //   console.log("cartinfor matchedAppointments", output(matchedAppointments))
      //   item.details.mitem.appointment = output(matchedAppointments);
      // });
      await dispatch({ type: CHECKOUT_STARTED, payload: cartInfo });
    }

  };

  useEffect(() => {
    console.log("later", isBookingEnabled, later)
  }, [step === 4])
  const today = new Date();
  today.setHours(23, 59, 59, 0);
  const now = today.getTime();
  const showSuccess = (props ,createdInvoice, paymentMethod) => {
    setShowCard(false);
    setTip(0);
    setCreatedInvoice(createdInvoice);
    setpayCard(paymentMethod);
    setPopupVisible(true);
    dispatch({ type: CART_INVOICED });

  };
  
  const handlePopupClose = () => {
    setPopupVisible(false);
    history.push(`/catalogue/store/${catalogueDetails?.url}`);
  };

  const buttons = [
    { label: "10%", value: 0.1 },
    { label: "15%", value: 0.15 },
    { label: "20%", value: 0.2 },
  ];
  const onPressTip = (e) => {
    const value = e.target.value;
    setSelectedIndex(value);
    const val = value * grandTotal;
    setTip(val.toFixed(2) + "");
  };
  const handleChangeTip = (e) => {
    // const tipValue = e.target.value / 100;
    const tipValue = e.target.value ;
    // const val = tipValue * grandTotal;
    const val = Number(tipValue);
    setTipError(null);
    if (e.target.value && e.target.value <= 0) {
      message.error("Tip can't be less than zero");
    }
    setTip(val);
    setSelectedIndex(-1);
  };
  const doSearch = async () => {
    if (!searchRef?.current?.input?.value) {
      setItems([]);
      setSearchTxt();
      return;
    }
    setSearchTxt(searchRef?.current?.input?.value);
    if (objid && bizID) {
      setIsLoading(true);

      const resp = await UserActions.searchCatalogueItems({
        bizid: bizID,
        catalogueId: objid,
        searchTxt: searchRef?.current?.input?.value,
      });

      setIsLoading(false);
      if (resp.success) setItems(resp.items);
      else {
        setItems([]);
        setSearchTxt();

        message.error("No item found matching text");
      }
    } else {
      history.replace(`/qr/${loc}`);
    }
  };
  const getLocationOffer = async () => {
    if (parentLocation) {
      let offerCount = await UserActions.fetchLocOffers(parentLocation.objid);

      if (offerCount.success && offerCount?.resp[0]?.count > 0) {
        setShowOffer({
          value: true,
          count: offerCount.resp[0]?.count,
          img: offerCount.resp[0]?.cmpnImg,
          activeCampaignCount: offerCount.resp[0]?.activeCampaignCount,
          cmpnQrCode:offerCount.resp[0]?.cmpnQrCode,
          bizName: offerCount.resp[0].bizName,

        });
        
      }
    }
  };
  function findUniqueObjects(objects) {
    const uniqueObjects = [];

    objects.forEach((obj) => {
      // Convert objects to strings for comparison
      const objString = JSON.stringify(obj);

      // Check if the object is not already in the uniqueObjects array
      if (!uniqueObjects.some((uniqueObj) => JSON.stringify(uniqueObj) === objString)) {
        uniqueObjects.push(obj);
      }
    });

    return uniqueObjects;
  }

  const addMoreQty = (item) => {

    // addObject(dataNew[0])

  }

  const RenderItem = ({ item, index }) => {
    const {
      objid,
      qty,
      price,
      unitPrice,
      tax,
      whole,
      addon,
      total,
      mitem,
      wholeDetails,
      addonDetails,
      unit,
      instruction,
    } = item;
    const item_name = `${mitem.name} ${whole ? "(" + wholeDetails.modName + ")" : ""
      }`;
    const item_price = price + (whole ? wholeDetails.price : 0);
    let newData = mitem?.appointment && mitem?.appointment.filter((item) => item !== undefined)
    let newDate = newData && uiView(newData);
    return (
      <>
        {!isTab() ? (
          <Row
            // className="catalogue_checkout-step3-productDetails"
            style={{ marginTop: "2rem" }}
          >
            <Col
              className="catalogue_checkout-step3-productDetails"
              style={{ marginLeft: "1.2rem", marginBottom: "1.5rem" }}
            >
              <Row
                className="catalogue_checkout-step3-data"
                style={{
                  border: mitem?.appointment ? "1px solid var(--SECONDRY-20, #D3D6E1)" : "none"
                }}
                align={"middle"}
              >
                <Col  span={11} xs={11} sm={10} md={9} lg={11} xl={11} xxl={11}
                 
                  className="catalogue_checkout-step3-itenNameDetails"
                >
                  {" "}
                  {item_name}
                </Col>
                {addon.length > 0 &&
                  addonDetails.map((item) => {
                    return (
                      <Row key={item.modName} justify="space-between">
                        <Col>{item.modName}</Col>
                        <Col>
                          {`+ ${currencySymbol || "$"}${item.price.toFixed(2)}`}
                        </Col>
                      </Row>
                    );
                  })}

                {instruction && (
                  <Text style={{ margin: "1rem 0" }}>{instruction}</Text>
                )}
                <Col span={11} xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
                  <Button size="small" type="text" disabled={qty === 0}>
                    <img
                      alt="minusIcon"
                      src={minusIcon}
                      onClick={() => {
                        if (mitem?.appointment) {
                          const cartItem = cart[item.key];
                          isBookingEnabled && cartItem?.mitem?.appointment?.pop();
                          let dataApp = appointmentData.filter((obj) => obj.objid === item.objid)
                          if (dataApp?.length > 0) {
                            let resp = dataApp[0]
                            resp.qty = resp?.qty - 1
                            addObject(resp)
                          }
                        }
                        handleChgQty(
                          item.key,
                          qty - (WholeUnits?.includes(unit) ? 1 : 0.1)
                        )
                      }
                      }
                      className="catalogue_step3-minusBtn"
                    />
                  </Button>

                  <InputNumber
                    onChange={(value) => {
                      if (WholeUnits?.includes(unit)) {
                        if (isBookingEnabled) {
                          setQty(value)
                          setAppointVisible(true)
                          setItemName(item_name)
                          setItemKey(item.key)
                          setItemId(item.objid)
                          console.log("item.objid", item);
                          setSelectedItemData(item)
                          setUnit(unit);

                          // addMoreQty(item)

                        } else {
                          handleChgQty(item.key, value);
                        }


                      } else {
                        if (isBookingEnabled) {
                          setQty(value)
                          setAppointVisible(true)
                          setItemName(item_name)
                          setItemKey(item.key)
                          setItemId(item.objid)
                          console.log("item.objid", item);
                          setSelectedItemData(item)
                          setUnit(unit);
                          // addMoreQty(item)

                        } else {
                          handleChgQty(item.key, value);
                        }

                      }
                    }}
                    // disabled={isBookingEnabled}
                    placeholder={value}
                    readOnly
                    value={qty}
                    min={1}
                    controls={false}
                    step={WholeUnits?.includes(unit) ? 1 : 0.1}
                    precision={2}
                    className="catalogue_input_number_btn"
                  />
                  <Button size="small" type="text">
                    <img
                      alt="addIcon"
                      src={addIcon}
                      onClick={() => {
                        setAddClicked(true);
                        setItemName(item_name)
                        setItemKey(item.key)
                        setQty(qty + 1)
                        console.log("qty", qty + 1)
                        if (isBookingEnabled) {
                          setAppointVisible(true)
                          setItemName(item_name)
                          setItemKey(item.key)
                          setItemId(item.objid)
                          console.log("item on add ", item);
                          setSelectedItemData(item)
                          setUnit(unit);
                        }
                        else {
                          handleChgQty(
                            item.key,
                            qty + (WholeUnits?.includes(unit) ? 1 : 0.1)
                          )
                        }

                      }
                      }
                      className="catalogue_step3-addBtn"
                    />
                  </Button>
                </Col>

                <Col span={1} className="catalogue_checkout-step3-bussDetails">
                  {" "}
                  {currencySymbol || "$"}
                  {item_price.toFixed(2)}
                </Col>
              </Row>
              {mitem?.appointment &&
                <Row className="catalogue_checkout-step3-appoint" justify={"space-between"}
                >
                  <Col span={24}>
                    <Row gutter={[12, 12]}>

                      {/* {findUniqueObjects(mitem?.appointment)?.map((dateObject) => { */}

                      {newDate && newDate?.map((dateObject, index) => {
                        console.log("dateObject", dateObject)
                        const date = Object?.keys(dateObject);
                        const timeSlots = Object?.values(dateObject)[0];
                        return (
                          <>
                            <Row style={{ backgroundColor: '#D3D6E1', borderRadius: '10px', padding: "10px", margin: "2px" }} key={date} gutter={[12, 12]}>
                              <Button
                                style={{
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  padding: "10px 48px",
                                  height: "46px",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                  border: "1px solid #D3D6E1"
                                }}
                              >{date}
                              </Button>
                              <Col key={timeSlots}>
                                <Button
                                  style={{
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    padding: "10px 48px",
                                    height: "46px",
                                    alignItems: "center",
                                    borderRadius: "10px",
                                    border: "1px solid #D3D6E1"
                                  }}
                                >
                                  {`${moment(timeSlots?.startTime.slice(0, 5), "HH:mm").format('hh:mm A')} - ${moment(timeSlots?.endTime.slice(
                                    0,
                                    5
                                  ), "HH:mm").format('hh:mm A')} `}
                                </Button>
                              </Col>
                              {/* <Col span={1}></Col> */}
                              <Col span={1}>
                                <img src={edit_btn} onClick={() => {
                                  setItemId(item.objid)
                                  setSelectedItemData(item)
                                  setAddClicked(false);
                                  setIndexSelected(index)
                                  console.log("index>>>", index, item.objid);
                                  // if (getAppData(item.key).length == qty) {
                                  //   setQty(1)
                                  // } else if (getAppData(item.key).length > qty) {
                                  //   setQty(0)
                                  // } else {
                                  //   setQty(qty)
                                  // }
                                  setQty(qty + 2)
                                    ; setAppointVisible(true); setItemName(item_name); setItemKey(item.key)
                                }} style={{ cursor: "pointer", alignItems: 'center' }} /></Col>
                            </Row>
                          </>
                        );
                      })}
                    </Row>
                  </Col>
                  {/* <Col>
                    <img src={edit_btn} onClick={() => {
                      setAddClicked(false);
                      if (getAppData(item.key).length == qty) {
                        setQty(1)
                      } else if (getAppData(item.key).length > qty) {
                        setQty(0)
                      } else {
                        setQty(qty)
                      }

                      ; setAppointVisible(true); setItemName(item_name); setItemKey(item.key)
                    }} style={{ cursor: "pointer" }} /></Col> */}
                </Row>
              }
            </Col >

            <Col style={{ paddingLeft: "1rem", marginTop: "1rem" }}>
              {" "}
              <i
                onClick={() => {
                  setItemId(item.objid)
                  setShowConfirmation({ value: true, id: item.key })
                  setlater(false);
                }
                }
                style={{ color: "red", fontSize: "1.5rem", cursor: "pointer" }}
                className="fa fa-trash"
                aria-hidden="true"
              ></i>
            </Col>
          </Row >
        ) : (
          //mobile view step3

          <Row
            // className="catalogue_checkout-step3-productDetails"
            style={{ marginTop: "2rem" }}
          >
            <Col className="catalogue_checkout-step3-productDetails-mobile">
              <Row align={"middle"}>
                <Col
                  span={10}
                  className="catalogue_checkout-step3-itemNameDetails"
                  style={{ marginLeft: "1.5rem" }}
                >
                  {" "}
                  {item_name}
                </Col>
                {addon.length > 0 &&
                  addonDetails.map((item) => {
                    return (
                      <Row key={item.modName} justify="space-between">
                        <Col>{item.modName}</Col>
                        <Col>
                          {`+ ${currencySymbol || "$"}${item.price.toFixed(2)}`}
                        </Col>
                      </Row>
                    );
                  })}

                {instruction && <Text>{instruction}</Text>}

                <Col span={9}>
                  <img
                    alt="minusIcon"
                    className="catalogue_step3-minusBtn-mobile"
                    src={minusIcon}
                    disabled={qty <= 1}
                    onClick={() =>
                      handleChgQty(
                        item.key,
                        qty - (WholeUnits?.includes(unit) ? 1 : 0.1)
                      )
                    }
                  />
                  <InputNumber
                    className="catalogue_input_number_btn-mobile"
                    onChange={(value) => {
                      if (WholeUnits?.includes(unit)) {
                        handleChgQty(item.key, value);
                      } else {
                        handleChgQty(item.key, value);
                      }
                    }}
                    value={qty}
                    min={1}
                    controls={false}
                    step={WholeUnits?.includes(unit) ? 1 : 0.1}
                    precision={2}
                  />

                  <img
                    alt="addIcon"
                    className="catalogue_step3-addBtn-mobile"
                    src={addIcon}
                    onClick={() =>
                      handleChgQty(
                        item.key,
                        qty + (WholeUnits?.includes(unit) ? 1 : 0.1)
                      )
                    }
                  />
                </Col>

                <Col className="catalogue_checkout-step3-itemNameDetails">
                  {" "}
                  {currencySymbol || "$"}
                  {item_price.toFixed(2)}
                </Col>
                {/* <Col span={1}/> */}
              </Row>
              {mitem?.appointment &&
                <Row className="catalogue_checkout-step3-appoint" justify={"space-between"}
                >
                  <Col span={24}>
                    <Row gutter={[12, 12]}>

                      {/* {findUniqueObjects(mitem?.appointment)?.map((dateObject) => { */}
                      {mitem?.appointment?.map((dateObject, index) => {
                        const date = Object.keys(dateObject);
                        const timeSlots = Object.values(dateObject)[0];
                        return (
                          <>
                            <Row style={{ backgroundColor: '#D3D6E1', borderRadius: '10px', padding: "10px", margin: "2px" }} key={date} gutter={[12, 12]}>
                              <Button
                                style={{
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  padding: "10px 48px",
                                  height: "46px",
                                  alignItems: "center",
                                  borderRadius: "10px",
                                  border: "1px solid #D3D6E1"
                                }}
                              >{date}
                              </Button>
                              <Col key={timeSlots}>
                                <Button
                                  style={{
                                    display: "inline-flex",
                                    justifyContent: "center",
                                    padding: "10px 48px",
                                    height: "46px",
                                    alignItems: "center",
                                    borderRadius: "10px",
                                    border: "1px solid #D3D6E1"
                                  }}
                                >
                                  {/* {`${timeSlots?.startTime?.slice(0, 5)} - ${timeSlots?.endTime?.slice(0, 5)}`} */}
                                  {`${moment(timeSlots?.startTime.slice(0, 5), "HH:mm").format('hh:mm A')} - ${moment(timeSlots?.endTime.slice(
                                    0,
                                    5
                                  ), "HH:mm").format('hh:mm A')} `}
                                </Button>
                              </Col>
                              {/* <Col span={1}></Col> */}
                              <Col span={1}>
                                <img src={edit_btn} onClick={() => {
                                  setSelectedItemData(item)
                                  setAddClicked(false);
                                  setIndexSelected(index)
                                  setQty(qty + 2)
                                  console.log("item.objId id", item)
                                    ; setAppointVisible(true); setItemName(item_name); setItemKey(item.key); setItemId(item.objid)
                                }} style={{ cursor: "pointer", alignItems: 'center' }} /></Col>
                            </Row>
                          </>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              }
            </Col>

            <Col>
              {" "}
              <i
                onClick={() =>
                  setShowConfirmation({ value: true, id: item.key })
                }
                style={{
                  color: "red",
                  fontSize: "1.5rem",
                  marginLeft: "1rem",
                  paddingTop: "2rem",
                }}
                className="fa fa-trash"
                aria-hidden="true"
              ></i>
            </Col>
          </Row>
        )}
      </>
    );
  };

  return (
    <div className="home catalogue" ref={topContainer}>
      <ConfirmationModal
        visible={showConfirmation.value}
        onCancel={() => setShowConfirmation({ value: false, id: null })}
        onConfirm={() => {
          let data = appointmentData.filter((item) => item.objid !== itemId)
          setAppointmentData(data)
          const cartItem = cart[showConfirmation?.id];
          // if (cartItem.mitem) {
          //   cartItem.mitem.appointment = [];
          // }
          if (cartItem?.mitem) {
            delete cartItem.mitem.appointment
            delete selectedItem?.appointment
          }
          setSelectedItem(selectedItem)
          dispatch({ type: CART_DELETED, payload: showConfirmation.id });
          setShowConfirmation({ value: false, id: null });
        }}
        title="Remove Item"
        description="Are you sure you want to remove this item ?"
      />
      <Row align="middle" justify="center" className="catalogue_header">
        <Col align="center" xs={6} sm={8} md={4}>
          <Space>
            {step !== 1 && (
              <FiChevronLeft
                onClick={() => handleBack(showConfirmation.id)}
                style={{ fontSize: "3.5rem", color: "#fff", cursor: "pointer" }}
              />
            )}
            {step === 1 && (
              <FiChevronLeft
                onClick={() => {
                  handlePreviewBack();
                }}
                style={{ fontSize: "3.5rem", color: "#fff", cursor: "pointer" }}
              />
            )}
            <Logo collapsed={isMobile() ? true : false} />
          </Space>
        </Col>
        <Col align="center" xs={8} sm={8} md={16} span={16}>
          <h3 style={{ color: "#fff", marginBottom: "0" }}>
            Order at{" "}
            {catalogueDetail?.biz?.bizName || currentLocation || bizName}
          </h3>
        </Col>

        <Col align="center" xs={8} sm={8} md={4}>
          <Space>
            <TopMenu
              catalogue={true}
              loginFunction={() => takeToLogin()}
              {...props}
            />
            {step != 4 && (
              <Badge count={Object.keys(cart).length}>
                <FiShoppingCart
                  onClick={() => {
                    step != 4 && setStep(4);
                  }}
                  style={{
                    fontSize: isTab() ? "2rem" : "2rem",
                    color: "#fff",
                    cursor: "pointer",
                  }}
                />
              </Badge>
            )}
          </Space>
        </Col>
      </Row>
      {step === 1 && (
        <>
          {!isTab() ? (
            <div >
              {/* <Col style={{ paddingLeft: "6rem", paddingTop: "2rem" }}>
                <Button
                  type="link"
                  onClick={() => history.goBack()}
                  className="offer_cards--go-back-btn"
                >
                  <ArrowLeftOutlined style={{ verticalAlign: "middle" }} /> Go
                  Back
                </Button>
              </Col> */}
              {/* <Button
                style={{ padding: "24px 165px 24px 143px" }}
                type="link"
                onClick={() => history.goBack()}
                className="offer_cards--go-back-btn"
              >
                <ArrowLeftOutlined style={{ verticalAlign: "middle" }} /> Go
                Back
              </Button> */}
              <Row
                style={{ width: "100%", padding: "1rem 2rem" }}
                justify={"center"}
              >
                <Col span={6} md={8} lg={6}>
                  <div className="catalogue_firstCol">
                    <Row>
                      <Col align="center">
                        <Row>
                          <img
                            alt="Store"
                            className="catalogue_company_logo_updated"
                            src={
                              bizDetails?.mainLogo
                                ? `${bizDetails?.mainLogo
                                }?t=${new Date().getTime()}`
                                : Images.PlaceHolder
                            }
                            loading="lazy"
                          />
                        </Row>
                        <Row align={"middle"}>
                          <Col style={{ padding: "0.5rem 12px" }}>
                            <Row align={"middle"}>
                              <Text className="catalogue_bizname">
                                {bizDetails?.bizName}
                              </Text>
                            </Row>
                            <OverAllReview
                              onStarsClick={() => setSeeAllComments(true)}
                              disabled={true}
                              reviewsOverview={reviewsOverview}
                              rating={reviewsOverview?.overallRating}
                              text=" out of 5"
                              totalRatings={`(${reviewsOverview?.totalRatings} Reviews)`}
                              onClick={() => {
                                setSeeAllComments(true);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div
                      style={{
                        border: "0.0625rem solid #D3D6E1",
                        width: "90%",
                        margin: "1.5rem",
                      }}
                    />
                    <Row align={"middle"}>
                      <Col style={{ padding: "0.5rem 12px" }}>
                        <Row align={"middle"}>
                          {parentLocation !== undefined &&
                            parentLocation.deliveryOptions && (
                              <>
                                <img
                                  src={Van}
                                  alt="van"
                                  className="catalogue_icons"
                                />
                                <Text className="catalogue_headings">
                                  {DeliveryTypes.filter((row) =>
                                    parentLocation?.deliveryOptions
                                      .split(",")
                                      .includes(row?.key)
                                  )
                                    .map((row) => row?.val)
                                    .join(", ")}
                                </Text>
                              </>
                            )}
                        </Row>
                        <Col style={{ marginLeft: ".5rem" }}>
                          <Text className="catalogue_subheadings">
                            {parseAddress(catalogueDetails?.parentLocation)}
                          </Text>
                        </Col>
                      </Col>
                    </Row>

                    <div
                      style={{
                        border: "0.0625rem solid #D3D6E1",
                        width: "90%",
                        margin: "1.5rem",
                      }}
                    />
                    {closureInfo && (
                      <>
                        <Row align={"middle"}>
                          <Col style={{ padding: "0.5rem 12px" }}>
                            <Row align={"middle"}>
                              <img
                                src={Clock}
                                alt="clock"
                                className="catalogue_icons"
                              />
                              <Text className="catalogue_headings">Time</Text>
                            </Row>
                            <Col style={{ marginLeft: ".5rem" }}>
                              <Text className="catalogue_subheadings">
                                {closureInfo}
                              </Text>
                            </Col>
                          </Col>
                        </Row>
                        <div
                          style={{
                            border: "0.0625rem solid #D3D6E1",
                            width: "90%",
                            margin: "1.5rem",
                          }}
                        />
                      </>
                    )}
                    {parentLocation &&
                      parentLocation.phone !== "" &&
                      parentLocation.phone !== undefined && (
                        <>
                          <Row align={"middle"}>
                            <Col style={{ padding: "0.5rem 12px" }}>
                              <Row align={"middle"}>
                                <img
                                  src={Phone}
                                  alt="phone"
                                  className="catalogue_icons"
                                />
                                <Text className="catalogue_headings">
                                  Contact Number
                                </Text>
                              </Row>

                              <Col style={{ marginLeft: ".5rem" }}>
                                <Text
                                  className="catalogue_subheadings"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    window.open(`tel:${parentLocation.phone}`)
                                  }
                                >
                                  {parentLocation.phone}
                                </Text>
                              </Col>
                            </Col>
                          </Row>
                          <div
                            style={{
                              border: "0.0625rem solid #D3D6E1",
                              width: "90%",
                              margin: "1.5rem",
                            }}
                          />
                        </>
                      )}
                    {catalogueDetails?.biz &&
                      catalogueDetails?.biz?.website !== undefined &&
                      catalogueDetails?.biz?.website !== null && (
                        <>
                          <Row align={"middle"}>
                            <Col style={{ padding: "0.5rem 12px" }}>
                              <Row align={"middle"}>
                                <div
                                  style={{
                                    padding: "0.2rem",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Popconfirm
                                    overlay
                                    placement="top"
                                    title={
                                      <div style={{ width: "24rem" }}>
                                        You are visiting the link{" "}
                                        {catalogueDetails?.biz?.website} outside
                                        Fyndr domain that is not verified or
                                        secured by Fyndr, do you want to
                                        continue ?
                                      </div>
                                    }
                                    onConfirm={() => {
                                      if (
                                        !catalogueDetails?.biz?.website.includes(
                                          "https://www.fyndr.us/"
                                        )
                                      ) {
                                        window.open(
                                          `https://${catalogueDetails?.biz?.website}`
                                        );
                                      } else {
                                        window.open(
                                          `${catalogueDetails?.biz?.website}`
                                        );
                                      }
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <img
                                      src={Website}
                                      alt="website"
                                      className="catalogue_icons"
                                    />
                                    <Text className="catalogue_headings">
                                      Website
                                    </Text>
                                  </Popconfirm>
                                </div>
                              </Row>
                              <Col style={{ marginLeft: ".5rem" }}>
                                <Text
                                  onClick={() => {
                                    if (
                                      !catalogueDetails?.biz?.website.includes(
                                        "https://www.fyndr.us/"
                                      )
                                    ) {
                                      window.open(
                                        `https://${catalogueDetails?.biz?.website}`
                                      );
                                    } else {
                                      window.open(
                                        `${catalogueDetails?.biz?.website}`
                                      );
                                    }
                                  }}
                                  className="catalogue_subheadings"
                                  style={{
                                    cursor: "pointer",
                                    borderBottom: "1px solid #D3D6E1",
                                  }}
                                >
                                  {catalogueDetails?.biz?.website}
                                </Text>
                              </Col>
                            </Col>
                          </Row>

                          {/* {parentLocation?.workingHours !== null && parentLocation?.workingHours.length > 5 && (
                    <div
                      style={{
                        padding: "0.2rem",
                        borderRadius: "50%",
                        border: ".3px solid #ddd",
                        margin: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedItemWorkingHours(parentLocation);
                        setViewWorkingHourModal(true);
                      }}
                    >
                      <FaClock
                        size={25}
                        style={{ fontSize: "1.2rem", color: "#223369" }}
                      />
                    </div>
                  )} */}

                          <div
                            style={{
                              border: "0.0625rem solid #D3D6E1",
                              width: "90%",
                              margin: "1.5rem",
                            }}
                          />
                        </>
                      )}
                    <Row
                      justify={"center"}
                      align={"middle"}
                      style={{ padding: "1.5rem", width: "100%" }}
                    >
                      <Col xs={24} sm={24} md={24}>
                        {parentLocation && parentLocation.lat !== null && (
                          <Button
                            className="getDirectionBtn"
                            icon={
                              <img
                                src={Location}
                                alt="location"
                                className="catalogue_btn-icons"
                              />
                            }
                            type="primary"
                            onClick={() => setShowMapModal(true)}
                          >
                            Get Directions
                          </Button>
                        )}
                        <Col />
                        <Col xs={24} sm={24} md={24}>
                          <Button
                            disabled={showOffer.count < 1 ? true : false}
                            icon={
                              <img
                                src={Store}
                                alt="store"
                                className="catalogue_btn-icons"
                              />
                            }
                            onClick={() => {
                              if(showOffer.activeCampaignCount > 1){
                                props.history.push("/offers", {
                                      locQRId: parentLocation?.qrid,
                                    })
                              } else {

                                history.push(`/offer-details/${bizDetails.bizName.replace(/[.\W]+/g, "-")}/${showOffer.cmpnQrCode}`)
                              }
                            }}
                            className="getDirectionBtn"
                            style={{
                              background:
                                showOffer.count < 1 ? "#d3d3d3" : "#223369",
                            }}
                          >
                            {" "}
                            {showOffer.count} Active Offers
                          </Button>
                        </Col>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={14} style={{ marginLeft: "1.5rem" }}>
                  <div style={{ position: "relative" }}>
                    {catalogueinfo && Array.isArray(catalogueinfo?.images) ? (
                      <img
                        alt="cat_img"
                        style={{
                          borderRadius: "10px",
                          display: "block",
                          width: "100%",
                          // height: "27.625rem",
                          aspectRatio: "16/9",
                          objectFit: "cover",
                          marginBottom: "1.5rem",
                        }}
                        src={catalogueinfo?.images[0]?.img_url || Images.PlaceHolder}
                      />
                    ) : (
                      <>
                        {catalogueDetails &&
                          Array.isArray(catalogueDetails?.images) && (
                            <img
                              alt="cat_img"
                              style={{
                                borderRadius: "10px",
                                display: "block",
                                width: "100%",
                                // height: "27.625rem",
                                aspectRatio: "16/9",
                                objectFit: "cover",
                                marginBottom: "1.5rem",
                              }}
                              src={catalogueDetails?.images[0].img_url}
                            />
                          )}
                      </>
                    )}
                    <div className="catalogue_img-icons-div">
                      <Col style={{ paddingRight: "1.25rem" }}>
                        {/* {biz && bizLikes[bizID] && (
                          <> */}
                        {liked && liked["isDeleted"] === false && (
                          <div className="catalogue_img-icons">
                            <AiFillHeart
                              color={"#fff"}
                              style={{
                                width: "2.5rem",
                                height: "2.5rem",
                                paddingRight: ".4rem",
                              }}
                              onClick={() => {
                                doLike(catalogueinfo.objid, liked);
                              }}
                            />
                            <Text
                              style={{ fontSize: "1.125rem", color: "white" }}
                            >
                              {bizLikes[bizID]}
                            </Text>
                          </div>
                        )}
                        {(!liked?.hasOwnProperty("likedId") ||
                          liked["isDeleted"] === true) && (
                            <div className="catalogue_img-icons">
                              <img
                                src={Heart}
                                alt="heart"
                                style={{ paddingRight: ".4rem" }}
                                onClick={() => doLike(catalogueinfo.objid, liked)}
                              />
                              <Text
                                style={{ fontSize: "1.125rem", color: "white" }}
                              >
                                {bizLikes[bizID]}
                              </Text>
                            </div>
                          )}
                        {/* </>
                        )} */}
                      </Col>
                      <Col>
                        <div className="catalogue_img-share-icons">
                          <img
                            src={copyShare}
                            alt="share"
                            onClick={copyTextToClipboard}
                          />
                        </div>
                      </Col>
                    </div>
                  </div>
                  <div
                    className="catalogue_holder"
                    style={{
                      borderRadius: "10px",
                      marginLeft: "0rem",
                      width: "100%",
                      height: "auto",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {isLoading ? (
                      <SkeletonLoader />
                    ) : (
                      <>
                        {step === 1 && (
                          <>
                            <div
                              style={{
                                marginBottom: "2rem",
                                width: "100%",
                              }}
                            >
                              <Row className="catalogue_main-heading">
                                {"Categories"}
                              </Row>
                              <div
                                style={{
                                  border: "0.0625rem solid #D3D6E1",
                                  width: "100%",
                                }}
                              />
                              <Row
                                style={{
                                  padding: "1.25rem 1.25rem 0rem 1.25rem",
                                }}
                              >
                                <Input
                                  onPressEnter={() => doSearch()}
                                  onSearch={() => doSearch()}
                                  onBlur={() => doSearch()}
                                  size="large"
                                  placeholder="Search here"
                                  loading={isLoading}
                                  prefix={
                                    <SearchOutlined
                                      style={{ color: "#D3D6E1" }}
                                    />
                                  }
                                  ref={searchRef}
                                  defaultValue={searchTxt}
                                  style={{
                                    width: "100%",
                                    height: "2.875rem",
                                    fontWeight: "400",
                                    fontSize: "1.25rem",
                                    borderRadius: "5px",
                                  }}
                                  className="catalogue_search_updated"
                                  justify="space-between"
                                />
                              </Row>
                            </div>
                            {items.length > 0 ? (
                              items.map((item, key) => {
                                return (
                                  <MobileRow
                                    key={key}
                                    onClick={() => {
                                      setSelectedItem(item);
                                      setModalVisible(true);
                                      // setStep(3);
                                    }}
                                    item={item}
                                    type={ITEM}
                                    symbol={currencySymbol || "$"}
                                  />
                                );
                              })
                            ) : (
                              <Row className="catalogue_hidden-scroll">
                                {catalogueCategories &&
                                  catalogueCategories.map((item, key) => {
                                    return (
                                      <Col
                                        xs={24}
                                        sm={24}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        align={"center"}
                                      >
                                        <div
                                          className="imageText"
                                          style={{
                                            backgroundImage: `url(${item.images
                                              ? item.images[0].img_url
                                              : Images?.Images?.PlaceHolder
                                              })`,
                                          }}
                                          onClick={() => {
                                            setSelectedCategory(item);
                                            setStep(2);
                                          }}
                                        >
                                          <div className="imageTextDiv1"></div>

                                          <div className="imageTextDiv2">
                                            <Text className="imageTextDivText">
                                              {item.name}
                                            </Text>
                                          </div>
                                        </div>
                                      </Col>
                                    );
                                  })}
                              </Row>
                            )}
                          </>
                        )}

                        {step === 3 && (
                          <Row
                            class="catalogue_detail_layout"
                            align="middle"
                            justify="center"
                            style={{ maxWidth: "100%" }}
                          >
                            <Col
                              style={{
                                minHeight: isTab() ? "80vh" : "100vh",
                              }}
                              align={isTab() ? "center" : "left"}
                              xs={24}
                              md={20}
                            >
                              <Row justify="center" gutter={[20]}>
                                <Col
                                  style={{ maxWidth: "100%" }}
                                  xs={24}
                                  md={12}
                                >
                                  {isTab() ? null : (
                                    <h2 className="catalogue__title">
                                      {`${selectedItem?.item.name}- ${currencySymbol || "$"
                                        }${Number(selectedItem?.price).toFixed(
                                          2
                                        )} / ${selectedItem?.item.unit}`}
                                    </h2>
                                  )}
                                  <img
                                    alt="Item"
                                    className="catalogue_detail_page"
                                    src={
                                      selectedItem?.item.images
                                        ? selectedItem?.item.images[0].img_url
                                        : Images.PlaceHolder
                                    }
                                  />
                                  {isTab() ? null : (
                                    <>
                                      <div
                                        style={{
                                          textAlign: "left",
                                          maxWidth: "90%",
                                        }}
                                      >
                                        {selectedItem?.item?.description && (
                                          <RichTextViewer
                                            content={` ${selectedItem?.item.description}`}
                                          />
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          marginTop: "1rem",
                                          marginBottom: "1rem",
                                        }}
                                      >
                                        <Row
                                          style={{ width: "80%" }}
                                          justify="start"
                                          align="stretch"
                                          gutter={[20]}
                                        >
                                          <Col span={12}>
                                            <h5>Quantity</h5>
                                          </Col>
                                          <Col span={12}>
                                            <Row>
                                              <Button
                                                disabled={quantity <= 1}
                                                onClick={() =>
                                                  setQuantity(
                                                    quantity -
                                                    (WholeUnits?.includes(
                                                      selectedItem?.item?.unit
                                                    )
                                                      ? 1
                                                      : 0.1)
                                                  )
                                                }
                                                type="primary"
                                              >
                                                -
                                              </Button>

                                              <InputNumber
                                                onChange={(value) => {
                                                  if (
                                                    WholeUnits?.includes(
                                                      selectedItem?.item?.unit
                                                    )
                                                  ) {
                                                    setQuantity(
                                                      Number(value).toFixed(0)
                                                    );
                                                  } else {
                                                    setQuantity(value);
                                                  }
                                                }}
                                                value={quantity}
                                                min={1}
                                                style={{ maxWidth: "40%" }}
                                                step={
                                                  WholeUnits?.includes(
                                                    selectedItem?.item?.unit
                                                  )
                                                    ? 1
                                                    : 0.1
                                                }
                                                precision={2}
                                              />
                                              <Button
                                                onClick={() =>
                                                  setQuantity(
                                                    quantity +
                                                    (WholeUnits?.includes(
                                                      selectedItem?.item?.unit
                                                    )
                                                      ? 1
                                                      : 0.1)
                                                  )
                                                }
                                                type="primary"
                                              >
                                                +
                                              </Button>
                                            </Row>
                                          </Col>
                                        </Row>
                                        <Row align="middle">
                                          <Col span={12}>
                                            <h5
                                              style={{ margin: "1.5rem 0" }}
                                            >{`Total: `}</h5>
                                          </Col>
                                          <Col span={12}>
                                            <h5
                                              style={{ margin: "1rem 0" }}
                                            >{`${currencySymbol || "$"}${Number(
                                              total
                                            ).toFixed(2)}`}</h5>
                                          </Col>
                                        </Row>
                                      </div>

                                      <AddToCart
                                        biz={bizDetails}
                                        style={{ width: "100%" }}
                                        addToCart={
                                          indvid ? addToCart : takeToLogin
                                        }
                                      ></AddToCart>
                                      {Object.keys(cart).length > 0 && (
                                        <Button
                                          style={{
                                            marginTop: "1rem",
                                            borderRadius: "5px",
                                          }}
                                          type="primary"
                                          size="large"
                                          onClick={() => {
                                            setStep(4);
                                          }}
                                          disabled={
                                            roleType === ROLE_SUPPORT ||
                                              roleType === ROLE_MANAGER ||
                                              isMasquerade
                                              ? true
                                              : false
                                          }
                                        >
                                          Checkout
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </Col>
                                {isTab() ? null : (
                                  <Col align="end" xs={24} md={12}>
                                    <h2 className="catalogue__title">
                                      Select variations / addons
                                    </h2>
                                    <div className="catalogue_detail_options">
                                      <>
                                        <OptionRow
                                          unit={selectedItem?.item.unit}
                                          row={getItemAsModifier()}
                                        />
                                        {wholeModifiers?.map((row, key) => {
                                          return <OptionRow row={row} />;
                                        })}

                                        {addOnModifiers?.length > 0 && (
                                          <Checkbox.Group
                                            style={{ minWidth: "100%" }}
                                            onChange={handleAddOnModifier}
                                          >
                                            {addOnModifiers?.map((row, key) => {
                                              return <OptionRow row={row} />;
                                            })}
                                          </Checkbox.Group>
                                        )}
                                      </>
                                    </div>
                                    <h4 style={{ margin: "1rem 0" }}>
                                      Special Instructions
                                    </h4>
                                    <Input.TextArea
                                      onChange={(event) =>
                                        setInstr(event.target.value)
                                      }
                                      rows={4}
                                      style={{ margin: "1rem 0" }}
                                      placeholder="Instructions: spice level, order for, include, exclude"
                                      value={instr}
                                    />
                                  </Col>
                                )}
                                <Divider />
                                <Col xs={22} md={0}>
                                  <h2 className="catalogue__title">
                                    {`${selectedItem?.item.name}- ${currencySymbol || "$"
                                      }${selectedItem?.price} / ${selectedItem?.item.unit
                                      }`}
                                  </h2>
                                  <div style={{ textAlign: "justify" }}>
                                    {selectedItem?.item?.description && (
                                      <RichTextViewer
                                        content={` ${selectedItem?.item.description}`}
                                      />
                                    )}
                                  </div>
                                  {isTab() ? null : (
                                    <>
                                      <AddToCart
                                        biz={bizDetails}
                                        style={{ marginTop: "3 .5rem" }}
                                        addToCart={
                                          indvid ? addToCart : takeToLogin
                                        }
                                      ></AddToCart>
                                      {Object.keys(cart).length > 0 && (
                                        <Button
                                          style={{
                                            width: "100%",
                                            marginTop: "1rem",
                                            borderRadius: "5px",
                                          }}
                                          type="primary"
                                          size="large"
                                          onClick={() => {
                                            setStep(4);
                                          }}
                                          disabled={
                                            roleType === ROLE_SUPPORT ||
                                              roleType === ROLE_MANAGER ||
                                              isMasquerade
                                              ? true
                                              : false
                                          }
                                        >
                                          Checkout
                                        </Button>
                                      )}
                                    </>
                                  )}
                                </Col>
                              </Row>

                              <Col xs={24} md={0} style={{ margin: "1rem 0" }}>
                                <h2 className="catalogue__title">
                                  Select variations / addons
                                </h2>
                                <div className="catalogue_detail_options">
                                  <>
                                    <OptionRow
                                      unit={selectedItem?.item.unit}
                                      row={getItemAsModifier()}
                                    />
                                    {wholeModifiers?.map((row, key) => {
                                      return <OptionRow row={row} />;
                                    })}

                                    {addOnModifiers?.length > 0 && (
                                      <Checkbox.Group
                                        onChange={handleAddOnModifier}
                                      >
                                        {addOnModifiers?.map((row, key) => {
                                          return <OptionRow row={row} />;
                                        })}
                                      </Checkbox.Group>
                                    )}
                                  </>
                                </div>
                                <Input
                                  onChange={(event) =>
                                    setInstr(event.target.value)
                                  }
                                  style={{ margin: "1rem 0" }}
                                  placeholder="Instructions: spice level, order for, include, exclude"
                                  value={instr}
                                />
                              </Col>
                            </Col>
                          </Row>
                        )}
                        {step === 3 && checkoutCartView && (
                          <Row
                            className="catalogue_detail_page_cart_card"
                            align="middle"
                            justify="center"
                            style={{ position: "relative" }}
                          >
                            <Col xs={24}>
                              <Row justify="end" align="top">
                                <Col align="right" justify="top">
                                  <i
                                    onClick={() => setCheckoutCartView(false)}
                                    className="fa fa-close"
                                    style={{
                                      color: Theme.grey,
                                      fontSize: "3.5rem",
                                      position: "absolute",
                                      top: -50,
                                      right: 2,
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row
                                style={{ width: "100%", marginBottom: "2rem" }}
                                align="middle"
                                justify="space-between"
                              >
                                <Col span={10}>
                                  <h3>{`Total: ${currencySymbol || "$"}${Number(
                                    total
                                  ).toFixed(2)}`}</h3>
                                </Col>
                                <Col span={12} align="end">
                                  <Row>
                                    <Button
                                      disabled={quantity <= 1}
                                      onClick={() =>
                                        setQuantity(
                                          quantity -
                                          (WholeUnits?.includes(
                                            selectedItem?.item?.unit
                                          )
                                            ? 1
                                            : 0.1)
                                        )
                                      }
                                      type="primary"
                                    >
                                      -
                                    </Button>

                                    <InputNumber
                                      onChange={(value) => {
                                        if (
                                          WholeUnits?.includes(
                                            selectedItem?.item?.unit
                                          )
                                        ) {
                                          setQuantity(Number(value).toFixed(0));
                                        } else {
                                          setQuantity(value);
                                        }
                                      }}
                                      value={quantity}
                                      min={1}
                                      style={{ maxWidth: "40%" }}
                                      step={
                                        WholeUnits?.includes(
                                          selectedItem?.item?.unit
                                        )
                                          ? 1
                                          : 0.1
                                      }
                                      precision={2}
                                    />

                                    <Button
                                      onClick={() =>
                                        setQuantity(
                                          quantity +
                                          (WholeUnits?.includes(
                                            selectedItem?.item?.unit
                                          )
                                            ? 1
                                            : 0.1)
                                        )
                                      }
                                      type="primary"
                                    >
                                      +
                                    </Button>
                                  </Row>
                                </Col>
                              </Row>
                              <Row align="middle" justify="center">
                                <AddToCart
                                  biz={bizDetails}
                                  style={{ width: "100%" }}
                                  addToCart={indvid ? addToCart : takeToLogin}
                                ></AddToCart>
                                {Object.keys(cart).length > 0 && (
                                  <Button
                                    style={{
                                      width: "100%",
                                      marginTop: "1rem",
                                      borderRadius: "5px",
                                    }}
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                      setStep(4);
                                    }}
                                    disabled={
                                      roleType === ROLE_SUPPORT ||
                                        roleType === ROLE_MANAGER ||
                                        isMasquerade
                                        ? true
                                        : false
                                    }
                                  >
                                    Checkout
                                  </Button>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            // mobile screen
            <div>
              <Col>
                <div style={{ position: "relative" }}>
                  {catalogueinfo && Array.isArray(catalogueinfo?.images) && (
                    <img
                      alt="cat_img"
                      style={{
                        display: "block",
                        width: "100%",
                        height: "auto",
                        objectFit: "cover",
                        aspectRatio: "16/9",
                        marginBottom: "1.5rem",
                      }}
                      src={catalogueinfo?.images[0]?.img_url || Images.PlaceHolder}
                    />
                  )}
                  <div className="catalogue_img-icons-div">
                    <Col style={{ paddingRight: "1.25rem" }}>
                      {/* {biz && bizLikes[bizID] && ( */}
                      {liked && liked["isDeleted"] === false && (
                        <div className="catalogue_img-icons">
                          <AiFillHeart
                            color={"#fff"}
                            style={{
                              width: "2.5rem",
                              height: "2.5rem",
                              paddingRight: ".4rem",
                            }}
                            onClick={() => {
                              doLike(catalogueinfo.objid, liked);
                            }}
                          />
                          <Text
                            style={{ fontSize: "1.125rem", color: "white" }}
                          >
                            {bizLikes[bizID]}
                          </Text>
                        </div>
                      )}
                      {(!liked?.hasOwnProperty("likedId") ||
                        liked["isDeleted"] === true) && (
                          <div className="catalogue_img-icons">
                            <img
                              src={Heart}
                              alt="heart"
                              style={{ paddingRight: ".4rem" }}
                              onClick={() => doLike(catalogueinfo.objid, liked)}
                            />
                            <Text
                              style={{ fontSize: "1.125rem", color: "white" }}
                            >
                              {bizLikes[bizID]}
                            </Text>
                          </div>
                        )}
                      {/* )} */}
                    </Col>
                    <Col>
                      <div className="catalogue_img-share-icons">
                        <img
                          src={copyShare}
                          alt="share"
                          onClick={copyTextToClipboard}
                        />
                      </div>
                    </Col>
                  </div>
                </div>
                <div className="catalogue_img_div-mobile">
                  <Row align={"middle"}>
                    <Col align="center">
                      <Row>
                        <img
                          alt="Store"
                          className="catalogue_company_logo_updated_mobile "
                          src={
                            bizDetails?.mainLogo
                              ? `${bizDetails?.mainLogo
                              }?t=${new Date().getTime()}`
                              : Images.PlaceHolder
                          }
                          loading="lazy"
                        />
                      </Row>
                      <Row
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          width: "90%",
                        }}
                      >
                        <Text className="catalogue_bizname">
                          {bizDetails?.bizName}
                        </Text>
                        <OverAllReview
                          onStarsClick={() => setSeeAllComments(true)}
                          disabled={true}
                          reviewsOverview={reviewsOverview}
                          rating={reviewsOverview?.overallRating}
                          text=" out of 5"
                          totalRatings={`(${reviewsOverview?.totalRatings} Reviews)`}
                          onClick={() => {
                            setSeeAllComments(true);
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                  <div className="catalogue_divider" />
                  <div style={{ position: "relative" }}>
                    {isCollapsed && (
                      <div style={{ bottom: "0", left: "0", right: "0" }}>
                        <Row align={"middle"}>
                          <Col style={{ padding: "0.5rem 12px" }}>
                            <Row align={"middle"}>
                              {parentLocation !== undefined &&
                                parentLocation.deliveryOptions && (
                                  <>
                                    <img
                                      src={Van}
                                      alt="van"
                                      className="catalogue_icons"
                                    />
                                    <Text className="catalogue_headings">
                                      {DeliveryTypes.filter((row) =>
                                        parentLocation.deliveryOptions
                                          .split(",")
                                          .includes(row.key)
                                      )
                                        .map((row) => row.val)
                                        .join(", ")}
                                    </Text>
                                  </>
                                )}
                            </Row>
                            <Col style={{ marginLeft: ".5rem" }}>
                              <Text className="catalogue_subheadings">
                                {parseAddress(catalogueDetails?.parentLocation)}
                              </Text>
                            </Col>
                          </Col>
                        </Row>
                        <div className="catalogue_divider" />

                        {closureInfo && (
                          <>
                            <Row align={"middle"}>
                              <Col style={{ padding: "0.5rem 12px" }}>
                                <Row align={"middle"}>
                                  <img
                                    src={Clock}
                                    alt="clock"
                                    className="catalogue_icons"
                                  />
                                  <Text className="catalogue_headings">
                                    Time
                                  </Text>
                                </Row>
                                <Col style={{ marginLeft: ".5rem" }}>
                                  <Text className="catalogue_subheadings">
                                    {closureInfo}
                                  </Text>
                                </Col>
                              </Col>
                            </Row>
                            <div className="catalogue_divider" />
                          </>
                        )}
                        {parentLocation &&
                          parentLocation.phone !== "" &&
                          parentLocation.phone !== undefined && (
                            <>
                              <Row align={"middle"}>
                                <Col style={{ padding: "0.5rem 12px" }}>
                                  <Row align={"middle"}>
                                    <img
                                      src={Phone}
                                      alt="phone"
                                      className="catalogue_icons"
                                    />
                                    <Text className="catalogue_headings">
                                      Contact Number
                                    </Text>
                                  </Row>

                                  <Col style={{ marginLeft: ".5rem" }}>
                                    <Text
                                      className="catalogue_subheadings"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        window.open(
                                          `tel:${parentLocation.phone}`
                                        )
                                      }
                                    >
                                      {parentLocation.phone}
                                    </Text>
                                  </Col>
                                </Col>
                              </Row>
                              <div className="catalogue_divider" />
                            </>
                          )}
                        {catalogueDetails?.biz &&
                          catalogueDetails?.biz?.website !== undefined &&
                          catalogueDetails?.biz?.website !== null && (
                            <>
                              <Row align={"middle"}>
                                <Col style={{ padding: "0.5rem 12px" }}>
                                  <Row align={"middle"}>
                                    <div
                                      style={{
                                        padding: "0.2rem",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <Popconfirm
                                        overlay
                                        placement="top"
                                        title={
                                          <div style={{ width: "24rem" }}>
                                            You are visiting the link{" "}
                                            {catalogueDetails?.biz?.website}{" "}
                                            outside Fyndr domain that is not
                                            verified or secured by Fyndr, do you
                                            want to continue ?
                                          </div>
                                        }
                                        onConfirm={() => {
                                          if (
                                            !catalogueDetails?.biz?.website.includes(
                                              "https://www.fyndr.us/"
                                            )
                                          ) {
                                            window.open(
                                              `https://${catalogueDetails?.biz?.website}`
                                            );
                                          } else {
                                            window.open(
                                              `${catalogueDetails?.biz?.website}`
                                            );
                                          }
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <img
                                          src={Website}
                                          alt="website"
                                          className="catalogue_icons"
                                        />
                                        <Text className="catalogue_headings">
                                          Website
                                        </Text>
                                      </Popconfirm>
                                    </div>
                                  </Row>
                                  <Col style={{ marginLeft: ".5rem" }}>
                                    <Text
                                      onClick={() => {
                                        if (
                                          !catalogueDetails?.biz?.website.includes(
                                            "https://www.fyndr.us/"
                                          )
                                        ) {
                                          window.open(
                                            `https://${catalogueDetails?.biz?.website}`
                                          );
                                        } else {
                                          window.open(
                                            `${catalogueDetails?.biz?.website}`
                                          );
                                        }
                                      }}
                                      className="catalogue_subheadings"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {catalogueDetails?.biz?.website}
                                    </Text>
                                  </Col>
                                </Col>
                              </Row>
                              {/* {parentLocation?.workingHours !== null && parentLocation?.workingHours.length > 5 && (
              <div
                style={{
                  padding: "0.2rem",
                  borderRadius: "50%",
                  border: ".3px solid #ddd",
                  margin: "1rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectedItemWorkingHours(parentLocation);
                  setViewWorkingHourModal(true);
                }}
              >
                <FaClock
                  size={25}
                  style={{ fontSize: "1.2rem", color: "#223369" }}
                />
              </div>
            )} */}
                              <div
                                style={{
                                  border: "0.0625rem solid #D3D6E1",
                                  width: "90%",
                                  margin: "12px 12px 16px 12px",
                                }}
                              />
                            </>
                          )}

                        <Row style={{ margin: "0rem 1.5rem 0rem 1.5rem " }}>
                          <Col span={24}>
                            {parentLocation && parentLocation.lat !== null && (
                              <Button
                                className="getDirectionBtn catalogue_btn-icons"
                                icon={
                                  <img
                                    src={Location}
                                    alt="location"
                                    style={{ marginBottom: ".5rem" }}
                                  />
                                }
                                type="primary"
                                onClick={() => setShowMapModal(true)}
                              >
                                Get Directions
                              </Button>
                            )}
                            <Col />
                            <Col span={24}>
                              <Button
                                disabled={showOffer.count < 1 ? true : false}
                                icon={
                                  <img
                                    src={Store}
                                    alt="store"
                                    style={{
                                      marginRight: ".5rem",
                                      marginBottom: ".5rem",
                                    }}
                                  />
                                }
                                onClick={() => {
                                  if(showOffer.activeCampaignCount > 1){
                                    props.history.push("/offers", {
                                          locQRId: parentLocation?.qrid,
                                        })
                                  } else {
    
                                    history.push(`/offer-details/${bizDetails.bizName.replace(/[.\W]+/g, "-")}/${showOffer.cmpnQrCode}`)
                                  }
                                }}
                                className="getDirectionBtn catalogue_btn-icons"
                                style={{
                                  background:
                                    showOffer.count < 1 ? "#d3d3d3" : "#223369",
                                }}
                              >
                                {" "}
                                {showOffer.count} Active Offers
                              </Button>
                            </Col>
                          </Col>
                        </Row>
                        <div
                          style={{
                            border: "0.0625rem solid #D3D6E1",
                            width: "90%",
                            margin: "12px",
                          }}
                        />
                      </div>
                    )}
                    <div className="catalogue_collapse-view">
                      <Button
                        type="text"
                        icon={
                          isCollapsed ? (
                            <img
                              src={viewless}
                              alt="view less"
                              style={{ margin: "0rem .5rem" }}
                            />
                          ) : (
                            <img
                              src={viewmore}
                              alt="view less"
                              style={{ margin: "0rem .5rem" }}
                            />
                          )
                        }
                        style={{
                          color: "#257CDB",
                          width: "100%",
                          alignContent: "center",
                        }}
                        onClick={toggleCollapse}
                      >
                        {isCollapsed ? "View Less" : "View More"}
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  className="catalogue_holder"
                  style={{
                    height: "auto",
                    margin: "1rem",
                    borderRadius: "10px",
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {isLoading ? (
                    <SkeletonLoader />
                  ) : (
                    <>
                      {step === 1 && (
                        <>
                          <div
                            style={{
                              marginBottom: "2rem",
                              width: "100%",
                            }}
                          >
                            <Row className="catalogue_main-heading">
                              {"Categories"}
                            </Row>
                            <div
                              style={{
                                border: "0.0625rem solid #D3D6E1",
                                width: "100%",
                              }}
                            />
                            <Row
                              style={{
                                padding: "1.25rem 1.25rem 0rem 1.25rem",
                              }}
                            >
                              <Input
                                onPressEnter={() => doSearch()}
                                onSearch={() => doSearch()}
                                onBlur={() => doSearch()}
                                size="large"
                                placeholder="Search here"
                                loading={isLoading}
                                prefix={
                                  <SearchOutlined
                                    style={{ color: "#D3D6E1" }}
                                  />
                                }
                                ref={searchRef}
                                defaultValue={searchTxt}
                                style={{
                                  width: "100%",
                                  height: "46px",
                                  fontWeight: "400",
                                  fontSize: "14px",
                                  borderRadius: "5px",
                                }}
                                className="catalogue_search_updated"
                                justify="space-between"
                              />
                            </Row>
                          </div>
                          {items.length > 0 ? (
                            items.map((item, key) => {
                              return (
                                <MobileRow
                                  key={key}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setModalVisible(true);
                                    // setStep(3);
                                  }}
                                  item={item}
                                  type={ITEM}
                                  symbol={currencySymbol || "$"}
                                />
                              );
                            })
                          ) : (
                            <Row className="catalogue_hidden-scroll-mobile ">
                              {catalogueCategories &&
                                catalogueCategories.map((item, key) => {
                                  return (
                                    <Col
                                      xs={24}
                                      sm={24}
                                      md={12}
                                      lg={12}
                                      xl={12}
                                      xxl={12}
                                      align={"center"}
                                    >
                                      <div
                                        className="imageText-mobile"
                                        style={{
                                          backgroundImage: `url(${item.images
                                            ? item.images[0].img_url
                                            : Images.Images?.PlaceHolder
                                            })`,
                                        }}
                                        onClick={() => {
                                          setSelectedCategory(item);
                                          setStep(2);
                                        }}
                                      >
                                        <div className="imageTextDiv1-mobile"></div>
                                        <div className="imageTextDiv2-mobile">
                                          <Text className="imageTextDivText-mobile">
                                            {item.name}
                                          </Text>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                })}
                            </Row>
                          )}
                        </>
                      )}

                      {step === 2 && (
                        <div>
                          <div
                            style={{
                              padding: "0 2rem 2rem 2rem",
                              width: "auto",
                              maxWidth: "100vw",
                            }}
                          >
                            <Row
                              style={{ paddingBottom: "2rem" }}
                              className="catalogue_body_updated"
                              align="middle"
                              gutter={[20]}
                            >
                              <Col align="center" xs={6} md={4}>
                                <img
                                  alt="Store"
                                  className="catalogue_company_logo_updated"
                                  src={
                                    bizDetails?.mainLogo
                                      ? `${bizDetails?.mainLogo
                                      }?t=${new Date().getTime()}`
                                      : Images.PlaceHolder
                                  }
                                  loading="lazy"
                                // src={`${bizDetails?.mainLogo}?t=${new Date().getTime()}`}
                                />
                              </Col>
                              <Col xs={15}>
                                <Text style={{ fontSize: "1.5rem" }}>
                                  {currentLocation}
                                  {step === 3
                                    ? `/ ${catalogueDetails?.name}`
                                    : null}
                                </Text>
                                <br />
                                <text style={{ color: "#8898aa" }}>
                                  {parseAddress(
                                    currentLocation?.parentLocation
                                  )}
                                </text>
                                <br />
                                {step !== 3 && (
                                  <text style={{ color: "black" }}>
                                    {catalogueDetails?.name}

                                    {step !== 1 && step !== 4
                                      ? ` / ${selectedCategory?.name}`
                                      : null}
                                  </text>
                                )}
                                <br />
                              </Col>
                            </Row>
                            <Input
                              prefix={<SearchOutlined />}
                              onPressEnter={() => doSearch()}
                              onSearch={() => doSearch()}
                              onBlur={() => doSearch()}
                              size="large"
                              loading={isLoading}
                              ref={searchRef}
                              defaultValue={searchTxt}
                              className="catalogue_search_updated"
                            />
                          </div>
                          {items.length == 0 &&
                            catalogueItemsList &&
                            catalogueItemsList.map((item, key) => {
                              if (
                                item.status === "inactive" ||
                                new Date(item.availableFrom).getTime() > now
                              ) {
                                return null;
                              }
                              return (
                                <MobileRow
                                  key={key}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setModalVisible(true);
                                    // setStep(3);
                                  }}
                                  item={item}
                                  type={ITEM}
                                  symbol={currencySymbol || "$"}
                                />
                              );
                            })}

                          {items.length > 0 &&
                            items.map((item, key) => {
                              return (
                                <MobileRow
                                  key={key}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setModalVisible(true);
                                    // setStep(3);
                                  }}
                                  item={item}
                                  type={ITEM}
                                  symbol={currencySymbol || "$"}
                                />
                              );
                            })}
                        </div>
                      )}

                      {step === 3 && (
                        <Row
                          class="catalogue_detail_layout"
                          align="middle"
                          justify="center"
                          style={{ maxWidth: "100%" }}
                        >
                          <Col
                            style={{ minHeight: isTab() ? "80vh" : "100vh" }}
                            align={isTab() ? "center" : "left"}
                            xs={24}
                            md={20}
                          >
                            <Row justify="center" gutter={[20]}>
                              <Col style={{ maxWidth: "100%" }} xs={24} md={12}>
                                {isTab() ? null : (
                                  <h2 className="catalogue__title">
                                    {`${selectedItem?.item.name}- ${currencySymbol || "$"
                                      }${Number(selectedItem?.price).toFixed(
                                        2
                                      )} / ${selectedItem?.item.unit}`}
                                  </h2>
                                )}
                                <img
                                  alt="Item"
                                  className="catalogue_detail_page"
                                  src={
                                    selectedItem?.item.images
                                      ? selectedItem?.item.images[0].img_url
                                      : Images.PlaceHolder
                                  }
                                />
                                {isTab() ? null : (
                                  <>
                                    <div
                                      style={{
                                        textAlign: "left",
                                        maxWidth: "90%",
                                      }}
                                    >
                                      {selectedItem?.item?.description && (
                                        <RichTextViewer
                                          content={` ${selectedItem?.item.description}`}
                                        />
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        marginTop: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Row
                                        style={{ width: "80%" }}
                                        justify="start"
                                        align="stretch"
                                        gutter={[20]}
                                      >
                                        <Col span={12}>
                                          <h5>Quantity</h5>
                                        </Col>
                                        <Col span={12}>
                                          <Row>
                                            <Button
                                              disabled={quantity <= 1}
                                              onClick={() =>
                                                setQuantity(
                                                  quantity -
                                                  (WholeUnits?.includes(
                                                    selectedItem?.item?.unit
                                                  )
                                                    ? 1
                                                    : 0.1)
                                                )
                                              }
                                              type="primary"
                                            >
                                              -
                                            </Button>

                                            <InputNumber
                                              onChange={(value) => {
                                                if (
                                                  WholeUnits?.includes(
                                                    selectedItem?.item?.unit
                                                  )
                                                ) {
                                                  setQuantity(
                                                    Number(value).toFixed(0)
                                                  );
                                                } else {
                                                  setQuantity(value);
                                                }
                                              }}
                                              value={quantity}
                                              min={1}
                                              style={{ maxWidth: "40%" }}
                                              step={
                                                WholeUnits?.includes(
                                                  selectedItem?.item?.unit
                                                )
                                                  ? 1
                                                  : 0.1
                                              }
                                              precision={2}
                                            />
                                            <Button
                                              onClick={() =>
                                                setQuantity(
                                                  quantity +
                                                  (WholeUnits?.includes(
                                                    selectedItem?.item?.unit
                                                  )
                                                    ? 1
                                                    : 0.1)
                                                )
                                              }
                                              type="primary"
                                            >
                                              +
                                            </Button>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <Row align="middle">
                                        <Col span={12}>
                                          <h5
                                            style={{ margin: "1.5rem 0" }}
                                          >{`Total: `}</h5>
                                        </Col>
                                        <Col span={12}>
                                          <h5 style={{ margin: "1rem 0" }}>{`${currencySymbol || "$"
                                            }${Number(total).toFixed(2)}`}</h5>
                                        </Col>
                                      </Row>
                                    </div>

                                    <AddToCart
                                      biz={bizDetails}
                                      style={{ width: "100%" }}
                                      addToCart={
                                        indvid ? addToCart : takeToLogin
                                      }
                                    ></AddToCart>
                                    {Object.keys(cart).length > 0 && (
                                      <Button
                                        style={{
                                          width: "100%",
                                          marginTop: "1rem",
                                          borderRadius: "5px",
                                        }}
                                        type="primary"
                                        size="large"
                                        onClick={() => {
                                          setStep(4);
                                        }}
                                        disabled={
                                          roleType === ROLE_SUPPORT ||
                                            roleType === ROLE_MANAGER ||
                                            isMasquerade
                                            ? true
                                            : false
                                        }
                                      >
                                        Checkout
                                      </Button>
                                    )}
                                  </>
                                )}
                              </Col>
                              {isTab() ? null : (
                                <Col align="end" xs={24} md={12}>
                                  <h2 className="catalogue__title">
                                    Select variations / addons
                                  </h2>
                                  <div className="catalogue_detail_options">
                                    <>
                                      <OptionRow
                                        unit={selectedItem?.item.unit}
                                        row={getItemAsModifier()}
                                      />
                                      {wholeModifiers?.map((row, key) => {
                                        return <OptionRow row={row} />;
                                      })}

                                      {addOnModifiers?.length > 0 && (
                                        <Checkbox.Group
                                          style={{ minWidth: "100%" }}
                                          onChange={handleAddOnModifier}
                                        >
                                          {addOnModifiers?.map((row, key) => {
                                            return <OptionRow row={row} />;
                                          })}
                                        </Checkbox.Group>
                                      )}
                                    </>
                                  </div>
                                  <h4 style={{ margin: "1rem 0" }}>
                                    Special Instructions
                                  </h4>
                                  <Input.TextArea
                                    onChange={(event) =>
                                      setInstr(event.target.value)
                                    }
                                    rows={4}
                                    style={{ margin: "1rem 0" }}
                                    placeholder="Instructions: spice level, order for, include, exclude"
                                    value={instr}
                                  />
                                </Col>
                              )}
                              <Divider />
                              <Col xs={22} md={0}>
                                <h2 className="catalogue__title">
                                  {`${selectedItem?.item.name}- ${currencySymbol || "$"
                                    }${selectedItem?.price} / ${selectedItem?.item.unit
                                    }`}
                                </h2>
                                <div style={{ textAlign: "justify" }}>
                                  {selectedItem?.item?.description && (
                                    <RichTextViewer
                                      content={` ${selectedItem?.item.description}`}
                                    />
                                  )}
                                </div>
                                {isTab() ? null : (
                                  <>
                                    <AddToCart
                                      biz={bizDetails}
                                      style={{ marginTop: "3 .5rem" }}
                                      addToCart={
                                        indvid ? addToCart : takeToLogin
                                      }
                                    ></AddToCart>
                                    {Object.keys(cart).length > 0 && (
                                      <Button
                                        style={{
                                          width: "100%",
                                          marginTop: "1rem",
                                          borderRadius: "5px",
                                        }}
                                        type="primary"
                                        size="large"
                                        onClick={() => {
                                          setStep(4);
                                        }}
                                        disabled={
                                          roleType === ROLE_SUPPORT ||
                                            roleType === ROLE_MANAGER ||
                                            isMasquerade
                                            ? true
                                            : false
                                        }
                                      >
                                        Checkout
                                      </Button>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>

                            <Col xs={24} md={0} style={{ margin: "1rem 0" }}>
                              <h2 className="catalogue__title">
                                Select variations / addons
                              </h2>
                              <div className="catalogue_detail_options">
                                <>
                                  <OptionRow
                                    unit={selectedItem?.item.unit}
                                    row={getItemAsModifier()}
                                  />
                                  {wholeModifiers?.map((row, key) => {
                                    return <OptionRow row={row} />;
                                  })}

                                  {addOnModifiers?.length > 0 && (
                                    <Checkbox.Group
                                      onChange={handleAddOnModifier}
                                    >
                                      {addOnModifiers?.map((row, key) => {
                                        return <OptionRow row={row} />;
                                      })}
                                    </Checkbox.Group>
                                  )}
                                </>
                              </div>
                              <Input
                                onChange={(event) =>
                                  setInstr(event.target.value)
                                }
                                style={{ margin: "1rem 0" }}
                                placeholder="Instructions: spice level, order for, include, exclude"
                                value={instr}
                              />
                            </Col>
                          </Col>
                        </Row>
                      )}
                      {step === 3 && checkoutCartView && (
                        <Row
                          className="catalogue_detail_page_cart_card"
                          align="middle"
                          justify="center"
                          style={{ position: "relative" }}
                        >
                          <Col xs={24}>
                            <Row justify="end" align="top">
                              <Col align="right" justify="top">
                                <i
                                  onClick={() => setCheckoutCartView(false)}
                                  className="fa fa-close"
                                  style={{
                                    color: Theme.grey,
                                    fontSize: "3.5rem",
                                    position: "absolute",
                                    top: -50,
                                    right: 2,
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row
                              style={{ width: "100%", marginBottom: "2rem" }}
                              align="middle"
                              justify="space-between"
                            >
                              <Col span={10}>
                                <h3>{`Total: ${currencySymbol || "$"}${Number(
                                  total
                                ).toFixed(2)}`}</h3>
                              </Col>
                              <Col span={12} align="end">
                                <Row>
                                  <Button
                                    disabled={quantity <= 1}
                                    onClick={() =>
                                      setQuantity(
                                        quantity -
                                        (WholeUnits?.includes(
                                          selectedItem?.item?.unit
                                        )
                                          ? 1
                                          : 0.1)
                                      )
                                    }
                                    type="primary"
                                  >
                                    -
                                  </Button>

                                  <InputNumber
                                    onChange={(value) => {
                                      if (
                                        WholeUnits?.includes(
                                          selectedItem?.item?.unit
                                        )
                                      ) {
                                        setQuantity(Number(value).toFixed(0));
                                      } else {
                                        setQuantity(value);
                                      }
                                    }}
                                    value={quantity}
                                    min={1}
                                    style={{ maxWidth: "40%" }}
                                    step={
                                      WholeUnits?.includes(
                                        selectedItem?.item?.unit
                                      )
                                        ? 1
                                        : 0.1
                                    }
                                    precision={2}
                                  />

                                  <Button
                                    onClick={() =>
                                      setQuantity(
                                        quantity +
                                        (WholeUnits?.includes(
                                          selectedItem?.item?.unit
                                        )
                                          ? 1
                                          : 0.1)
                                      )
                                    }
                                    type="primary"
                                  >
                                    +
                                  </Button>
                                </Row>
                              </Col>
                            </Row>
                            <Row align="middle" justify="center">
                              <AddToCart
                                biz={bizDetails}
                                style={{ width: "100%" }}
                                addToCart={indvid ? addToCart : takeToLogin}
                              ></AddToCart>
                              {Object.keys(cart).length > 0 && (
                                <Button
                                  style={{
                                    width: "100%",
                                    marginTop: "1rem",
                                    borderRadius: "5px",
                                  }}
                                  type="primary"
                                  size="large"
                                  onClick={() => {
                                    setStep(4);
                                  }}
                                  disabled={
                                    roleType === ROLE_SUPPORT ||
                                      roleType === ROLE_MANAGER ||
                                      isMasquerade
                                      ? true
                                      : false
                                  }
                                >
                                  Checkout
                                </Button>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </div>
          )}
        </>
      )}
      {step !== 1 && (
        <Row
          align="middle"
          justify="center"
          style={{
            paddingLeft: "4rem",
            paddingRight: "3rem",
          }}
        ></Row>
      )}

      <div className="catalogue_holder">
        {isLoading ? (
          <SkeletonLoader />
        ) : (
          <>
            {step === 2 && (
              <>
                {!isTab() ? (
                  <div style={{ paddingTop: "1.5rem", borderRadius: "10px" }}>
                    <div
                      className="cat_head"
                      style={{
                        backgroundImage: `url(${catalogueinfo?.images[0]?.img_url})`,
                        borderRadius: "10px",
                      }}
                    >
                      <div
                        className="cat_head_overLay"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          display: "flex",
                          alignItems: "center",
                          position: "relative",
                          justifyContent: "center",
                        }}
                      >
                        <Space
                          style={{
                            position: "absolute",
                            right: "20px",
                            top: "20px",
                          }}
                        >
                          <Col style={{ height: "10vh" }}>
                            {/* {biz && bizLikes[bizID] && (
                                <> */}
                            {/* <div className="catalogue_img-icons-step2">
                                <img src={Heart} alt="heart" />
                              </div> */}
                            {liked && liked["isDeleted"] === false && (
                              <div className="catalogue_img-icons">
                                <AiFillHeart
                                  color={"#fff"}
                                  style={{
                                    width: "2.5rem",
                                    height: "2.5rem",
                                    paddingRight: ".4rem",
                                  }}
                                  onClick={() => {
                                    doLike(catalogueinfo.objid, liked);
                                  }}
                                />
                                <Text
                                  style={{
                                    fontSize: "1.125rem",
                                    color: "white",
                                  }}
                                >
                                  {bizLikes[bizID]}
                                </Text>
                              </div>
                            )}
                            {(!liked?.hasOwnProperty("likedId") ||
                              liked["isDeleted"] === true) && (
                                <div className="catalogue_img-icons-step2">
                                  <img
                                    src={Heart}
                                    alt="heart"
                                    style={{ paddingRight: ".4rem" }}
                                    onClick={() =>
                                      doLike(catalogueinfo.objid, liked)
                                    }
                                  />
                                  <Text
                                    style={{
                                      fontSize: "1.125rem",
                                      color: "white",
                                    }}
                                  >
                                    {bizLikes[bizID]}
                                  </Text>
                                </div>
                              )}
                            {/* </>
                              )} */}
                          </Col>

                          <Col style={{ height: "10vh" }}>
                            <div className="catalogue_img-share-icons">
                              <img
                                src={copyShare}
                                alt="share"
                                onClick={copyTextToClipboard}
                              />
                            </div>
                          </Col>
                        </Space>
                        <Row
                          style={{
                            paddingTop: "1.5rem",
                          }}
                        >
                          {/* <Col span={20} style={{ height: "10vh" }}></Col> */}

                          <Col
                            span={24}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "40vh",
                            }}
                          >
                            <div style={{ lineHeight: "0px" }}>
                              <Text
                                style={{
                                  fontSize: "1.875rem",
                                  color: "white",
                                  fontWeight: "400px",
                                  alignContent: "center",
                                  lineHeight: "150%",
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingBottom: "8px",
                                }}
                              >
                                {selectedCategory?.name}
                                {step === 3
                                  ? `/ ${catalogueDetails?.name}`
                                  : null}
                              </Text>

                              <br />
                              {currentLocation?.parentLocation && (
                                <text style={{ color: "#8898aa" }}>
                                  {parseAddress(
                                    currentLocation?.parentLocation
                                  )}
                                </text>
                              )}
                              <br />
                              {step !== 3 && (
                                <Button
                                  className="catalogue_btn-icons"
                                  style={{
                                    width: "100%",
                                    borderRadius: "10px",
                                    padding: "10px 20px",
                                    height: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                    border: "none",
                                  }}
                                  size="large"
                                  icon={
                                    <img
                                      alt="store"
                                      src={store_blue}
                                      style={{ paddingRight: "12px" }}
                                    />
                                  }
                                >
                                  <text
                                    onClick={() => setStep(1)}
                                    style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "400px",
                                      // marginBottom: "0.5rem",
                                      // paddingRight: "0.4rem",
                                      color: "#4E5C87",
                                    }}
                                  >
                                    <span style={{ color: "#257CDB" }}>
                                      {catalogueDetails?.name}
                                    </span>
                                    {step !== 1 && step !== 4
                                      ? `  /  ${selectedCategory?.name}`
                                      : null}
                                  </text>
                                </Button>
                              )}
                              <br />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>

                    <div
                      className="catalogue_holder"
                      style={{
                        borderRadius: "10px",
                        marginLeft: "0rem",
                        width: "100%",
                        height: "auto",
                        border: "1px solid rgba(0, 0, 0, 0.1)",
                        marginTop: "1.5rem",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "2rem",
                          width: "100%",
                        }}
                      >
                        <Row
                          style={{
                            color: "#223369",
                            fontSize: "1.25rem",
                            fontWeight: "500",
                            padding: "1.25rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Col span={12}>
                            Order at{" "}
                            {catalogueDetail?.biz?.bizName ||
                              currentLocation ||
                              bizName}
                          </Col>
                          <Col
                            span={12}
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <img src={dropdown_icon} slt="dropdown" />
                          </Col>
                        </Row>
                        <div
                          style={{
                            border: "0.0625rem solid #D3D6E1",
                            width: "100%",
                          }}
                        />
                        <Row
                          style={{
                            padding: "1.25rem 1.25rem 0rem 1.25rem",
                          }}
                        >
                          <Input
                            onPressEnter={() => doSearch()}
                            onSearch={() => doSearch()}
                            onBlur={() => doSearch()}
                            size="large"
                            placeholder="Search here"
                            loading={isLoading}
                            prefix={
                              <SearchOutlined style={{ color: "#D3D6E1" }} />
                            }
                            ref={searchRef}
                            defaultValue={searchTxt}
                            style={{
                              width: "100%",
                              height: "2.875rem",
                              fontWeight: "400",
                              fontSize: "1rem",
                              borderRadius: "5px",
                            }}
                            className="catalogue_search_updated"
                            justify="space-between"
                          />
                        </Row>
                        <Row style={{ padding: "1.25rem" }}>
                          {items.length == 0 &&
                            catalogueItemsList.map((item, key) => {
                              let listItem = "ITEM" === ITEM ? item.item : item;

                              if (
                                item.status === "inactive" ||
                                new Date(item.availableFrom).getTime() > now
                              ) {
                                return null;
                              }
                              return (
                                <Col
                                  xs={24}
                                  sm={24}
                                  md={12}
                                  lg={8}
                                  xl={8}
                                  xxl={8}
                                  align={"center"}
                                >
                                  <div
                                    className="imageText-step2"
                                    style={{
                                      backgroundImage: `url(${listItem.images
                                        ? listItem.images[0].img_url
                                        : Images?.Images?.PlaceHolder
                                        })`,
                                    }}
                                  // onClick={() => {
                                  //   setSelectedCategory(item);
                                  //   setStep(2);
                                  // }}
                                  >
                                    <div className="imageTextDiv1-step2"></div>

                                    <div className="imageTextDiv2-step2">
                                      <text className="imageTextDivText-step2">
                                        {listItem?.name}
                                        <br />

                                        {item?.price
                                          ? `${currencySymbol || "$"} ${Number(
                                            item?.price
                                          ).toFixed(2)}`
                                          : "$0"}
                                      </text>
                                      <Row>
                                        <Col span={20}>
                                          <div>
                                            <Button
                                              className="btn-step2"
                                              type="primary"
                                              onClick={() => {
                                                setlater(false)
                                                setSelectedItem(item);
                                                setModalVisible(true);
                                                setItemId(item.objid)
                                                console.log("item.objid", item.objid);
                                                item.qty = 1
                                                // addObject(item)
                                                // setStep(3);
                                                setSelectedItem(item);
                                                console.log("item1>>>", item);
                                              }}
                                            >
                                              Add
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              );
                            })}
                        </Row>
                        {items.length == 0 &&
                          items.map((item, key) => {
                            return (
                              <Col>
                                <MobileRow
                                  key={key}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    setModalVisible(true);
                                    // setStep(3);
                                  }}
                                  item={item}
                                  type={ITEM}
                                  symbol={currencySymbol || "$"}
                                />
                              </Col>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  // mobile screen step2
                  <div>
                    <Col>
                      <div
                        className="cat_head-step2-mobile"
                        style={{
                          backgroundImage: `url(${catalogueinfo?.images[0].img_url})`,

                          marginBottom: "1.5rem",
                        }}
                      >
                        <div className="cat_head_overLay-step2-mobile">
                          <Row style={{ paddingTop: "1.5rem" }}>
                            <div className="catalogue_img-icons-div-step2-mobile">
                              <Col style={{ paddingRight: "1.25rem" }}>
                                {/* {biz && bizLikes[bizID] && ( */}
                                {/* <div className="catalogue_img-icons-step2-mobile">
                                  <img src={Heart} alt="heart" />
                                </div> */}
                                {liked && liked["isDeleted"] === false && (
                                  <div className="catalogue_img-icons">
                                    <AiFillHeart
                                      color={"#fff"}
                                      style={{
                                        width: "2.5rem",
                                        height: "2.5rem",
                                        paddingRight: ".4rem",
                                      }}
                                      onClick={() => {
                                        doLike(catalogueinfo.objid, liked);
                                      }}
                                    />
                                    <Text
                                      style={{
                                        fontSize: "1.125rem",
                                        color: "white",
                                      }}
                                    >
                                      {bizLikes[bizID]}
                                    </Text>
                                  </div>
                                )}
                                {(!liked?.hasOwnProperty("likedId") ||
                                  liked["isDeleted"] === true) && (
                                    <div className="catalogue_img-icons-step2-mobile">
                                      <img
                                        src={Heart}
                                        alt="heart"
                                        style={{ paddingRight: ".4rem" }}
                                        onClick={() =>
                                          doLike(catalogueinfo.objid, liked)
                                        }
                                      />
                                      <Text
                                        style={{
                                          fontSize: "1.125rem",
                                          color: "white",
                                        }}
                                      >
                                        {bizLikes[bizID]}
                                      </Text>
                                    </div>
                                  )}
                                {/* )} */}
                              </Col>
                              <Col>
                                <div className="catalogue_img-icons-step2-mobile">
                                  <img src={Share} alt="share" />
                                </div>
                              </Col>
                            </div>

                            <Col
                              span={24}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "48vh",
                              }}
                            >
                              <div style={{ lineHeight: "0px" }}>
                                <Text
                                  style={{
                                    fontSize: "24px",
                                    color: "white",
                                    fontWeight: "400px",
                                    alignContent: "center",
                                    lineHeight: "150%",
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingBottom: "8px",
                                  }}
                                >
                                  {currentLocation}
                                  {step === 3
                                    ? `/ ${catalogueDetails?.name}`
                                    : null}
                                </Text>

                                <br />
                                {currentLocation?.parentLocation && (
                                  <text style={{ color: "#8898aa" }}>
                                    {parseAddress(
                                      currentLocation?.parentLocation
                                    )}
                                  </text>
                                )}
                                <br />
                                {step !== 3 && (
                                  <Button
                                    className="catalogue_btn-icons"
                                    style={{
                                      // width: "100%",
                                      display: "flex",
                                      justifyContent: "center",
                                      borderRadius: "10px",
                                      padding: "0px 20px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    size="large"
                                    icon={
                                      <img
                                        alt="storeBlue"
                                        src={store_blue}
                                        style={{
                                          marginRight: ".5rem",
                                          // marginBottom: ".5rem",
                                        }}
                                      />
                                    }
                                  >
                                    <text
                                      onClick={() => setStep(1)}
                                      style={{
                                        fontSize: "1.5rem",
                                        fontWeight: "400px",
                                        color: "#4E5C87",
                                        // marginTop: ".2rem",
                                      }}
                                    >
                                      <span style={{ color: "#257CDB" }}>
                                        {catalogueDetails?.name}
                                      </span>
                                      {step !== 1 && step !== 4
                                        ? `  /  ${selectedCategory?.name}`
                                        : null}
                                    </text>
                                  </Button>
                                )}
                                <br />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div
                        className="catalogue_holder"
                        style={{
                          height: "auto",
                          margin: "16px",
                          borderRadius: "10px",
                          border: "1px solid rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {isLoading ? (
                          <SkeletonLoader />
                        ) : (
                          <>
                            {step === 2 && (
                              <>
                                <div
                                  style={{
                                    marginBottom: "2rem",
                                    width: "100%",
                                  }}
                                >
                                  <Row
                                    style={{
                                      color: "#223369",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      padding: "1.25rem",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Col span={12}>
                                      Order at{" "}
                                      {bizName ||
                                        catalogueDetails?.biz?.bizName ||
                                        currentLocation}
                                    </Col>
                                    <Col
                                      span={12}
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <img src={dropdown_icon} alt="dropdown" />
                                    </Col>
                                  </Row>

                                  <div
                                    style={{
                                      border: "0.0625rem solid #D3D6E1",
                                      width: "100%",
                                    }}
                                  />
                                  <Row
                                    style={{
                                      padding: "1.25rem 1.25rem 0rem 1.25rem",
                                    }}
                                  >
                                    <Input
                                      onPressEnter={() => doSearch()}
                                      onSearch={() => doSearch()}
                                      onBlur={() => doSearch()}
                                      size="large"
                                      placeholder="Search here"
                                      loading={isLoading}
                                      prefix={
                                        <SearchOutlined
                                          style={{ color: "#D3D6E1" }}
                                        />
                                      }
                                      ref={searchRef}
                                      defaultValue={searchTxt}
                                      style={{
                                        width: "100%",
                                        height: "46px",
                                        fontWeight: "400",
                                        fontSize: "14px",
                                        borderRadius: "5px",
                                      }}
                                      className="catalogue_search_updated"
                                      justify="space-between"
                                    />
                                  </Row>
                                </div>

                                <Row className="catalogue_hidden-scroll-mobile ">
                                  {items.length == 0 &&
                                    catalogueItemsList.map((item, key) => {
                                      let listItem =
                                        "ITEM" === ITEM ? item.item : item;

                                      if (
                                        item.status === "inactive" ||
                                        new Date(item.availableFrom).getTime() >
                                        now
                                      ) {
                                        return null;
                                      }
                                      return (
                                        <Col
                                          xs={24}
                                          sm={24}
                                          md={12}
                                          lg={12}
                                          xl={12}
                                          xxl={12}
                                          align={"center"}
                                        >
                                          <div
                                            className="imageText-step2-mobile"
                                            style={{
                                              backgroundImage: `url(${listItem.images
                                                ? listItem.images[0].img_url
                                                : Images?.Images?.PlaceHolder
                                                })`,
                                            }}
                                          >
                                            <div className="imageTextDiv1-step2-mobile"></div>
                                            <div className="imageTextDiv2-step2-mobile">
                                              <text className="imageTextDivText-step2-mobile">
                                                {listItem?.name}
                                                <br />

                                                {item?.price
                                                  ? `${currencySymbol || "$"
                                                  } ${Number(
                                                    item?.price
                                                  ).toFixed(2)}`
                                                  : "null"}
                                              </text>
                                              <Row>
                                                <Col span={20}>
                                                  <div>
                                                    <Button
                                                      className="btn-step2"
                                                      type="primary"
                                                      onClick={() => {
                                                        setSelectedItem(item);
                                                        setModalVisible(true);
                                                        console.log("item2>>>", item);

                                                        // setStep(3);
                                                      }}
                                                    >
                                                      Add
                                                    </Button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </div>
                                          </div>
                                        </Col>
                                      );
                                    })}
                                </Row>
                                {items.length == 0 &&
                                  items.map((item, key) => {
                                    return (
                                      <Col>
                                        <MobileRow
                                          key={key}
                                          onClick={() => {
                                            setSelectedItem(item);
                                            setModalVisible(true);
                                            // setStep(3);
                                          }}
                                          item={item}
                                          type={ITEM}
                                          symbol={currencySymbol || "$"}
                                        />
                                      </Col>
                                    );
                                  })}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Col>
                  </div>
                )}
              </>
            )}

            {step === 4 && (
              <>
                {!isTab() ? (
                  <div
                    className="catalogue_holder"
                    style={{
                      borderRadius: "10px",
                      marginLeft: "0rem",
                      width: "100%",
                      height: "auto",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      marginTop: "1.5rem",
                    }}
                  >
                    {grandTotal === 0 && (
                      <div
                        style={{
                          minHeight: "30vh",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",

                          minWidth: "100%",
                        }}
                        align="center"
                        span={24}
                      >
                        <div>
                          <FiShoppingCart
                            style={{
                              fontSize: "4rem",
                              color: Theme.blue,
                              marginRight: "1rem",
                            }}
                          />
                        </div>
                        <h3 style={{ letterSpacing: "2px" }}>Cart is empty.</h3>
                        <br />
                      </div>
                    )}
                    {grandTotal > 0 && (
                      <>
                        <div>
                          <Row className="catalogue_checkout-step3-bussName">
                            <Col span={1} />
                            <Col>
                              Order at{" "}
                              {catalogueDetail?.biz?.bizName ||
                                currentLocation ||
                                bizName}
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                    <Divider
                      style={{
                        color: "#000000",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                    />

                    {grandTotal > 0 && (
                      <>
                        <Row
                          justify={"center"}
                          style={{ marginBottom: "2rem" }}
                        >
                          <Col>
                            <h5>Overview Order</h5>
                          </Col>
                        </Row>

                        <Row className="catalogue_checkout-step3-heading">
                          <Col span={1}></Col>
                          <Col span={10}>
                            <h5>Product</h5>
                          </Col>
                          {/* <Col ></Col> */}

                          <Col span={10}>Quantity</Col>

                          <Col span={2}>Price</Col>
                          <Col span={1}></Col>
                        </Row>

                        {Object.values(cartWithTax).map((item) => {
                          return <RenderItem key={item.key} item={item} />;
                        })}

                        <Divider
                          style={{
                            color: "#000000",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                        />
                      </>
                    )}

                    {grandTotal > 0 && (
                      <>
                        <Row style={{ paddingBottom: "1rem" }}>
                          <Col span={1} />
                          <Col
                            span={19}
                            className="catalogue_step3-calculation"
                          >
                            Total Price
                          </Col>
                          <Col span={1} />
                          <Col span={1} className="catalogue_step3-amountCalc">
                            {currencySymbol}
                            {grandTotal.toFixed(2)}
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "1rem" }}>
                          <Col span={1} />
                          <Col
                            span={19}
                            className="catalogue_step3-calculation"
                          >
                            Tax
                          </Col>
                          <Col span={1} />
                          <Col span={1} className="catalogue_step3-amountCalc">
                            {currencySymbol}
                            {totalTax ? totalTax.toFixed(2) : 0}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={1} />
                          <Col span={3} className="catalogue_step3-calculation">
                            Add Tip :
                          </Col>
                          <Col span={16} className="tipCol">
                            <Radio.Group
                              className="calatlogue_ste3-buttonTip"
                              options={buttons}
                              onChange={onPressTip}
                              value={selectedIndex}
                              optionType="button"
                              buttonStyle="solid"
                            />
                            <Input
                              style={{ width: "20%" }}
                              placeholder="$"
                              onChange={handleChangeTip}
                            />
                          </Col>
                          <Col span={1} />
                          <Col span={1} className="catalogue_step3-amountCalc">
                            {currencySymbol}
                            {tip}
                          </Col>
                        </Row>
                        <Divider
                          style={{
                            color: "#000000",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                        />

                        <Row>
                          <Col span={1} />
                          <Col
                            span={19}
                            className="catalogue_step3-calculation"
                          >
                            Total Payable Amount
                          </Col>
                          <Col span={1} style={{ alignContent: "end" }} />
                          <Col span={1} className="catalogue_step3-amountCalc">
                            {currencySymbol}
                            {(
                              (totalTax ? totalTax : 0) +
                              Number(tip) +
                              grandTotal
                            ).toFixed(2)}
                          </Col>
                        </Row>
                        <Divider
                          style={{
                            color: "#000000",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                        />
                      </>
                    )}

                    {grandTotal > 0 && (
                      <>
                        <Row style={{ marginBottom: "24px" }}>
                          <Col span={1} />
                          <Col>
                            <div
                              className="catalogue_step3-calculation"
                              style={{
                                marginBottom: "2rem",
                                cursor: "pointer",
                              }}
                            >
                              Delivery Date & Time :
                            </div>

                            <Radio.Group
                              value={delDay}
                              onChange={(event) => {
                                setDelDay(event.target.value);
                              }}
                            >
                              <Radio
                                value={1}
                                className="catalogue_step3-deleviryDay"
                              >
                                Today
                              </Radio>
                              <Radio value={2}>Tomorrow</Radio>
                            </Radio.Group>
                          </Col>
                          <Col
                            className="catalogue_step3-deleviryTime"
                            style={{ paddingTop: "3rem" }}
                          >
                            {(delDay == 1 && slots1.length > 1) ||
                              (delDay == 2 && slots2.length > 1) ? (
                              <Row>
                                <Col>
                                  <Select
                                    defaultValue={delTime}
                                    onChange={(item) => setDelTime(item)}
                                  >
                                    {delDay === 1
                                      ? slots1.map((item, key) => {
                                        return (
                                          <Option value={item.value}>
                                            {item.label}
                                          </Option>
                                        );
                                      })
                                      : slots2.map((item, key) => {
                                        return (
                                          <Option value={item.value}>
                                            {item.label}
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                </Col>
                              </Row>
                            ) : (
                              <Row style={{ paddingTop: "1rem" }}>
                                <Text
                                  style={{
                                    color: "red",
                                    fontWeight: "bold",
                                  }}
                                >
                                  The store is closed for this delivery option{" "}
                                </Text>
                              </Row>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={1} />
                          <Col span={22}>
                            <Input
                              className="catalogue_step3-abc"
                              value={custMessage}
                              placeholder="Special Instruction (Optional)"
                              onChange={(event) =>
                                setCustMessage(event.target.value)
                              }
                            />
                          </Col>
                          <Col span={1} />
                        </Row>
                        <Row justify="center" align="middle">
                          <Col xs={24} md={10} align="center">
                            {biz &&
                              grandTotal > 0 &&
                              catalogueLocation &&
                              catalogueLocation?.biz?.bizid === bizid &&
                              fromBizAccount &&
                              buyerQrId !== qrid && (
                                <>
                                  <Button
                                    style={{
                                      width: "100%",
                                      marginTop: "1rem",
                                      borderRadius: "5px",
                                    }}
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                      startCheckout(true);
                                    }}
                                  >
                                    Send Invoice
                                  </Button>
                                </>
                              )}
                            {!showCard &&
                              grandTotal > 0 &&
                              (!biz ||
                                (biz &&
                                  catalogueLocation &&
                                  catalogueLocation?.biz?.bizid !== bizid)) && (
                                <>
                                  <Button
                                    className="catalogue_step3-payBtn"
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                      if (isPreview) {
                                        message.success(
                                          "User will be able to pay and proceed "
                                        );
                                      } else {
                                        startCheckout(false);
                                        setShowCard(true);
                                      }
                                    }}
                                  >
                                    Pay Now
                                  </Button>
                                </>
                              )}

                            {biz &&
                              grandTotal > 0 &&
                              catalogueLocation &&
                              catalogueLocation?.biz?.bizid === bizid &&
                              !fromBizAccount &&
                              buyerQrId !== qrid && (
                                <div
                                  style={{
                                    marginTop: "3rem",

                                    textAlign: "center",
                                  }}
                                >
                                  <h4 style={{ color: Theme.red }}>
                                    {" "}
                                    Placing orders in your own business is
                                    restricted.
                                  </h4>
                                </div>
                              )}
                          </Col>
                        </Row>
                      </>
                    )}
                    <div style={{ minHeight: "40vh" }}>
                      {showCard && (
                        <Modal
                          title="Payment Options"
                          width={isTab() ? "100%" : "60%"}
                          onCancel={() => setShowCard(false)}
                          visible={true}
                          footer={null}
                        >
                          <Divider />
                          <CardPay
                            total={(
                              (totalTax ? totalTax : 0) +
                              Number(tip) +
                              grandTotal
                            ).toFixed(2)}
                            params={{ channel: areAppointmentsAvailable ? "catalog_appointment" : "catalog", tip: tip }}
                            callback={(formData, invoiceId, additionalCheckbox, createdInvoice, paymentMethod) => {
                              showSuccess(props , createdInvoice , paymentMethod)}
                            }
                          />
                        </Modal>
                      )}
                    </div>
                  </div>
                ) : (
                  // mobile view for step3
                  <div
                    className="catalogue_holder"
                    style={{
                      borderRadius: "10px",
                      marginLeft: "0rem",
                      width: "100%",
                      height: "auto",
                      border: "1px solid rgba(0, 0, 0, 0.1)",
                      marginTop: "1.5rem",
                    }}
                  >
                    {grandTotal === 0 && (
                      <div
                        style={{
                          minHeight: "30vh",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",

                          minWidth: "100%",
                        }}
                        align="center"
                        span={24}
                      >
                        <div>
                          <FiShoppingCart
                            style={{
                              fontSize: "4rem",
                              color: Theme.blue,
                              marginRight: "1rem",
                            }}
                          />
                        </div>
                        <h3 style={{ letterSpacing: "2px" }}>Cart is empty.</h3>
                        <br />
                      </div>
                    )}
                    {grandTotal > 0 && (
                      <>
                        <div>
                          <Row className="catalogue_checkout-step3-bussName">
                            <Col span={1} />
                            <Col>
                              Order at{" "}
                              {bizName ||
                                catalogueDetails?.biz?.bizName ||
                                currentLocation}
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                    <Divider
                      style={{
                        color: "#000000",
                        border: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                    />
                    {grandTotal > 0 && (
                      <>
                        <Row
                          justify={"center"}
                          style={{ marginBottom: "2rem" }}
                        >
                          <Col className="catalogue_step3-calculation-mobile">
                            Overview Order
                          </Col>
                        </Row>

                        <Row className="catalogue_checkout-step3-heading-mobile">
                          <Col span={1}></Col>
                          <Col>Product</Col>
                          <Col span={7}></Col>

                          <Col span={8}>Quantity</Col>
                          <Col>Price</Col>
                        </Row>

                        {Object.values(cartWithTax).map((item) => {
                          return <RenderItem key={item.key} item={item} />;
                        })}

                        <Divider
                          style={{
                            color: "#000000",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                        />
                      </>
                    )}

                    {grandTotal > 0 && (
                      <>
                        <Row style={{ paddingBottom: "1rem" }}>
                          <Col span={1} />
                          <Col
                            span={19}
                            className="catalogue_step3-calculation-mobile"
                          >
                            Total Price
                          </Col>
                          {/* <Col span={1} /> */}
                          <Col
                            span={1}
                            className="catalogue_step3-amountCalc-mobile"
                          >
                            {currencySymbol}
                            {grandTotal.toFixed(2)}
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "1rem" }}>
                          <Col span={1} />
                          <Col
                            span={19}
                            className="catalogue_step3-calculation-mobile"
                          >
                            Tax
                          </Col>
                          {/* <Col span={1} /> */}
                          <Col
                            span={1}
                            className="catalogue_step3-amountCalc-mobile"
                          >
                            {currencySymbol}
                            {totalTax.toFixed(2)}
                          </Col>
                        </Row>
                        <Row>
                          <Col span={1} />
                          <Col
                            span={4}
                            className="catalogue_step3-calculation-mobile"
                          >
                            Add Tip :
                          </Col>
                          <Col span={15}>
                            <Radio.Group
                              className="catalogue_ste3-buttonTip-mobile"
                              options={buttons}
                              onChange={onPressTip}
                              value={selectedIndex}
                              optionType="button"
                              buttonStyle="solid"
                            />
                          </Col>

                          <Col
                            span={1}
                            className="catalogue_step3-amountCalc-mobile"
                          >
                            {currencySymbol}
                            {tip}
                          </Col>
                        </Row>
                        <Divider
                          style={{
                            color: "#000000",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                        />

                        <Row>
                          <Col span={1} />
                          <Col
                            span={19}
                            className="catalogue_step3-calculation-mobile"
                          >
                            Total Payable Amount
                          </Col>
                          <Col span={1} style={{ alignContent: "end" }} />
                          <Col
                            span={1}
                            className="catalogue_step3-amountCalc-mobile"
                          >
                            {currencySymbol}
                            {(totalTax + Number(tip) + grandTotal).toFixed(2)}
                          </Col>
                        </Row>
                        <Divider
                          style={{
                            color: "#000000",
                            border: "1px solid rgba(0, 0, 0, 0.2)",
                          }}
                        />
                      </>
                    )}

                    {grandTotal > 0 && (
                      <>
                        <Row>
                          <Col span={1} />
                          <Col span={23}>
                            <div
                              className="catalogue_step3-calculation-mobile"
                              style={{
                                marginBottom: "1.5rem",
                                cursor: "pointer",
                              }}
                            >
                              Delivery Date & Time
                            </div>

                            <Radio.Group
                              value={delDay}
                              onChange={(event) => {
                                setDelDay(event.target.value);
                              }}
                            >
                              <div
                                className="catalogue_step3-deleviryTime-mobile"
                                style={{ marginBottom: "1.5rem" }}
                              >
                                {(delDay == 1 && slots1.length > 1) ||
                                  (delDay == 2 && slots2.length > 1) ? (
                                  <Row>
                                    <Col>
                                      <Select
                                        defaultValue={delTime}
                                        onChange={(item) => setDelTime(item)}
                                        style={{
                                          width: "100%",

                                          cursor: "pointer",
                                        }}
                                      >
                                        {delDay === 1
                                          ? slots1.map((item, key) => {
                                            return (
                                              <Option value={item.value}>
                                                {item.label}
                                              </Option>
                                            );
                                          })
                                          : slots2.map((item, key) => {
                                            return (
                                              <Option value={item.value}>
                                                {item.label}
                                              </Option>
                                            );
                                          })}
                                      </Select>
                                    </Col>
                                  </Row>
                                ) : (
                                  <Row
                                  // style={{ paddingTop: "1rem" }}
                                  >
                                    <Text
                                      style={{
                                        color: "red",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      The store is closed for this delivery
                                      option{" "}
                                    </Text>
                                  </Row>
                                )}
                              </div>
                              <Radio
                                value={1}
                                className="catalogue_step3-deleviryDay-mobile"
                              >
                                Today
                              </Radio>
                              <Radio value={2}>Tomorrow</Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "0.5rem", width: "100%" }}>
                          <Col span={1} />
                          <Col>
                            <Input
                              className="catalogue_step3-instruction-mobile"
                              value={custMessage}
                              placeholder="Special Instruction (Optional)"
                              onChange={(event) =>
                                setCustMessage(event.target.value)
                              }
                            />
                          </Col>
                          {/* <Col span={1} /> */}
                        </Row>

                        <Row justify="center" align="middle">
                          <Col xs={24} md={10} align="center">
                            {biz &&
                              grandTotal > 0 &&
                              catalogueLocation &&
                              catalogueLocation?.biz?.bizid === bizid &&
                              fromBizAccount &&
                              buyerQrId !== qrid && (
                                <>
                                  <Button
                                    style={{
                                      width: "100%",
                                      marginTop: "1rem",
                                      borderRadius: "5px",
                                    }}
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                      startCheckout(true);
                                    }}
                                  >
                                    Send Invoice
                                  </Button>
                                </>
                              )}
                            {!showCard &&
                              grandTotal > 0 &&
                              (!biz ||
                                (biz &&
                                  catalogueLocation &&
                                  catalogueLocation?.biz?.bizid !== bizid)) && (
                                <>
                                  <Button
                                    className="catalogue_step3-payBtn-mobile"
                                    type="primary"
                                    size="large"
                                    onClick={() => {
                                      if (isPreview) {
                                        message.success(
                                          "User will be able to pay and proceed "
                                        );
                                      } else {
                                        startCheckout(false);
                                        setShowCard(true);
                                      }
                                    }}
                                  >
                                    Pay Now
                                  </Button>
                                </>
                              )}

                            {biz &&
                              grandTotal > 0 &&
                              catalogueLocation &&
                              catalogueLocation?.biz?.bizid === bizid &&
                              !fromBizAccount &&
                              buyerQrId !== qrid && (
                                <div
                                  style={{
                                    marginTop: "3rem",

                                    textAlign: "center",
                                  }}
                                >
                                  <h4 style={{ color: Theme.red }}>
                                    {" "}
                                    Placing orders in your own business is
                                    restricted.
                                  </h4>
                                </div>
                              )}
                          </Col>
                        </Row>
                      </>
                    )}

                    <div style={{ minHeight: "40vh" }}>
                      {showCard && (
                        <Modal
                          title="Payment Options"
                          width={isTab() ? "100%" : "60%"}
                          onCancel={() => setShowCard(false)}
                          visible={true}
                          footer={null}
                        >
                          <Divider />
                          <CardPay
                            total={(
                              totalTax +
                              Number(tip) +
                              grandTotal
                            ).toFixed(2)}
                            params={{ channel: areAppointmentsAvailable ? "catalog_appointment" : "catalog", tip: tip }}
                            callback={() => {
                              showSuccess();
                            }}
                          />
                        </Modal>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>

      {/* <ViewStoreModal
        title="Add to Cart"
        closeIcon={closeIcon}
        onCancel={() => {
          setModalVisible(false);
          setQuantity(1);
        }}
        visible={modalVisible}
        heading={
          <div style={{ width: "100%", height: "15rem" }}>
            <img
              alt="fyndr"
              src={
                selectedItem?.item?.images
                  ? selectedItem?.item?.images[0].img_url
                  : Images.PlaceHolder
              }
              className="viewStoreModal_image"
            ></img>
          </div>
        }
        footer={
          <Row justify={"space-between"} style={{ margin: "0.75rem 0.5rem" }}>
            <Col span={8} xs={10}>
              {Object.keys(cart).length > 0 && (
                <Button
                  className="viewStoreModal_btn-footer"
                  type="primary"
                  style={{
                    width: "100%",
                    fontSize: "1.2rem",
                    height: "2.75rem",
                    padding: "0",
                  }}
                  size="large"
                  onClick={() => {
                    setStep(4);
                    setModalVisible(false);
                  }}
                >
                  Checkout
                </Button>
              )}
            </Col>
            <Col span={8} xs={10} xxl={12}>
              <AddToCart
                biz={bizDetails}
                style={{
                  backgroundColor: "white",
                  color: "#3F9AF7",
                  width: "151px",
                  fontSize: "1.2rem",
                  height: "2.75rem",
                  padding: "0",
                  border: "1px solid #257CDB"
                }}
                addToCart={indvid ? addToCart : takeToLogin}
              />
            </Col>
          </Row>
        }
        modalText={
          <>
            <Row>
              <Text style={{ fontSize: "1.25rem", paddingBottom: "0.75rem" }}>
                {" "}
                Select variations / addons
              </Text>
            </Row>
            <Row>
              <Text style={{ fontSize: "1rem", fontWeight: "400" }}>
                {`${selectedItem?.item?.name}- ${currencySymbol || "$"}${Number(
                  selectedItem?.price
                ).toFixed(2)} / ${selectedItem?.item?.unit}`}{" "}
                {`${currencySymbol || "$"}${Number(selectedItem?.price).toFixed(
                  2
                )}`}
                {selectedItem?.item?.description && (
                  <p>{`${selectedItem?.item?.description}`}</p>
                )}
              </Text>
            </Row>
          </>
        }
        addon={
          <div className="catalogue_detail_options_modal">
            <Row justify="space-between">
              <Col span={24}>
                <OptionRowModal
                  unit={selectedItem?.item?.unit}
                  row={getItemAsModifier()}
                />
                {wholeModifiers?.map((row, key) => {
                  return <OptionRowModal row={row} />;
                })}

                {addOnModifiers?.length > 0 && (
                  <Checkbox.Group
                    style={{ minWidth: "100%" }}
                    onChange={handleAddOnModifier}
                  >
                    {addOnModifiers?.map((row, key) => {
                      return <OptionRowModal row={row} />;
                    })}
                  </Checkbox.Group>
                )}
              </Col>
            </Row>
          </div>
        }
        InputBox={
          <Input.TextArea
            onChange={(event) => setInstr(event.target.value)}
            // rows={3}
            style={{
              borderRadius: "0.3125rem",
              height: "5.3125rem",
              fontSize: "0.875rem",
            }}
            placeholder="Instructions: spice level, order for, include, exclude"
            value={instr}
          />
        }
        buttonincrdecr={
          <Row justify={"space-between"}>
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Row style={{ fontSize: "1.25rem", fontWeight: "400" }}>
                  <Text>{`Total: `}</Text>
                  <Text>{`${currencySymbol || "$"}${Number(total).toFixed(
                    2
                  )}`}</Text>
                </Row>

                <Row>
                  <Col>
                    <Row>
                      <Button
                        // size="large"
                        style={{
                          borderRadius: " 0.3125rem 0rem 0rem 0.3125rem",
                          backgroundColor: quantity <= 1 && "#F2F2F2",
                          height: "2.75rem",
                          padding: "0",
                        }}
                        icon={
                          <img
                            src={minus}
                            alt="plus"
                            style={{ marginBottom: ".2rem" }}
                          />
                        }
                        disabled={quantity <= 1}
                        onClick={() =>
                          setQuantity(
                            quantity -
                            (WholeUnits?.includes(selectedItem?.item?.unit)
                              ? 1
                              : 0.1)
                          )
                        }
                        type="primary"
                      />

                      <InputNumber
                        type="number"
                        className="viewStoreModal-input"
                        controls={false}
                        // size="large"
                        style={{ height: "2.75rem", width: "6rem" }}
                        onChange={(value) => {
                          if (WholeUnits?.includes(selectedItem?.item?.unit)) {
                            setQuantity(value);
                          } else {
                            setQuantity(value);
                          }
                        }}
                        value={quantity}
                        min={1}
                        step={
                          WholeUnits?.includes(selectedItem?.item?.unit)
                            ? 1
                            : 0.1
                        }
                        precision={2}
                      />
                      <Button
                        // size="large"
                        style={{
                          borderRadius: "0rem 0.3125rem 0.3125rem 0rem",
                          height: "2.75rem",
                          padding: "0",
                        }}
                        icon={
                          <img
                            src={plus}
                            alt="plus"
                            style={{ marginBottom: ".2rem" }}
                          />
                        }
                        onClick={() =>
                          setQuantity(
                            quantity +
                            (WholeUnits?.includes(selectedItem?.item?.unit)
                              ? 1
                              : 0.1)
                          )
                        }
                        type="primary"
                      />
                    </Row>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        }
      /> */}
      <CustomizedModal
        modalWidth={"550px"}
        visible={modalVisible}
        title="Add to Cart"
        onCancel={() => {
          setModalVisible(false);
          setQuantity(1);
          setSlider(1)
        }}
        // footer={isBookingEnabled && !later ? null : true}
        footer={isBookingEnabled && later !== true ? null : true}
        btn1Type="primary"
        btn1Style={{
          width: "108px",
          color: "#fff",
          border: "1px solid #257CDB",
          backgroundColor: "#257CDB",
          align: "middle",
          display: Object.keys(cart).length > 0 ? "block" : "none",
        }}
        cart={Object.keys(cart).length}
        onBtn1Click={() => {
          setlater(false);
          setStep(4);
          setModalVisible(false);
        }}
        btn1="Checkout"
        btn2="Add to Cart"
        btn1Disabled={
          roleType === ROLE_SUPPORT || roleType === ROLE_MANAGER || isMasquerade
            ? true
            : false
        }
        btn2Style={{
          backgroundColor: "white",
          color: "#257CDB",
          width: "151px",
          border: "1px solid #257CDB",
          padding: "0px",
        }}
        onBtn2Click={() => (indvid ? addToCart() : takeToLogin())}
        btn2Icon={
          <img src={cartImg} alt="cart" style={{ marginRight: "8px" }} />
        }
        btn2Disabled={bizDetails !== undefined && !bizDetails.merchantId}
        content={
          <>
            <div style={{ padding: "24px 24px 0px 24px" }}>
              <div style={{ marginBottom: "16px" }}>
                <img
                  alt="fyndr"
                  src={
                    selectedItem?.item?.images
                      ? selectedItem?.item?.images[0].img_url
                      : Images.PlaceHolder
                  }
                  className="viewStoreModal_image"
                ></img>
              </div>
            </div>
            {slider === 2 ?
              <>
                <Row style={{ padding: "0px 24px 16px 24px" }}>
                  <img src={backbtn} onClick={() => setSlider(1)} style={{ cursor: "pointer" }} />
                  <span style={{ color: "#257CDB", paddingLeft: "12px", fontSize: "15px", fontWeight: "400" }}>Booking For : {selectedItem?.item?.name}</span>
                </Row>
                <Divider className="customize_divider" style={{ margin: "0px", margin: "0px 0px" }} />

                <SlotBooking appointments={appointmentData} cartlength={Object.keys(cart).length > 0}
                  objId={itemId}
                  locId={locId}
                  select={
                    <select
                      style={{
                        width: "100%",
                        height: "46px",
                        borderRadius: "5px",
                        border: "1px solid var(--SECONDRY-20, #D3D6E1)",
                        background: " #FFF",
                      }}
                    >
                      <option >
                        Choose Location
                      </option>
                    </select>
                  }
                  qty={quantity}
                  icon={<img src={success} />}
                  add={"Add to cart"}
                  next={"Next"} msgClassName={"catalogue_msg"}
                  nextDisabled={dataLength < 1}
                  onNextClick={(e) => {
                    console.log("what are the e>>>", e);
                    let data = appointmentData.filter((item) => item.objid == itemId)
                    console.log("data>>", data.length, data);
                      if(data?.length>0){

                        data[0].appointment = e
                        addObject(data[0])
                      }
                    setDataLength(e.length);
                    setSlider(2);
                  }}
                  disabled={bizDetails !== undefined && !bizDetails.merchantId}
                  checkoutDisabled={
                    roleType === ROLE_SUPPORT || roleType === ROLE_MANAGER || isMasquerade
                      ? true
                      : false
                  }
                  msg={Object.keys(cart).length > 0 && "Item Added Successfully to the cart"}
                  checkout={"Checkout"}
                  classNameAdd="catalogue_calendar"
                  classNameCheckout="catalogue_calendar"
                  addButtonClick={(e) => {
                    console.log("on later e", e);
                    setDate(e)
                    if (indvid) {
                      addToCart(e)
                    }
                    else {
                      takeToLogin()
                    }
                  }}
                  buttonClick={(e) => {
                    setShowConfirmation({ value: false, id: selectedItem?.id })
                    setStep(4);
                    setModalVisible(false);
                    setSlider(1)
                  }} />
              </>
              :
              <>
                <div style={{ padding: "24px 24px 0px 24px" }}>
                  <Row>
                    <Text
                      style={{
                        fontSize: "20px",
                        fontWeight: "500",
                        paddingBottom: "0.75rem",
                        color: "#333",
                      }}
                    >
                      {" "}
                      Select variations / addons
                    </Text>
                  </Row>
                  <Row>
                    <Text
                      style={{ fontSize: "1rem", fontWeight: "400", color: "#333" }}
                    >
                      {`${selectedItem?.item?.name}- ${currencySymbol || "$"}${Number(
                        selectedItem?.price
                      ).toFixed(2)} / ${selectedItem?.item?.unit}`}{" "}
                      {selectedItem?.item?.description && (
                        // <p>{`${selectedItem?.item?.description}`}</p>
                        <RichTextViewer content={selectedItem?.item?.description}/>
                      )}
                    </Text>
                  </Row>
                  <div className="catalogue_detail_options_modal">
                    <Row justify="space-between">
                      <Col span={24}>
                        <OptionRowModal
                          unit={selectedItem?.item?.unit}
                          row={getItemAsModifier()}
                        />

                        {wholeModifiers?.map((row, key) => {
                          return <OptionRowModal row={row} />;
                        })}
                        {addOnModifiers?.length > 0 && (
                          <>
                            <Divider className="previewUrlDivider" />
                            <div>
                              <h4
                                style={{
                                  color: "#223369",
                                  fontWeight: "500",
                                  paddingBottom: "10px",
                                }}
                              >
                                Add-ons
                              </h4>
                            </div>

                            <Checkbox.Group
                              style={{ minWidth: "100%" }}
                              onChange={handleAddOnModifier}
                            >
                              {addOnModifiers?.map((row, key) => {
                                return <OptionRowModal row={row} />;
                              })}
                            </Checkbox.Group>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                  <Input.TextArea
                    onChange={(event) => setInstr(event.target.value)}
                    // rows={3}
                    style={{
                      borderRadius: "10px",
                      height: "5.3125rem",
                      fontSize: "0.875rem",
                    }}
                    placeholder="Instructions: spice level, order for, include, exclude"
                    value={instr}
                  />
                  <Row justify={"center"} style={{ marginTop: "24px" }}>
                    <Col span={24}>
                      <Row align="middle" justify="space-between">
                        <Row
                          style={{
                            fontSize: "1.25rem",
                            fontWeight: "400",
                            color: "#333",
                          }}
                        >
                          <Text>{`Total: `}</Text>
                          <Text>{`${currencySymbol || "$"}${Number(total).toFixed(
                            2
                          )}`}</Text>
                        </Row>

                        <Row>
                          <Col>
                            <Row>
                              <Button
                                // size="large"
                                style={{
                                  borderRadius: "5px 0rem 0rem 5px",
                                  backgroundColor: quantity <= 1 && "#F2F2F2",
                                  height: "2.75rem",
                                  padding: "0",
                                }}
                                icon={
                                  <img
                                    src={minus}
                                    alt="plus"
                                    style={{ marginBottom: ".2rem" }}
                                  />
                                }
                                disabled={quantity <= 1}
                                onClick={() =>
                                  setQuantity(
                                    quantity -
                                    (WholeUnits?.includes(selectedItem?.item?.unit)
                                      ? 1
                                      : 0.1)
                                  )
                                }
                                type="primary"
                              />

                              <InputNumber
                                type="number"
                                className="viewStoreModal-input"
                                controls={false}
                                // size="large"
                                style={{
                                  height: "2.75rem",
                                  width: "6rem",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onChange={(value) => {
                                  if (
                                    WholeUnits?.includes(selectedItem?.item?.unit)
                                  ) {
                                    setQuantity(value);
                                  } else {
                                    setQuantity(value);
                                  }
                                }}
                                value={quantity}
                                min={1}
                                step={
                                  WholeUnits?.includes(selectedItem?.item?.unit)
                                    ? 1
                                    : 0.1
                                }
                                precision={2}
                              />
                              <Button
                                // size="large"
                                style={{
                                  borderRadius: "0rem 5px 5px 0rem",
                                  height: "2.75rem",
                                  padding: "0",
                                }}
                                icon={
                                  <img
                                    src={plus}
                                    alt="plus"
                                    style={{ marginBottom: ".2rem" }}
                                  />
                                }
                                onClick={() => setQuantity(
                                  quantity +
                                  (WholeUnits?.includes(selectedItem?.item?.unit)
                                    ? 1
                                    : 0.1)
                                )
                                }
                                type="primary"
                              />
                            </Row>
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </div>
                {isBookingEnabled && !later &&
                  <>
                    <Divider className="customize_divider" style={{ margin: "0px", margin: "24px 0px" }} />

                    <Row justify={"space-between"} style={{ padding: "0px 24px 24px 24px" }}>
                      <Button type="primary" className="catalogue_calendar" onClick={() => {
                        setlater(true);
                        // console.log("on later item", selectedItem, quantity, appointmentData)
                        // selectedItem.qty = quantity
                        // addObject(selectedItem)
                      }}>
                        Schedule for later
                      </Button>
                      <Button type="primary" className="catalogue_calendar" onClick={() => {
                        let data = appointmentData?.filter((item) => item?.objid === selectedItem?.objid)


                        if (data?.length > 0) {
                          data[0].qty = data[0]?.qty + quantity
                          addObject(data[0])
                        } else {
                          selectedItem.qty = quantity
                          addObject(selectedItem)
                        }

                        setSlider(2)
                      }

                      }>Book an appointment
                      </Button>

                    </Row>
                  </>
                }
              </>
            }
          </>}
      />
      <Modal
        title="Get Direction"
        footer={null}
        onCancel={() => setShowMapModal(false)}
        className="getDirection_modal"
        onConfirm={() => setShowMapModal(false)}
        visible={showMapModal}
        closeIcon={closeIcon}
      >
        <div style={{ width: "100%", height: "20rem" }}>
          <GoogleMapNew
            zoom={11}
            defaultCenter={{
              lat: parentLocation?.lat,
              lng: parentLocation?.lng,
            }}
          >
            <Marker
              position={{
                lat: parentLocation?.lat,
                lng: parentLocation?.lng,
              }}
            />
          </GoogleMapNew>
        </div>
      </Modal>
      <Modal
        onOk={() => setViewWorkingHourModal(false)}
        onCancel={() => setViewWorkingHourModal(false)}
        // width={"33%"}
        visible={viewWorkingHourModal}
        title="Working Hours"
      >
        <div>
          {selectedItemWorkingHours &&
            selectedItemWorkingHours?.workingHours?.split(";").map((row) => {
              const { day, tm } = getTiming12(row);
              return (
                <div key={day} style={{ marginBottom: "1rem" }}>
                  <h5 style={{ width: 150 }}>{day}</h5>
                  <div>
                    {Array.isArray(tm) &&
                      tm.map((val) => {
                        return (
                          <h6 key={val} style={{ marginBottom: 5 }}>
                            {val}
                          </h6>
                        );
                      })}
                  </div>
                  <Divider />
                </div>
              );
            })}
        </div>
      </Modal>

      <ModalVerifyMobileUI
        visible={fetchLocation}
        title={<h5 style={{ textAlign: "center" }}>Locations</h5>}
        // onCancel={() => setFetchLocation(false)}
        // onOk={() => onCatalogue()}
        closable={false}
        footer={null}
        modalText={`Please Select Location`}
        input={
          <Row justify="space-between" align="middle">
            {locationsData.length > 0 ? (
              locationsData.map((item) => {
                return (
                  <Col
                    style={{ width: "100%", cursor: "pointer", padding: "1%" }}
                  >
                    <h6
                      onClick={() => { onCatalogue(item.objid); setLocId(item.objid) }}
                      className="storeUrl_text"
                    >
                      <Text className="storeUrl_text">{`${item?.addressLine1}, ${item?.addressLine2}, ${item?.city}, ${item?.postalCode}`}</Text>
                    </h6>
                  </Col>

                )
              })
            ) : (
              <Text>{"No location found"}</Text>
            )}
          </Row>
        }
      />
      <CustomizedModal
        visible={userComment}
        className="modelUploadImg_seeAllModal"
        title="Ratings & Reviews"
        onCancel={() => {
          setUserComment(false);
        }}
        modalWidth={!isTab() ? "820px" : "350px"}
        btn2="Submit"
        classNameBtn1="btn1"
        isLoading={isLoading}
        onBtn2Click={() => {
          handleOnSubmit()
        }}
        btn2Type="primary"
        btn2Disabled={isLoading}
        btn2Style={{ width: "147px", background: "#257CDB" }}
        content={
          <>
            <Row style={{ width: "100%" }}>
              <Col className="modelUploadImg_mainCol" span={24}>
                <Row className="modelUploadImg_starRow">
                  <Col style={{ paddingLeft: "26px" }}>
                    <div className="modelUploadImg_modelRateProduct">
                      Rate this product
                    </div>

                    <Row>
                      <Col>
                        <ReviewStars
                          rating={rating}
                          onChange={(value) => {
                            setRating(value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider className="divider-mobile" />
                <Row style={{ marginRight: "24px" }}>
                  <Col style={{ paddingLeft: "26px" }}>
                    <div className="modelUploadImg_modelShareExperience">
                      Share Your Experience
                    </div>
                    <div>
                      <TextArea
                        className="modelUploadImg_textArea"
                        onChange={(e) => {
                          setCommentForReview(e.target.value);
                        }}
                        value={commentForReview}
                      />
                    </div>
                  </Col>
                </Row>
                <div className=" modelUploadImg_modelAddImgtext">
                  Add Up to 6 Images
                </div>

                {!isTab() ? (
                  <Row className="modelUploadImg_uploadImgRow">
                    {selectedImages.map((image, index) => (
                      <Col
                        lg={12}
                        md={12}
                        xl={8}
                        sm={12}
                        key={index}
                        align={"middle"}
                      >
                        {(!image || !image.url) && (
                          <Button
                            onClick={() => handleButtonClick(index)}
                            className="modelUploadImg_addImgBtn"
                            style={{ border: "none", padding: "0px" }}
                          >
                            <div className="modelUploadImg_uploadeImgBtn">
                              <div className="modelUploadImg_uploadeImgDiv">
                                <img
                                  src={uploadImage}
                                  className="modelUploadImg_uploadeImg"
                                />
                              </div>
                              <div className="modelUploadImg_btnText">
                                Add Image
                              </div>
                            </div>
                          </Button>
                        )}
                        {image && image.url && (
                          <div style={{ position: "relative", width: "196px" }}>
                            <img
                              src={image.url}
                              className="modelUploadImg_uploadedImgDisplay"
                              alt="Uploaded Image"
                            />
                            <Button
                              className="modelUploadImg_cancelButton"
                              onClick={() => handleCancelClick(index)}
                            >
                              <img
                                src={cancelImg}
                                alt="cancelImg"
                                className="modelUploadImg_cancelImgUploadBtn"
                              />
                            </Button>
                          </div>
                        )}
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <div style={{ padding: "0 24px" }}>
                    <Row className="modelUploadImg_uploadImgRow-mobile">
                      <Col span={24}>
                        <div className=" modelUploadImg_modelAddImgtext-mobile">
                          Add Up to 6 Images
                        </div>
                        <Row
                          justify={"center"}
                          style={{ width: "100%", marginTop: "1.938rem" }}
                        >
                          {selectedImages?.map((image, index) => (
                            <Col>
                              {(!image || !image.url) && (
                                <Button
                                  onClick={(i) => handleButtonClick(i)}
                                  className="modelUploadImg_addImgBtnMob"
                                  style={{ border: "none", padding: "0px" }}
                                >
                                  <Col
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                    span={24}
                                  >
                                    <div className="modelUploadImg_uploadeImgBtn-mobile">
                                      <div className="modelUploadImg_uploadeImgDiv-mobile">
                                        <img
                                          src={uploadImage}
                                          className="modelUploadImg_uploadeImg-mobile"
                                        />
                                      </div>

                                      <div className="modelUploadImg_btnText-mobile">
                                        Add Image
                                      </div>
                                    </div>
                                  </Col>
                                </Button>
                              )}
                              {image && image.url && (
                                <div
                                  className="modelUploadImg_uploadedImgDivMob"
                                  style={{
                                    position: "relative",
                                    width: "130px",
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "4px",
                                  }}
                                >
                                  <img
                                    src={image.url}
                                    className="modelUploadImg_uploadedImgDisplayMob"
                                    alt="Uploaded Image"
                                  />
                                  <Button
                                    className="modelUploadImg_cancelButtonMob"
                                    onClick={(index) =>
                                      handleCancelClick(index)
                                    }
                                  >
                                    <img src={cancelImg} alt="cancelImg" />
                                  </Button>
                                </div>
                              )}
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </>
        }
      />
      <CustomizedModal
        visible={seeAllComments}
        footer={null}
        destroyOnClose={true}
        modalWidth="1169px"
        onCancel={() => {
          setSortByData({
            orderBy: "RATING",
            orderType: "DESC",
          });
          setSeeAllComments(false);
        }}
        title="All Reviews"
        className="allReviewComments"
        content={
          <>
            <AllReviewPopUp
              reviewsOverview={reviewsOverview}
              indv={indv}
              businessId={businessId}
              rating={reviewsOverview?.overallRating}
              ratingMap={reviewsOverview?.ratingMap}
              data={reviwdata}
              placeholder="Sort By"
              disabled={true}
              suffixIcon={<img src={Sliders} alt="Sliders" />}
              pagination={pagination}
              showHeader={false}
              columns={columns}
              dataSource={comments?.comments}
              handleTableChange={handleTableChange}
              starText={bizDetails?.bizName}
              defValue={JSON.stringify(sortByData)}
              onDropdownChange={(e) => {
                setPagination({
                  ...pagination,
                  current: 1,
                });
                setSortByData(JSON.parse(e));
              }}
              options={[
                {
                  value: JSON.stringify({
                    orderBy: "RATING",
                    orderType: "ASC",
                  }),
                  label: "Rating : Low To High",
                },
                {
                  value: JSON.stringify({
                    orderBy: "RATING",
                    orderType: "DESC",
                  }),
                  label: "Rating : High To Low",
                },
                {
                  value: JSON.stringify({
                    orderBy: "CREATED_DT",
                    orderType: "DESC",
                  }),
                  label: "Newest First",
                },
                {
                  value: JSON.stringify({
                    orderBy: "CREATED_DT",
                    orderType: "ASC",
                  }),
                  label: "Newest Last",
                },
              ]}
              text="Write A Review :"
              onClick={() => {
                setRating(0);
                setCommentForReview("");
                setSelectedImages(Array(6).fill(null));
                if (!indvid) {
                  dispatch({ type: SET_ROUTE, payload: currentRoute });
                  history.push("/login");
                } else {
                  setUserComment(true);
                }
              }}
            />
          </>
        }
      />
      <CustomizedModal
        title="Reply to the comment"
        visible={replyModalVisible}
        onCancel={() => {
          setReplyModalVisible(false);
        }}
        btn2Disabled={isLoading}
        onBtn2Click={() => {
          onHandleSubmit();
        }}
        btn2Type="primary"
        btn2="Submit"
        content={
          <Row style={{ padding: "24px 24px 0px 24px " }}>
            <Col span={24}>
              <Row>
                <div
                  style={{ paddingLeft: "0px" }}
                  className="reviewcomments_replyUserName"
                >{`${replyData?.user?.firstName} ${replyData?.user?.lastName}`}</div>

                <div className=" reviewcomments_replyRatingDuration">
                  {moment(replyData.createdDt, "YYYYMMDD").fromNow()}
                </div>
              </Row>

              <div
                style={{ marginTop: "12px", lineHeight: "10px", width: "100%" }}
              >
                <ReviewStars
                  disabled={true}
                  value={replyData.rating}
                  rating={replyData.rating}
                />
              </div>

              <Row>
                <UserCommentText
                  openParagraph={(data) => {
                    setTitle("Comment");
                    setParaVisible(true);
                    setSelectedPlatformUser(data);
                  }}
                  userText={replyData.review}
                  userReply={replyData.commentThread}
                />
              </Row>
              <Divider
                style={{ marginTop: "8px" }}
                className="divider-mobile"
              />
              <Row>
                <Col span={24}>
                  <TextArea
                    placeholder="Write Your Comment"
                    className="modelUploadImg_modalInputBox"
                    style={{ height: 135 }}
                    value={replyComment}
                    onChange={(e) => {
                      onChangeHandler(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
      />
      <CustomizedModal
        title="Report to the comment"
        visible={reportModalVisble}
        onCancel={() => {
          setReportModalVisible(false);
        }}
        btn2Type="primary"
        btn2="Submit"
        onBtn2Click={() => {
          onReportSubmit();
        }}
        btn2Disabled={isLoading}
        isLoading={isLoading}
        content={
          <Row style={{ padding: "24px 24px 0px 24px " }}>
            <Col span={24}>
              <Row>
                <div
                  style={{ paddingLeft: "0px" }}
                  className="reviewcomments_replyUserName"
                >{`${replyData?.user?.firstName} ${replyData?.user?.lastName}`}</div>

                <div className=" reviewcomments_replyRatingDuration">
                  {moment(replyData.createdDt, "YYYYMMDD").fromNow()}
                </div>
              </Row>

              <div
                style={{ marginTop: "12px", lineHeight: "10px", width: "100%" }}
              >
                <ReviewStars
                  disabled={true}
                  value={replyData.rating}
                  rating={replyData.rating}
                />
              </div>

              <Row>
                <UserCommentText
                  openParagraph={(data) => {
                    setTitle("Comment");
                    setParaVisible(true);
                    setSelectedPlatformUser(data);
                  }}
                  userText={replyData.review}
                />
              </Row>
              <Divider
                style={{ marginTop: "8px" }}
                className="divider-mobile"
              />
              <Row>
                <Col span={24}>
                  <TextArea
                    placeholder="Write Your Comment"
                    className="modelUploadImg_modalInputBox"
                    style={{ height: 135 }}
                    value={reportComment}
                    onChange={(e) => {
                      onReportHandler(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        }
      />
      <CustomizedModal
        visible={reportedCommentVisible}
        onCancel={() => setReportedCommentVisible(false)}
        footer={null}
        title={"Reported Comments"}
        content={
          <Row style={{ padding: "24px" }}>
            {reportData?.map((data, index) => {
              return (
                <Row className="particular-reviewModal_row">
                  <div className="particular-reviewModal_reportedComment">
                    {LongText(data?.reportedComment, index, true)}
                  </div>
                  <div className="particular-reviewModal_time">
                    {moment
                      .utc(data?.createdDt)
                      .local()
                      .format("YYYY-MM-DD HH:mm A")}
                  </div>
                </Row>
              );
            })}
          </Row>
        }
      />
      <Modal
        onCancel={() => {
          setAppointVisible(false);
        }}
        footer={null}
        title={catalogueDetail?.biz?.bizName}
        visible={appointVisible}
        closeIcon={closeIcon}
        width={"1032px"}
        className="customize_modal"
        style={{ height: "413px" }}
      >
        <SlotBooking appointments={appointmentData} next="Save" locId={locId}
          objId={itemId}
          later={addClicked && "Schedule for later"}
          laterClick={() => {
            setlater(true);
            handleChgQty(
              itemSelectedData.key,
              qty + (WholeUnits?.includes(unit) ? 0 : 0.1)
            )
            setlater(true);
            setSelectedItem(selectedItem)
            setAppointVisible(false)
          }}
          qty={qty}
          selectedData={addClicked && getAppData()}
          title={itemName} onNextClick={(e) => {
            console.log("item", itemkey, e)
            addClicked ?
              handleSaveButtonClick(itemkey, e) :
              handleEditButtonClick(itemkey, e);
          }} />
      </Modal>

      {popupVisible && (
          <PurchasePopup
            visible={popupVisible}
            onCancel={handlePopupClose}
            createdInvoice={createdInvoice}
            payCard={payCard}
            destroyOnClose={true}
          />
        )}

    </div>
  );
};
export default CataloguePageUrl;