import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Checkbox,
  Typography,
  message,
  Slider,
  Col,
  Card,
  Space,
  Modal,
} from "antd";
import Button from "antd-button-color";
import { parseAddress } from "../../common/util";
import UserActions from "../../actions";
import { Theme } from "../../common";
import { FaMapMarkerAlt } from "react-icons/fa";
import GoogleMap from "../../components/GoogleMap";
import { Circle } from "react-google-maps";
import { useHistory } from "react-router-dom";

import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

import Loader from "../../components/Loading/Loader";
import Pay from "./Pay";

const { Text } = Typography;

const PromoSearch = (props) => {
  let history = useHistory();
  const { cmpn_id, title, featured, cancelSubs } = props;

  const {
    indv: { address, qrid },
  } = useSelector(({ auth }) => auth);
  address && delete address.addressLine2;
  const [addressName, setAddressName] = useState(parseAddress(address));
  const [lat, setLat] = useState(address && address.lat);
  const [lng, setLng] = useState(address && address.lng);
  const [currentId, setCurrentId] = useState(null);
  const [ctr, setCtr] = useState(1);
  const [vcount, setVcount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [selectedDays, setSelectedDays] = useState(null);
  const [criteria, setCriteria] = useState(null);
  const [locList, setLocList] = useState({});
  const [flag, setFlag] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [label, setLabel] = useState("Current Location");
  const [showPlaces, setShowPlaces] = useState(false);
  const days = [30, 60, 90];

  const getCoordinates = async (value) => {
    let geoResult = await geocodeByAddress(value.value.description);
    let coordinates = await getLatLng(geoResult[0]);
    setLat(coordinates.lat);
    setLng(coordinates.lng);
    setAddressName(value.label);
    setLabel(value.label);
    setShowPlaces(false);
    const params = {
      name: value.label,
      lat: coordinates.lat,
      lng: coordinates.lng,
      radius: ctr,
    };
    doSearch(params);
  };

  const fetchIpAddress = async () => {
    if (navigator.geolocation) {
      try {
        setIsLoading(true);
        await navigator.geolocation.getCurrentPosition(setLatLng);

        setShowPlaces(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      alert("geolocation is not supported");
    }
  };

  function setLatLng(position) {
    const name = "Current Location";
    setAddressName(name);
    setLat(position.coords.latitude);
    setLng(position.coords.longitude);
    setIsLoading(false);
    const params = {
      name,
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      radius: ctr,
    };
    doSearch(params);
  }

  const removeSearch = (id) => {
    const list = locList;
    delete list[id];
    setLocList(list);
    setGrandTotal(calcTotal(list));
  };

  const calcTotal = (list) => {
    let grandTotal = 0;
    Object.values(list).forEach((row) => (grandTotal += Number(row.total)));
    grandTotal += vcount;
    return grandTotal;
  };

  const addLoc = () => {
    setCurrentId(Date.now());
    setShowPlaces(true);
  };

  const getVisitorCount = async (days) => {
    setIsLoading(true);
    try {
      const {
        resp: { visitorCount },
        success,
      } = await UserActions.getVisitorCount({
        qrid: qrid + "",
        visitInterval: days,
      });

      if (success && visitorCount) {
        setGrandTotal(grandTotal - vcount + visitorCount);
        setVcount(visitorCount);
      } else {
        setGrandTotal(grandTotal - vcount);
      }
    } catch (err) {
      message.error("Error getting visitors count");
    }

    setIsLoading(false);
  };

  const removeVcount = async () => {
    setGrandTotal(grandTotal - vcount);
    setVcount(0);
    setSelectedDays(0);
  };

  const doSearch = async (params) => {
    let cname, clat, clng, radius;
    cname = params.name;
    clat = params.lat;
    clng = params.lng;
    radius = params.radius;

    setIsLoading(true);
    let id = currentId;

    if (!id) {
      id = Date.now();
      setCurrentId(id);
    }

    const { resp, success } = await UserActions.getAudienceCount({
      searchLocs: [
        {
          latitude: clat,
          longitude: clng,
          distance: radius,
        },
      ],
    });

    setIsLoading(false);
    if (success) {
      const rec = {
        id,
        addressName: cname,
        lat: clat,
        lng: clng,
        radius,
        total: resp,
      };
      let list = locList;
      list[id] = rec;
      setLocList(list);
      setGrandTotal(calcTotal(list));
      setFlag(!flag);
    }
  };

  const proceed = () => {
    if (grandTotal > 0) {
      let tmp = { locList };
      if (vcount > 0) tmp["visited"] = { days: selectedDays, total: vcount };
      setCriteria(tmp);
      setModalVisible(true);
    } else {
      message.warning("Please select target customers..");
    }
  };

  const onPurchase = () => {
    setModalVisible(false);
    history.goBack();
  };

  const renderRow = (params) => {
    const { id, addressName, radius, days, total } = params;
    return (
      <>
        <Row>
          <Col>
            <div>
              <Text className="promotion_addressText">
                {addressName.replace(/(.{60})..+/, "$1…")}
              </Text>
            </div>
            <div style={{ paddingBottom: "12px" }}>
              <Text className="promotion_miles">{radius} mile/s</Text>
              {days && <h5>{days} days</h5>}
            </div>
            <Row gutter={[20, 20]}>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={7}
                xxl={7}
                className="promotion_countAll"
              >
                <div>
                  <Button className="promotion_totalVisitors">
                    <Text strong>{total}</Text>
                  </Button>
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={7}
                xxl={7}
                className="promotion_countAll"
              >
                <Button
                  onClick={() => removeSearch(id)}
                  type="warning"
                  className="promotion_totalDelete"
                >
                  Delete
                </Button>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={10}
                xxl={10}
                className="promotion_loc"
              >
                {" "}
                {Object.keys(locList).length > 0 && (
                  <Row justify="center">
                    <Button
                      className="promotion_locAdd"
                      type="primary"
                      onClick={addLoc}
                      size="large"
                      style={{ backgroundColor: "#257CDB" }}
                    >
                      Add another location
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col span={12}>
          <Text className="promotion_visitedCustomer">
            Location based search
          </Text>
          <Card className="propmotion_LoCcard">
            <Row justify="space-between" style={{ width: "100%" }}>
              <Col xs={0} sm={24} md={24} lg={24} xl={11} xxl={12}>
                <Button
                  type="default"
                  onClick={() => setShowPlaces(true)}
                  className="promotion_locBtn"
                >
                  <FaMapMarkerAlt
                    style={{
                      fontSize: "20px",
                      color: Theme.grey,
                      marginRight: "15px",
                    }}
                  />
                  {addressName ? `${addressName.substring(0, 20)} ..` : ""}
                </Button>
              </Col>

              <Col
                xs={0}
                sm={24}
                md={24}
                lg={24}
                xl={12}
                xxl={10}
                className="sliderPromotion"
              >
                <h6>
                  {" "}
                  <Text>
                    Within {ctr} mile{ctr > 1 ? "s" : ""}
                  </Text>
                </h6>
                <Slider
                  defaultValue={ctr}
                  max={30}
                  minimumValue={1}
                  maximumValue={100}
                  step={2}
                  style={{ color: "#3F9AF7" }}
                  onAfterChange={(val) => {
                    setCtr(val);
                    doSearch({ name: addressName, lat, lng, radius: val });
                  }}
                />
              </Col>
            </Row>

            <div style={{ height: "40vh", width: "100%", marginTop: "1rem" }}>
              <GoogleMap zoom={ctr > 5 ? 10 : 13} defaultCenter={{ lat, lng }}>
                <Circle
                  center={{ lat, lng }}
                  radius={Math.round(ctr * 1609.34)} //meters
                  options={{
                    strokeColor: "#3399ff",
                    strokeWeight: "3",
                    fillColor: "rgba(80, 191, 255, 0.2)",
                    fillOpacity: "0.2",
                  }}
                />
              </GoogleMap>
            </div>

            <div style={{ marginTop: 10 }}>
              {Object.values(locList).map((row) => renderRow(row))}
            </div>
          </Card>
        </Col>

        <Col span={12}>
          <Text className="promotion_visitedCustomer">
            Loyalty based search
          </Text>
          <Card>
            <Row>
              <Col>
                <Text className="promotion_visitedCustomer">
                  Customers who visited my business within last:
                </Text>
                <Row
                  row
                  space="between"
                  style={{ width: "100%", marginTop: "1rem" }}
                >
                  <Col>
                    <Space direction="horizontal">
                      {days.map((val) => {
                        return (
                          <Checkbox
                            checked={val === selectedDays}
                            onChange={(e) => {
                              if (e?.target?.checked) {
                                setSelectedDays(val);
                              } else {
                                setSelectedDays(null)
                              }
                              getVisitorCount(val);
                            }}
                          >
                            <Text strong>{val}</Text>
                          </Checkbox>
                        );
                      })}
                      <Text strong>Days</Text>
                    </Space>
                  </Col>
                </Row>
                <Row style={{ marginTop: "12px" }}>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={12}
                    xxl={12}
                    className="promotion_countBtn"
                  >
                    <div>
                      {((selectedDays > 0 && !isLoading) || vcount > 0) && (
                        <Button
                          type="default"
                          size="large"
                          className="promotion_vCount"
                        >
                          <Text strong>{vcount}</Text>
                        </Button>
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={12}
                    xxl={12}
                    className="promotion_del"
                  >
                    {selectedDays > 0 && (
                      <Button
                        size="large"
                        onClick={removeVcount}
                        type="warning"
                        className="promotion_deleteVcount"
                      >
                        Delete
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>

          {/* <Card style={{ marginTop: "2rem" }}>
            <h4>Loyalty based search</h4>

            <div>
              <Text strong>Customers who visited my business within last:</Text>
              <Row
                row
                space="between"
                style={{ width: "100%", marginTop: "1rem" }}
              >
                <Col span={16}>
                  <Space direction="horizontal">
                    {days.map((val) => {
                      return (
                        <Checkbox
                          checked={val === selectedDays}
                          onChange={() => {
                            setSelectedDays(val);
                            getVisitorCount(val);
                          }}
                        >
                          <Text strong>{val}</Text>
                        </Checkbox>
                      );
                    })}
                    <Text strong>Days</Text>
                  </Space>
                </Col>
                <Col span={8}>
                  <Space direction="horizontal" size="large">
                    <div style={{ width: "8rem" }}>
                      {((selectedDays > 0 && !isLoading) || vcount > 0) && (
                        <Button
                          type="default"
                          size="large"
                          style={{ width: "8rem" }}
                        >
                          <Text strong>{vcount}</Text>
                        </Button>
                      )}
                    </div>

                    {selectedDays > 0 && (
                      <Button
                        size="large"
                        onClick={removeVcount}
                        type="warning"
                      >
                        Delete
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>
            </div>
          </Card> */}
        </Col>
      </Row>

      {grandTotal !== 0 && (
        <Row justify="center" style={{ marginTop: "2rem" }}>
          <h5
            style={{ marginTop: "2rem" }}
          >{`Total Target Customers: ${grandTotal}`}</h5>
        </Row>
      )}
      <Row justify="center" style={{ marginTop: "1rem" }}>

        <Button
          disabled={isLoading}
          size="large"
          type="primary"
          onClick={proceed}
          className="promotion_proceedPay"
          style={{ backgroundColor: "#257CDB" }}

        >
          Proceed{isLoading && <Loader />}
        </Button>
      </Row>

      <Modal
        title={`Campaign Promotion : ${title}`}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        className="promotion-modal"
      >
        <Pay
          cmpn_id={cmpn_id}
          title={title}
          featured={featured}
          criteria={criteria}
          total_recipients={grandTotal}
          cancelSubs={cancelSubs}
          callback={() => {
            setModalVisible(false);
          }}
          onPurchase={onPurchase}
        />
      </Modal>

      <Modal
        onCancel={() => setShowPlaces(false)}
        title="Select Location"
        visible={showPlaces}
        footer={null}
        bodyStyle={{ height: 400 }}
        width={"50%"}
      >
        <GooglePlacesAutocomplete
          selectProps={{
            onChange: (value) => {
              if (
                value.value === "myLocation" ||
                value.label == "Current Location"
              ) {
                fetchIpAddress();
              } else {
                getCoordinates(value);
              }
            },
            defaultOptions: [
              {
                label: `${label}`,
                value: "myLocation",
              },
            ],
            placeholder: `${addressName ? addressName : "Location"}`,
          }}
        />
      </Modal>
    </div>
  );
};

export default PromoSearch;
