// import {useSelector} from "react-redux";
import Geocode from "react-geocode";

import {
  fetch_retry_delete,
  fetch_retry_get,
  fetch_retry_post,
  fetch_retry_put,
  setToken,
} from "../network/ApiManager";
import { Actions } from "../common";
import getEnvVars from "../environment";
import { getFormattedDt, parseTerms, parseAddress } from "../common/util";
import actions, { debug } from "./index";
import { store } from "../store";
import platformVairables from "./platformVairables";
import { message } from "antd";
const {
  ACCOUNT_FOUND,
  LOG_OUT,
  SETTINGS_FOUND,
  LOCATION_EDITED,
  GET_EVENTS,
  TERMS_FOUND,
  SET_TERMS_LIST,
  TERMS_NOT_FOUND,
  ACCOUNT_EDITED,
  LOADER_VISIBLE,
  ADMIN_ACCOUNT,
  ROLE_FYNDR,
  REFFERAL_WALLET,
  REFFERAL_CODE,
  SETTINGS_FOUND_PLATFORM,
  ROLE_TYPE
} = Actions;
//classic Login
const {
  base,
  gatewayNew,
  auth,
  qrPrefix,
  app: { GOOGLE_API_KEY },
} = getEnvVars();

Geocode.setApiKey(GOOGLE_API_KEY);
Geocode.setLanguage("en");

const classicLogin = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/signin`;
  try {
    data.mode = "classic";
    const response = await fetch_retry_post(endpoint, data);
    if (response.ok) {
      setToken({ ...response.headers });
      let accountResponse = await dispatch(
        getAccount({ email: data.email, regMode: "facebook" })
      );
      return { success: true, ...accountResponse };
    } else {
      const resp = await response.json();
      return { success: false, msg: resp.message };
    }
  } catch (err) {
    return { success: false, msg: err };
  }
};

const googlePermissionCheck = () => async (dispatch) => {
  const endpoint = `${base}/appointment/googleCalendar/login`;
  try {

    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {

      return { success: true, data: resp };
    } else {

      return { success: false, msg: resp.message };
    }
  } catch (err) {
    return { success: false, msg: err };
  }
};
export const fetchPdf = (pdfID) => async (dispatch) => {
  const endpoint = `${base}/interactions/terms/?termKey=${pdfID}`;

  try {
    const response = await fetch_retry_get(endpoint);
    if (response.ok) {
      let resp = await response.json();
      return resp.url;
    }
  } catch (error) {
    console.error(error);
  }
};
export const syncAppointment = (params) => async (dispatch) => {
  const { bizId } = params;


  // const endpoint = `${base}/appointment/event/business?businessId=${bizId}`;
  const endpoint = `${base}/appointment/googleCalendar/bookEvent?businessId=${bizId}`;
  delete params.bizId;
  try {
    let response = await fetch_retry_put(endpoint, params);
    const resp = await response.json();
    console.log("AdminAddUser res", resp, response);

    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: "Error on adding events." };
    }
  } catch (error) {
    return { success: false, msg: error.message || error?.response?.data?.errorMessages[0] };
  }
};
export const getAccount = (data) => async (dispatch) => {

  const endpoint = `${base}/identity/account`;
  try {
    const response = await fetch_retry_post(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      console.log("what is googlePermissionCheck res 1", resp);



      if (resp.accountStatus === "DELETED") {
        throw {
          status: "DELETED",
          message:
            "This Account has been deleted from the Fyndr Platform. Please contact support for help. ",
        };
      }
      if (data.regMode !== "classic") {
        const qrcode = resp.identity;

        let res = await actions.adminSetting({
          names: platformVairables?.data,
          countryId: -1,
        });

        const { refferalValues } = await dispatch(variablesParser(res));

        const { days_default, free_limits } = await dispatch(
          loadSettings(resp.setting)
        );

        localStorage.setItem("email", data.email);

        if (resp.isBusiness) {
          await dispatch({
            type: ACCOUNT_FOUND,
            payload: parseBizResp(qrcode, resp, days_default, free_limits),
          });
        } else {
          await dispatch({
            type: ACCOUNT_FOUND,
            payload: parseIndResp(qrcode, resp, days_default, free_limits),
          });
        }
        if (resp.entityType === ROLE_FYNDR) {

          await dispatch({
            type: ADMIN_ACCOUNT,
            payload: resp?.isBusiness
              ? parseBizResp(qrcode, resp, days_default, free_limits)
              : parseIndResp(qrcode, resp, days_default, free_limits),
          });
          localStorage.setItem(
            "adminAccessToken",
            localStorage.getItem("accessToken")
          );
        }


      }

      return {
        status: "exists",
        biz: resp.isBusiness,
        entityType: resp.entityType,
        googleCalendarPermissionGranted: resp?.googleCalendarPermissionGranted,
        isSubscribedToFyndrPromoEmails: resp?.isSubscribedToFyndrPromoEmails
      };
    } else if (response.status === 404) {
      return { status: "new" };
    } else if (response.status.includes("404")) {
      return { status: "new" };
    }
  } catch (err) {
    if (err?.message?.includes("404")) {
      return { status: "new" };
    } else if (err?.response?.status === 404) {
      return { status: "new", message: err?.response?.data?.message };
    }
    if (err?.status === "DELETED") {
      return { ...err };
    }
    return { status: "unknown" };
  }
  return { status: "unknown" };
};

const GetAllTerms = () => async (dispatch) => {
  const endpoint = `${base}/identity/termUnderReview`;
  try {
    const response = await fetch_retry_get(endpoint);
    if (response.ok) {
      const resp = await response.json();
      return resp;
    } else {
    }
  } catch (error) {
    console.error(error);
  }
};

const getTerms = (id) => async (dispatch) => {
  const endpoint = `${base}/identity/termByBizId?businessId=${id}`;
  try {
    let response = await fetch_retry_get(endpoint);
    if (response.ok) {
      const resp = await response.json();
      const parsedTerms = parseTerms(resp);
      await dispatch({ type: TERMS_FOUND, payload: parsedTerms });

      if (parsedTerms["draft"])
        dispatch({
          type: SET_TERMS_LIST,
          payload: parsedTerms["draft"]["list"],
        });

      return { success: true };
    } else {
      await dispatch({ type: TERMS_NOT_FOUND });
    }
  } catch (error) {
    return { success: false };
  }
};
export const updateTerms = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/term`;
  try {
    const response = await fetch_retry_put(endpoint, data, "put");
    const resp = await response.json();
    if (response.ok) {
      return { success: true };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};

export const ContactUs = (payload) => async (dispatch) => {
  const endpoint = `${gatewayNew}/contact-us/mail`;
  payload.from = payload.email
  delete payload.email
  // const endpoint2 = 'https://api.gw.prod.fyndr.us/contact-us/mail'
  try {
    const response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch (error) {
    console.error(error);
    return { success: false };
  }
};
export const requestToken = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/token`;
  debug && console.log("request token", endpoint, data);
  try {
    const response = await fetch_retry_post(endpoint, { email: data });
    if (response.ok) {
      return { success: true };
    }
    return { success: false, msg: "Sorry, we couldn't find your account" };
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const verifyToken = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/confirmIdentity`;
  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();
    return { status: response.ok ? "ok" : "not-ok", msg: resp.message };
  } catch (err) {
    console.log("what ???", err.message);
    return { status: "not-ok", msg: err.message };
  }
};

export const addLocation = (payload) => async (dispatch) => {
  const endpoint = `${base}/identity/location`;
  try {
    const response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      await dispatch({ type: LOCATION_EDITED, payload: resp.locations });
      return { success: true, msg: resp.message };
    } else if (response.status === 409) {
      return {
        success: false,
        msg: `Interaction venue ${payload.locName} already exists..`,
      };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch (error) {
    console.error(error);
    return { success: false, msg: error?.message };
  }
};
export const updateLocation = (payload) => async (dispatch) => {
  const endpoint = `${base}/identity/location`;
  debug && console.log(endpoint, JSON.stringify(payload));
  try {
    const response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      await dispatch({ type: LOCATION_EDITED, payload: resp.locations });
      return { success: true, msg: "Update Successfully " };
    } else {
      return {
        success: false,
        msg: resp.message ? resp.message : "Error updating location",
      };
    }
  } catch (error) {
    console.error(error);
    return { success: false, msg: error?.message };
  }
};
export const getLocation = (payload) => async (dispatch) => {
  const endpoint = `${base}/identity/locationByid?objid=${payload}`;
  try {
    let response = await fetch_retry_get(endpoint);
    if (response.ok) {
      const resp = await response.json();
      return { success: true, payload: resp };
    }
  } catch (error) {
    return { success: false, message: error };
  }
};

export const deleteLocation = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/location`
  try {
    const response = await fetch_retry_delete(endpoint, data);
    if (response.ok) {
      const resp = await response.json();
      return {
        success: true,
        message: resp?.message,
      };
    } else {
      const resp = await response.json();
      return { success: false, message: resp.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};

export const getLocInteractions = (data) => async (dispatch) => {
  const endpoint = `${gatewayNew}/interactions/biz/activity`;
  try {
    let response = await fetch_retry_post(endpoint, data);
    if (response.ok) {
      const respJson = await response.json();
      const { direct, indirect } = parseInteractions(respJson, "loc");
      direct.sort((a, b) => a.time.localeCompare(b.time)).reverse();
      let indirectEnhanced = indirect;
      indirectEnhanced["count"] = direct.length;
      indirectEnhanced["days"] = data.interval;
      await dispatch({
        type: GET_EVENTS,
        payload: { direct, indirect: indirectEnhanced },
      });
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error(error);
  }
};
export const getIndvInteractions = (data) => async (dispatch) => {
  const endpoint = `${gatewayNew}/interactions/indv/activity`;
  debug && console.log(endpoint, data);
  try {
    const response = await fetch_retry_post(endpoint, data);
    debug && console.log("indv-interactions", response);
    if (response.ok) {
      const respJson = await response.json();
      const { direct, indirect } = parseInteractions(respJson, "indv");
      direct.sort((a, b) => a.time.localeCompare(b.time)).reverse();
      let indirectEnhanced = indirect;
      indirectEnhanced["count"] = direct.length;
      indirectEnhanced["days"] = data.interval;
      await dispatch({
        type: GET_EVENTS,
        payload: { direct, indirect: indirectEnhanced },
      });
      return { success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    console.error(error);
  }
};
export const logout = async (dispatch = store.dispatch) => {

  const broadcastChannel = new BroadcastChannel('auth-channel');

  dispatch({
    type: LOADER_VISIBLE,
    payload: true,
  });
  const endpoint = `${auth}/v1/token/invalidate`;
  let emailValue = await localStorage.getItem("email");

  let data = {
    email: btoa(emailValue),
  };

  try {
    const response = await fetch_retry_post(endpoint, data);
    const resp = await response.json();

    dispatch({
      type: LOG_OUT,
    });
    dispatch({ type: ROLE_TYPE, payload: null })

    // await localStorage.clear();

    var myItem = localStorage.getItem('tourOpen');
    var myItem2 = localStorage.getItem('tourOpenDashboard');
    var navTour = localStorage.getItem('tourSideNav')
    var interactionsTour = localStorage.getItem('tourOpenInteractions');
    var indicatorTour = localStorage.getItem("tourOpenIndicators")
    localStorage.clear();
    localStorage.setItem('tourOpen', myItem);
    localStorage.setItem('tourOpenDashboard', myItem2);
    localStorage.setItem('tourSideNav', navTour)
    localStorage.setItem('tourOpenInteractions', interactionsTour)
    localStorage.setItem('tourOpenIndicators', indicatorTour)

    broadcastChannel.postMessage({ type: 'logout' });

    window.location.reload();
    dispatch({
      type: LOADER_VISIBLE,
      payload: false,
    });
    return { success: true };
  } catch (err) {
    dispatch({
      type: LOG_OUT,
    });
    await localStorage.clear();
    broadcastChannel.postMessage({ type: 'logout' });
    window.location.reload();
    dispatch({
      type: LOADER_VISIBLE,
      payload: false,
    });
    return { success: true, msg: err };
  } finally {
    broadcastChannel.close();
  }
};
export const downloadPDF = (bizid) => async (dispatch) => {
  let endpoint = `${base}/identity/getLocationPDF?bizid=${bizid}`;
  try {
    let response = await fetch_retry_get(endpoint);
    if (response.ok) {
      const resp = await response.json();
      return { success: true, payload: resp };
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteUserAccount = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/deleteAccount/${data}`;
  try {
    const response = await fetch_retry_delete(endpoint, {});
    if (response.ok) {
      const resp = await response.json();
      debug && console.log("resp", resp);

      return resp;
    } else {
      return { success: false, message: response.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const verifyCode = (data) => async (dispatch) => {
  const { isBusiness, code, countryId } = data;
  const endpoint = `${base}/identity/verify?isBusiness=${isBusiness}&code=${code}&countryId=${countryId}`;
  try {
    const response = await fetch_retry_get(endpoint, {});
    if (response.ok) {
      const resp = await response.json();
      debug && console.log(" verifyRefCode resp", resp);

      return { success: true, message: resp.message, promoCode: resp.promocode };
    } else {
      return { success: false, message: response.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};


//internal Functions
const parseBizResp = (qrcode, resp, days_default, free_limits) => {
  let indv = {
    qrcode,
    biz: true,
    fname: resp.firstName,
    lname: resp.lastName,
    displayName: resp.displayName,
    gender: resp.gender,
    yob: resp.yob,
    email: resp.email,
    bizid: resp.bizid,
    bizName: resp.bizName,
    locations: resp.locations,
    address: resp.address,
    indvid: resp.indvid,
    fyndrHandle: resp.fyndrHandle,
    qrid: resp.qrid,
    website: resp.website,
    addonUrl: resp.addonUrl,
    bizType: resp.bizType,
    taxnbr: resp.taxnbr,
    mainLogo: resp.mainLogo,
    qrLogo: resp.qrLogo,
    deviceToken: resp.deviceToken,
    cards: resp.pmethod,
    qrPrefix,
    currency: resp?.currency || "USD",
    currencySymbol: resp?.currencySymbol || "$",
    showBiz: resp.showBiz,
    merchantId: resp.merchantId,
    payoutsEnabled: resp.payoutsEnabled,
    chargesEnabled: resp.chargesEnabled,
    merchantAllowed: resp.merchantAllowed,
    onBoarded: resp.detailsSubmitted,
    tags: resp.tags,
    accountStatus: resp.accountStatus,
    entityType: resp.entityType,
    entityRole: resp.entityRole,
    referralCode: resp.referralCode,
    regMode: resp.regMode,
    googleCalendarPermissionGranted: resp?.googleCalendarPermissionGranted,
    userTimeZone: resp?.userTimeZone,
    isSubscribedToFyndrPromoEmails: resp?.isSubscribedToFyndrPromoEmails,
  };
  if (days_default) {
    const { free, sub } = days_default;
    const subs = parseSubs(resp.subscription, free_limits);
    indv.days_default = subs.subscribedTo ? sub : free;
    indv.subs = subs;
  }

  return indv;
};

const parseIndResp = (qrcode, resp, days_default, free_limits) => {
  let indv = {
    qrcode,
    biz: false,
    fname: resp.firstName,
    lname: resp.lastName,
    displayName: resp.displayName,
    gender: resp.gender,
    email: resp.email,
    address: resp.address,
    indvid: resp.indvid,
    qrid: resp.qrid,
    fyndrHandle: resp.fyndrHandle,
    yob: resp.yob,
    deviceToken: resp.deviceToken,
    qrPrefix,
    currency: resp?.currency || "USD",
    currencySymbol: resp?.currencySymbol || "$",
    cards: resp.pmethod,
    entityType: resp.entityType,
    entityRole: resp.entityRole,
    referralCode: resp?.referralCode,
    regMode: resp?.regMode,
    userTimeZone: resp?.userTimeZone,
    isSubscribedToFyndrPromoEmails: resp?.isSubscribedToFyndrPromoEmails,
  };

  if (days_default) {
    const { free, sub } = days_default;
    indv.days_default = free;
    const subs = parseSubs(null, free_limits); //
    indv.days_default = subs.subscribedTo.length > 1 ? sub : free; //one is free plan
    indv.subs = subs;
  }

  return indv;
};

const parseSubs = (subscription, free_limits) => {
  let subscribedTo = [],
    maxLoc = free_limits ? free_limits.loc : 0;
  if (subscription) {
    subscription.forEach((row) => {
      if (row.status === "active") {
        const {
          priceid: { productId, freeLoc },
          startDt: { seconds },
          priceType,
        } = row;
        subscribedTo.push({
          productId,
          purchaseTime: getFormattedDt(seconds * 1000),
          priceType,
        });
        maxLoc = freeLoc > maxLoc ? freeLoc : maxLoc;
      }
    });
  }

  return { maxLoc, subscribedTo };
};
export const parseInteractions = (data, type) => {
  let result = [];
  let indirect = {};

  if (data) {
    if (type === "indv") {
      if (data.interactedBy) {
        data.interactedBy.forEach((obj) => {
          const row = extractRow(obj, "interactedBy", "by");
          if (row != null) result.push(row);
        });
      }
      if (data.interactedWith) {
        data.interactedWith.forEach((obj) => {
          const row = extractRow(obj, "interactedWith", "with");
          if (row != null) result.push(row);
        });
      }
    }

    if (data.visited) {
      data.visited.forEach((obj) => {
        const row = extractRow(
          obj,
          "visited",
          type === "indv" ? "loc" : "individual"
        );
        if (row != null) result.push(row);
      });
    }

    if (data.checkedInBy) {
      data.checkedInBy.forEach((obj) => {
        const row = extractRow(
          obj,
          "checkedInBy",
          type === "indv" ? "by" : "individual"
        );
        if (row != null) result.push(row);
      });
    }
    if (data.L2Network) indirect = data.L2Network;
  }
  return { direct: result, indirect };
};
export const extractRow = (obj, act, key) => {
  try {
    const { time, status, lat, lng, id, terms_accepted, consent_time } =
      obj["interaction"];
    const addr = key === "by" ? obj["by"] : key === "loc" ? obj["loc"] : null;
    if (status === "rejected") return null;
    else
      return {
        act,
        status,
        lat,
        lng,
        id,
        time,
        terms_accepted,
        consent_time,
        name: obj[key]["name"][0],
        qrId: obj.biz ? obj["biz"]["qrId"][0] : obj[key]["qrId"][0],
        bizName: obj.biz ? obj["biz"]["name"][0] : "",
        loc: addr
          ? {
            name: addr["name"][0],
            city: addr["city"][0],
            postalCode: addr["postalCode"][0],
          }
          : null,
      };
  } catch ({ message }) {
    console.error(message);
  }
  return null;
};

export const register = (payload) => async (dispatch) => {
  const endpoint = `${base}/identity/signup`;
  debug && console.log("signup", endpoint, JSON.stringify(payload));
  try {
    const response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (!response.ok) {
      return {
        success: false,
        msg: resp.message
          ? resp.message
          : "Error completing signup, please try again",
      };
    } else {
      setToken(response.headers);
      let res = await actions.adminSetting({
        names: platformVairables.data,
        countryId: -1,
      });

      const { refferalValues } = await dispatch(variablesParser(res));
      console.log("platform variable1", refferalValues);
      const { days_default, free_limits } = await dispatch(
        loadSettings(resp.setting)
      );

      if (resp.isBusiness)
        await dispatch({
          type: ACCOUNT_EDITED,
          payload: parseBizResp(resp.identity, resp, days_default, free_limits),
        });
      else
        await dispatch({
          type: ACCOUNT_EDITED,
          payload: parseIndResp(resp.identity, resp, days_default, free_limits),
        });
      return { success: true };
    }
  } catch (err) {
    return { success: false, msg: err?.message };
  }
};

export const updateProfile = (payload) => async (dispatch) => {
  const endpoint = `${base}/identity/profile/`;
  debug && console.log("updateProfile", endpoint, payload);
  try {
    const response = await fetch_retry_put(endpoint, payload);

    const resp = await response.json();

    if (!response.ok) {
      return {
        success: false,
        msg: resp.message ? resp.message : "Error updating profile",
      };
    } else {
      if (resp.isBusiness)
        await dispatch({
          type: ACCOUNT_EDITED,
          payload: parseBizResp(resp.identity, resp),
        });
      else
        await dispatch({
          type: ACCOUNT_EDITED,
          payload: parseIndResp(resp.identity, resp),
        });

      return { success: true, msg: "Profile Updated successfully" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const loadSettings = (resp) => async (dispatch) => {
  console.log("loadSettings", resp);
  let settings = {};
  resp.forEach((row) => {
    switch (row.name) {
      case "days_default":
        settings.days_default = row.valJson[0];
        break;
      case "messages":
        settings.messages = row.valJson;
        break;
      case "slides_biz":
        settings.slides_biz = row.valJson;
        break;
      case "slides_indv":
        settings.slides_indv = row.valJson;
        break;
      case "terms":
        settings.terms = row.valJson;
        break;
      case "terms_header":
        settings.terms_header = row.valTxt;
        break;
      case "terms_footer":
        settings.terms_footer = row.valTxt;
        break;
      case "free_limits":
        settings.free_limits = row.valJson[0];
        break;
      case "campaign_goal":
        settings.campaign_goal = row.valJson;
        break;
      case "campaign_dc":
        settings.campaign_dc = row.valJson;
        break;
      case "pmt_fee":
        settings.pmt_fee = row.valJson[0];
        break;
      case "campaign_search_page_size":
        settings.campaign_search_page_size = row.valNum;
        break;
      case "platform_acct":
        settings.platform_acct = row.valJson[0];
        break;
      default:
    }
  });
  await dispatch({ type: SETTINGS_FOUND, payload: settings });
  console.log("business_terms_header loadsettings", settings);

  return settings;
};

export const variablesParser = (res) => async (dispatch) => {
  let settings = {
    terms: [
      {
        content: "",
        id: null,
        title: "",
      },
      {
        content: "",
        id: null,
        title: "",
      },
    ],
    messages: [
      {
        label: "",
        bizValue: "",
        value: "",
      },
      {
        label: "",
        bizValue: "",
        value: "",
      },
    ],
    campaign_goal: [
      {
        value: "",
        label: "",
      },
      {
        value: "",
        label: "",
      },
      {
        value: "",
        label: "",
      },
    ],
    campaign_dc: [
      {
        descr: "",
        label: "",
        value: "",
      },
      {
        descr: "",
        label: "",
        value: "",
      },
    ],
    slides_biz: [
      {
        backgroundColor: "",
        descr: "",
        image: "",
        key: "",
        title: "",
      },
      {
        backgroundColor: "",
        descr: "",
        image: "",
        key: "",
        title: "",
      },
      {
        backgroundColor: "",
        descr: "",
        image: "",
        key: "",
        title: "",
      },
      {
        backgroundColor: "",
        descr: "",
        image: "",
        key: "",
        title: "",
      },
      {
        backgroundColor: "",
        descr: "",
        future: true,
        image: "",
        key: "",
        title: "",
      },
      {
        backgroundColor: "",
        descr: "",
        future: false,
        image: "",
        key: "",
        title: "",
      },
    ],
    slides_indv: [
      {
        backgroundColor: "",
        descr: "",
        image: "",
        key: "",
        title: "",
      },
      {
        backgroundColor: "",
        descr: "",
        image: "",
        key: "",
        title: "",
      },
      {
        backgroundColor: "",
        descr: "",
        future: true,
        image: "",
        key: "",
        title: "",
      },
      {
        backgroundColor: "",
        descr: "",
        future: false,
        image: "",
        key: "",
        title: "",
      },
    ],
    campaign_search_page_size: "",
    pmt_fee: {
      fyndr_offer_fee: {
        val_percent: 0,
      },
      fyndr_invoice_fee: {
        val_percent: 0,
        val_flat: 0,
        criteria: "",
      },
      stripe_fee: {
        val_percent: 0,
        val_flat: 0,
        criteria: "",
      },
      promo_fee: {
        base: 0,
        base_push: 0,
        free_count: 0,
        per_email: 0,
        per_push: 0,
      },
    },

    platform_acct: {
      bizid: "",
      business_country: "",
      business_name: "",
      currency: "",
      currency_symbol: "",
      merchant_id: "",
    },

    bizdir_search: [

      {
        keyword: "",
        title: "",
      },
      {
        keyword: "",
        title: "",
      }
    ],

    featuredSubscription: [
      {
        month: "1 Month",
        value: ""
      },
      {
        month: "3 Months",
        value: ""
      },
      {
        month: "6 Months",
        value: ""
      }

    ]
  };
  res?.data?.map((row) => {
    if (row.name === "business_terms_header") {
      return (settings.terms_header = row.value[0].countryValue);
    } else if (row.name === "business_terms_footer") {
      return (settings.terms_footer = row.value[0].countryValue);
    } else if (row.name === "covid19_waiver") {
      let data = settings.terms;
      data[0].title = "COVID-19 Waiver";
      data[0].id = row.id;
      data[0].content = row.value[0].countryValue;
      return data;
    } else if (row.name === "facility_access") {
      let data = settings.terms;
      data[1].title = "Facility Access";
      data[1].id = row.id;
      data[1].content = row.value[0].countryValue;
      return data;
    } else if (row.name === "interaction_covid19_business_message_b") {
      return (settings.messages[1].bizValue = row.value[0].countryValue);
    } else if (row.name === "interaction_covid19_business_message_a") {
      return (settings.messages[0].bizValue = row.value[0].countryValue);
    } else if (row.name === "interaction_covid19_label_a") {
      console.log("interaction_covid19_label_a", row.value[0].countryValue);
      return (settings.messages[0].label = row.value[0].countryValue);
    } else if (row.name === "interaction_covid19_label_b") {
      return (settings.messages[1].label = row.value[0].countryValue);
    } else if (row.name === "business_referral_amount") {
      return (settings.business_referral_amount = row);
    } else if (row.name === "customer_referral_amount") {
      return (settings.customer_referral = row);
    } else if (row.name === "interaction_covid19_message_a") {
      return (settings.messages[0].value = row.value[0].countryValue);
    } else if (row.name === "interaction_covid19_message_b") {
      return (settings.messages[1].value = row.value[0].countryValue);
    } else if (row.name === "instore") {
      let data = settings.campaign_goal;
      data[0].value = row.name;
      data[0].label = row.value[0].countryValue;
      return data;
    } else if (row.name === "online") {
      let data = settings.campaign_goal;
      data[1].value = row.name;
      data[1].label = row.value[0].countryValue;
      return data;
    } else if (row.name === "all") {
      let data = settings.campaign_goal;
      data[2].value = row.name;
      data[2].label = row.value[0].countryValue;
      return data;
    } else if (row.name === "campaign_instore_delivery_type_value") {
      let data = settings.campaign_dc;
      data[0].descr = row.description;
      data[0].value = row.value[0].countryValue;
      data[0].label = "Present In-Store";
    } else if (row.name === "campaign_online_delivery_type_value") {
      let data = settings.campaign_dc;
      data[1].descr = row.description;
      data[1].value = row.value[0].countryValue;
      data[1].label = "Host on Web & Mobile App";
    } else if (row.name === "business_slides_background_color_one") {
      let data = settings.slides_biz;
      data[0].backgroundColor = row.value[0].countryValue;
      data[0].key = "one";
    } else if (row.name === "business_slides_description_one") {
      console.log("rowValue" + row.value);
      settings.slides_biz[0].descr = row.value[0].countryValue;
    } else if (row.name === "business_slides_image_one") {
      let data = settings.slides_biz;
      data[0].image = row.value[0].countryValue;
    } else if (row.name === "business_slides_title_one") {
      let data = settings.slides_biz;
      data[0].title = row.value[0].countryValue;
    } else if (row.name === "business_slides_background_color_two") {
      let data = settings.slides_biz;
      data[1].backgroundColor = row.value[0].countryValue;
      data[1].key = "two";
    } else if (row.name === "business_slides_description_two") {
      console.log("rowValue" + row.value);
      settings.slides_biz[1].descr = row.value[0].countryValue;
    } else if (row.name === "business_slides_image_two") {
      let data = settings.slides_biz;
      data[1].image = row.value[0].countryValue;
    } else if (row.name === "business_slides_title_two") {
      let data = settings.slides_biz;
      data[1].title = row.value[0].countryValue;
    } else if (row.name === "business_slides_background_color_three") {
      let data = settings.slides_biz;
      data[2].backgroundColor = row.value[0].countryValue;
      data[2].key = "three";
    } else if (row.name === "business_slides_description_three") {
      settings.slides_biz[2].descr = row.value[0].countryValue;
    } else if (row.name === "business_slides_image_three") {
      let data = settings.slides_biz;
      data[2].image = row.value[0].countryValue;
    } else if (row.name === "business_slides_title_three") {
      let data = settings.slides_biz;
      data[2].title = row.value[0].countryValue;
    } else if (row.name === "business_slides_background_color_four") {
      let data = settings.slides_biz;
      data[3].backgroundColor = row.value[0].countryValue;
      data[3].key = "four";
    } else if (row.name === "business_slides_description_four") {
      settings.slides_biz[3].descr = row.value[0].countryValue;
    } else if (row.name === "business_slides_image_four") {
      let data = settings.slides_biz;
      data[3].image = row.value[0].countryValue;
    } else if (row.name === "business_slides_title_four") {
      let data = settings.slides_biz;
      data[3].title = row.value[0].countryValue;
    } else if (row.name === "business_slides_background_color_five") {
      let data = settings.slides_biz;
      data[4].backgroundColor = row.value[0].countryValue;
      data[4].key = "five";
    } else if (row.name === "individual_third_slide_isFutureFeature") {
      let data = settings.slides_indv;
      data[4].future = "true";
    } else if (row.name === "business_slides_description_five") {
      settings.slides_biz[4].descr = row.value[0].countryValue;
    } else if (row.name === "business_slides_image_five") {
      let data = settings.slides_biz;
      data[4].image = row.value[0].countryValue;
    } else if (row.name === "business_slides_title_five") {
      let data = settings.slides_biz;
      data[4].title = row.value[0].countryValue;
    } else if (row.name === "business_slides_background_color_six") {
      let data = settings.slides_biz;
      data[5].backgroundColor = row.value[0].countryValue;
      data[5].key = "six";
    } else if (row.name === "individual_third_slide_isFutureFeature") {
      let data = settings.slides_indv;
      data[5].future = "false";
    } else if (row.name === "business_slides_description_six") {
      settings.slides_biz[5].descr = row.value[0].countryValue;
    } else if (row.name === "business_slides_image_six") {
      let data = settings.slides_biz;
      data[5].image = row.value[0].countryValue;
    } else if (row.name === "business_slides_title_six") {
      let data = settings.slides_biz;
      data[5].title = row.value[0].countryValue;
    } else if (row.name === "individual_slides_background_color_one") {
      let data = settings.slides_indv;
      data[0].backgroundColor = row.value[0].countryValue;
      data[0].key = "one";
    } else if (row.name === "individual_slides_description_one") {
      let data = settings.slides_indv;
      data[0].descr = row.value[0].countryValue;
    } else if (row.name === "individual_slides_image_one") {
      let data = settings.slides_indv;
      data[0].image = row.value[0].countryValue;
    } else if (row.name === "individual_slides_title_one") {
      let data = settings.slides_indv;
      data[0].title = row.value[0].countryValue;
    } else if (row.name === "individual_slides_background_color_two") {
      let data = settings.slides_indv;
      data[1].backgroundColor = row.value[0].countryValue;
      data[1].key = "two";
    } else if (row.name === "individual_slides_description_two") {
      let data = settings.slides_indv;
      data[1].descr = row.value[0].countryValue;
    } else if (row.name === "individual_slides_image_two") {
      let data = settings.slides_indv;
      data[1].image = row.value[0].countryValue;
    } else if (row.name === "individual_slides_title_two") {
      let data = settings.slides_indv;
      data[1].title = row.value[0].countryValue;
    } else if (row.name === "individual_slides_background_color_three") {
      let data = settings.slides_indv;
      data[2].backgroundColor = row.value[0].countryValue;
      data[2].key = "three";
    } else if (row.name === "individual_slides_description_three") {
      let data = settings.slides_indv;
      data[2].descr = row.value[0].countryValue;
    } else if (row.name === "individual_third_slide_isFutureFeature") {
      let data = settings.slides_indv;
      data[2].future = "true";
    } else if (row.name === "individual_slides_image_three") {
      let data = settings.slides_indv;
      data[2].image = row.value[0].countryValue;
    } else if (row.name === "individual_slides_title_three") {
      let data = settings.slides_indv;
      data[2].title = row.value[0].countryValue;
    } else if (row.name === "individual_slides_background_color_four") {
      let data = settings.slides_indv;
      data[3].backgroundColor = row.value[0].countryValue;
    } else if (row.name === "individual_third_slide_isFutureFeature") {
      let data = settings.slides_indv;
      data[3].future = "false";
    } else if (row.name === "individual_slides_description_four") {
      let data = settings.slides_indv;
      data[3].descr = row.value[0].countryValue;
      data[3].key = "four";
    } else if (row.name === "individual_slides_image_four") {
      let data = settings.slides_indv;
      data[3].image = row.value[0].countryValue;
    } else if (row.name === "individual_slides_title_four") {
      let data = settings.slides_indv;
      data[3].title = row.value[0].countryValue;
    } else if (row.name === "campaign_search_page_size") {
      settings.campaign_search_page_size = row.value[0].countryValue;
    } else if (row.name === "fyndr_offer_fee_percent_value") {
      settings.pmt_fee.fyndr_offer_fee.val_percent = parseInt(
        row.value[0].countryValue
      );
    } else if (row.name === "fyndr_invoice_fee_percent_value") {
      settings.pmt_fee.fyndr_invoice_fee.val_percent = parseFloat(
        row.value[0].countryValue
      );
    } else if (row.name === "fyndr_invoice_fee_flat_value") {
      settings.pmt_fee.fyndr_invoice_fee.val_flat = parseFloat(
        row.value[0].countryValue
      );
    } else if (row.name === "fyndr_invoice_fee_selection_criteria") {
      settings.pmt_fee.fyndr_invoice_fee.criteria = row.value[0].countryValue;
    } else if (row.name === "stripe_fee_percent_value") {
      settings.pmt_fee.stripe_fee.val_percent = parseFloat(
        row.value[0].countryValue
      );
    } else if (row.name === "stripe_fee_flat_value") {
      settings.pmt_fee.stripe_fee.val_flat = parseFloat(
        row.value[0].countryValue
      );
    } else if (row.name === "stripe_fee_criteria") {
      settings.pmt_fee.stripe_fee.criteria = row.value[0].countryValue;
    } else if (row.name === "campaign_promotion_fee_base_value") {
      settings.pmt_fee.promo_fee.base = parseFloat(row.value[0].countryValue);
    } else if (row.name === "campaign_promotion_push_notification_base_fee") {
      settings.pmt_fee.promo_fee.base_push = parseFloat(row.value[0].countryValue);
    } else if (row.name === "campaign_promotion_free_count") {
      settings.pmt_fee.promo_fee.free_count = parseInt(
        row.value[0].countryValue
      );
    } else if (row.name === "campaign_promotion_fee_per_email") {
      settings.pmt_fee.promo_fee.per_email = parseFloat(
        row.value[0].countryValue
      );
    } else if (row.name === "campaign_promotion_fee_per_push_notification") {
      settings.pmt_fee.promo_fee.per_push = parseFloat(
        row.value[0].countryValue
      );
    } else if (row.name === "fyndr_account_name") {
      let data = settings.platform_acct;
      data.business_name = row.value[0].countryValue;
    } else if (row.name === "fyndr_account_country") {
      let data = settings.platform_acct;
      data.business_country = row.value[0].countryValue;
    } else if (row.name === "fyndr_account_business_id") {
      let data = settings.platform_acct;
      data.bizid = parseInt(row.value[0].countryValue);
    } else if (row.name === "fyndr_account_merchant_id") {
      let data = settings.platform_acct;
      data.merchant_id = row.value[0].countryValue;
    } else if (row.name === "fyndr_account_currency") {
      let data = settings.platform_acct;
      data.currency = row.value[0].countryValue;
    } else if (row.name === "fyndr_account_currency_symbol") {
      let data = settings.platform_acct;
      data.currency_symbol = row.value[0].countryValue;
    } else if (row.name === "business_directory_search_keyword_1") {
      let data = settings.bizdir_search;
      data[0].keyword = row.value[0].countryValue;
    } else if (row.name === "business_directory_search_title_1") {
      let data = settings.bizdir_search;
      data[0].title = row.value[0].countryValue;
    } else if (row.name === "business_directory_search_keyword_2") {
      let data = settings.bizdir_search;
      data[1].keyword = row.value[0].countryValue;
    } else if (row.name === "business_directory_search_title_2") {
      let data = settings.bizdir_search;
      data[1].title = row.value[0].countryValue;
    } else if (row.name === "featured_cmpn_one_month_fee") {
      let data = settings.featuredSubscription;
      data[0].value = row.value[0].countryValue;
    }
    else if (row.name === "featured_cmpn_three_month_fee") {
      let data = settings.featuredSubscription;
      data[1].value = row.value[0].countryValue;
    }
    else if (row.name === "featured_cmpn_six_month_fee") {
      let data = settings.featuredSubscription;
      data[2].value = row.value[0].countryValue;
    }
  });

  await dispatch({ type: REFFERAL_WALLET, payload: settings });
  await dispatch({ type: SETTINGS_FOUND_PLATFORM, payload: settings });

  console.log("business_terms_header", settings);
  return settings;
};

export const linkRefferalCode = () => async (dispatch) => {
  const link_refferal_code = window.location.search.slice(1);
  await dispatch({ type: REFFERAL_CODE, payload: link_refferal_code });
  return link_refferal_code;
};
const getGeoLocation = (address) => async (dispatch) => {
  try {
    const response = await Geocode.fromAddress(parseAddress(address));
    if (response.status === "OK") {
      return {
        success: true,
        addr: response.results[0]["geometry"]["location"],
      };
    }
  } catch ({ message }) {
    return { success: false };
  }
  return { success: false };
};

const getLocationFromPostalCode = (address) => async (dispatch) => {
  try {
    const response = await Geocode.fromAddress(address);
    if (response.status === "OK") {
      const addr_list = response.results[0].address_components;
      let city, state;
      addr_list.forEach((row) => {
        if (row.types.includes("administrative_area_level_1"))
          state = row.short_name;
        if (row.types.includes("locality")) city = row.short_name;
      });
      const { lat, lng } = response.results[0]["geometry"]["location"];
      return { success: true, city, state, lat, lng };
    }
  } catch (err) {
    return { success: false };
  }
  return { success: false };
};

export const resetPassword = (data) => async (dispatch) => {
  const endpoint = `${base}/identity/resetPassword`;
  try {
    const response = await fetch_retry_put(endpoint, data);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};
export const orderDelivery = (params) => async (dispatch) => {
  const { BusinessId, pgStart, pgSize } = params;
  const endpoint = `${base}/order/details/${BusinessId}?pgStart=${pgStart}&pgSize=${pgSize}`;

  delete params.pgStart;
  delete params.pgSize;
  delete params.BusinessId;
  try {
    let response = await fetch_retry_post(endpoint, params);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, ...resp };
    } else {
      return { success: false, msg: "Error fetching orders delivery details" };
    }
  } catch (error) {
    console.warn(error);
  }
};
export const getDisputeReasons = () => async (dispatch) => {
  const endpoint = `${base}/invoice/fetchDisputeReasons`;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    return { success: true, payload: Object.entries(resp?.disputeReasons) };
  } catch (error) {
    console.error(error);
  }
};
export const raiseDispute = (payload, invoiceId) => async () => {
  const endpoint = `${base}/invoice/raiseDispute/${invoiceId}`;
  try {
    let response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    return { success: true, msg: resp.message };
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const saveCommentsforBiz = (payload) => async () => {
  const endpoint = `${base}/dispute/comment`;
  try {
    let response = await fetch_retry_post(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { ...resp };
    } else {
      return { success: false, msg: "" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const disputeStatusUpdate = (payload, disputeId) => async () => {
  const endpoint = `${base}/invoice/dispute/status/${disputeId}`;
  try {
    let response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { ...resp };
    } else {
      return { success: false, msg: "error updating dispute status" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
};
export const disputeListingsForBiz = (params) => async () => {
  const { pgstart, pgSize } = params;
  const endpoint = `${base}/dispute/search?pgStart=${pgstart}&pgSize=${pgSize}`;

  delete params.pgstart;
  delete params.pgSize;
  try {
    let response = await fetch_retry_post(endpoint, params);

    const resp = response.json();
    if (response.ok) {
      return { success: true, ...resp };
    } else {
      return { success: false, msg: "can't fetch list of disputes" };
    }
  } catch (error) {
    console.log(error);
  }
};
const fetchComments = (disputeId) => async () => {
  const endpoint = `${base}/dispute/fetchComments/${disputeId}`;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = response.json();
    return { success: true, payload: resp };
  } catch (error) {
    console.log(error);
  }
};

const verifyWalletForTransac = (payload, userId) => async () => {
  const endpoint = `${base}/wallet/verify`;
  try {
    let response = await fetch_retry_post(endpoint, payload);
    const resp = response.json();
    return { success: true, data: resp };
  } catch (error) {
    console.log(error);
  }
};

export const GooglePermission = (params) => async (dispatch) => {
  const endpoint = `${base}/appointment/googleCalendar/permission`;

  try {
    let response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    console.log("GooglePermission response>>>", response);

    if (response.ok) {
      return { success: true, resp };
    } else {
      return { success: false, resp };
    }
  } catch (error) {
    return { success: false, msg: error?.response?.data?.errorMessages[0] };
    // console.warn("errorMessages",error);
  }
};
export const walletTransaction = (data) => async (dispatch) => {
  const { userId, pgStart, pgSize } = data;
  const endpoint = `${base}/wallet/transactions?userId=${userId}&pgStart=${pgStart}&pgSize=${pgSize}`;
  try {
    const response = await fetch_retry_get(endpoint, {});
    if (response.ok) {
      const resp = await response.json();
      return { success: true, ...resp };
    } else {
      return { success: false, message: response.message };
    }
  } catch (error) {
    return { success: false, message: error.message };
  }
};
export const fetchIndustryType = () => async (dispatch) => {
  const endpoint = `${base}/identity/businessTypes`;
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    return resp;
  } catch (error) {
    console.log("error", error);
  }
};
export const fetchSlotTimings = (payload, locationId) => async (dispatch) => {
  const endpoint = `${base}/appointment/slots?locationId=${locationId}`
  try {
    const response = await fetch_retry_post(endpoint, payload);
    if (response.ok) {
      const resp = await response.json();
      console.log("resp", resp)
      return { success: true, resp: resp };
    } else {
      return { success: false, message: response }
    }
  }
  catch (error) {
    // return { success: false, resp: error?.response?.data?.message || error?.response?.data?.errorMessages[0] }
    console.log("error", error);
  }
}

export const fetchBusinessAppointmentList = (params) => async (dispatch) => {
  const { pgStart, pgSize, businessId, locationId } = params;

  const endpoint = `${base}/appointment/business/${businessId}/location/${locationId}?pgStart=1&pgSize=5`;
  delete params?.businessId;
  delete params?.locationId;
  try {
    const response = await fetch_retry_post(endpoint, params);
    if (response.ok) {
      const resp = await response.json();
      console.log("ressssss>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", resp)
      return { success: true, resp: resp };
    } else {
      return { success: false, message: response }
    }
  }
  catch (error) {
    return { success: false, resp: error?.response?.data?.message || error?.response?.data?.errorMessages[0] }
  }
}

export const fetchAppointmentCount = (params) => async () => {
  const { businessId, locationId, month, year } = params;
  const endpoint = `${base}/appointment/count/business/${businessId}/location/${locationId}?month=${month}&year=${year}`
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, ...resp };
    } else {
      return { success: false, msg: "Error fetching appointmmet counts" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
}

export const fetchEntityList = () => async () => {
  const endpoint = `${base}/identity/entity`
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, data: resp };
    } else {
      return { success: false, msg: "Error fetching entities list" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
}

export const fetchFaqCategoryList = (params) => async () => {
  const { entityId } = params;
  const endpoint = `${base}/identity/faq_category/${entityId}`
  try {
    const response = await fetch_retry_get(endpoint);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, data: resp };
    } else {
      return { success: false, msg: "Error fetching FAQ Categories" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
}

export const fetchFaqQuestionAnswer = (params) => async () => {
  const { searchStr, categoryId } = params;
  let endpoint = `${base}/identity/frequentlyAskedQuestions/category/${categoryId}`;

  if (searchStr) {
    endpoint = `${endpoint}?text=${searchStr}`;
  }

  params.fetchByGoal = params.fetchByGoal.toUpperCase()
  delete params.searchStr;
  delete params.categoryId;

  try {
    const response = await fetch_retry_get(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, data: resp };
    } else {
      return { success: false, msg: "Error fetching FAQ questions answers" };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
}

export const emailUnsubscribe = (payload) => async (dispatch) => {
  const endpoint = `${base}/identity/email_unsubscription`;
  try {
    const response = await fetch_retry_put(endpoint, payload);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const emailSubscribe = (params) => async (dispatch) => {
  const { emailId } = params;
  const endpoint = `${base}/identity/email_subscription/${emailId}`;
  delete params.emailId;

  try {
    const response = await fetch_retry_put(endpoint, params);
    const resp = await response.json();

    if (response.ok) {
      return { success: true, msg: resp.message };
    } else {
      return { success: false, msg: resp.message };
    }
  } catch ({ message }) {
    return { success: false, msg: message };
  }
};

export const FetchPromoCodes = (params) => async (dispatch) => {
  const { text, status } = params
  let endpoint = `${base}/admin/promocode/list?`
  if (text) {
    endpoint = `${endpoint}text=${text}`;
  }
  if (status) {
    endpoint = `${endpoint}&status=${status}`;
  }
  delete params.status;
  delete params.text;
  try {
    let response = await fetch_retry_get(endpoint);
    const resp = await response.json();
    if (response.ok) {
      return { success: true, resp };
    } else {
      return { success: false, msg: "Error fetching promocodes" };
    }
  } catch (error) {
    return { success: false, msg: error.message };
  }
}

export const userActions = {
  classicLogin,
  GetAllTerms,
  updateTerms,
  getAccount,
  fetchPdf,
  ContactUs,
  logout,
  addLocation,
  getLocation,
  updateLocation,
  getLocInteractions,
  getIndvInteractions,
  downloadPDF,
  getTerms,
  getGeoLocation,
  updateProfile,
  requestToken,
  verifyToken,
  resetPassword,
  getLocationFromPostalCode,
  register,
  deleteUserAccount,
  orderDelivery,
  getDisputeReasons,
  raiseDispute,
  saveCommentsforBiz,
  disputeStatusUpdate,
  disputeListingsForBiz,
  fetchComments,
  fetchIndustryType,
  verifyCode,
  verifyWalletForTransac,
  walletTransaction,
  fetchSlotTimings,
  googlePermissionCheck,
  GooglePermission,
  fetchBusinessAppointmentList,
  syncAppointment,
  fetchAppointmentCount,
  deleteLocation,
  fetchEntityList,
  fetchFaqCategoryList,
  fetchFaqQuestionAnswer,
  emailUnsubscribe,
  emailSubscribe,
  FetchPromoCodes,
};
