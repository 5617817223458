import React, { useEffect, useState, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Space,
  notification,
  Popconfirm,
  message,
  Button,
  Typography,
  Divider,
  Modal,
  Collapse,
  Table,
  Select,
  Input,
  Image,
  Radio,
} from "antd";

import {
  CheckCircleOutlined,
  DownloadOutlined,
  ArrowLeftOutlined,
  FormOutlined,
} from "@ant-design/icons";
import clipboard_black from "../../assets/images/clipboard_black.png";

import ReviewComments from "../../components/ReviewComments/ReviewComments";
import { AiFillHeart } from "react-icons/ai";
import Axios from "axios";
import Phone from "../../assets/images/offer-phone.svg";
import Globe from "../../assets/images/offer-globe.svg";
import Heart from "../../assets/images/offer-heart.svg";
import Share from "../../assets/images/offer-share.svg";
import mail from "../../assets/images/mail.svg";
import Placeholder from "../../assets/images/placeholder-image.jpg";
import ReviewStar from "../../assets/images/review-star-icon.svg";
import Sliders from "../../assets/images/sliders.svg";
import { parseAddress, getFormattedDt } from "../../common/util";
import GoogleMapNew from "../../components/GoogleMap";
import { Marker } from "react-google-maps";
import Loader from "../../components/Loading/Loader";
import { useSelector, useDispatch } from "react-redux";
import { Actions } from "../../common";
import CardPay from "../Payment/CardPay";
import { Helmet } from "react-helmet";
import accordion from "../../assets/images/Accordian.svg";
import uploadImage from "../../assets/images/uploadImage.svg";
import cancelImg from "../../assets/images/cancel-img.svg";
import featureImage from "../../assets/images/featuredLogo.png";
import UserActions from "../../actions";
import {
  DocViewer,
  BizInfo,
  ImageSlider,
  RichTextViewer,
} from "../../components";
import { isMobile } from "../../common/util";
import { isTab } from "../../common/util";
import getEnvVars from "../../environment";
import ItemView from "./ItemView";

import { Theme } from "../../common";
import Images from "../../assets/images/Images";
import { getMaxLimit, getRemainingOffer } from "../../utils/Helper";
import viewmore from "../../assets/images/viewmore.svg";
import viewless from "../../assets/images/viewless.svg";
import QuantitySelector from "../../components/QuantitySelector";
import RatingAverage from "../../components/RatingChart/RatingAverage";
import RatingChart from "../../components/RatingChart/RatingChart";
import ReviewStars from "../../components/ReviewStars/ReviewStars";

import closeBtn from "../../assets/images/closeBtn.svg";
import Phoneblue from "../../assets/images/PhoneBlue.svg";
import facebook from "../../assets/images/facebook.svg";
import actions from "../../actions";
import UserCommentText from "../../components/ReviewComments/UserCommentText";
import moment from "moment";
import CustomizedModal from "../../components/CustomizedModal/CustomizedModal";
import AllReviewPopUp from "../../components/AllReviewPopUP/AllReviewPopUp";
import { useRecoilState } from "recoil";
import { offerPageState } from "../../recoil/Atom";
import { FacebookIcon, FacebookShareButton } from "react-share";
import LoadingOverlay from "react-loading-overlay";
import SlotBooking from "../../components/SlotBooking/SlotBooking";
import AllAppointment from "../../components/AllAppointment/AllAppointment";
import { useGoogleLogin } from "react-google-login";
import OfferSummary from "../../components/OfferSummary/OfferSummary";
import HelmetFyndr from "../../components/HelmetFyndr";
import usePageSEO from "../../components/usePageSEO";
import PurchasePopup from "./PurchasePopup";
const { TextArea } = Input;
const { Panel } = Collapse;
const { Text } = Typography;
const { web, qrPrefix } = getEnvVars();
const PREVIEW = "preview";
const OfferDetails = (props) => {
  const [userComment, setUserComment] = useState(false);
  const [replyComment, setReplyComment] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const topContainer = useRef();
  const [offerPageSetting, setOfferPageSetting] =
    useRecoilState(offerPageState);
  const [appointLoading, setAppointmentLoading] = useState(false);
  const [reportComment, setReportComment] = useState("");
  const [appointVisible, setAppointVisible] = useState(false);
  const [appointmentData, setAppointmentData] = useState([]);
  const [title, setTitle] = useState("");
  const [paraVisible, setParaVisible] = useState(false);
  const [selectedPlatformUser, setSelectedPlatformUser] = useState({});
  const [later, setLater] = useState(false);
  const [viewLocation, setViewLocation] = useState(false);
  const closeIcon = (
    <img src={closeBtn} alt="closeBtn" className="closeIconBtnAllReviewPopUp" />
  );

  const [consentVisible, setConsentVisible] = useState(false);

  const [liked, setLiked] = useState(props?.history?.location?.state?.liked);
  const { state } = props?.history?.location;
  const locQRId = props?.history?.location?.state?.locQRId;

  let history = useHistory();
  const currentRoute = useLocation().pathname;
  const { preview } = useSelector(({ preview }) => preview);
  const { SET_ROUTE, ROLE_MANAGER, ROLE_SUPPORT } = Actions;
  const [cmpn, setCmpn] = useState(preview ? preview : undefined);
  const [bizIdUser, setBizId] = useState(null);
  const [cart, setCart] = useState({});
  const [total, setTotal] = useState(0);
  const [, setTotalTax] = useState(null);
  const [taxRate, setTaxRate] = useState(null);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);
  const [cartCurrency, setCartCurrency] = useState(null);
  const [cartCurrencySymbol, setCartCurrencySymbol] = useState(null);
  const [showCard, setShowCard] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = useState(true);
  const [seeAllComments, setSeeAllComments] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [reviewsOverview, setReviewsOverview] = useState(null);
  const [comments, setComments] = useState(null);
  const [commentForReview, setCommentForReview] = useState("");
  const [rating, setRating] = useState(0);
  const [businessId, setBusinessId] = useState("");
  const [selectedImages, setSelectedImages] = useState(Array(6).fill(null));
  const [reportModalVisble, setReportModalVisible] = useState(false);
  const [replyModalVisible, setReplyModalVisible] = useState(false);
  const [replyData, setReplyData] = useState({});
  const [reportData, setReportData] = useState();
  const [expandedComments, setExpandedComments] = useState([]);
  const [expandedReplies, setExpandedReplies] = useState([]);
  const [allVisible, setAllVisible] = useState(false);
  const [reportedCommentVisible, setReportedCommentVisible] = useState(false);
  const [locPhone, setLocPhone] = useState("");
  const [popupVisible, setPopupVisible] = useState(false);
  const [createdInvoice, setCreatedInvoice] = useState(null);
  const [payCard, setpayCard] = useState(null);
  const [selectedLocation,setSelectedLocation]=useState()
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [locPagination, setLocPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
    showSizeChanger: true,
  });

  const [sortByData, setSortByData] = useState({
    orderBy: "RATING",
    orderType: "DESC",
  });
  const [userappointmentModal, setUserAppointmentModal] = useState(false);
  const [numQty, setNumQty] = useState(null);
  const [userallappointmentModal, setUserAllAppointmentModal] = useState(false);

  const [showNumberModal, setShowNumberModal] = useState(false);
  const {
    roleType,
    indv,
    indv: { indvid, address },
  } = useSelector(({ auth }) => auth);
  const [lat, setLat] = useState(
    offerPageSetting?.lat || address?.lat || 33.6629442
  );
  const [lng, setLng] = useState(
    offerPageSetting?.lng || address?.lng || -112.0182329
  );
  const [addressName, setAddressName] = useState(
    offerPageSetting?.addressName || parseAddress(address)
  );
  const { isMasquerade } = useSelector(({ admin }) => admin);

  const { cmpnsLiked } = useSelector(({ cmpn }) => {
    return cmpn;
  });
  const { CMPN_LIKED, CHECKOUT_STARTED } = Actions;

  const [isCollapsed, setIsCollapsed] = useState(false);
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const scrollToTop = () => {
    if (topContainer.current) {
      topContainer.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    // To make sure page starts from the top
    topContainer.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }, []);
  useEffect(() => {
    localStorage.removeItem("googleAccessToken");
  }, []);

  useEffect(() => {
    (async () => {
      await setOfferPageSetting({
        ...offerPageSetting,
        lat,
        lng,
        addressName,
      });
    })();
  }, [lat, lng, addressName]);

  const accordionCollapse = () => {
    setAccordion(!isAccordion);
  };
  const [isAccordion, setAccordion] = useState(false);

  const [sortBy, setSortBy] = useState(
    state?.item && state?.item?.cmpnType === "coupons" ? "DISCOUNT" : "PRICE"
  );
  const [sortingOrder, setSortingOrder] = useState("ASC");
  const cardRef = useRef(null);
  const bizEmail = cmpn?.biz?.bizEmail;
  const sortingOptions = [
    {
      value: JSON.stringify({
        orderBy: "PRICE",
        orderType: "DESC",
      }),
      label: "Price: High To Low",
    },
    {
      value: JSON.stringify({
        orderBy: "PRICE",
        orderType: "ASC",
      }),
      label: "Price: Low To High",
    },
    {
      value: JSON.stringify({
        orderBy: "DISCOUNT",
        orderType: "DESC",
      }),
      label: "Discount: High To Low",
    },
    {
      value: JSON.stringify({
        orderBy: "DISCOUNT",
        orderType: "ASC",
      }),
      label: "Discount: Low To High",
    },
  ];

  const sortingOptions2 = [
    {
      value: JSON.stringify({
        orderBy: "DISCOUNT",
        orderType: "DESC",
      }),
      label: "Discount: High To Low",
    },
    {
      value: JSON.stringify({
        orderBy: "DISCOUNT",
        orderType: "ASC",
      }),
      label: "Discount: Low To High",
    },
  ];

  const [selectedSorting, setSelectedSorting] = useState(
    JSON.stringify({
      orderBy: "PRICE",
      orderType: "ASC",
    })
  );
  const [selectedCouponSorting, setSelectedCouponSorting] = useState(
    JSON.stringify({
      orderBy: "DISCOUNT",
      orderType: "DESC",
    })
  );

  useEffect(() => {
    if (showCard) {
      cardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [showCard]);
  useEffect(() => {
    if (bizIdUser) {
      getReviewList(bizIdUser);
      getReviewOverViews(bizIdUser);
    }
  }, [sortByData, pagination.current, pagination.total, pagination.pageSize]);
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
    });
  };
  let country = indv?.address?.country;
  let postalCode = indv?.address?.postalCode;

  const fetchGiftOffer = async (formData, invoiceId) => {
    setIsLoading(true);

    const payload = {
      invoiceId: invoiceId,
      gifteeEmail: formData.email,
      gifteePhone: formData.phoneNumber,
      message: formData.message,
      gifteeName: formData.name,
      countryCode: formData.countryCode,
    };

    const response = await actions.GiftOffer(payload);

    if (response?.success) {
      message.success(response?.message);
    } else {
      message.error(response?.message[0]);
    }
    setIsLoading(false);
  };

  const fetchCmpn = async (cmpn_id) => {
    setIsLoading(true);
    const response = await UserActions.fetchCmpnForSoloView(cmpn_id);
    const { success, cmpn } = response;
    setIsLoading(false);
    if (success) {
      if (cmpn.status !== "active") {
        message.error("Campaign is not active");
        props.history.pop();
      } else {
        setCmpn(cmpn);

        const metaData = {
          title: `${cmpn?.title} | ${props.match.params.bizName}`,
          description: cmpn?.descr,
          image: cmpn?.cmpnImgs[0]?.imgUri,
        };

        // fetch('http://localhost:5000/set-meta-data', { // Ensure the correct URL
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(metaData),
        // })
        //   .then(response => response.text())
        //   .then(data => {
        //     console.log('Meta data updated:', data);
        //   })
        //   .catch(error => {
        //     console.error('Error updating meta data:', error);
        //   });
      }
    } else {
      message.error("Error getting campaign details");
    }
  };

  const [enabledLoc, setEnableLoc] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState(enabledLoc);
  const [postalCodeValue, setPostalCodeValue] = useState({});
  const [tempLoc, setTempLoc] = useState(enabledLoc);
  const [selectedObj, setSelectedObj] = useState({});
  const [newSelectedObj, setNewSelectedObj] = useState({});
  const [locationDropdownData, setLocationDropdownData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [locChange, setLocChange] = useState(false);
  const [locName, setLocName] = useState("");
  const handleSelectChange = (e) => {
    setLocChange(true);
    setTempLoc(e);
  };
  const [enable, setEnable] = useState(false);
  const [locationEnabledValue, setLocationEnabledValue] = useState(false);

  const fetchTheCampaign = async (QR, sortBy, sortingOrder) => {
    setIsLoading(true);
    let params = {
      lat,
      lng,
    };
    let response = await UserActions.fetchByQR(
      QR,
      params,
      sortBy,
      sortingOrder
    );
    setLocPagination({
      ...locPagination,
      total: response?.payload?.campaignLocationCount,
    });

    if (response.success) {
      setIsLoading(false);
      setCmpn(response.payload);
      const metaData = {
        title: `${response.payload?.title} | ${props.match.params.bizName}`,
        description: response.payload?.descr,
        image: response.payload?.cmpnImgs[0]?.imgUri,
      };

      // fetch(`http://localhost:5000/set-meta-data`, { // Ensure the correct URL
      // // fetch(`${web}/set-meta-data`, { // Ensure the correct URL
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(metaData),
      // })
      //   .then(response => response.text())
      //   .then(data => {
      //     console.log('Meta data updated:', data);
      //   })
      //   .catch(error => {
      //     console.error('Error updating meta data:', error);
      //   });
      // setEnable(response.payload.cmpnOffers)
      let initial = response.payload.cmpnLocs
        .filter((item) => item?.isBookingEnabled)
        .map((item) => item?.locationId);
      setEnableLoc(initial[0]);
      // if(response?.payload?.goal === "online")
      // {
      //   setSelectedLocationId(initial[0])

      // }
      setMerchantID(response.payload.biz.merchantId);
      setBizId(response.payload.biz.bizid);
      const isLogin = indv?.indvid ? true : false;
      getReviewList(response.payload.biz.bizid);
      getReviewOverViews(response.payload.biz.bizid);
      if (isLogin) {
        await UserActions.fetchBizinfo({
          bizId: [response.payload.biz.bizid],
        });
      }
    }
  };

  const onHandleSubmit = () => {
    if (replyComment === "") {
      message.error("Please enter your reply");
    } else {
      replyToTheComment(replyData?.commentId);
    }
  };

  const onReportSubmit = () => {
    if (reportComment === "") {
      message.error("Please add text to report the comment");
    } else {
      reportToTheComment(replyData?.commentId);
    }
  };

  const onChangeHandler = (value) => {
    setReplyComment(value.target.value);
  };

  const onReportHandler = (value) => {
    setReportComment(value.target.value);
  };

  const getReviewList = async (cmpn) => {
    let pgStart = pagination.current;
    let pgSize = pagination.pageSize;
    let res = await UserActions.fetchReviewsratings(
      cmpn,
      sortByData,
      pgStart,
      pgSize
    );
    setPagination({
      ...pagination,
      total: res?.cmpn?.count,
    });
    setComments(res.cmpn);
  };

  const replyToTheComment = async (commentId) => {
    setIsLoading(true);
    let res = await UserActions.addReply({
      commentId: commentId,
      businessId: businessId,
      reply: replyComment,
    });
    if (res.success) {
      setIsLoading(false);
      message.success(res.msg);
      getReviewList(bizIdUser);
      setReplyComment("");
      setReplyModalVisible(false);
    }
  };

  const [getTimes, setGetTime] = useState([]);
  const [qtyEnabled, setQtyEnabled] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const reportToTheComment = async (commentId) => {
    let response = await UserActions.addReport({
      commentId: commentId,
      businessId: businessId,
      reportMessage: reportComment,
    });
    if (response.success) {
      message.success(response?.msg);
      getReviewList(bizIdUser);
      setReportComment("");
      setReportModalVisible(false);
    }
  };

  const getReviewOverViews = async (cmpn) => {
    let res = await UserActions.fetchReviewsOverview(cmpn);
    setReviewsOverview(res.cmpn);
    setBusinessId(res?.cmpn?.bizId);
  };
  const doLike = async (cmpnId, liked) => {
    let payload = {
      cmpnId,
      indvId: indvid,
      bizId: cmpn.biz.bizid,
    };
    if (liked) {
      payload.objid = liked.likedId;
      payload.isDeleted = !liked.isDeleted;
    }
    const resp = await UserActions.likeCmpn(payload);

    if (resp.success) {
      dispatch({ type: CMPN_LIKED, payload: resp.payload });
    } else {
      message.error("Please login first");
    }
  };
  const QR = props.match?.params.qrCode;
  const errorNotification = () => {
    notification.error({
      message: "Campaign Expired",
      description: "This campaign is expired.",
    });
    props.history.replace("/");
  };
  useEffect(() => {
    (async () => {
      if (QR !== PREVIEW && !state?.isSolo) {
        await fetchTheCampaign(QR, sortBy, sortingOrder);
      } else if (state?.isSolo) {
        fetchCmpn(state.cmpn_id);
      }
    })();
  }, [sortBy]);

  useEffect(() => {
    console.log("what in enable", enabledLoc);
  }, [enabledLoc]);
  const columns = [
    {
      title: "Datatype",

      render: (data, key) => {
        return (
          <Col span={24}>
            <ReviewComments
              reportModalVisibleClick={() => {
                if (data?.isReportingAllowed === true) {
                  setReplyData(data);
                  setReportComment("");
                  setReportModalVisible(true);
                } else {
                  setReportModalVisible(false);
                  setReportData(data.reportedComments);
                  setReportedCommentVisible(true);
                }
              }}
              openParagraph={(data) => {
                setSeeAllComments(false);
                setTitle("Comment");
                setParaVisible(true);
                setSelectedPlatformUser(data);
              }}
              indv={indv?.bizid}
              businessId={businessId}
              setModalVisible={() => {
                setReplyData(data);
                setReplyModalVisible(true);
              }}
              data={data}
            />
          </Col>
        );
      },
    },
  ];
  const LocColumn = [
    {
      title: "Datatype",
      render: (data, key) => {
        return (
          <Row>
            <Col xs={24} sm={24} md={18} xl={18} xxl={18}>
              <div key={data.locName}>
                <div>
                  {locationEnabledValue && <Radio checked={selectedLocationId === data.locationId} onChange={() => {
                    setSelectedLocationId(data.locationId);
                    setSelectedLocation(data)
                    setLocName(data.locName)
                    setPostalCodeValue({ "postalCode": data.postalCode, "country": data.country })
                  }} />}
                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#7F7F7F",
                    }}
                  >
                    {data.locName || "Location Name"}
                    <br />
                    {(data?.country && parseAddress(data)) || "Address"}
                  </Text>
                </div>
                <div className="bottom-margin-12">
                  <Text
                    style={{
                      fontSize: "14px",
                      fontWeight: "400",
                      color: "#7F7F7F",
                    }}
                  >
                    ({data.distance ? data.distance.toFixed(1) : "0"} miles)
                  </Text>
                </div>

                <div className="bottom-margin-12">
                  {data.phone && (
                    <BizInfo
                      icon={
                        <img
                          src={Phone}
                          alt="phone"
                          className="catalogue_valid-icons"
                        />
                      }
                      size={20}
                      phone={data.phone}
                      callback={(value) => {
                        setLocPhone(data.phone);
                        setShowNumberModal(value);
                      }}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={6} xl={6} xxl={6}>
              {(cmpn.cmpnUrl || data.catalogueId) && (
                <div className="bottom-margin-12">
                  <BizInfo
                    link={cmpn.cmpnUrl}
                    icon={
                      cmpn.cmpnUrl ? (
                        <img
                          src={Globe}
                          alt="mail"
                          className="catalogue_valid-icons"
                        />
                      ) : (
                        ""
                      )
                    }
                    size={20}
                    showStore={
                      data.catalogueId !== null &&
                        data.catalogueId !== undefined && !locationEnabledValue
                        ? true
                        : false
                    }
                    onClickViewStore={() => {
                      scrollToTop();
                      setIsLoading(true);
                      setTimeout(() => {
                        onCatalogue(data.locationId);
                        handleLinkClick();
                        setIsLoading(false);
                      }, 1000);
                    }}
                  />
                </div>
              )}
            </Col>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    cmpn && setLiked(cmpnsLiked[cmpn?.objid]);
  }, [cmpnsLiked]);
  {
  }
  const addr =
    cmpn && cmpn.biz?.showBiz
      ? `${cmpn?.cmpnLocs[0]?.distance?.toFixed(1)} miles, ${parseAddress(
        cmpn?.cmpnLocs[0]
      )}`
      : "";
  // const url = `${web}/offer-details/${QR}`;

  useEffect(() => {
    if (cmpn && indv) {
      persistChanges();
    }
  }, [cart, appointmentData]);

  useEffect(() => {
    if (cmpn) {
      let currentDate = new Date();
      let endDate = new Date(cmpn.endDt);
      if (currentDate > endDate) {
        errorNotification();
      } else {
      }
    }
    let resCmpn = cmpn?.offers?.every(
      (item) =>
        // item.offerLimit === 0
        item.offerLimit !== null &&
        Number(item.offerLimit) - Number(item.offerSold) === 0
    );
    setButtonVisible(resCmpn == false ? true : false);
  }, [cmpn]);
  const [merchantId, setMerchantID] = useState(cmpn?.biz?.merchantId || null);

  const getTax = async (offer) => {
    // if (taxRate) return taxRate;
    if (!offer?.stdTax) {
      const tmp = offer?.taxPercent / 100;
      setTaxRate(tmp);
      return tmp;
    }
    //fetch applicable tax
    // const resultArray = cmpn?.cmpnLocs
    // ?.filter((item) => item.locationId == selectedLocationId)
    const resp = await UserActions.getTax({
      postalCode: cmpn.goal === "online" ? postalCode : postalCodeValue?.postalCode || cmpn?.cmpnLocs[0]?.postalCode,
      country: cmpn.goal === "online" ? country : postalCodeValue?.country || cmpn?.cmpnLocs[0]?.country,
    });
    if (!resp.success) {
      message.error("Tax could not be obtained");
      return false;
    }
    setTaxRate(resp.data.taxRate);
    setDisabledInput(false);
    return resp.data.taxRate;
  };
  console.log("taxRate",taxRate)
  const [quantity, setQuantity] = useState(null);
  const [offername, setOfferName] = useState("");

  const handleChangeQty = async (id, num, isLater = false) => {
    setNumQty(num);
    setDisabledInput(true);
    if (!indv) {
      return;
    }
    const offer = cmpn?.offers?.filter((row) => row?.objid === id)[0];
    setOfferName(offer?.title);
    const rate = await getTax(offer);
    if (rate === false) {
      return false;
    }
    const qty = Math.ceil(Number(num));
    const totalAmt = qty * Number(offer?.offerPrice);
    const tax = totalAmt * Number(rate);
    const rowTotal = totalAmt + tax;
    offer.rowTotal = rowTotal?.toFixed(2);
    offer.tax = tax.toFixed(2);
    offer.totalAmt = totalAmt.toFixed(2);
    offer.qty = qty;
    offer.isLater = isLater;
    setQuantity(qty);
    offer.unitTax = (Number(offer.offerPrice) * Number(rate)).toFixed(2);
    let grandTotal = 0;
    let grandTax = 0;
    Object.values(cart)
      .filter((row) => row?.objid !== id)
      .forEach((row) => {
        grandTotal += Number(row?.rowTotal);
        grandTax += Number(row?.tax);
      });
    setCart((prevState) => ({ ...prevState, [id]: offer }));
    let tmp = grandTotal + rowTotal;
    setTotal(tmp > 0 ? tmp.toFixed(2) : null);
    setTotalTax((grandTax + tax).toFixed(2));
    if (!cartCurrency) {
      setCartCurrency(offer.currency);
      setCartCurrencySymbol(offer.currencySymbol);
    }
    setSelectedId(id);

    // if (enable) { addObject(offer) }
  };

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const onCatalogue = async (locId) => {
    const resp = await UserActions.getCatalogueByLoc({
      locId: locId,
    });
    if (!resp.success) {
      message.error("Error retrieving catalogue");
    } else {
      history.push(`/catalogue/store/${resp.payload.catalogue.url}`, {
        catalogueDetail: resp.payload,
        locationId: locId,
        // fromBizAccount: true,
      });
    }
  };
  const handleButtonClick = (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = (event) => handleFileChange(event, index);
    fileInput.click();
  };
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const maxFileSize = 5 * 1024 * 1024;
    if (!/^image\//.test(file.type)) {
      message.error(`Please upload image type files only.`);
    } else if (file.type === "image/svg+xml") {
      message.error("SVG files are not allowed.");
    } else if (file.size <= maxFileSize) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const URL = reader.result.replace(/^data:image\/[a-z]+;base64,/, "");
        const imageType = file.type.replace(/^image\//, "");
        const updatedSelectedImages = selectedImages.map((image, i) => {
          if (i === index) {
            if (imageType === "svg+xml") {
              return {
                url: { Placeholder },
                imageType: "jpg",
                image: "",
              };
            } else {
              return {
                url: reader.result,
                imageType: imageType,
                image: URL,
              };
            }
          }
          return image;
        });

        setSelectedImages(updatedSelectedImages);
      };
    } else {
      message.error("The image file size should be less than or equal to 5MB.");
    }
  };

  const handleCancelClick = (index) => {
    let selectedImagesArr = [...selectedImages];
    selectedImagesArr[index] = null;
    setSelectedImages(selectedImagesArr);
  };

  const handleOnSubmit = () => {
    if (rating === 0) {
      message.error("Minimum rating should be 1");
    } else if (commentForReview === "") {
      message.error("Please add the comment to proceed");
    } else {
      AddReviewComments();
    }
  };
  useEffect(() => {
    setAppointmentData(appointmentData);
  }, [appointmentData]);
  const AddReviewComments = async () => {
    setIsLoading(true);
    let newImgs = selectedImages
      .filter(
        (item) =>
          item !== undefined && item !== null && Object.keys(item).length !== 0
      )
      .map((item) => {
        return {
          imageType: item?.imageType,
          image: item?.image,
        };
      });
    let response = await actions.addReview({
      businessId: businessId,
      userId: indvid,
      text: commentForReview,
      rating: rating,
      images: newImgs,
    });
    if (response?.success === true) {
      setIsLoading(false);
      message.success(response?.msg);
      setUserComment(false);
      setRating(0);
      setCommentForReview("");
      getReviewList(businessId);
      getReviewOverViews(businessId);
    } else {
      message.error(response?.msg);
      setCommentForReview("");
      setUserComment(false);
    }
  };

  let reviewdata = [
    {
      star: 5,
      count: 2000,
    },
    {
      star: 4,
      count: 1500,
    },
    {
      star: 3,
      count: 100,
    },
    {
      star: 2,
      count: 50,
    },
    {
      star: 1,
      count: 20,
    },
  ];
  function monthToNumber(month) {
    const months = {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    };

    return months[month];
  }

  function getFullDayName(abbreviatedDay) {
    abbreviatedDay = abbreviatedDay?.toUpperCase();
    const dayMap = {
      SUN: "Sunday",
      MON: "Monday",
      TUE: "Tuesday",
      WED: "Wednesday",
      THU: "Thursday",
      FRI: "Friday",
      SAT: "Saturday",
    };
    if (dayMap.hasOwnProperty(abbreviatedDay)) {
      return dayMap[abbreviatedDay];
    } else {
      return "Invalid day abbreviation";
    }
  }
  const output = (data) => {
    let dataArray = data;
    let lData = dataArray?.filter(
      (item) => item !== null && item !== undefined
    );
    let flatArray = dataArray?.length > 1 ? lData?.flat() : dataArray;
    const results = flatArray?.map((item) => {
      const dateKey = Object.keys(item)[0];
      const appointmentInfo = item[dateKey];
      const [month, day, dayOfWeek] = dateKey.split(" ");
      return {
        [`${new Date().getFullYear()}-${monthToNumber(month)}-${day
          .toString()
          .padStart(2, "0")}`]: {
          bookingDay: getFullDayName(dayOfWeek?.slice(1, -1)).toUpperCase(),
          endTime: appointmentInfo.endTime,
          locId: appointmentInfo.locId,
          startTime: appointmentInfo.startTime,
          objId: appointmentInfo.objId,
        },
      };
    });
    return results;
  };

  const areAppointmentsAvailable =
    appointmentData !== undefined &&
    appointmentData.some(
      (item) => item?.appointment && item?.appointment.length > 0
    );
  const persistChanges = async () => {
    let baseAmount = 0;
    const selOffers = Object.values(cart).filter((row) => row?.qty > 0);
    selOffers.forEach((row) => (baseAmount += Number(row.totalAmt)));
    const { endDt, finePrint, biz, title } = cmpn;
    const { bizid, bizName, country } = biz || {};
    const taxAmount = Number(baseAmount) * Number(taxRate);

    const invoiceDetails = {
      business_name: bizName,
      business_country: country,
      cmpn_id: cmpn.objid,
      cmpn_end_dt: endDt,
      cmpn_title: title,
      fine_print: finePrint,
      tax_rate: taxRate,
      loc_name: locName,
      locId: selectedLocationId,
      offers: selOffers.map((row) => {

        const matchingAppointments = appointmentData.find(
          (item) => item?.objid === row?.objid
        );
        console.log(
          "persisit",
          matchingAppointments?.appointment,
          matchingAppointments
        );
        const newappoint = output(row?.appointment);
        const payload = {
          offer_id: row.objid,
          offer_price: row.offerPrice,
          qty: row.qty,
          retail_price: row.retailPrice,
          title: row.title,
          usage_limit: row.usageLimit,
          discount_type: row.discountType,
          discount_amount: row.amount,
          validity_period: row.validityPeriod,
          qty_total: row.totalAmt,
          row_tax: row.tax,
          row_total: row.rowTotal,
          unit_tax: row.unitTax,
          ...(matchingAppointments?.appointment?.length > 0 && {
            appointment: newappoint,
          }),
        };
        return payload;
      }),
    };
    const cartInfo = {
      bizid,
      merchantId,
      baseAmount: baseAmount.toFixed(2),
      currency: selOffers.length > 0 ? selOffers[0].currency : null,
      currencySymbol: selOffers.length > 0 ? selOffers[0].currencySymbol : null,
      taxAmount: taxAmount?.toFixed(2),
      invoiceDetails,
    };

    await dispatch({ type: CHECKOUT_STARTED, payload: cartInfo });

    if (baseAmount === 0) setShowCard(false);
  };

  const setRoute = async () => {
    dispatch({ type: SET_ROUTE, payload: currentRoute });
    history.push("/login");
  };

  const locationDropdowm = () => {
    const resultArray = cmpn?.cmpnLocs
      ?.filter((item) => item.isBookingEnabled)
      .map((obj) => {
        const label = parseAddress(obj);
        return {
          ...obj,
          value: obj?.locationId,
          label: obj?.locName,
        };
      });
    setLocationDropdownData(resultArray);
  };

  const showSuccess = (props, createdInvoice, paymentMethod) => {
    setCreatedInvoice(createdInvoice);
    setpayCard(paymentMethod);
    setPopupVisible(true);
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
    props.history.push("/offers");
  };

  const copyTextToClipboard = (url, msg) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          message.success(`${msg} ${url}`);
        })
        .catch((error) => {
          message.error(`Error copying text:, ${error}`);
        });
    } else {
      message.error(`Error copying text`);
    }
  };
  const checkout = async () => {

    if (!total) {
      message.warn("You have not selected any offer yet");
    } else {
      let data = appointmentData.map((item) => {
        return item?.appointment !== undefined && item?.appointment;
      });

      let flatArray = data.flat();
      let lData = data.filter((item) => item === undefined);
      if (lData.length > 0 && !later) {
        message.warn("please select appointment slot for all item");
      } else {
        console.log("lData else");

        setShowCard(true);
      }
    }
  };

  const numberStyle = {
    wrap: {
      padding: "2px 2.26ex 2px 2px",
      borderRadius: "6px 3px 3px 6px",
      fontSize: 16,
    },
    input: {
      borderRadius: "4px 2px 2px 4px",
      padding: "0.1ex 1ex",
      border: "1px solid #ccc",
      marginRight: 10,
      display: "block",
      fontWeight: "400",
      width: "4rem",
      height: "2rem",
    },
    "input:focus": {
      outline: "none",
    },
    arrowUp: {
      borderBottomColor: "#999",
    },
    arrowDown: {
      borderTopColor: "#999",
    },
  };

  const Mailto = ({ email, subject, body, children }) => {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`mailto:${email}?subject=${encodeURIComponent(subject) || ""
          }&body=${encodeURIComponent(body) || ""}`}
      >
        {children}
      </a>
    );
  };

  const download = async (e, uri, title) => {
    e.stopPropagation();
    let fileDownload = require("js-file-download");
    const res = await Axios.get(`${uri}?time=${new Date().getTime()}`, {
      responseType: "blob",
    });
    fileDownload(res.data, `${title}.jpeg`);
  };

  const toggleExpandComment = (index) => {
    setExpandedComments((prevExpandedComments) => {
      const newExpandedComments = [...prevExpandedComments];
      newExpandedComments[index] = !newExpandedComments[index];
      return newExpandedComments;
    });
  };

  const toggleExpandReply = (index) => {
    setExpandedReplies((prevExpandedReplies) => {
      const newExpandedReplies = [...prevExpandedReplies];
      newExpandedReplies[index] = !newExpandedReplies[index];
      return newExpandedReplies;
    });
  };

  const LongText = (text, index, isReply) => {
    const MAX_LENGTH = 400;
    const isLongText = text?.length > MAX_LENGTH;
    const displayText = isLongText
      ? `${text.substring(0, MAX_LENGTH)}...`
      : text;

    const isExpanded = isReply
      ? expandedReplies[index]
      : expandedComments[index];

    const toggleExpand = isReply ? toggleExpandReply : toggleExpandComment;

    return (
      <>
        <RichTextViewer content={isExpanded ? text : displayText} />
        {isLongText && (
          <Text
            style={{ color: "#1890ff", cursor: "pointer" }}
            onClick={() => toggleExpand(index)}
          >
            {isExpanded ? " Read Less" : "Read More"}
          </Text>
        )}
      </>
    );
  };

  function addObject(newObject) {
    // let objectsArray = [];
    // Check if the object with the same objid already exists
    const index = appointmentData?.findIndex(
      (obj) => obj?.objid === newObject?.objid
    );
    console.log(
      "Object index",
      appointmentData,
      index,
      appointmentData?.length > 0,
      newObject
    );
    if (index !== -1) {
      if (newObject?.qty !== 0) {
        appointmentData[index] = newObject;
        console.log(
          `Object with objid ${newObject?.objid} updated in the array.`
        );
      } else {
        appointmentData.splice(index, 1);
        setIsLoading(true);
      }
      if (newObject?.qty === 0) {
        // appointmentData[index]?.appointment?.pop()
        delete newObject?.appointment;
        console.log(`Object with to be minused`, newObject, newObject?.qty);
      }
      if (
        appointmentData[index]?.appointment?.length > newObject?.qty &&
        appointmentData[index]?.objid === newObject?.objid
      ) {
        console.log("in here", index);
        appointmentData[index].appointment.pop();
        setIsLoading(true);
      }
    } else {
      // If the object doesn't exist, add it to the array
      appointmentData.push(newObject);
      console.log(`Object with objid ${newObject?.objid} added to the array.`);
    }

    setAppointmentData(appointmentData);
    setIsLoading(false);
    console.log("appData appointmentData", appointmentData);
  }

  const Confirm = ({ url, content }) => {
    const link = !url?.startsWith("http") ? `https://${url}` : url;
    return (
      <Popconfirm
        overlay
        placement="top"
        title={
          <div className="offer_cards--visiting-link">
            You are visiting the link {url} outside Fyndr domain that is not
            verified or secured by Fyndr, do you want to continue ?
          </div>
        }
        onConfirm={() => {
          window.open(link);
        }}
        okText="Yes"
        cancelText="No"
      >
        {content}
      </Popconfirm>
    );
  };
  const totalofferSold = cmpn?.offers.reduce(function (sum, current) {
    return sum + current.offerSold;
  }, 0);

  const handleTableChangeViewLocations = (pagination) => {
    setLocPagination({
      ...pagination,
    });
  };

  usePageSEO({
    title: `${cmpn?.title} | ${props.match.params.bizName}`,
    description: cmpn?.descr,
    keywords: ["keyword1", "keywords2"],
    ogTitle: `${cmpn?.title} | ${props.match.params.bizName}`,
    ogDescription: cmpn?.descr,
    ogImage: cmpn?.cmpnImgs[0]?.imgUri,
    ogUrl: `${web}/offer-details/${props.match.params.bizName}`,
  });

  // page after opening any one of the offers.
  return (
    <>
      <LoadingOverlay
        active={isLoading}
        spinner
        text=""
        styles={{
          overlay: (base) => ({
            ...base,
            background: "#f5f5f5",
          }),
        }}
      >
        <div ref={topContainer}>
          {cmpn && (
            <HelmetFyndr
              title={`${cmpn?.title} | ${props.match.params.bizName}`}
              imgUrl={cmpn?.cmpnImgs[0]?.imgUri}
              url={`${web}/offer-details/${props.match.params.bizName}`}
              descr={cmpn?.descr}
            />
          )}

          <Row justify="center">
            <Col span={24}>
              {cmpn && cmpn.status !== "active" && QR !== PREVIEW && (
                <Row justify="center">
                  <Col>
                    <h4>
                      Sorry, the offer you are searching is not available
                      anymore..
                    </h4>
                  </Col>
                </Row>
              )}

              <Row className="titleoffline">
                {!isLoading && (
                  <Col>
                    <Button
                      type="link"
                      onClick={() => {
                        // if (state !== undefined) {
                        //   props.history.replace("/offers");
                        // } else {
                        //   props.history.goBack();
                        // }
                        if (locQRId) {
                          props.history.goBack() || history.push('/');
                        } else if (locQRId === undefined) {
                          props.history.goBack() || history.push('/');
                        } else {
                          props.history.replace("/offers")|| history.push('/');
                        }
                      }}
                      className="offer_cards--go-back-btn"
                    >
                      <ArrowLeftOutlined style={{ verticalAlign: "middle" }} />{" "}
                      Go Back
                    </Button>
                  </Col>
                )}

                <Space dir="horizontal" size={"middle"}>
                  {cmpn?.cmpnType && (
                    <Col className="titleText">
                      <Text style={{ verticalAlign: "middle" }}>
                        {cmpn?.cmpnType[0].toUpperCase() +
                          cmpn?.cmpnType.substring(1)}
                      </Text>
                    </Col>
                  )}

                  {(cmpn?.cmpnType === "offers" ||
                    cmpn?.cmpnType === "events") && (
                      <Col>
                        <Select
                          disabled={cmpn?.offers?.length > 1 ? false : true}
                          defaultValue={JSON.stringify({
                            orderBy: "PRICE",
                            orderType: "ASC",
                          })}
                          onChange={(e) => {
                            const { orderBy, orderType } = JSON.parse(e);
                            fetchTheCampaign(QR, orderBy, orderType);
                            setSelectedSorting(e);
                          }}
                          className="reviewrating_filterBTN"
                          options={sortingOptions}
                          value={selectedSorting}
                          suffixIcon={<img src={Sliders} alt="Sliders" />}
                          placeholder="Sort By"
                        />
                      </Col>
                    )}
                  {cmpn?.cmpnType === "coupons" && (
                    <Select
                      disabled={cmpn?.offers?.length > 1 ? false : true}
                      defaultValue={selectedCouponSorting}
                      onChange={(e) => {
                        const { orderBy, orderType } = JSON.parse(e);
                        fetchTheCampaign(QR, orderBy, orderType);
                        setSelectedCouponSorting(e);
                      }}
                      className="reviewrating_filterBTN"
                      options={sortingOptions2}
                      value={selectedCouponSorting}
                      suffixIcon={<img src={Sliders} alt="Sliders" />}
                      style={{
                        fontWeight: 400,
                        fontSize: "18px",
                        color: "#223369",
                        background: "#FFFFFF",
                        border: "1px solid #D3D6E1",
                        borderRadius: "5px",
                        height: "46px",
                        width: "15vw",
                      }}
                      placeholder="Sort By"
                    />
                  )}
                </Space>
              </Row>

              {((cmpn && cmpn.status === "active") ||
                (QR === PREVIEW &&
                  !(props?.history?.location?.state?.isSolo == true))) && (
                  <Card
                    headStyle={{ padding: "0px" }}
                    bodyStyle={{ padding: "0px" }}
                    // className="offer_cards--main-card"
                    className="offer-details-main-card"
                  >
                    <Row
                      justify={"space-between"}
                      className="offer_cards--offer-deatilsrow"
                    >
                      <Col align="center" xs={24} sm={8} md={8}>
                        {/* card with image after opening any one of the offer */}
                        {!isMobile() ? (
                          <Card
                            bodyStyle={{ padding: "0 0 24px 0" }}
                            style={{ borderRadius: "10px", marginBottom: "24px" }}
                            className="offer_cards--offer-details-border offer_cards--offer-main-card"
                          >
                            <ImageSlider
                              imageClass="offer_cards--cmpn-img_big"
                              cmpnImgs={cmpn?.cmpnImgs}
                              cmpnVideos={cmpn?.campaignVideo}
                            />

                            <Col align="left">
                              <Row
                                justify="space-between"
                                className="offer_cards--offer-and-icons"
                              >
                                <Col xs={0} sm={24} md={24}>
                                  <div className="bottom-margin-24">
                                    <Text
                                      className="offer_cards--offer-bizName"
                                      style={{ color: "#3A3A3A" }}
                                    >
                                      {cmpn.biz?.bizName}
                                    </Text>
                                  </div>
                                </Col>
                              </Row>

                              <Col xs={24} sm={0} md={0}>
                                <Row
                                  style={{
                                    padding: "0 12px 12px 12px",
                                  }}
                                >
                                  <Col xs={0} sm={24} md={24}>
                                    <div className="bottom-margin-24">
                                      <Text className="offer_cards--offer-bizName">
                                        {cmpn.biz?.bizName}
                                      </Text>
                                      <img
                                        src={ReviewStar}
                                        alt="ReviewStar"
                                        style={{ verticalAlign: "middle" }}
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>

                              <Col xs={0} sm={24} md={24}>
                                <div className="offer_cards--offer-and-icons">
                                  {reviewsOverview && (
                                    <Row>
                                      <Col>
                                        <ReviewStars
                                          onStarsClick={() => setAllVisible(true)}
                                          disabled={true}
                                          rating={reviewsOverview?.overallRating}
                                        />
                                      </Col>
                                      <Col style={{ alignSelf: "center" }}>
                                        <div className="ratingAvg">
                                          {`${reviewsOverview?.overallRating}`}{" "}
                                          out of 5
                                        </div>
                                      </Col>
                                      <Col style={{ alignSelf: "center" }}>
                                        <div
                                          onClick={() => {
                                            setAllVisible(true);
                                          }}
                                          className="totalReviews"
                                        >
                                          {`(${reviewsOverview?.totalRatings} Reviews)`}
                                        </div>
                                      </Col>
                                    </Row>
                                  )}
                                  {cmpn?.isFeatured && (
                                    <Row>
                                      <Col>
                                        <Image
                                          preview={false}
                                          src={featureImage}
                                          style={{
                                            width: "121px",
                                            height: "30px",
                                            marginTop: "10px",
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                  )}

                                  {/* <div style={{ paddingTop: "0.888rem" }}>
                                <Text
                                  style={{
                                    color: "#7F7F7F",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {addr}
                                </Text>
                              </div>

                              <div>
                                <Text
                                  style={{
                                    color: "#7F7F7F",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Phone: {cmpn.biz?.phone}
                                </Text>
                              </div> */}

                                  <Space
                                    direction="horizontal"
                                    size="medium"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    <Mailto
                                      email=""
                                      subject={`${cmpn.biz?.bizName}: ${cmpn.title}`}
                                      body={`Found this deal on fyndr:\n${cmpn.title
                                        }\n${`${web}/offer-details/${props.match.params.bizName.replace(
                                          /[.\W]+/g,
                                          "-"
                                        )}/${QR}`}`}
                                      children={
                                        <img
                                          src={Share}
                                          alt="share"
                                          className="offer-det-act"
                                        />
                                      }
                                    />

                                    {cmpn.biz?.website && (
                                      <Confirm
                                        content={
                                          <img
                                            src={Globe}
                                            alt="globe"
                                            className="offer-det-act"
                                          />
                                        }
                                        url={cmpn.biz?.website}
                                      />
                                    )}

                                    {/* {isMobile() ? (
                                  <a href={`tel:${cmpn.biz?.phone}`}>
                                    <img
                                      src={Phone}
                                      alt="phone"
                                      className="offer-det-act"
                                    />
                                  </a>
                                ) : (
                                  <img
                                    src={Phone}
                                    alt="phone"
                                    onClick={() => setShowNumberModal(true)}
                                    className="offer-det-act"
                                  />
                                )} */}

                                    {liked && liked["isDeleted"] === false && (
                                      <AiFillHeart
                                        className="offer-det-act"
                                        style={{
                                          fontSize: "35px",
                                        }}
                                        color={"#2764CD"}
                                        onClick={() => doLike(cmpn.objid, liked)}
                                      />
                                    )}

                                    {liked &&
                                      (!liked.hasOwnProperty("likedId") ||
                                        liked.isDeleted === true) && (
                                        <Row>
                                          <img
                                            src={Heart}
                                            alt="heart"
                                            className="offer-det-act"
                                            onClick={() =>
                                              doLike(cmpn.objid, liked)
                                            }
                                          />
                                        </Row>
                                      )}
                                    {bizEmail && (
                                      <a href={`mailto:${bizEmail}`}>
                                        <img
                                          src={mail}
                                          alt="mail"
                                          className="offer-det-act"
                                        ></img>
                                      </a>
                                    )}
                                    <FacebookShareButton
                                      url={`${qrPrefix.replace(
                                        "/qr/",
                                        ""
                                      )}${currentRoute}`}
                                    // url={`https://api-gateway.dev.fyndr.us/secure/promo/offer-details/bizName/qrCode`}
                                    // className="Demo__some-network__share-button"
                                    >
                                      <img src={facebook}></img>
                                    </FacebookShareButton>
                                  </Space>
                                </div>
                                {!viewLocation && (
                                  <Divider
                                    style={{
                                      background: "#D3D6E1",
                                      height: "1px",
                                    }}
                                  />
                                )}
                                <div className="offer_cards--offer-and-icons">
                                  {!viewLocation && (
                                    <>
                                      <div className="bottom-margin-24">
                                        <Text
                                          style={{
                                            fontWeight: "400",
                                            fontSize: "20px",
                                            color: "#223369",
                                          }}
                                        >
                                          Nearest Valid Locations:
                                        </Text>
                                      </div>
                                      {cmpn &&
                                        cmpn?.cmpnLocs &&
                                        cmpn?.cmpnLocs
                                          .slice(0, 10)
                                          .map((row, index) => {
                                            const {
                                              distance,
                                              locName,
                                              phone,
                                              catalogueId,
                                              locationId,
                                            } = row;

                                            return (
                                              <div key={locName}>
                                                <div>
                                                  <Text
                                                    style={{
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      color: "#7F7F7F",
                                                    }}
                                                  >
                                                    {locName || "Location Name"}
                                                    <br />
                                                    {(row?.country &&
                                                      parseAddress(row)) ||
                                                      "Address"}
                                                  </Text>
                                                </div>
                                                <div className="bottom-margin-12">
                                                  <Text
                                                    style={{
                                                      fontSize: "14px",
                                                      fontWeight: "400",
                                                      color: "#7F7F7F",
                                                    }}
                                                  >
                                                    (
                                                    {distance
                                                      ? distance.toFixed(1)
                                                      : "0"}{" "}
                                                    miles)
                                                  </Text>
                                                </div>

                                                <div className="bottom-margin-12">
                                                  {phone && (
                                                    <BizInfo
                                                      icon={
                                                        phone ? (
                                                          <img
                                                            src={Phone}
                                                            alt="phone"
                                                            className="catalogue_valid-icons"
                                                          />
                                                        ) : (
                                                          ""
                                                        )
                                                      }
                                                      size={20}
                                                      phone={phone}
                                                      callback={(value) => {
                                                        setLocPhone(phone);
                                                        setShowNumberModal(value);
                                                      }}
                                                    />
                                                  )}
                                                </div>

                                                {(cmpn.cmpnUrl ||
                                                  catalogueId) && (
                                                    <div className="bottom-margin-12">
                                                      <BizInfo
                                                        link={cmpn.cmpnUrl}
                                                        icon={
                                                          cmpn.cmpnUrl ? (
                                                            <img
                                                              src={Globe}
                                                              alt="mail"
                                                              className="act"
                                                            />
                                                          ) : (
                                                            ""
                                                          )
                                                        }
                                                        size={20}
                                                        showStore={
                                                          catalogueId !== null &&
                                                            catalogueId !== undefined
                                                            ? true
                                                            : false
                                                        }
                                                        onClickViewStore={() => {
                                                          scrollToTop();
                                                          setIsLoading(true);
                                                          setTimeout(() => {
                                                            onCatalogue(locationId);
                                                            handleLinkClick();
                                                            setIsLoading(false);
                                                          }, 1000);
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                {index < 9 &&
                                                  index <
                                                  cmpn.cmpnLocs.length - 1 && (
                                                    <Divider
                                                      style={{
                                                        background: "#D3D6E1",
                                                        height: "1px",
                                                      }}
                                                    />
                                                  )}
                                              </div>
                                            );
                                          })}
                                      {cmpn.campaignLocationCount > 10 && (
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            setViewLocation(true);
                                            fetchTheCampaign(
                                              QR,
                                              sortBy,
                                              sortingOrder
                                            );
                                          }}
                                        >
                                          View All Locations
                                        </Button>
                                      )}
                                    </>
                                  )}
                                  {reviewsOverview && indv?.biz !== true && (
                                    <>
                                      <Divider
                                        style={{
                                          background: "#D3D6E1",
                                          height: "1px",
                                        }}
                                      />
                                      <div
                                        onClick={() => {
                                          if (!indvid) {
                                            dispatch({
                                              type: SET_ROUTE,
                                              payload: currentRoute,
                                            });
                                            history.push("/login");
                                          } else if (indv?.biz !== true) {
                                            setUserComment(true);
                                          }
                                        }}
                                      >
                                        <div>
                                          <Text className="reviewrating_writeReview-sideBar">
                                            Review this business :
                                          </Text>
                                        </div>
                                        <div>
                                          <ReviewStars
                                            disabled={true}
                                            className="reviewrating_rateStars"
                                            rating={
                                              reviewsOverview?.overallRating
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            </Col>
                          </Card>
                        ) : (
                          <Card
                            bodyStyle={{ padding: "0 0 0 0" }}
                            style={{ borderRadius: "10px", marginBottom: "24px" }}
                            className="offer_cards--offer-details-border"
                          >
                            <ImageSlider
                              imageClass="offer_cards--cmpn-img_big"
                              cmpnImgs={cmpn?.cmpnImgs}
                              cmpnVideos={cmpn?.campaignVideo}
                            />

                            <div
                              style={{
                                margin: "0px 12px",
                                borderBottom: "1px solid #D3D6E1",
                              }}
                            >
                              <Text
                                style={{
                                  display: "flex",
                                  color: "#3A3A3A",
                                  fontSize: "20px",
                                  fontWeight: "400",
                                  lineHeight: "100%",
                                  paddingBottom: "24px",
                                }}
                              >
                                {cmpn.biz?.bizName}
                              </Text>
                            </div>
                            <div
                              style={{
                                position: "relative",
                                margin: "24px 12px",
                              }}
                            >
                              {isCollapsed && (
                                <>
                                  <div
                                    style={{
                                      display: "flex",
                                      textAlign: "justify",
                                    }}
                                  >
                                    <Space direction="vertical">
                                      <Text>{addr}</Text>
                                    </Space>
                                  </div>

                                  <Row
                                    className="offer_cards--offer-icons-div"
                                    style={{ width: "100%" }}
                                  >
                                    <Col xs={5} sm={6} md={6}>
                                      <Mailto
                                        email=""
                                        subject={`${cmpn.biz?.bizName}: ${cmpn.title}`}
                                        body={`Found this deal on fyndr:\n${cmpn.title
                                          }\n${`${web}/offer-details/${props.match.params.bizName.replace(
                                            /[.\W]+/g,
                                            "-"
                                          )}/${QR}`}`}
                                        children={
                                          <img
                                            src={Share}
                                            alt="share"
                                            className="act"
                                          />
                                        }
                                      />{" "}
                                    </Col>

                                    {cmpn.biz?.website && (
                                      <Col
                                        xs={5}
                                        sm={6}
                                        md={6}
                                        align={"center"}
                                        className="offer_cards--icon-border"
                                      >
                                        <Confirm
                                          content={
                                            <img
                                              src={Globe}
                                              alt="globe"
                                              className="act"
                                            />
                                          }
                                          url={cmpn.biz?.website}
                                        />
                                      </Col>
                                    )}
                                    {liked && (
                                      <Col
                                        xs={5}
                                        sm={6}
                                        md={6}
                                        align={"center"}
                                        className="offer_cards--icon-border"
                                      >
                                        <Space
                                          direction="horizontal"
                                          style={{ gap: "0px" }}
                                        >
                                          {liked && liked["isDeleted"] === false && (
                                            <AiFillHeart
                                              className="act-new"
                                              style={{
                                                fontSize: "35px",
                                              }}
                                              color={"#2764CD"}
                                              onClick={() =>
                                                doLike(cmpn.objid, liked)
                                              }
                                            />
                                          )}

                                          {liked &&
                                            (!liked.hasOwnProperty("likedId") ||
                                              liked.isDeleted === true) && (
                                              <Row>
                                                <img
                                                  src={Heart}
                                                  alt="heart"
                                                  className="act"
                                                  onClick={() =>
                                                    doLike(cmpn.objid, liked)
                                                  }
                                                />
                                              </Row>
                                            )}
                                        </Space>
                                      </Col>
                                    )}
                                    {bizEmail && (
                                      <Col
                                        xs={5}
                                        sm={6}
                                        md={6}
                                        className="offer_cards--icon-border"
                                      >
                                        <a href={`mailto:${bizEmail}`}>
                                          <img
                                            src={mail}
                                            alt="mail"
                                            className="offer-det-act"
                                          ></img>
                                        </a>
                                      </Col>
                                    )}
                                    <Col
                                      className="offer_cards--icon-border"
                                      xs={4}
                                      sm={6}
                                      md={6}
                                      align={"center"}
                                    >
                                      <FacebookShareButton
                                        // url={`${qrPrefix.replace(
                                        //   "/qr/",
                                        //   ""
                                        // )}${currentRoute}`}
                                        url={`https://api-gateway.dev.fyndr.us/secure/promo/offer-details/bizName/qrCode`}
                                        className="Demo__some-network__share-button"
                                      >
                                        <img src={facebook}></img>
                                      </FacebookShareButton>
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <div className="catalogue_collapse-view">
                                <Button
                                  type="text"
                                  icon={
                                    isCollapsed ? (
                                      <img
                                        src={viewless}
                                        alt="view less"
                                        style={{ margin: "0rem .5rem" }}
                                      />
                                    ) : (
                                      <img
                                        src={viewmore}
                                        alt="view less"
                                        style={{ margin: "0rem .5rem" }}
                                      />
                                    )
                                  }
                                  style={{
                                    color: "#257CDB",
                                    width: "100%",
                                    alignContent: "center",
                                  }}
                                  onClick={toggleCollapse}
                                >
                                  {isCollapsed ? "View Less" : "View More"}
                                </Button>
                              </div>
                            </div>
                          </Card>
                        )}
                      </Col>
                      <Col
                        xs={24}
                        sm={16}
                        md={16}
                        className="offer_cards--details-left-pad"
                      >
                        {cmpn.cmpnType === "brochure" && cmpn.brochureUri && (
                          <div>
                            <DocViewer
                              width={isMobile() ? window.innerWidth : 600}
                              uri={cmpn.brochureUri}
                              title={cmpn.title}
                              className="brochure_img"
                            />
                          </div>
                        )}
                        {/* download brochure aftre selcting any one of the offer */}
                        {cmpn.cmpnType === "brochure" && cmpn.brochureUri && (
                          <Row
                            justify="center"
                            className="offer_cards--brochure-btn-row"
                          >
                            <Button
                              type="primary"
                              size="large"
                              className="offer_cards--brochure-btn"
                              onClick={(e) =>
                                download(e, cmpn.brochureUri, cmpn.title)
                              }
                            >
                              Download
                              <DownloadOutlined className="offer_cards--brochure-btn-icon" />
                            </Button>
                          </Row>
                        )}
                        {totalofferSold >= 10 && (
                          <div style={{ marginTop: "1rem", marginLeft: "1rem" }}>
                            Total Offers Sold :{" "}
                            <Text strong>{totalofferSold}</Text>
                          </div>
                        )}
                        {cmpn.cmpnType === "offers" && (
                          <Card
                            className="offer_cards--offer-details offer_cards--offer-details-border"
                            style={{ minWidth: "100%" }}
                          >
                            {cmpn.offers &&
                              cmpn.offers
                                .filter((row) => row.status === "active")
                                .sort((a, b) => b.offerLimit - a.offerLimit)
                                .map((item, key) => {
                                  // const { validityPeriod } = item;
                                  // const { validTill } = decodeValidity(validityPeriod);
                                  const discount = `${item.discountType === "%"
                                    ? item.amount + "%"
                                    : item.currencySymbol + item.amount
                                    } Off`;

                                  return (
                                    <div>
                                      <Card
                                        key={key}
                                        style={{
                                          background: "#EAF2FC",
                                          border: "none",
                                          marginBottom: "1.5rem",
                                          cursor: "pointer",
                                          borderRadius: "10px",
                                        }}
                                        className={`item-card ribbon-container ${item.amount > 5
                                          ? "ribbon-success"
                                          : "ribbon-info"
                                          }`}
                                        onClick={() => {
                                          // setModalVisible(true);
                                          setShowCloseIcon(true);
                                          setSelectedOffer(item);
                                        }}
                                      >
                                        <Row align="middle">
                                          <Col
                                            xl={8}
                                            lg={8}
                                            md={8}
                                            sm={24}
                                            xs={24}
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              whiteSpace: "normal",
                                              backgroundImage: `url(${item.imgUri
                                                ? item?.imgUri
                                                : cmpn.cmpnImgs[1]?.imgUri
                                                  ? cmpn.cmpnImgs[1]?.imgUri
                                                  : Images.PlaceHolder
                                                })`,
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                            }}
                                            className="offer_cards--img_big"
                                          >
                                            {isTab() ? (
                                              <div>
                                                <div className="offer_cards--ribbon-text">
                                                  {discount}
                                                </div>
                                                <svg
                                                  width="39"
                                                  height="70"
                                                  viewBox="0 0 39 70"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="offer_cards--ribbon"
                                                >
                                                  <path
                                                    d="M0 0H22.9C28.5949 0 31.4424 0 33.6097 1.12587C35.4362 2.07462 36.9254 3.56382 37.8741 5.39026C39 7.55764 39 10.4051 39 16.1V70L19.5 45.3704L0 70V0Z"
                                                    fill="#FFE606"
                                                  />
                                                </svg>
                                              </div>
                                            ) : (
                                              <div>
                                                {!indvid && merchantId && (
                                                  <div>
                                                    <div className="offer_cards--ribbon-text">
                                                      {discount}
                                                    </div>
                                                    <svg
                                                      width="39"
                                                      height="70"
                                                      viewBox="0 0 39 70"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="offer_cards--ribbon"
                                                    >
                                                      <path
                                                        d="M0 0H22.9C28.5949 0 31.4424 0 33.6097 1.12587C35.4362 2.07462 36.9254 3.56382 37.8741 5.39026C39 7.55764 39 10.4051 39 16.1V70L19.5 45.3704L0 70V0Z"
                                                        fill="#FFE606"
                                                      />
                                                    </svg>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </Col>
                                          <Col
                                            xs={24}
                                            sm={24}
                                            md={16}
                                            className="offer_cards--offer-details offer_cards--offer-padding"
                                          >
                                            <Row>
                                              <Col
                                                span={24}
                                                style={{
                                                  paddingBottom: "12px",
                                                  paddingTop: "12px",
                                                }}
                                              >
                                                <Text className="offer_cards--offer-itemTitle">
                                                  {item.title}
                                                </Text>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Space direction="vertical">
                                                <Row>
                                                  <Space>
                                                    {cmpn.cmpnType ===
                                                      "offers" && (
                                                        <Space>
                                                          <Text
                                                            delete
                                                            className="offer_cards--offer-original-price"
                                                          >{`${item.currencySymbol
                                                            }${item?.retailPrice?.toFixed(
                                                              2
                                                            )} `}</Text>
                                                          <Text className="offer_cards--offer-discount">{`${item.currencySymbol
                                                            }${item?.offerPrice?.toFixed(
                                                              2
                                                            )}`}</Text>
                                                        </Space>
                                                      )}
                                                    {cmpn.cmpnType ===
                                                      "events" && (
                                                        <Space>
                                                          <Text
                                                            delete
                                                            className="offer_cards--offer-original-price"
                                                          >{`${item.currencySymbol
                                                            }${item?.retailPrice?.toFixed(
                                                              2
                                                            )} `}</Text>
                                                          <Text className="offer_cards--offer-discount">{`${item.currencySymbol
                                                            }${item?.offerPrice?.toFixed(
                                                              2
                                                            )}`}</Text>
                                                        </Space>
                                                      )}
                                                    {cmpn.cmpnType ===
                                                      "coupons" && (
                                                        <Space
                                                          direction="vertical"
                                                          style={{
                                                            textAlign: "left",
                                                          }}
                                                        >
                                                          <Text className="offer_cards--offer-discount-text">
                                                            {discount}
                                                          </Text>
                                                          <Text
                                                            style={{
                                                              color: "#223369",
                                                              fontStyle: "normal",
                                                              fontWeight: "500",
                                                              fontSize: "14px",
                                                            }}
                                                          >
                                                            {`${item.couponCode}`}
                                                          </Text>
                                                        </Space>
                                                      )}
                                                  </Space>
                                                </Row>

                                                <Col>
                                                  {indvid && merchantId && (
                                                    <Row
                                                      style={{
                                                        paddingBottom: "12px",
                                                      }}
                                                    >
                                                      <Space direction="vertical">
                                                        {item.offerLimit !==
                                                          null && (
                                                            <Text
                                                              style={{
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                              }}
                                                            >
                                                              Offer Remaining:
                                                              {getRemainingOffer(
                                                                item.offerLimit,
                                                                item.offerSold
                                                              )}
                                                            </Text>
                                                          )}
                                                        {item?.offerSold >=
                                                          10 && (
                                                            <Text
                                                              style={{
                                                                fontSize: "14px",
                                                                fontWeight: "400",
                                                              }}
                                                            >
                                                              Sold offer:{" "}
                                                              {item.offerSold}
                                                            </Text>
                                                          )}
                                                      </Space>
                                                    </Row>
                                                  )}

                                                  {indvid && merchantId && (
                                                    <Col
                                                      onClick={(e) =>
                                                        e.stopPropagation()
                                                      }
                                                    >
                                                      <Row
                                                        justify={"space-between"}
                                                        style={{
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <Col xs={16} md={14}>
                                                          <Row>
                                                            <Col
                                                              md={4}
                                                              sm={4}
                                                              xs={4}
                                                              style={{
                                                                alignSelf:
                                                                  "center",
                                                              }}
                                                            >
                                                              <Text>Qty:</Text>
                                                            </Col>

                                                            <Col
                                                              span={20}
                                                              style={{
                                                                paddingLeft:
                                                                  "8px",
                                                              }}
                                                            >
                                                              {console.log(
                                                                "cart data >>",
                                                                cart
                                                              )}
                                                              <QuantitySelector
                                                                modalVisble={
                                                                  item.isBookingEnabled
                                                                }
                                                                readOnly={
                                                                  item.isBookingEnabled
                                                                    ? true
                                                                    : false
                                                                }
                                                                openModal={() => {
                                                                  console.log(
                                                                    "item.isBookingEnabled",
                                                                    item
                                                                  );
                                                                  if (selectedLocationId) {
                                                                    if (
                                                                      item.isBookingEnabled
                                                                    ) {

                                                                      if(selectedLocation?.isBookingEnabled){
  
                                                                       setUserAppointmentModal(
                                                                         true
                                                                       );
                                                                      }
                                                                     
                                                                      setEnable(
                                                                        item.isBookingEnabled
                                                                      );
                                                                      locationDropdowm();
                                                                      setSelectedLocationId(
                                                                        selectedLocationId ||
                                                                        enabledLoc
                                                                      );
                                                                      console.log(
                                                                        "selectedLocationId",
                                                                        enabledLoc
                                                                      );
                                                                      setSelectedId(
                                                                        item?.objid
                                                                      );
                                                                      addObject(
                                                                        item
                                                                      );
                                                                      setNewSelectedObj(
                                                                        item
                                                                      );
                                                                    }
                                                                    setSelectedLocationId(
                                                                      selectedLocationId ||
                                                                      enabledLoc
                                                                    );
                                                                  } else {
                                                                    setLocationEnabledValue(true);
                                                                    setEnable(
                                                                      item.isBookingEnabled
                                                                    );
                                                                    locationDropdowm();
                                                                    // setSelectedLocationId(
                                                                    //   selectedLocationId ||
                                                                    //     enabledLoc
                                                                    // );


                                                                    setSelectedId(
                                                                      item?.objid
                                                                    );
                                                                    // addObject(
                                                                    //   item
                                                                    // );
                                                                    setNewSelectedObj(
                                                                      item
                                                                    );
                                                                    if(item.stdTax){
                                                                    setViewLocation(true)
                                                                    }
                                                                  }

                                                                }}
                                                                minusClick={() => {
                                                                  if (
                                                                    item
                                                                      .appointment
                                                                      ?.length > 0
                                                                  ) {
                                                                    item.appointment.pop();
                                                                  }
                                                                  addObject(item);
                                                                }}
                                                                plusClick={() => {
                                                                  if (selectedLocationId)
                                                                    addObject(item);
                                                                }}
                                                                item={item}
                                                                cart={cart}
                                                                handleChangeQty={(
                                                                  e,
                                                                  l
                                                                ) => {
                                                                  if (selectedLocationId) {
                                                                    handleChangeQty(
                                                                      e,
                                                                      l,
                                                                      l === 0
                                                                        ? false
                                                                        : item?.isLater
                                                                    );
                                                                  } else {
                                                                    // setPostalCodeValue({ "postalCode": cmpn?.cmpnLocs[0]?.postalCode, "country": cmpn?.cmpnLocs[0]?.country })

                                                                    if (cmpn.cmpnLocs?.length > 1 && item.stdTax) {
                                                                      console.log("item.isBookingEnabled I am here")
                                                                      setLocationEnabledValue(true);
                                                                      setViewLocation(true)
                                                                      handleChangeQty(
                                                                        e,
                                                                        l,
                                                                        l === 0
                                                                          ? false
                                                                          : item?.isLater
                                                                      );
                                                                    } else {

                                                                      handleChangeQty(
                                                                        e,
                                                                        l,
                                                                        l === 0
                                                                          ? false
                                                                          : item?.isLater
                                                                      );
                                                                    }

                                                                  }
                                                                }}
                                                                getMaxLimit={
                                                                  getMaxLimit
                                                                }
                                                                isDisabled={
                                                                  cart[item.objid]
                                                                    ?.qty >=
                                                                  getMaxLimit(
                                                                    item
                                                                  )
                                                                }
                                                                disabled={
                                                                  cart[item.objid]
                                                                    ?.qty >=
                                                                  getMaxLimit(
                                                                    item
                                                                  )
                                                                }
                                                                minusdisabled={
                                                                  cart[item.objid]
                                                                    ?.qty === 0
                                                                }
                                                              />
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                        <Col
                                                          xs={10}
                                                          md={8}
                                                          style={{
                                                            alignSelf: "center",
                                                            lineHeight: "24px",
                                                          }}
                                                        >
                                                          {item.usageLimit !==
                                                            -1 && (
                                                              <Text
                                                                style={{
                                                                  lineHeight:
                                                                    "20px",
                                                                }}
                                                              >
                                                                Limit: {""}
                                                                {item.usageLimit}
                                                              </Text>
                                                            )}
                                                        </Col>
                                                      </Row>
                                                    </Col>
                                                  )}
                                                </Col>

                                                {item.offerLimit !== null &&
                                                  Number(item.offerLimit) -
                                                  Number(item.offerSold) ===
                                                  0 && (
                                                    <Col className="list-item--offersoldout">
                                                      <Text
                                                        type="danger"
                                                        style={{
                                                          alignItems: "center",
                                                        }}
                                                        strong
                                                      >
                                                        Offer Sold Out
                                                      </Text>
                                                    </Col>
                                                  )}
                                              </Space>
                                              {!indvid && merchantId && (
                                                <Col
                                                  span={24}
                                                  className="offer_cards--buy-btn-row"
                                                >
                                                  <Button
                                                    type="primary"
                                                    className="offer_cards--buy-btn"
                                                    onClick={() => setRoute()}
                                                  >
                                                    Buy Now
                                                  </Button>
                                                </Col>
                                              )}
                                            </Row>
                                          </Col>
                                          {indvid &&
                                            cmpn.cmpnType === "offers" &&
                                            QR !== PREVIEW &&
                                            merchantId && (
                                              <Col
                                                sm={8}
                                                md={8}
                                                className={
                                                  item.amount > 5
                                                    ? "ticketGreen"
                                                    : "ticket"
                                                }
                                              >
                                                <div className="ribbon">
                                                  {discount}
                                                </div>
                                              </Col>
                                            )}
                                        </Row>
                                        {/* {indvid && merchantId && (
                                    <Col onClick={(e) => e.stopPropagation()}>
                                      <Row
                                        style={{
                                          marginRight: "4rem",
                                          width: "100%",
                                        }}
                                      >
                                        <Col xs={18} md={0}>
                                          <Row>
                                            <Col
                                              span={5}
                                              style={{
                                                paddingRight: "8px",
                                                alignSelf: "center",
                                              }}
                                            >
                                              <Text>Qty:</Text>
                                            </Col>

                                            <Col span={19}>
                                              <QuantitySelector
                                                item={item}
                                                cart={cart}
                                                handleChangeQty={
                                                  handleChangeQty
                                                }
                                                getMaxLimit={getMaxLimit}
                                                isDisabled={
                                                  cart[item.objid]?.qty >=
                                                  getMaxLimit(item)
                                                }
                                              />
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col
                                          xs={6}
                                          md={0}
                                          align={"right"}
                                          style={{ alignSelf: "center" }}
                                        >
                                          {item.usageLimit !== -1 && (
                                            <Text>
                                              Limit:
                                              {item.usageLimit}
                                            </Text>
                                          )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  )} */}
                                        {/* shown when inc the qty and click on proceed to pay button */}
                                        <Col xs={0} md={24}>
                                          {cart.hasOwnProperty(item.objid) &&
                                            cart[item.objid]["qty"] > 0 && (
                                              <Row
                                                style={{ marginTop: "1rem" }}
                                                align="middle"
                                                justify="space-between"
                                              >
                                                <Divider className="offer_cards--aftr-qty-inc" />
                                                <Col>
                                                  <Text
                                                    style={{
                                                      fontSize: "1rem",
                                                      fontWeight: "500",
                                                    }}
                                                  >{`Amount: ${item.currencySymbol
                                                    }${cart[item.objid]["totalAmt"]
                                                    }`}</Text>
                                                </Col>
                                                <Col>
                                                  <Text
                                                    style={{
                                                      fontSize: "1rem",
                                                      fontWeight: "500",
                                                    }}
                                                  >{`Tax: ${item.currencySymbol}${cart[item.objid]["tax"]
                                                    }`}</Text>
                                                </Col>
                                                <Col>
                                                  <Text
                                                    style={{
                                                      fontSize: "1rem",
                                                      fontWeight: "500",
                                                    }}
                                                  >{`Total: ${item.currencySymbol
                                                    }${cart[item.objid]["rowTotal"]
                                                    }`}</Text>
                                                </Col>
                                              </Row>
                                            )}
                                        </Col>
                                        {/* </Card> */}
                                      </Card>
                                    </div>
                                  );
                                })}
                            {!showCard &&
                              indvid &&
                              cmpn.cmpnType === "offers" &&
                              QR !== PREVIEW &&
                              merchantId && (
                                <Row
                                  justify="center"
                                  style={{
                                    marginTop: "1rem",
                                    width: "100%",
                                  }}
                                >
                                  <Button
                                    type="primary"
                                    className="offer_cards--active"
                                    style={{ minWidth: "16rem" }}
                                    onClick={() => checkout()}
                                    disabled={
                                      roleType === ROLE_SUPPORT ||
                                        roleType === ROLE_MANAGER ||
                                        isMasquerade
                                        ? true
                                        : false
                                    }
                                  >
                                    Proceed to pay{" "}
                                    {/* {total ? `(${cartCurrencySymbol}${total})` : ""} */}
                                  </Button>
                                </Row>
                              )}
                          </Card>
                        )}

                        {cmpn?.offers.length >= 1 &&
                          (cmpn.cmpnType === "events" ||
                            cmpn.cmpnType === "coupons") && (
                            <Card
                              className="offer_cards--offer-details offer_cards--offer-details-border"
                              style={{ minWidth: "100%" }}
                            >
                              {cmpn?.offers &&
                                cmpn.offers
                                  .filter((row) => row.status === "active")
                                  .sort((a, b) => b.offerLimit - a.offerLimit)
                                  .map((item, key) => {
                                    const discount = `${item.discountType === "%"
                                      ? item.amount + "%"
                                      : item.currencySymbol + item.amount
                                      } Off`;

                                    return (
                                      <div>
                                        <Card
                                          key={key}
                                          style={{
                                            background: "#EAF2FC",
                                            border: "none",
                                            marginBottom: "1.5rem",
                                            cursor: "pointer",
                                            borderRadius: "10px",
                                          }}
                                          className={`item-card ribbon-container ${item.amount > 5
                                            ? "ribbon-success"
                                            : "ribbon-info"
                                            }`}
                                          onClick={() => {
                                            setShowCloseIcon(true);
                                            setSelectedOffer(item);
                                          }}
                                        >
                                          <Row align="middle">
                                            <Col
                                              xl={8}
                                              lg={8}
                                              md={8}
                                              sm={24}
                                              xs={24}
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <img
                                                className="offer_cards--img_big"
                                                src={
                                                  item.imgUri
                                                    ? item?.imgUri
                                                    : cmpn.cmpnImgs[1]
                                                      ?.thumbnailUri
                                                      ? cmpn.cmpnImgs[1]
                                                        ?.thumbnailUri
                                                      : Images.PlaceHolder
                                                }
                                                alt="offer.."
                                              />
                                              {isTab() ? (
                                                <div>
                                                  <div className="offer_cards--ribbon-text">
                                                    {discount}
                                                  </div>
                                                  <svg
                                                    width="39"
                                                    height="70"
                                                    viewBox="0 0 39 70"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="offer_cards--ribbon"
                                                  >
                                                    <path
                                                      d="M0 0H22.9C28.5949 0 31.4424 0 33.6097 1.12587C35.4362 2.07462 36.9254 3.56382 37.8741 5.39026C39 7.55764 39 10.4051 39 16.1V70L19.5 45.3704L0 70V0Z"
                                                      fill="#FFE606"
                                                    />
                                                  </svg>
                                                </div>
                                              ) : (
                                                <div>
                                                  {!indvid && merchantId && (
                                                    <div>
                                                      <div className="offer_cards--ribbon-text">
                                                        {discount}
                                                      </div>
                                                      <svg
                                                        width="39"
                                                        height="70"
                                                        viewBox="0 0 39 70"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="offer_cards--ribbon"
                                                      >
                                                        <path
                                                          d="M0 0H22.9C28.5949 0 31.4424 0 33.6097 1.12587C35.4362 2.07462 36.9254 3.56382 37.8741 5.39026C39 7.55764 39 10.4051 39 16.1V70L19.5 45.3704L0 70V0Z"
                                                          fill="#FFE606"
                                                        />
                                                      </svg>
                                                    </div>
                                                  )}
                                                </div>
                                              )}
                                            </Col>
                                            <Col
                                              xs={24}
                                              sm={24}
                                              md={16}
                                              className="offer_cards--offer-details offer_cards--offer-padding"
                                            >
                                              <Row>
                                                <Col
                                                  span={24}
                                                  style={{
                                                    paddingBottom: "12px",
                                                    paddingTop: "12px",
                                                  }}
                                                >
                                                  <Text className="offer_cards--offer-itemTitle">
                                                    {item.title}
                                                  </Text>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Space direction="vertical">
                                                  <Row>
                                                    <Space>
                                                      {cmpn.cmpnType ===
                                                        "offers" && (
                                                          <Space>
                                                            <Text
                                                              delete
                                                              className="offer_cards--offer-original-price"
                                                            >{`${item.currencySymbol}${item.retailPrice} `}</Text>
                                                            <Text className="offer_cards--offer-discount">{`${item.currencySymbol}${item.offerPrice}`}</Text>
                                                          </Space>
                                                        )}
                                                      {cmpn.cmpnType ===
                                                        "events" && (
                                                          <Space>
                                                            <Text
                                                              delete
                                                              className="offer_cards--offer-original-price"
                                                            >{`${item.currencySymbol}${item.retailPrice} `}</Text>
                                                            <Text className="offer_cards--offer-discount">{`${item.currencySymbol}${item.offerPrice}`}</Text>
                                                          </Space>
                                                        )}
                                                      {cmpn.cmpnType ===
                                                        "coupons" && (
                                                          <Space
                                                            direction="vertical"
                                                            style={{
                                                              textAlign: "left",
                                                            }}
                                                          >
                                                            <Text className="offer_cards--offer-discount-text">
                                                              {discount}
                                                            </Text>
                                                            <Text
                                                              style={{
                                                                color: "#223369",
                                                                fontStyle: "normal",
                                                                fontWeight: "500",
                                                                fontSize: "14px",
                                                              }}
                                                            >
                                                              {`${item.couponCode}`}
                                                            </Text>
                                                          </Space>
                                                        )}
                                                    </Space>
                                                  </Row>
                                                  <Col>
                                                    {cmpn.cmpnType === "events" &&
                                                      indvid &&
                                                      merchantId && (
                                                        <Row className="bottom-margin-12">
                                                          <Space>
                                                            {item.offerLimit !==
                                                              null && (
                                                                <Text
                                                                  style={{
                                                                    fontSize:
                                                                      "14px",
                                                                    fontWeight:
                                                                      "400",
                                                                  }}
                                                                >
                                                                  Offer Remaining:
                                                                  {getRemainingOffer(
                                                                    item.offerLimit,
                                                                    item.offerSold
                                                                  )}
                                                                </Text>
                                                              )}
                                                            {item?.offerSold >=
                                                              10 && (
                                                                <Text
                                                                  style={{
                                                                    fontSize:
                                                                      "14px",
                                                                    fontWeight:
                                                                      "400",
                                                                  }}
                                                                >
                                                                  Sold offer:{" "}
                                                                  {item.offerSold}
                                                                </Text>
                                                              )}
                                                          </Space>
                                                        </Row>
                                                      )}

                                                    {indvid &&
                                                      merchantId &&
                                                      cmpn.cmpnType ===
                                                      "events" && (
                                                        <Col
                                                          onClick={(e) =>
                                                            e.stopPropagation()
                                                          }
                                                        >
                                                          <Row
                                                            justify={
                                                              "space-between"
                                                            }
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <Col xs={16} md={14}>
                                                              <Row>
                                                                <Col
                                                                  md={4}
                                                                  xs={4}
                                                                  sm={4}
                                                                  style={{
                                                                    alignSelf:
                                                                      "center",
                                                                  }}
                                                                >
                                                                  <Text>
                                                                    Qty:
                                                                  </Text>
                                                                </Col>

                                                                <Col
                                                                  span={20}
                                                                  style={{
                                                                    paddingLeft:
                                                                      "8px",
                                                                  }}
                                                                >
                                                                  <QuantitySelector
                                                                    item={item}
                                                                    cart={cart}
                                                                    handleChangeQty={
                                                                      handleChangeQty
                                                                    }
                                                                    getMaxLimit={
                                                                      getMaxLimit
                                                                    }
                                                                    isDisabled={
                                                                      cart[
                                                                        item.objid
                                                                      ]?.qty >=
                                                                      getMaxLimit(
                                                                        item
                                                                      )
                                                                    }
                                                                    minusClick={() => {
                                                                      if (
                                                                        item
                                                                          .appointment
                                                                          ?.length >
                                                                        0
                                                                      ) {
                                                                        item.appointment.pop();
                                                                      }
                                                                      addObject(
                                                                        item
                                                                      );
                                                                    }}
                                                                    plusClick={() => {
                                                                      addObject(
                                                                        item
                                                                      );
                                                                    }}
                                                                  />
                                                                </Col>
                                                              </Row>
                                                            </Col>
                                                            <Col
                                                              xs={10}
                                                              md={8}
                                                              style={{
                                                                alignSelf:
                                                                  "center",
                                                                lineHeight:
                                                                  "24px",
                                                              }}
                                                            >
                                                              {item.usageLimit !==
                                                                -1 && (
                                                                  <Text>
                                                                    Limit:{" "}
                                                                    {
                                                                      item.usageLimit
                                                                    }
                                                                  </Text>
                                                                )}
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                      )}
                                                  </Col>

                                                  {item.offerLimit !== null &&
                                                    Number(item.offerLimit) -
                                                    Number(item.offerSold) ===
                                                    0 && (
                                                      <Col className="list-item--offersoldout">
                                                        <Text
                                                          type="danger"
                                                          style={{
                                                            alignItems: "center",
                                                          }}
                                                          strong
                                                        >
                                                          Offer Sold Out
                                                        </Text>
                                                      </Col>
                                                    )}
                                                </Space>
                                                {!indvid && merchantId && (
                                                  <Col
                                                    span={24}
                                                    className="offer_cards--buy-btn-row"
                                                  >
                                                    <Button
                                                      type="primary"
                                                      className="offer_cards--buy-btn"
                                                      onClick={() => setRoute()}
                                                    >
                                                      Buy Now
                                                    </Button>
                                                  </Col>
                                                )}
                                              </Row>
                                            </Col>
                                            {indvid &&
                                              cmpn.cmpnType === "events" &&
                                              QR !== PREVIEW &&
                                              merchantId && (
                                                <Col
                                                  sm={8}
                                                  md={8}
                                                  className={
                                                    item.amount > 5
                                                      ? "ticketGreen"
                                                      : "ticket"
                                                  }
                                                >
                                                  <div className="ribbon">
                                                    {discount}
                                                  </div>
                                                </Col>
                                              )}
                                            {indvid &&
                                              cmpn.cmpnType === "offers" &&
                                              QR !== PREVIEW &&
                                              merchantId && (
                                                <Col
                                                  sm={8}
                                                  md={8}
                                                  className={
                                                    item.amount > 5
                                                      ? "ticketGreen"
                                                      : "ticket"
                                                  }
                                                >
                                                  <div className="ribbon">
                                                    {discount}
                                                  </div>
                                                </Col>
                                              )}
                                          </Row>
                                          {/* {indvid && merchantId && (
                                        <Col
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          <Row
                                            style={{
                                              marginRight: "4rem",
                                              width: "100%",
                                            }}
                                          >
                                            <Col xs={16} md={0}>
                                              <Row>
                                                <Col
                                                  span={6}
                                                  style={{
                                                    alignSelf: "center",
                                                  }}
                                                >
                                                  <Text>Qty:</Text>
                                                </Col>

                                                <Col span={18}>
                                                  <QuantitySelector
                                                    item={item}
                                                    cart={cart}
                                                    handleChangeQty={
                                                      handleChangeQty
                                                    }
                                                    getMaxLimit={getMaxLimit}
                                                    isDisabled={
                                                      cart[item.objid]?.qty >=
                                                      getMaxLimit(item)
                                                    }
                                                  />
                                                </Col>
                                              </Row>
                                            </Col>
                                            <Col
                                              xs={6}
                                              md={0}
                                              align={"right"}
                                              style={{ alignSelf: "center" }}
                                            >
                                              {item.usageLimit !== -1 && (
                                                <Text>
                                                  Limit:
                                                  {item.usageLimit}
                                                </Text>
                                              )}
                                            </Col>
                                          </Row>
                                        </Col>
                                      )} */}
                                          {/* shown when inc the qty and click on proceed to pay button */}
                                          <Col xs={0} sm={24} md={24}>
                                            {cart.hasOwnProperty(item.objid) &&
                                              cart[item.objid]["qty"] > 0 && (
                                                <Row
                                                  style={{ marginTop: "1rem" }}
                                                  align="middle"
                                                  justify="space-between"
                                                >
                                                  <Divider className="offer_cards--aftr-qty-inc" />
                                                  <Col>
                                                    <Text strong>{`Amount: ${item.currencySymbol
                                                      }${cart[item.objid]["totalAmt"]
                                                      }`}</Text>
                                                  </Col>
                                                  <Col>
                                                    <Text strong>{`Tax: ${item.currencySymbol
                                                      }${cart[item.objid]["tax"]
                                                      }`}</Text>
                                                  </Col>
                                                  <Col>
                                                    <Text strong>{`Total: ${item.currencySymbol
                                                      }${cart[item.objid]["rowTotal"]
                                                      }`}</Text>
                                                  </Col>
                                                </Row>
                                              )}
                                          </Col>
                                        </Card>
                                        {/* {indvid &&
                                    cmpn.cmpnType === "offers" &&
                                    QR !== PREVIEW &&
                                    merchantId && (
                                      <Row
                                        justify="center"
                                        style={{
                                          marginTop: "1rem",
                                          width: "100%",
                                        }}
                                      >
                                        <Button
                                          key={
                                            showCard
                                              ? "card-open"
                                              : "card-closed"
                                          }
                                          className="offer_cards--active"
                                          disabled={
                                            !(
                                              cart.hasOwnProperty(item.objid) &&
                                              cart[item.objid]["qty"] > 0
                                            )
                                          }
                                          onClick={() => checkout()}
                                        >
                                          Proceed to Pay
                                        </Button>
                                      </Row>
                                    )} */}
                                      </div>
                                    );
                                  })}
                              {!showCard &&
                                indvid &&
                                cmpn.cmpnType === "events" &&
                                QR !== PREVIEW &&
                                merchantId && (
                                  <Row
                                    justify="center"
                                    style={{
                                      marginTop: "1rem",
                                      width: "100%",
                                    }}
                                  >
                                    <Button
                                      type="primary"
                                      className="offer_cards--active"
                                      style={{ minWidth: "16rem" }}
                                      disabled={
                                        roleType === ROLE_SUPPORT ||
                                          roleType === ROLE_MANAGER ||
                                          isMasquerade
                                          ? true
                                          : false
                                      }
                                      onClick={() => checkout()}
                                    >
                                      Proceed to pay{" "}
                                      {/* {total ? `(${cartCurrencySymbol}${total})` : ""} */}
                                    </Button>
                                  </Row>
                                )}
                            </Card>
                          )}

                        {/* after proceed to pay add your payment card */}
                        {showCard && (
                          <>
                            {!appointLoading && (
                              <div>
                                <Row justify="center">
                                  <Col xs={24} md={24}>
                                    <Card
                                      title="Offer Summary"
                                      style={{
                                        width: "100%",
                                        cursor: "pointer",
                                        borderRadius: "10px",
                                      }}
                                    >
                                      <AllAppointment
                                        editClick={(e, i) => {
                                          setSelectedIndex(i);
                                          setSelectedObj(e);
                                          setSelectedLocationId(
                                            selectedLocationId
                                          );
                                          setAppointVisible(true);
                                          {
                                            setAppointmentLoading(true);
                                          }
                                        }}
                                        data={appointmentData}
                                        bizName={
                                          selectedObj?.title ||
                                          selectedOffer?.title
                                        }
                                      />
                                    </Card>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            <div ref={cardRef}>
                              <Row
                                justify="center"
                                className="offer_cards--paymnt-add"
                              >
                                <Col span={24}>
                                  <CardPay
                                    total={total}
                                    params={{
                                      channel: areAppointmentsAvailable
                                        ? "offer_appointment"
                                        : cmpn.cmpnType,
                                    }}
                                    callback={(
                                      formData,
                                      invoiceId,
                                      additionalCheckbox,
                                      createdInvoice,
                                      paymentMethod
                                    ) => {
                                      showSuccess(
                                        props,
                                        createdInvoice,
                                        paymentMethod
                                      );
                                      {
                                        additionalCheckbox &&
                                          fetchGiftOffer(formData, invoiceId);
                                      }
                                    }}
                                  />
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                        {cmpn.finePrint !== null && cmpn.finePrint !== "" && (
                          <Card
                            className="offer_cards--offer-details"
                            style={{
                              minWidth: "100%",
                              padding: "15px",
                              border: "1px solid #D3D6E1",
                            }}
                          >
                            <div>
                              <div className="fine-print-details">
                                Terms & Conditions:
                              </div>
                              <div className="fine-print-details-text">
                                {LongText(`${cmpn.finePrint}`, 0, false)}
                              </div>
                            </div>
                          </Card>
                        )}
                        {cmpn.descr !== null && cmpn.descr !== "" && (
                          <Card
                            className="offer_cards--offer-details"
                            style={{
                              minWidth: "100%",
                              padding: "15px",
                              border: "1px solid #D3D6E1",
                            }}
                          >
                            <div className="offer_cards--details">
                              <div className="offer_cards--offer-finePrintText">
                                Details:
                              </div>
                              <div className="fine-print-details-text">
                                {LongText(`${cmpn.descr}`, 0, true)}
                              </div>
                            </div>
                          </Card>
                        )}
                        <Col xs={24} sm={0} md={0}>
                          <Card
                            className="offer_cards--offer-details"
                            style={{ minWidth: "100%", padding: "15px" }}
                          >
                            <div className="bottom-margin-24">
                              <Text
                                style={{
                                  fontWeight: "400",
                                  fontSize: "20px",
                                  color: "#223369",
                                }}
                              >
                                Nearest Valid Locations:
                              </Text>
                            </div>
                            {cmpn &&
                              cmpn?.cmpnLocs &&
                              cmpn?.cmpnLocs.slice(0, 10).map((row, index) => {
                                const {
                                  distance,
                                  locName,
                                  phone,
                                  catalogueId,
                                  locationId,
                                } = row;

                                return (
                                  <div
                                    key={locName}
                                    style={{ marginTop: "0.4rem" }}
                                  >
                                    <div>
                                      <Text
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#7F7F7F",
                                        }}
                                      >
                                        {locName || "Location Name"}
                                        <br />
                                        {(row?.country && parseAddress(row)) ||
                                          "Address"}
                                      </Text>
                                    </div>

                                    <div className="bottom-margin-12">
                                      <Text
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                          color: "#7F7F7F",
                                        }}
                                      >
                                        ({distance ? distance.toFixed(1) : "0"}{" "}
                                        miles)
                                      </Text>
                                    </div>

                                    {phone && (
                                      <BizInfo
                                        icon={
                                          phone ? (
                                            <img
                                              src={Phone}
                                              alt="phone"
                                              className="catalogue_valid-icons"
                                            />
                                          ) : (
                                            ""
                                          )
                                        }
                                        size={20}
                                        phone={phone}
                                        callback={(value) => {
                                          setLocPhone(phone);
                                          setShowNumberModal(value);
                                        }}
                                      />
                                    )}

                                    <BizInfo
                                      link={cmpn.cmpnUrl}
                                      icon={
                                        cmpn.cmpnUrl ? (
                                          <img
                                            src={Globe}
                                            style={{ border: "1px solid purple" }}
                                            alt="mail"
                                            className="catalogue_valid-icons"
                                          />
                                        ) : (
                                          ""
                                        )
                                      }
                                      size={20}
                                      showStore={
                                        catalogueId !== null &&
                                          catalogueId !== undefined
                                          ? true
                                          : false
                                      }
                                      onClickViewStore={() => {
                                        scrollToTop();
                                        setIsLoading(true);
                                        setTimeout(() => {
                                          onCatalogue(locationId);
                                          handleLinkClick();
                                          setIsLoading(false);
                                        }, 1000);
                                      }}
                                    />
                                    {index < 9 &&
                                      index < cmpn.cmpnLocs.length - 1 && (
                                        <Divider
                                          style={{
                                            background: "#D3D6E1",
                                            height: "1px",
                                          }}
                                        />
                                      )}
                                  </div>
                                );
                              })}
                            {cmpn.campaignLocationCount > 10 && (
                              <Button
                                type="link"
                                onClick={() => {
                                  setViewLocation(true);
                                  fetchTheCampaign(QR, sortBy, sortingOrder);
                                }}
                              >
                                View All Locations
                              </Button>
                            )}
                          </Card>
                        </Col>

                        {cmpn.goal !== "online" &&
                          cmpn &&
                          cmpn.cmpnLocs?.length > 0 &&
                          cmpn.biz?.showBiz &&
                          QR !== PREVIEW && (
                            <Row justify="center">
                              <Card
                                className="offer_cards--offer-details"
                                align="center"
                                bordered={isMobile() ? false : true}
                                style={{
                                  minWidth: "100%",
                                  border: "1px solid #D3D6E1",
                                }}
                              >
                                <div className="offer_cards--map">
                                  <GoogleMapNew
                                    zoom={11}
                                    defaultCenter={{
                                      lat: cmpn?.cmpnLocs[0]?.lat,
                                      lng: cmpn?.cmpnLocs[0]?.lng,
                                    }}
                                  >
                                    {Array.isArray(cmpn?.cmpnLocs) &&
                                      cmpn?.cmpnLocs.map((item, key) => {
                                        return (
                                          <Marker
                                            key={key}
                                            position={{
                                              lat: item.lat,
                                              lng: item.lng,
                                            }}
                                          />
                                        );
                                      })}
                                  </GoogleMapNew>
                                </div>
                              </Card>
                            </Row>
                          )}
                        {/* Ratings & Reviews desktop view */}
                        {!isMobile() ? (
                          <>
                            <Row className="reviewrating_mainratingRow">
                              <Col
                                className="reviewrating_mainratingCol"
                                span={24}
                              >
                                <Row>
                                  <Col span={20}>
                                    <text className="reviewrating_headerText">
                                      Ratings & Reviews
                                    </text>
                                  </Col>

                                  <Col span={4}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                      }}
                                    >
                                      <img
                                        className="reviewrating_accordionImg"
                                        src={accordion}
                                        alt="view less"
                                        style={{
                                          cursor: "pointer",
                                          transform: isAccordion
                                            ? "rotate(180deg)"
                                            : "rotate(0deg)",
                                          transition: "transform 0.5s",
                                        }}
                                        onClick={accordionCollapse}
                                      />
                                    </div>
                                  </Col>
                                  <Col span={1} />
                                </Row>
                                {isAccordion && (
                                  <div>
                                    <Row align={"middle"}>
                                      {/* <Col span={1} /> */}
                                      <Col
                                        lg={6}
                                        md={6}
                                        xs={6}
                                        style={{ paddingLeft: "3rem" }}
                                      >
                                        {reviewsOverview && (
                                          <RatingAverage
                                            rating={
                                              reviewsOverview?.overallRating
                                            }
                                          />
                                        )}
                                      </Col>

                                      <Col
                                        lg={5}
                                        md={4}
                                        xs={6}
                                        xl={5}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Divider
                                          className="reviewrating_ratingReviewDivider"
                                          type="vertical"
                                        />
                                      </Col>
                                      <Col
                                        lg={13}
                                        md={14}
                                        xs={12}
                                        style={{
                                          marginTop: "2rem",
                                        }}
                                      >
                                        {reviewsOverview && (
                                          <RatingChart
                                            ratingMap={reviewsOverview?.ratingMap}
                                            data={reviewdata}
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                    {!indv?.biz && indv?.bizid !== businessId && (
                                      <>
                                        <Row style={{ marginTop: "3.5rem" }}>
                                          <Col lg={11} md={14} xs={12}>
                                            <div>
                                              <text className="reviewrating_writeReview">
                                                Write A Review :
                                              </text>
                                            </div>
                                            <div className="reviewrating_writeReviewInput">
                                              <Button
                                                className="reviewrating_inputBox"
                                                onClick={() => {
                                                  setRating(0);
                                                  setCommentForReview("");
                                                  setSelectedImages(
                                                    Array(6).fill(null)
                                                  );
                                                  if (!indvid) {
                                                    dispatch({
                                                      type: SET_ROUTE,
                                                      payload: currentRoute,
                                                    });
                                                    history.push("/login");
                                                  } else {
                                                    setUserComment(true);
                                                  }
                                                }}
                                              >
                                                <FormOutlined className="reviewrating_formOutLine" />
                                              </Button>
                                            </div>
                                          </Col>

                                          <Col lg={11} md={10}>
                                            <div>
                                              <Text className="reviewrating_writeReviewRateUs">
                                                {cmpn.biz?.bizName}
                                              </Text>
                                            </div>
                                            <div className="reviewrating_writeReviewStars">
                                              {reviewsOverview && (
                                                <ReviewStars
                                                  disabled={true}
                                                  rating={
                                                    reviewsOverview?.overallRating
                                                  }
                                                />
                                              )}
                                            </div>
                                          </Col>
                                        </Row>
                                      </>
                                    )}
                                    <Row style={{ marginTop: "1.68rem" }}>
                                      <Col span={11}>
                                        <Select
                                          defaultValue={JSON.stringify(
                                            sortByData
                                          )}
                                          onChange={(e) => {
                                            setPagination({
                                              ...pagination,
                                              current: 1,
                                            });
                                            setSortByData(JSON.parse(e));
                                            console.log(
                                              "Value selected",
                                              JSON.parse(e)
                                            );
                                          }}
                                          className="reviewrating_filterBTN"
                                          options={[
                                            {
                                              value: JSON.stringify({
                                                orderBy: "RATING",
                                                orderType: "ASC",
                                              }),
                                              label: "Rating : Low To High",
                                            },
                                            {
                                              value: JSON.stringify({
                                                orderBy: "RATING",
                                                orderType: "DESC",
                                              }),
                                              label: "Rating : High To Low",
                                            },
                                            {
                                              value: JSON.stringify({
                                                orderBy: "CREATED_DT",
                                                orderType: "DESC",
                                              }),
                                              label: "Newest First",
                                            },
                                            {
                                              value: JSON.stringify({
                                                orderBy: "CREATED_DT",
                                                orderType: "ASC",
                                              }),
                                              label: "Newest Last",
                                            },
                                          ]}
                                          suffixIcon={
                                            <img src={Sliders} alt="Sliders" />
                                          }
                                          style={{
                                            fontWeight: 400,
                                            fontSize: "18px",
                                            color: "#223369",
                                            background: "#FFFFFF",
                                            border: "1px solid #D3D6E1",
                                            borderRadius: "5px",
                                            height: "46px",
                                          }}
                                          placeholder="Sort By"
                                        />
                                        {/* <Button className="reviewcomments_filterCommnetButton">
                                      Sort By
                                      <img
                                        src={filter}
                                        style={{ paddingLeft: "4.781rem" }}
                                      />
                                    </Button> */}
                                      </Col>
                                      <Col span={13}>
                                        <div className="reviewcomments_seeAllButton">
                                          <Button
                                            style={{
                                              border: "none",
                                            }}
                                            onClick={() => {
                                              setSeeAllComments(true);
                                            }}
                                          >
                                            <p className="reviewcomments_SeeAllbtn">
                                              See all
                                            </p>
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col span={24}>
                                        {comments?.comments.map((item) => {
                                          return (
                                            <ReviewComments
                                              data={item}
                                              openParagraph={(data) => {
                                                setTitle("Comment");
                                                setParaVisible(true);
                                                setSelectedPlatformUser(data);
                                              }}
                                              indv={indv?.bizid}
                                              entityType={indv?.entityType}
                                              businessId={businessId}
                                              setModalVisible={() => {
                                                setReplyData(item);
                                                setReplyModalVisible(true);
                                              }}
                                              reportModalVisibleClick={() => {
                                                if (
                                                  item?.isReportingAllowed ===
                                                  true
                                                ) {
                                                  setReplyData(item);
                                                  setReportComment("");
                                                  setReportModalVisible(true);
                                                } else {
                                                  setReportData(
                                                    item.reportedComments
                                                  );
                                                  setReportedCommentVisible(true);
                                                }
                                              }}
                                            />
                                          );
                                        })}
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </>
                        ) : (
                          // mobile screen
                          <Row className="reviewrating_mainratingRow">
                            <Col
                              className="reviewrating_mainratingCol-mobile"
                              span={24}
                            >
                              <Row>
                                <Col span={20}>
                                  <text className="reviewrating_headerText-mobile">
                                    Ratings & Reviews
                                  </text>
                                </Col>

                                <Col span={4}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <img
                                      src={accordion}
                                      alt="view less"
                                      style={{
                                        transform: isAccordion
                                          ? "rotate(180deg)"
                                          : "rotate(0deg)",
                                        transition: "transform 0.5s",
                                      }}
                                      onClick={accordionCollapse}
                                    />
                                  </div>
                                </Col>
                                <Col span={1} />
                              </Row>
                              {isAccordion && (
                                <div>
                                  <Row>
                                    {/* <Col span={1} /> */}
                                    <Col
                                      span={9}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {reviewsOverview && (
                                        <RatingAverage
                                          rating={reviewsOverview?.overallRating}
                                        />
                                      )}
                                    </Col>
                                    <Col span={3}>
                                      <Divider
                                        type="vertical"
                                        style={{
                                          border: "1px solid #CCCCCC",
                                          height: "110px",
                                          top: "1.5em",
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      span={11}
                                      style={{
                                        marginTop: "2rem",
                                      }}
                                    >
                                      {reviewsOverview && (
                                        <RatingChart
                                          ratingMap={reviewsOverview?.ratingMap}
                                          data={reviewdata}
                                        />
                                      )}
                                    </Col>
                                  </Row>

                                  {indv?.bizid !== businessId && (
                                    <>
                                      <Divider className="divider-mobile" />

                                      <Row>
                                        <Col xs={9}>
                                          <div className="reviewrating_rateTheStore-mobile">
                                            {cmpn?.biz?.bizName}
                                          </div>
                                        </Col>
                                        <Col
                                          xs={13}
                                          className="reviewrating_star-mobile"
                                        >
                                          {reviewsOverview && (
                                            <ReviewStars
                                              disabled={true}
                                              rating={
                                                reviewsOverview?.overallRating
                                              }
                                            />
                                          )}
                                        </Col>
                                      </Row>

                                      <Divider className="divider-mobile" />
                                      <Row style={{ marginTop: "3.5rem" }}>
                                        <Col span={24}>
                                          <div>
                                            <text className="reviewrating_shareExp-mobile">
                                              Share Your Experience :
                                            </text>
                                          </div>

                                          <div className="reviewrating_writeReviewInput">
                                            <Button
                                              className="reviewrating_inputBox-mobile"
                                              onClick={() => {
                                                setRating(0);
                                                setCommentForReview("");
                                                setSelectedImages(
                                                  Array(6).fill(null)
                                                );
                                                if (!indvid) {
                                                  history.push("/login");
                                                } else {
                                                  setUserComment(true);
                                                }
                                              }}
                                            >
                                              <FormOutlined className="reviewrating_formOutLine-mobile" />
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                  )}
                                  <Divider className="divider-mobile" />
                                  <Row
                                    style={{ marginTop: "1.68rem" }}
                                    justify={"space-between"}
                                    align={"middle"}
                                  >
                                    <Col span={16}>
                                      <Select
                                        defaultValue={JSON.stringify(sortByData)}
                                        onChange={(e) => {
                                          setPagination({
                                            ...pagination,
                                            current: 1,
                                          });
                                          setSortByData(JSON.parse(e));
                                        }}
                                        className="reviewrating_filterBTN"
                                        options={[
                                          {
                                            value: JSON.stringify({
                                              orderBy: "RATING",
                                              orderType: "ASC",
                                            }),
                                            label: "Rating : Low To High",
                                          },
                                          {
                                            value: JSON.stringify({
                                              orderBy: "RATING",
                                              orderType: "DESC",
                                            }),
                                            label: "Rating : High To Low",
                                          },
                                          {
                                            value: JSON.stringify({
                                              orderBy: "CREATED_DT",
                                              orderType: "DESC",
                                            }),
                                            label: "Newest First",
                                          },
                                          {
                                            value: JSON.stringify({
                                              orderBy: "CREATED_DT",
                                              orderType: "ASC",
                                            }),
                                            label: "Newest Last",
                                          },
                                        ]}
                                        suffixIcon={
                                          <img src={Sliders} alt="Sliders" />
                                        }
                                        style={{
                                          fontWeight: 400,
                                          fontSize: "18px",
                                          color: "#223369",
                                          background: "#FFFFFF",
                                          border: "1px solid #D3D6E1",
                                          borderRadius: "5px",
                                          height: "46px",
                                          width: "170px",
                                        }}
                                        placeholder="Sort By"
                                      />
                                    </Col>
                                    <Col span={4}>
                                      <div className="reviewcomments_seeAllButton-mobile">
                                        <Button
                                          className="reviewcomments_seeAllText"
                                          style={{
                                            border: "none",
                                          }}
                                          onClick={() => {
                                            setSeeAllComments(true);
                                          }}
                                        >
                                          <p
                                            style={{
                                              color: "#257cdb",
                                              fontSize: "16px",
                                              fontWeight: "400",
                                            }}
                                          >
                                            See all
                                          </p>
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col span={24}>
                                      {comments?.comments.map((item) => {
                                        return (
                                          <ReviewComments
                                            reportModalVisibleClick={() => {
                                              if (
                                                item?.isReportingAllowed === true
                                              ) {
                                                setReplyData(item);
                                                setReportComment("");
                                                setReportModalVisible(true);
                                              } else {
                                                setReportModalVisible(false);
                                                setReportedCommentVisible(true);
                                                setReportData(
                                                  item.reportedComments
                                                );
                                              }
                                            }}
                                            data={item}
                                            openParagraph={(data) => {
                                              setTitle("Comment");
                                              setParaVisible(true);
                                              setSelectedPlatformUser(data);
                                            }}
                                            indv={indv?.bizid}
                                            businessId={businessId}
                                            setModalVisible={() => {
                                              setReplyData(item);
                                              setReplyModalVisible(true);
                                            }}
                                          />
                                        );
                                      })}
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </Card>
                )}
            </Col>
          </Row>

          <Modal
            title="Offer Feedback"
            visible={modalVisible}
            footer={null}
            onCancel={() => {
              setModalVisible(false);
            }}
            closable={showCloseIcon}
            closeIcon={closeIcon}
            width="500px"
            className="offerFeedback_modal"
          >
            <ItemView
              isPreview={QR === PREVIEW}
              offer={selectedOffer}
              image={cmpn?.cmpnImgs[1]?.imgUri}
              callback={() => {
                setShowCloseIcon(false);
              }}
              viewClose={() => {
                setShowCloseIcon(true);
              }}
            />
          </Modal>
          <CustomizedModal
            btn2Disabled={isLoading}
            visible={userComment}
            className="modelUploadImg_seeAllModal"
            title="Ratings & Reviews"
            onCancel={() => {
              setUserComment(false);
            }}
            modalWidth={!isMobile() ? "820px" : "350px"}
            btn2="Submit"
            onBtn2Click={() => handleOnSubmit()}
            btn2Type="primary"
            btn2Style={{ width: "147px", background: "#257CDB" }}
            content={
              <>
                <Row style={{ width: "100%" }}>
                  <Col className="modelUploadImg_mainCol" span={24}>
                    <Row className="modelUploadImg_starRow">
                      <Col style={{ paddingLeft: "26px" }}>
                        <div className="modelUploadImg_modelRateProduct">
                          Rate this product
                        </div>

                        <Row>
                          <Col>
                            <ReviewStars
                              rating={rating}
                              onChange={(value) => {
                                setRating(value);
                              }}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Divider className="divider-mobile" />
                    <Row style={{ marginRight: "24px" }}>
                      <Col style={{ paddingLeft: "26px" }}>
                        <div className="modelUploadImg_modelShareExperience">
                          Share Your Experience
                        </div>
                        <div>
                          <TextArea
                            className="modelUploadImg_textArea"
                            onChange={(e) => {
                              setCommentForReview(e.target.value);
                            }}
                            value={commentForReview}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className=" modelUploadImg_modelAddImgtext">
                      Add Up to 6 Images
                    </div>

                    {!isMobile() ? (
                      <Row className="modelUploadImg_uploadImgRow">
                        {selectedImages.map((image, index) => (
                          <Col
                            lg={12}
                            md={12}
                            xl={8}
                            sm={12}
                            key={index}
                            align={"middle"}
                          >
                            {(!image || !image.url) && (
                              <Button
                                onClick={() => handleButtonClick(index)}
                                className="modelUploadImg_addImgBtn"
                                style={{ border: "none", padding: "0px" }}
                              >
                                <div className="modelUploadImg_uploadeImgBtn">
                                  <div className="modelUploadImg_uploadeImgDiv">
                                    <img
                                      src={uploadImage}
                                      className="modelUploadImg_uploadeImg"
                                    />
                                  </div>
                                  <div className="modelUploadImg_btnText">
                                    Add Image
                                  </div>
                                </div>
                              </Button>
                            )}
                            {image && image.url && (
                              <div
                                style={{ position: "relative", width: "196px" }}
                              >
                                <img
                                  src={image.url}
                                  className="modelUploadImg_uploadedImgDisplay"
                                  alt="Uploaded Image"
                                />
                                <Button
                                  className="modelUploadImg_cancelButton"
                                  onClick={() => handleCancelClick(index)}
                                >
                                  <img
                                    src={cancelImg}
                                    alt="cancelImg"
                                    className="modelUploadImg_cancelImgUploadBtn"
                                  />
                                </Button>
                              </div>
                            )}
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <div style={{ padding: "0 24px" }}>
                        <Row className="modelUploadImg_uploadImgRow-mobile">
                          <Col span={24}>
                            <div className=" modelUploadImg_modelAddImgtext-mobile">
                              Add Up to 6 Images
                            </div>
                            <Row
                              justify={"center"}
                              style={{ width: "100%", marginTop: "1.938rem" }}
                            >
                              {selectedImages?.map((image, index) => (
                                <Col>
                                  {(!image || !image.url) && (
                                    <Button
                                      onClick={(i) => handleButtonClick(i)}
                                      className="modelUploadImg_addImgBtnMob"
                                      style={{ border: "none", padding: "0px" }}
                                    >
                                      <Col
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                        span={24}
                                      >
                                        <div className="modelUploadImg_uploadeImgBtn-mobile">
                                          <div className="modelUploadImg_uploadeImgDiv-mobile">
                                            <img
                                              src={uploadImage}
                                              className="modelUploadImg_uploadeImg-mobile"
                                            />
                                          </div>

                                          <div className="modelUploadImg_btnText-mobile">
                                            Add Image
                                          </div>
                                        </div>
                                      </Col>
                                    </Button>
                                  )}
                                  {image && image.url && (
                                    <div
                                      className="modelUploadImg_uploadedImgDivMob"
                                      style={{
                                        position: "relative",
                                        width: "130px",
                                        display: "flex",
                                        justifyContent: "center",
                                        paddingTop: "4px",
                                      }}
                                    >
                                      <img
                                        src={image.url}
                                        className="modelUploadImg_uploadedImgDisplayMob"
                                        alt="Uploaded Image"
                                      />
                                      <Button
                                        className="modelUploadImg_cancelButtonMob"
                                        onClick={(index) =>
                                          handleCancelClick(index)
                                        }
                                      >
                                        <img src={cancelImg} alt="cancelImg" />
                                      </Button>
                                    </div>
                                  )}
                                </Col>
                              ))}
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            }
          />
          <CustomizedModal
            title="Reply to the comment"
            visible={replyModalVisible}
            onCancel={() => {
              setReplyModalVisible(false);
            }}
            btn2Disabled={isLoading}
            onBtn2Click={() => {
              onHandleSubmit();
            }}
            btn2Type="primary"
            btn2="Submit"
            content={
              <Row style={{ padding: "24px 24px 0px 24px " }}>
                <Col span={24}>
                  <Row>
                    <div
                      style={{ paddingLeft: "0px" }}
                      className="reviewcomments_replyUserName"
                    >{`${replyData?.user?.firstName} ${replyData?.user?.lastName}`}</div>

                    <div className=" reviewcomments_replyRatingDuration">
                      {moment(replyData.createdDt, "YYYYMMDD").fromNow()}
                    </div>
                  </Row>

                  <div
                    style={{
                      marginTop: "12px",
                      lineHeight: "10px",
                      width: "100%",
                    }}
                  >
                    <ReviewStars
                      disabled={true}
                      value={replyData.rating}
                      rating={replyData.rating}
                    />
                  </div>

                  <Row>
                    <UserCommentText
                      openParagraph={(data) => {
                        setTitle("Comment");
                        setParaVisible(true);
                        setSelectedPlatformUser(data);
                      }}
                      userText={replyData.review}
                      userReply={replyData.commentThread}
                    />
                  </Row>
                  <Divider
                    style={{ marginTop: "8px" }}
                    className="divider-mobile"
                  />
                  <Row>
                    <Col span={24}>
                      <TextArea
                        placeholder="Write Your Comment"
                        className="modelUploadImg_modalInputBox"
                        style={{ height: 135 }}
                        value={replyComment}
                        onChange={(e) => {
                          onChangeHandler(e);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          />
          <CustomizedModal
            visible={paraVisible}
            onCancel={() => setParaVisible(false)}
            footer={null}
            title={title}
            content={
              <Row>
                <Text
                  style={{
                    fontSize: "16px",
                    lineHeight: "13px",
                    padding: "24px",
                  }}
                >
                  {selectedPlatformUser}
                </Text>
              </Row>
            }
          />
          <CustomizedModal
            visible={reportedCommentVisible}
            onCancel={() => setReportedCommentVisible(false)}
            footer={null}
            title={"Reported Comments"}
            content={
              <Row style={{ padding: "24px" }}>
                {reportData?.map((data, index) => {
                  return (
                    <Row className="particular-reviewModal_row">
                      <div className="particular-reviewModal_reportedComment">
                        {LongText(data?.reportedComment, index, true)}
                      </div>
                      <div className="particular-reviewModal_time">
                        {moment
                          .utc(data?.createdDt)
                          .local()
                          .format("YYYY-MM-DD HH:mm A")}
                      </div>
                    </Row>
                  );
                })}
              </Row>
            }
          />
          <CustomizedModal
            visible={seeAllComments}
            footer={null}
            modalWidth="1169px"
            onCancel={() => {
              setPagination({
                ...pagination,
                current: 1,
              });
              setSeeAllComments(false);
            }}
            title="All Reviews"
            className="allReviewComments"
            content={
              <Row>
                <Col
                  style={{ maxHeight: "80vh" }}
                  className="allReviewComments_scroll"
                  span={24}
                >
                  <Table
                    pagination={pagination}
                    showHeader={false}
                    columns={columns}
                    dataSource={comments?.comments}
                    onChange={handleTableChange}
                    className="allReviewComments_table"
                  />
                </Col>
              </Row>
            }
          />
          <CustomizedModal
            visible={allVisible}
            footer={null}
            destroyOnClose={true}
            modalWidth="1169px"
            onCancel={() => {
              setSortByData({
                orderBy: "RATING",
                orderType: "DESC",
              });
              setRating(0);
              setCommentForReview("");
              setSelectedImages(Array(6).fill(null));
              setPagination({
                ...pagination,
                current: 1,
              });
              setAllVisible(false);
            }}
            title="All Reviews"
            className="allReviewComments"
            content={
              <>
                <AllReviewPopUp
                  reviewsOverview={reviewsOverview}
                  indv={indv}
                  businessId={businessId}
                  rating={reviewsOverview?.overallRating}
                  ratingMap={reviewsOverview?.ratingMap}
                  data={reviewdata}
                  placeholder="Sort By"
                  disabled={true}
                  suffixIcon={<img src={Sliders} alt="Sliders" />}
                  pagination={pagination}
                  showHeader={false}
                  columns={columns}
                  dataSource={comments?.comments}
                  handleTableChange={handleTableChange}
                  starText={cmpn?.biz?.bizName}
                  defValue={JSON.stringify(sortByData)}
                  onDropdownChange={(e) => {
                    setPagination({
                      ...pagination,
                      current: 1,
                    });
                    setSortByData(JSON.parse(e));
                  }}
                  options={[
                    {
                      value: JSON.stringify({
                        orderBy: "RATING",
                        orderType: "ASC",
                      }),
                      label: "Rating : Low To High",
                    },
                    {
                      value: JSON.stringify({
                        orderBy: "RATING",
                        orderType: "DESC",
                      }),
                      label: "Rating : High To Low",
                    },
                    {
                      value: JSON.stringify({
                        orderBy: "CREATED_DT",
                        orderType: "DESC",
                      }),
                      label: "Newest First",
                    },
                    {
                      value: JSON.stringify({
                        orderBy: "CREATED_DT",
                        orderType: "ASC",
                      }),
                      label: "Newest Last",
                    },
                  ]}
                  text="Write A Review :"
                  onClick={() => {
                    setRating(0);
                    setCommentForReview("");
                    setSelectedImages(Array(6).fill(null));
                    if (!indvid) {
                      dispatch({ type: SET_ROUTE, payload: currentRoute });
                      history.push("/login");
                    } else {
                      setSeeAllComments(false);
                      setUserComment(true);
                    }
                  }}
                />
                <CustomizedModal
                  btn2Disabled={isLoading}
                  visible={userComment}
                  className="modelUploadImg_seeAllModal"
                  title="Ratings & Reviews"
                  onCancel={() => {
                    setUserComment(false);
                  }}
                  modalWidth={!isMobile() ? "820px" : "350px"}
                  btn2="Submit"
                  onBtn2Click={() => handleOnSubmit()}
                  btn2Type="primary"
                  btn2Style={{ width: "147px", background: "#257CDB" }}
                  content={
                    <>
                      <Row style={{ width: "100%" }}>
                        <Col className="modelUploadImg_mainCol" span={24}>
                          <Row className="modelUploadImg_starRow">
                            <Col style={{ paddingLeft: "26px" }}>
                              <div className="modelUploadImg_modelRateProduct">
                                Rate this product
                              </div>

                              <Row>
                                <Col>
                                  <ReviewStars
                                    rating={rating}
                                    onChange={(value) => {
                                      setRating(value);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Divider className="divider-mobile" />
                          <Row style={{ marginRight: "24px" }}>
                            <Col style={{ paddingLeft: "26px" }}>
                              <div className="modelUploadImg_modelShareExperience">
                                Share Your Experience
                              </div>
                              <div>
                                <TextArea
                                  className="modelUploadImg_textArea"
                                  onChange={(e) => {
                                    setCommentForReview(e.target.value);
                                  }}
                                  value={commentForReview}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className=" modelUploadImg_modelAddImgtext">
                            Add Up to 6 Images
                          </div>

                          {!isMobile() ? (
                            <Row className="modelUploadImg_uploadImgRow">
                              {selectedImages.map((image, index) => (
                                <Col
                                  lg={12}
                                  md={12}
                                  xl={8}
                                  sm={12}
                                  key={index}
                                  align={"middle"}
                                >
                                  {(!image || !image.url) && (
                                    <Button
                                      onClick={() => handleButtonClick(index)}
                                      className="modelUploadImg_addImgBtn"
                                      style={{ border: "none", padding: "0px" }}
                                    >
                                      <div className="modelUploadImg_uploadeImgBtn">
                                        <div className="modelUploadImg_uploadeImgDiv">
                                          <img
                                            src={uploadImage}
                                            className="modelUploadImg_uploadeImg"
                                          />
                                        </div>
                                        <div className="modelUploadImg_btnText">
                                          Add Image
                                        </div>
                                      </div>
                                    </Button>
                                  )}
                                  {image && image.url && (
                                    <div
                                      style={{
                                        position: "relative",
                                        width: "196px",
                                      }}
                                    >
                                      <img
                                        src={image.url}
                                        className="modelUploadImg_uploadedImgDisplay"
                                        alt="Uploaded Image"
                                      />
                                      <Button
                                        className="modelUploadImg_cancelButton"
                                        onClick={() => handleCancelClick(index)}
                                      >
                                        <img
                                          src={cancelImg}
                                          alt="cancelImg"
                                          className="modelUploadImg_cancelImgUploadBtn"
                                        />
                                      </Button>
                                    </div>
                                  )}
                                </Col>
                              ))}
                            </Row>
                          ) : (
                            <div style={{ padding: "0 24px" }}>
                              <Row className="modelUploadImg_uploadImgRow-mobile">
                                <Col span={24}>
                                  <div className=" modelUploadImg_modelAddImgtext-mobile">
                                    Add Up to 6 Images
                                  </div>
                                  <Row
                                    justify={"center"}
                                    style={{
                                      width: "100%",
                                      marginTop: "1.938rem",
                                    }}
                                  >
                                    {selectedImages?.map((image, index) => (
                                      <Col>
                                        {(!image || !image.url) && (
                                          <Button
                                            onClick={(i) =>
                                              handleButtonClick(i)
                                            }
                                            className="modelUploadImg_addImgBtnMob"
                                            style={{
                                              border: "none",
                                              padding: "0px",
                                            }}
                                          >
                                            <Col
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                              span={24}
                                            >
                                              <div className="modelUploadImg_uploadeImgBtn-mobile">
                                                <div className="modelUploadImg_uploadeImgDiv-mobile">
                                                  <img
                                                    src={uploadImage}
                                                    className="modelUploadImg_uploadeImg-mobile"
                                                  />
                                                </div>

                                                <div className="modelUploadImg_btnText-mobile">
                                                  Add Image
                                                </div>
                                              </div>
                                            </Col>
                                          </Button>
                                        )}
                                        {image && image.url && (
                                          <div
                                            className="modelUploadImg_uploadedImgDivMob"
                                            style={{
                                              position: "relative",
                                              width: "130px",
                                              display: "flex",
                                              justifyContent: "center",
                                              paddingTop: "4px",
                                            }}
                                          >
                                            <img
                                              src={image.url}
                                              className="modelUploadImg_uploadedImgDisplayMob"
                                              alt="Uploaded Image"
                                            />
                                            <Button
                                              className="modelUploadImg_cancelButtonMob"
                                              onClick={(index) =>
                                                handleCancelClick(index)
                                              }
                                            >
                                              <img
                                                src={cancelImg}
                                                alt="cancelImg"
                                              />
                                            </Button>
                                          </div>
                                        )}
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  }
                />
              </>
            }
          />

          <Modal
            visible={showNumberModal}
            onCancel={() => {
              setShowNumberModal(false);
              setLocPhone("");
            }}
            footer={null}
            title="Call us"
            className="callUs_modal"
            closeIcon={closeIcon}
          >
            <Row justify={"center"}>
              <img alt="bluePhone" src={Phoneblue} />
              <Text
                style={{
                  color: "#257CDB",
                  fontSize: "1.25rem",
                  fontWeight: "400",
                  marginLeft: "16px",
                }}
              >
                {locPhone !== "" ? locPhone : cmpn?.biz?.phone}
              </Text>
            </Row>
          </Modal>

          <CustomizedModal
            title="Report to the comment"
            visible={reportModalVisble}
            onCancel={() => {
              setReportModalVisible(false);
            }}
            btn2Type="primary"
            btn2="Submit"
            onBtn2Click={() => {
              onReportSubmit();
            }}
            btn2Disabled={isLoading}
            isLoading={isLoading}
            content={
              <Row style={{ padding: "24px 24px 0px 24px " }}>
                <Col span={24}>
                  <Row>
                    <div
                      style={{ paddingLeft: "0px" }}
                      className="reviewcomments_replyUserName"
                    >{`${replyData?.user?.firstName} ${replyData?.user?.lastName}`}</div>

                    <div className=" reviewcomments_replyRatingDuration">
                      {moment(replyData.createdDt, "YYYYMMDD").fromNow()}
                    </div>
                  </Row>

                  <div
                    style={{
                      marginTop: "12px",
                      lineHeight: "10px",
                      width: "100%",
                    }}
                  >
                    <ReviewStars
                      disabled={true}
                      value={replyData.rating}
                      rating={replyData.rating}
                    />
                  </div>

                  <Row>
                    <UserCommentText
                      openParagraph={(data) => {
                        setTitle("Comment");
                        setParaVisible(true);
                        setSelectedPlatformUser(data);
                      }}
                      userText={replyData.review}
                    />
                  </Row>
                  <Divider
                    style={{ marginTop: "8px" }}
                    className="divider-mobile"
                  />
                  <Row>
                    <Col span={24}>
                      <TextArea
                        placeholder="Write Your Comment"
                        className="modelUploadImg_modalInputBox"
                        style={{ height: 135 }}
                        value={reportComment}
                        onChange={(e) => {
                          onReportHandler(e);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          />
          <Modal
            onCancel={() => {
              let dataNew = appointmentData?.filter(
                (item) => item?.objid == selectedId
              );
              console.log("on cancel", Object.assign({}, ...dataNew));
              if (!later) {
                console.log("oncancel if");
                handleChangeQty(
                  selectedId,
                  dataNew[0]?.appointment?.length,
                  dataNew[0]?.isLater
                );
                addObject(Object.assign({}, ...dataNew));
              } else {
                console.log("oncancel else");

                handleChangeQty(selectedId, quantity - 1, dataNew[0]?.isLater);
                addObject(Object.assign({}, ...dataNew));
              }
              setUserAppointmentModal(false);
            }}
            footer={null}
            title={cmpn?.biz?.bizName}
            visible={userappointmentModal}
            closeIcon={closeIcon}
            width={"1085px"}
            className="customize_modal"
            style={{ height: "413px" }}
          >
            <SlotBooking
              appointments={appointmentData}
              locId={selectedLocationId}
              objId={selectedId}
              title={offername}
              qty={1}
              laterClick={() => {
                let data = appointmentData.filter(
                  (item) => item?.objid === selectedId
                );
                setLater(true);
                handleChangeQty(
                  selectedId,
                  quantity === 0 ? 1 : quantity,
                  true
                );
                addObject(Object.assign({}, ...data));
                setUserAppointmentModal(false);
              }}
              later={"Schedule for later"}
              // onLaterDisabled={areAppointmentsAvailable ? true : false}
              select={
                <Select
                  className="location-dropdown"
                  defaultValue={selectedLocationId}
                  onChange={(e, value) => {

                    if (appointmentData.find((item) => item?.appointment?.length > 0) || appointmentData.find((item) => item.isLater === true)) {
                      handleSelectChange(e);
                    }
                    else {
                      setSelectedLocationId(e);
                      setLocName(value?.locName)
                    }
                    setPostalCodeValue({ "postalCode": value.postalCode, "country": value.country })
                    handleChangeQty(selectedId, quantity)
                    console.log("selectedLocationId>>>", e, value);
                  }}
                  style={{
                    minWidth: "100%",
                    borderRadius: "5px",
                    background: " #FFF",
                  }}
                  // className="reviewrating_filterBTN"
                  options={locationDropdownData}
                  value={selectedLocationId}
                />
              }
              next="Next"
              disabled={
                roleType === ROLE_SUPPORT ||
                  roleType === ROLE_MANAGER ||
                  isMasquerade
                  ? true
                  : false
              }
              onNextClick={(data) => {
                setQtyEnabled(true);
                let appData =
                  appointmentData &&
                  appointmentData.find((item) => item?.objid === selectedId);
                if (appData?.appointment) {
                  appData?.appointment.push(data[data.length - 1]);
                } else if (appData) {
                  appData.appointment = [data[data?.length - 1]];
                }
                addObject(appData);
                setGetTime(data);
                {
                  // if (appData?.qty === appData?.appointment?.length)
                  setUserAppointmentModal(false);

                  // setSelectedLocationId(null);
                }
                {
                  setUserAllAppointmentModal(true, appointmentData);
                }
              }}
            />
          </Modal>
          {!appointLoading && (
            <Modal
              onCancel={() => {
                setUserAllAppointmentModal(false);
              }}
              footer={null}
              title={selectedObj?.title || cmpn?.biz?.bizName}
              visible={userallappointmentModal}
              closeIcon={closeIcon}
              width={"1085px"}
              // className="customize_modal"
              className="modal-popup"
              style={{
                height: "413px",
                padding: isMobile() ? "1px 1px 1px 1px" : "10px 60px 10px 20px",
              }}
            >
              <AllAppointment
                btn2Name={"Save"}
                onSave={"Save"}
                data={appointmentData}
                title={offername}
                editClick={(e, i) => {
                  setSelectedObj(e);
                  setSelectedIndex(i);
                  setSelectedLocationId(selectedLocationId);
                  setAppointVisible(true);
                  {
                    setAppointmentLoading(true);
                  }
                }}
                bizName={selectedObj?.title || selectedOffer?.title}
                buttonClick={() => {
                  {
                    setUserAppointmentModal(true);
                  }
                  {
                    setUserAllAppointmentModal(false);
                  }
                }}
                SaveOnClick={() => {
                  {
                    persistChanges();
                    setUserAllAppointmentModal(false);
                  }
                }}
              />
            </Modal>
          )}
          <Modal
            onCancel={() => {
              setAppointVisible(false);
              setAppointmentLoading(false);
            }}
            footer={null}
            title={selectedObj?.title || cmpn?.biz?.bizName}
            visible={appointVisible}
            closeIcon={closeIcon}
            width={"1032px"}
            className="customize_modal"
            style={{ height: "413px" }}
          >
            <SlotBooking
              next="Save"
              qty={1}
              appointments={appointmentData}
              title={offername}
              locId={selectedLocationId}
              objId={selectedId}
              select={
                <Select
                  defaultValue={selectedLocationId}
                  // onChange={handleSelectChange}
                  onChange={(e, value) => {
                    if (appointmentData?.appointment?.length > 0 || appointmentData.find((item) => item.isLater === true)) {
                      handleSelectChange(e);
                    }
                    else {
                      setSelectedLocationId(e);
                    }
                    setPostalCodeValue({ "postalCode": value.postalCode, "country": value.country })
                    handleChangeQty(selectedId, quantity)
                    console.log("selectedLocationId>>>", e, value);
                  }}
                  style={{
                    minWidth: "100%",
                    borderRadius: "5px",
                    background: " #FFF",
                  }}
                  options={locationDropdownData}
                  value={selectedLocationId}
                />
              }
              onNextClick={(e) => {
                let dataNew = selectedObj;
                const last = e[e.length - 1];
                dataNew.appointment[selectedIndex] = last;
                addObject(dataNew);
                persistChanges();
                {
                  setAppointVisible(false);
                }
                console.log("on this");
                setAppointmentLoading(false);
              }}
            />
          </Modal>
          <Modal
            visible={locChange}
            onCancel={() => {
              setSelectedLocationId(selectedLocationId);
              setLocChange(false);
            }}
            onOk={async () => {
              setCart({});
              let tempArr = [];
              setAppointmentData(tempArr);

              setSelectedLocationId(tempLoc);
              appointmentData?.forEach((obj) => {
                delete obj.appointment;
              });
              newSelectedObj.qty = 1;
              const data = appointmentData?.filter(
                (obj) => obj?.objid === newSelectedObj?.objid
              );
              setAppointmentData(data);

              setTotal(0);
              handleChangeQty(selectedId, 1);
              setLocChange(false);
              // setUserAppointmentModal(false);
            }}
          >
            <h5>Do you want to change location?</h5>
            <Text>
              Changing location will delete all the scheduled appointment in
              your cart.
            </Text>
          </Modal>
          <CustomizedModal
            visible={viewLocation}
            onCancel={() => {
              setViewLocation(false);
              setLocPagination({
                ...locPagination,
                current: 1,
                pageSize: 10,
              });
              setSelectedLocationId(null);
              setLocationEnabledValue(false);
            }}
            footer={null}
            content={
              <Col span={24} style={{ maxHeight: "90vh", height: "90vh" }}>
                <Table
                  className="locations-table"
                  style={{ height: "85vh", width: "100%" }}
                  bordered
                  pagination={locPagination}
                  columns={LocColumn}
                  dataSource={cmpn?.cmpnLocs}
                  size="medium"
                  onChange={handleTableChangeViewLocations}
                  showHeader={false}
                />
                {locationEnabledValue && <div style={{ display: 'flex', margin: 2, justifyContent: 'center' }}><Button
                  type="primary"
                  // className="offer_cards--active"
                  style={{ minWidth: "5rem", alignItems: 'center' }}
                  onClick={() => {

                    setEnable(
                      newSelectedObj.isBookingEnabled
                    );
                    locationDropdowm();
                    setSelectedLocationId(
                      selectedLocationId
                    );


                    setSelectedId(
                      newSelectedObj?.objid
                    );
                    addObject(
                      newSelectedObj
                    );
                    setNewSelectedObj(
                      newSelectedObj
                    );

                    handleChangeQty(selectedId, 1, false)
                    setViewLocation(false)
                    if(enable && selectedLocation?.isBookingEnabled){
                      setUserAppointmentModal(
                        true
                      );
                    }
                    
                    setLocationEnabledValue(false);
                  }}
                  disabled={
                    !selectedLocationId
                  }
                >
                  Save
                </Button>
                </div>

                }
              </Col>
            }
          />
        </div>
        {popupVisible && (
          <PurchasePopup
            visible={popupVisible}
            onCancel={handlePopupClose}
            createdInvoice={createdInvoice}
            payCard={payCard}
            destroyOnClose={true}
          />
        )}
      </LoadingOverlay>
    </>
  );
};
export default OfferDetails;