import React, { useState, useEffect } from 'react'
import {
    Breadcrumb,
    Table,
    Col,
    Button,
    Input,
    Divider,
    Row,
    DatePicker
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import actions from "../../../actions";
import PulseLoader from "react-spinners/PulseLoader";
import { MINIMUM_SEARCH_LIMIT } from "../../../common/config";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { findHomePathFromRole } from "../../../common/util";
import DropdownComponent from '../../../components/Dashboard/DropdownComponent';
const { RangePicker } = DatePicker;
export default function Revenue() {
    const history = useHistory()
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [country] = useState([
        { value: "US", label: "US" },
        { value: "IN", label: "IN" },
    ]);
    const [businessName, setBusinessName] = useState("")
    const [values, setValues] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 100,
        total: 0,
    });
    const tableColumn = [
        {
            title: "Business Name ",
            dataIndex: "businessName",
            key: "name",
            width: 140,
            render: (data, key) => {
                return <p>{data ? data : "-"}</p>;
            },
        },
        {
            title: "Total Payments Process",
            dataIndex: "totalRevenue",
            key: "Payments",
            render: (data, key) => {
                return <p>{data ? `${key.currencySymbol}${data}` : "-"}</p>;
            },
        },
        {
            title: "Total Offers Payments",
            dataIndex: "offers",
            key: "Offers",
            render: (data, key) => {
                return <p>{data ? `${key.currencySymbol}${data}` : "-"}</p>;
            },
        },
        {
            title: "Total Catalogue Payments",
            dataIndex: "catalog",
            key: "Catalogue",
            render: (data, key) => {
                return <p>{data ? `${key.currencySymbol}${data}` : "-"}</p>;
            },
        },
        {
            title: "Total Promotional Payments",
            dataIndex: "cmpnPromo",
            key: "Promotional",
            render: (data, key) => {
                return <p>{data ? `${key.currencySymbol}${data}` : "-"}</p>;
            },
        },
        {
            title: "Total Fyndr Cash",
            dataIndex: "fyndrCash",
            key: "Fyndr Cash",
            render: (data, key) => {
                return <p>{data ? `${key.currencySymbol}${data}` : "-"}</p>;
            },
        },
        {
            title: "Total Interaction Payments",
            dataIndex: "interaction",
            key: "Interaction",
            render: (data, key) => {
                return < p > {data ? `${key.currencySymbol}${data}` : "-"}</p >;
            },
        },
        {
            title: "Total Events Payments",
            dataIndex: "event",
            key: "Event",
            render: (data, key) => {
                return <p>{data ? `${key.currencySymbol}${data}` : "-"}</p>;
            },
        },
        {
            title: "Action",
            key: "action",
            width: 90,
            render: (_, record) => {
                return (
                    <Button onClick={() => masqueradeUser(record.objId)} type="link">
                        Login
                    </Button>
                );
            },
        }
    ];
    const revenueDetails = async (text) => {
        setLoading(true);
        const pgstart = text?.pgstart || pagination?.current
        let response = await actions.detailsApi({
            country: "US",
            businessName: text,
            startDate: values[0],
            endDate: values[1],
            pgstart,
            pgSize: pagination?.pageSize,
        })
        setPagination({
            ...pagination,
            total: response?.count,
        })
        if (response?.success === true) {
            setLoading(false);
            setData(response?.revenueDetails);
        }
    };
    useEffect(() => {
        revenueDetails(businessName);

    }, [values, pagination.current,])

    const handleTableChange = (pagination) => {
        setPagination({
            ...pagination
        })
    }
    const onChange = (moments, dateStrings) => {
        setValues(dateStrings);
    };
    const handleSearch = (value) => {
        if (value.length >= MINIMUM_SEARCH_LIMIT) {
            revenueDetails(value);
        } else if (value.length == 0) {
            revenueDetails("");
        }
    }

    //masqueradeUser
    const masqueradeUser = async (id) => {
        const channel = new BroadcastChannel('masquerade-channel');
        let response = await actions.MasqueradeUser(id);
        if (response.success === true) {
            channel.postMessage({ userId: id });
            let path = await findHomePathFromRole(response.role)
            history.push(path)

        } else {
            message.error(response.msg);
        }
    };
    return (
        <div className="admin_container no-breadcrumb container-mw-xl chapter">
            <div className="quickLoader">
                <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
            </div>
            <article className="article">
                <Breadcrumb>
                    <Breadcrumb.Item>Admin</Breadcrumb.Item>
                    <Breadcrumb.Item>Revenue</Breadcrumb.Item>
                </Breadcrumb>
                <h2
                    className="article-title"
                    style={{
                        marginBottom: "-2rem",
                        marginTop: "-2rem",
                        paddingTop: "3rem",
                        paddingBottom: "2rem",
                    }}
                >
                    Revenue
                </h2>
                <Divider />
                <div style={{ marginBottom: "2rem" }}>
                    <Row justify="space-between" gutter={[8, 8]}>
                        <Col style={{ width: "70%" }}>
                            <Row gutter={[8,]}>
                                <Col style={{ width: "60%" }}>
                                    <Input
                                        placeholder="Search"
                                        type="search"
                                        suffix={<SearchOutlined />}
                                        style={{height:"36px"}}
                                        onPressEnter={
                                            (e) => {
                                                setPagination({
                                                    ...pagination,
                                                    current: 1,

                                                });
                                                setBusinessName(e.target.value)
                                                handleSearch(e.target.value)
                                            }
                                        }
                                    />
                                </Col>
                                <Col style={{ width: "30%" }}>
                                    <DropdownComponent placeholder="Country" options={country} value={"US"} style={{ minWidth: "100%" }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <RangePicker
                             style={{height:"36px"}}
                                onChange={onChange}
                                allowClear={true} />
                        </Col>
                    </Row>
                </div>
                <Table
                    className="admin-table"
                    bordered
                    pagination={pagination}
                    columns={tableColumn}
                    dataSource={data}
                    size="medium"
                    onChange={handleTableChange}
                />
            </article>
        </div>
    )
}
