import React, { useEffect, useState, useRef } from "react";
import {
  Breadcrumb,
  Button,
  Col,
  Divider,
  Input,
  Row,
  Table,
  Tag,
  Modal,
  Typography,
  DatePicker,
  Popover
} from "antd";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, SortAscendingOutlined } from "@ant-design/icons";
import DropdownComponent from "../../../components/Dashboard/DropdownComponent";
import actions from "../../../actions";
import CustomizedModal from "../../../components/CustomizedModal/CustomizedModal";
import PulseLoader from "react-spinners/PulseLoader";
import { MINIMUM_SEARCH_LIMIT } from "../../../common/config";
import { message } from "antd";
import { findHomePathFromRole, findHomePathFromRoleMGR, getDateNew, parseAddress } from "../../../common/util";
import { getStatesOfCountry } from "country-state-city/lib/state";
import StripeUI from "../../../components/StripeUI/StripeUI";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../../../common";
import getEnvVars from "../../../environment";
import { fetch_retry_post } from "../../../network/ApiManager";
const { ROLE_SUPPORT, ROLE_MANAGER, ROLE_TYPE } = Actions;
const { RangePicker } = DatePicker;
const { base } = getEnvVars();

// const {getStatesOfCountry} = lazy(() => import('country-state-city/lib/state'));


export default function AdminCustomer(props) {

  const {
    indv: { userTimeZone },
  } = useSelector(({ auth }) => auth);

  const history = useHistory()
  const location = useLocation();
  const { Text } = Typography;
  const Ref = useRef(null);


  //setting the reducer

  const [visible, setModalVisible] = useState(false);
  const [businessUpdate, setBusinessUpdate] = useState(false);
  const [emailUpdate, setEmailUpdate] = useState(false);
  const [email, setEmail] = useState("")
  const [userType, setUserType] = useState(location?.state?.userType ? location?.state?.userType : []);
  const [stateType, setStateType] = useState([]);
  const [userStatus, setUserStatus] = useState(location?.state?.userStatus ? location?.state?.userStatus : []);
  const [text, setText] = useState("");
  const [data, setData] = useState([]);
  const [dataDwn, setDataDwn] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [accountStatus, setAccountStatus] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [dateOrder, setOrderDate] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [countryValue, setCountryValue] = useState("US");
  const [loading, setLoading] = useState(false);
  const [stateData, setStateData] = useState([]);
  const [errors, setErrors] = useState();
  const [stripeVisible, setStripeVisible] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const [token, setToken] = useState("");
  const [tokenError, setTokenError] = useState(null)
  const [phoneNo, setPhoneNo] = useState({});
  const [regMode, setRegMode] = useState("")
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [newEmail, setNewEmail] = useState("")
  const [verified, setVerified] = useState(true)
  const { isMasquerade } = useSelector(({ admin }) => admin);

  const {
    indv: { entityRole },
  } = useSelector(({ auth }) => auth);
  const [selectedStatus, setSelectedStatus] = useState("")
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 100,
    total: 0,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    getStateData(countryValue);
  }, [])
  //masqueradeUser

  const getContant = () => {
    switch (dateOrder) {
      case 1:
        return `Click to sort ascending`
      case 3:
        return `Click to cancel sorting`
      case 2:
        return `Click to sort descending`
      default:
        break;
    }
  }
  const getColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "#E2FFE5";
      case "DELETED":
        return "#EAEAEA";
      case "INACTIVE":
        return "#FFD6D6";
      case "SUSPENDED":
        return "#f3f3ba";
      default:
        return null;
    }
  };
  function formatDate(dt) {
    const date = new Date(dt).toISOString().split('T')[0];
    let [year, month, day] = date.split('-');
    return `${month}-${day}-${year}`;
  }
  const tableColumn = [
    {
      title: "Customer Name ",
      dataIndex: "name",
      key: "name",

      // sorter: (a, b) => a.name.localeCompare(b.name),
      // sortOrder: "ascend",
      align: "left"

    },
    {
      title: "Customer Type ",
      dataIndex: "business",
      key: "business",
      align: "left",

      render: (data, key) => {
        return <p>{data ? "Business" : "Individual"}</p>;
      },
    },
    {
      title: "Business Name",
      dataIndex: "businessName",
      key: "name",
      align: "left",

      render: (data, record) => {
        return <Row justify={"space-between"}>
          <p>{data ? data : "-"}</p>

        </Row>
          ;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      align: "left",


      render: (data, key) => {
        return (
          <p>{parseAddress(data)}</p>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "left",
      width: 190,
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      width: 120,
      align: "left",

      render: (data, key) => {
        return <p>{`${data?.phoneNumber ? data?.phoneNumber : "-"} `}</p>;
      },
    },
    {
      title: (
        <Popover
          content={
            getContant()
          }
        >
          <div onClick={() => {
            setPagination({
              ...pagination,
              current: 1,

            });
            setOrderDate(prevStep => (prevStep === 3 ? 1 : prevStep + 1))
          }
          } style={{ display: "flex", alignItems: "center" }}>
            Registered Date <ArrowUpOutlined style={{ color: dateOrder === 2 && "#2DCE89", height: 20, width: 20 }} />  <ArrowDownOutlined style={{ color: dateOrder === 3 && "#2DCE89", height: 20, width: 20 }} />
          </div>
        </Popover>
      ),
      dataIndex: "createDt",
      key: "createDt",
      width: 120,
      align: "left",
      // sorter: (a, b) => moment(a.createDt).unix() - moment(b.createDt).unix(),
      render: (createDt, key) => {
        return <p>{getDateNew(createDt, userTimeZone)}</p>
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "left",


      render: (item) => (
        <Tag
          style={{
            color: "#000",
            borderRadius: "1rem",
            padding: ".5rem .5rem",
          }}
          color={getColor(item)}
          key={item}
        >
          {item.toUpperCase()}
        </Tag>
      ),
    },
    {
      title: "Actions",
      key: "action",
      align: "left",
      width: 100,


      render: (_, record) => {
        return (
          <Col>
            <Button onClick={() => masqueradeUser(record.objId)} type="link">
              Login
            </Button>
            <br />
            {entityRole !== ROLE_SUPPORT && <Button
              type="link"
              onClick={() => {
                setModalVisible(true);
                setSelectedUser(record);
                setSelectedStatus(record.status);
              }}
            >
              Update Status
            </Button>}
            {entityRole !== ROLE_SUPPORT && entityRole !== ROLE_MANAGER && record?.business && <Button
              type="link"
              onClick={() => {
                setSelectedUser(record);
                getStripeDetailsInfo(record?.objId);
              }}
            >
              {"Check Stripe Status"}
            </Button>}
            {entityRole !== ROLE_SUPPORT && entityRole !== ROLE_MANAGER && record?.business && <Button
              type="link"
              onClick={() => {
                setPhoneNo(record?.phone);
                setSelectedUser(record);
                setRegMode(record?.regMode)
                setEmail(record?.email);
                setNewEmail(record?.email)
                setEmailUpdate(true);
              }}
            >
              {"Update Email Address"}
            </Button>}
            {entityRole !== ROLE_SUPPORT && entityRole !== ROLE_MANAGER && record?.business && <Button
              type="link"
              onClick={() => {
                setSelectedUser(record);
                setBusinessUpdate(true);
                setBusinessName(record?.businessName);
              }}
            >
              {"Update Business Name"}
            </Button>}

          </Col>
        );
      },
    },
  ];
  const country = [
    { value: "US", label: "United States" },
    { value: "IN", label: "India" },
    { value: "AU", label: "Australia" },
    { value: "CA", label: "Canada" },
    { value: "GB", label: "United Kingdom" },
    { value: "NZ", label: "New Zealand" },
  ];
  const customerType = [
    { value: "BUSINESS", label: "BUSINESS" },
    { value: "INDIVIDUAL", label: "INDIVIDUAL" },
  ];
  const currentStatus = [
    { value: "ACTIVE", label: "ACTIVE", color: "dot-status-active" },
    { value: "INACTIVE", label: "INACTIVE", color: "dot-status-inactive" },
    { value: "SUSPENDED", label: "SUSPENDED", color: "dot-status-suspended" },
    { value: "DELETED", label: "DELETED", color: "dot-status-deleted" },
  ];
  const AdminSearchFunction = async (text) => {
    setLoading(true);

    const pgstart = text.pgstart || pagination.current
    let response = await actions.AdminUserSearch({
      userStatus: userStatus, userType: userType, text: text,
      pgstart,
      pgSize: pagination.pageSize,
      startDate: startDate,
      endDate: endDate,
      state: stateType,
      dateOrder: dateOrder === 2 ? "ASC" : (dateOrder === 3 ? "DESC" : "")
    })
    setPagination({
      ...pagination,
      total: response?.count,
    })

    if (response?.success === true) {
      setLoading(false);
      setData(response?.users);
    }
  };
  const AdminSearchFunctionDwn = async (text) => {
    setLoading(true);

    let response = await actions.AdminUserListDownload({
      userStatus: userStatus, userType: userType, text: text,
      startDate: startDate,
      endDate: endDate,
      state: stateType,
      dateOrder: dateOrder === 2 ? "ASC" : (dateOrder === 3 ? "DESC" : "")
    })
    

    if (response?.success === true) {
      setLoading(false);
      setDataDwn(response?.users);
    }
  };
  const formattedData =dataDwn && dataDwn?.map(user => {
    // const address = [
    //   user.address.addressLine1,
    //   user.address.addressLine2,
    //   user.address.city,
    //   user.address.state,
    //   user.address.postalCode,
    //   user.address.country
    // ].filter(Boolean).join(', '); 
    const phone = `${user?.phone?.countryCode} ${user?.phone?.phoneNumber}`;
    const registeredDate = moment(user?.createDt).format('MM-DD-YYYY'); // Convert to readable date format

    return {
      "Customer Name": user?.name,
      "Customer Type": user?.business ? "Business" : "Individual",
      "Business Name": user?.businessName || "-",
      "Email": user?.email,
      "Phone": phone,
      "Registered Date": registeredDate,
      "Status": user?.status,
      "Address Line 1": user?.address?.addressLine1?.replace(/, +/g, ',\n') || "-",
      "Address Line 2": user?.address?.addressLine2?.replace(/, +/g, ',\n') || "-",
      "City": user?.address?.city.replace(/, +/g, ',\n') || "-",
      "State": user?.address?.state.replace(/, +/g, ',\n') || "-",
      "PostalCode": user?.address?.postalCode.replace(/, +/g, ',\n') || "-",
      "Country": user?.address?.country?.replace(/, +/g, ',\n') || "-",
    };
  });

  // Function to convert the formatted data to CSV and download it
  const convertToCSV = (data) => {
    const headers = [
      "Customer Name",
      "Customer Type",
      "Business Name",
      "Email",
      "Phone",
      "Registered Date",
      "Status",
      "Address Line 1",
      "Address Line 2",
      "City",
      "State",
      "PostalCode",
      "Country"

    ].join(',') + '\n';

    const rows = data.map(row => [
      row["Customer Name"],
      row["Customer Type"],
      row["Business Name"],
      row["Email"],
      row["Phone"],
      row["Registered Date"],
      row["Status"],
      row["Address Line 1"],
      row["Address Line 2"],
      row["City"],
      row["State"],
      row["PostalCode"],
      row["Country"],

    ].join(',')).join('\n');

    return headers + rows;
  };

  // Function to trigger CSV download
  const downloadCSV = () => {
    const csvData = convertToCSV(formattedData);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'fyndr_users_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };




  //effects
  useEffect(() => {
    //calling the first api here
    
    AdminSearchFunction(text);
    AdminSearchFunctionDwn(text);

  }, [userStatus, userType, pagination.current, pagination.total, pagination.pageSize, endDate, startDate, stateType, dateOrder])
  let objId = selectedUser?.objId

  const UpdateStatusFunction = async (callback) => {
    let payload = { accountStatus: accountStatus };
    setLoading(true);
    let resp = await actions.UserStatusUpdate(payload, objId);
    if (resp.success === true) {
      AdminSearchFunction(text);
      message.success(resp.msg);
    } else {
      message.error(resp.msg);
    }
    setLoading(false);
    callback();
  };
  const updateBusinessNameApi = async () => {
    let payload = { businessName: businessName };
    setLoading(true);
    let resp = await actions.businessNameUpdate(payload, objId);
    if (resp.success === true) {
      AdminSearchFunction(text);
      setBusinessUpdate(false);
      message.success(resp.msg);
    } else {
      setBusinessUpdate(false);
      message.error(resp.msg);
    }
    setLoading(false);
  };

  const updateEmailAddress = async () => {
    let payload = { email: newEmail };
    setLoading(true);
    let resp = await actions.emailUpdate(payload, objId);
    if (resp.success === true) {
      AdminSearchFunction(text);
      setEmailUpdate(false);
      message.success(resp.msg);
    } else {
      message.error(resp.msg);
      setEmailUpdate(false);
    }
    setLoading(false);
  };

  const handleSearch = (value) => {
    setText(value);
    if (value.length >= MINIMUM_SEARCH_LIMIT) {
      AdminSearchFunction(value);
    } else if (value.length == 0) {
      AdminSearchFunction("");
    }

  }
  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination
    })

  };
  const getStateData = async (value) => {
    let getStateByCode = await getStatesOfCountry(value);
    const newArrayOfObj = getStateByCode?.map(({
      isoCode: value,
      name: label,
      ...rest
    }) => ({
      value,
      label,
      ...rest
    }));
    if (value === "US") {
      let countiesToRemove = ['American Samoa', 'Baker Island', 'Guam', 'Howland Island', 'Jarvis Island', 'Johnston Atoll', 'Kingman Reef', 'Midway Atoll', 'Navassa Island', 'Northern Mariana Islands', 'Palmyra Atoll', 'Puerto Rico', 'United States Minor Outlying Islands', 'United States Virgin Islands', 'Wake Island'];
      let counties = newArrayOfObj?.filter(county => !countiesToRemove?.includes(county?.label));
      setStateData(counties)
    } else {
      setStateData(newArrayOfObj)
    }

  }
  const masqueradeUser = async (id) => {

    const channel = new BroadcastChannel('masquerade-channel');

    if (entityRole === ROLE_SUPPORT || entityRole === ROLE_MANAGER) {
      dispatch({ type: ROLE_TYPE, payload: entityRole })
    }
    let response = await actions.MasqueradeUser(id);
    
    if (response.success === true) { 
      channel.postMessage({ userId: id, entityRole : entityRole });
      if (entityRole === ROLE_SUPPORT || entityRole === ROLE_MANAGER) {
        let path = await findHomePathFromRoleMGR(entityRole, response.role)  
        history.push(path)
      }
      else {
        let path = await findHomePathFromRole(response.role)
        history.push(path)
      }

    } else {
      message.error(response.msg);
    }

  };

  const handleEmailChange = (e) => {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setNewEmail(e.target.value);
    const isValid = emailRegex.test(e.target.value);
    setIsValidEmail(isValid);
  };


  const resendCode = async () => {
    setToken("");
    const endpoint = `${base}/identity/verify/resendVerificationCode?type=phone`;
    const payload = {
      email: email,
      countryCode: phoneNo.countryCode,
      phone: phoneNo.phoneNumber,
      registerMode: regMode,
    };

    try {
      const response = await fetch_retry_post(endpoint, payload);
      if (response.ok) {
        setVerified(false)
        message.success(response.data.message);
        onClickReset();
      }
    } catch (err) {
      message.error(err.message);
      setEmailUpdate(false);

    }
  };

  const handleChangeToken = () => {
    let phoneno = /^\d+$/;
    if (!phoneno.test(token)) {
      message.error("Verification code should be in numbers");
    } else {
      verifyCode();
    }
  };
  const verifyCode = async () => {
    const endpoint = `${base}/identity/verify/verifyVerificationCode?type=phone`;
    const payload = {
      email: email,
      countryCode: phoneNo?.countryCode,
      phone: phoneNo.phoneNumber,
      verificationCode: token,
    };
    try {
      const response = await fetch_retry_post(endpoint, payload);
      if (response.ok) {
        setVerified(true);
        message.success(response.data.message);
        updateEmailAddress()
        setToken("");
      }
    } catch (err) {
      setTokenError(err.message);
      message.error(err.message);
    }
  };


  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("00:00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 45);
    return deadline;
  };
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  const getStripeDetailsInfo = async (indvid) => {
    let resp = await actions.verifyStripeRegistrationAdmin(indvid)

    if (resp.success) {
      setErrors(resp?.response?.info);
      setStripeVisible(true)
    } else {
      setStripeVisible(true)
    }
  };

  const resetStripeStatus = async (objId) => {
    let resp = await actions.ResetStripeStatus(objId);
    if (resp.success) {
      message.success(resp?.resp?.message);
      setStripeVisible(false)
    } else {
      message.error(resp.msg);
      setStripeVisible(false)
    }
  }
  return (
    <div className="admin_container no-breadcrumb container-mw-xl chapter">
      <div className="quickLoader">
        <PulseLoader color={"#3F9AF7"} loading={loading} size={10} />
      </div>
      <article className="article">
        <Row justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>Admin</Breadcrumb.Item>
              <Breadcrumb.Item>Users</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* <Col className="admin-dashboard-user">
            <h6 className="admin-dashboard-userdetails" >Logged in as Vish Cafe</h6>
          </Col> */}
        </Row>
        <Row justify="space-between">
          <h2
            className="article-title"
            style={{
              marginBottom: "-2rem",
              marginTop: "-2rem",
              paddingTop: "3rem",
              paddingBottom: "2rem",
            }}
          >
            Users
          </h2>

        </Row>
        <Divider />
        <div style={{ marginBottom: "2rem" }}>
          <Row style={{ marginTop: "1rem" }}>
            <Col style={{ width: "100%" }}>
              <Row gutter={[8, 16]}>
                <Col style={{ width: "20%" }}>
                  <Input
                    placeholder="Search"
                    type="search"
                    suffix={<SearchOutlined />}
                    onPressEnter={
                      (e) => {
                        setPagination({
                          ...pagination,
                          current: 1,

                        });
                        handleSearch(e.target.value)
                      }
                    }
                  /></Col>
                <Col style={{ width: "15%" }} >
                  <DropdownComponent value={userStatus} placeholder="Status" style={{ minWidth: "100%" }} onChange={(e) => {

                    setPagination({
                      ...pagination,
                      current: 1,

                    });
                    setUserStatus(e);

                  }} options={currentStatus} mode="multiple" />
                </Col>
                <Col style={{ width: "15%" }}>
                  <DropdownComponent placeholder="Customer Type" style={{ minWidth: "100%" }} onChange={(e) => {
                    setPagination({
                      ...pagination,
                      current: 1,

                    });
                    setUserType(e);

                  }} options={customerType} value={userType} mode="multiple" />
                </Col>
                <Col style={{ width: "20%" }} >
                  <RangePicker onChange={(e) => {
                    if (e === null) {
                      setStartDate("")
                      setEndDate("")
                    } else {
                      setPagination({
                        ...pagination,
                        current: 1,

                      });
                      setStartDate(moment(e[0]._d).format('YYYY-MM-DD'))
                      setEndDate(moment(e[1]._d).format('YYYY-MM-DD'))
                    }
                  }}
                    allowClear={true} />
                </Col>
                <Col style={{ width: '15%' }} >

                  <DropdownComponent placeholder="Country" onChange={(e) => {
                    setCountryValue(e)
                    getStateData(e)
                    setStateType([])

                  }} options={country} style={{ minWidth: "100%" }} defaultValue={countryValue} />

                </Col>
                <Col style={{ width: '15%' }}>
                  <DropdownComponent placeholder="State" style={{ minWidth: "100%" }} onChange={(e) => {
                    setPagination({
                      ...pagination,
                      current: 1,

                    });
                    setStateType(e);

                  }} options={stateData} value={stateType} mode="multiple" />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row justify="end" style={{marginBottom:5}}>
          <Button
            loading={loading}
            disabled={loading}
            type="primary"
            onClick={() => {
              downloadCSV();
            }}
          >
            Download User List
          </Button>
        </Row>
        <div>
          <Table
            className="admin-table"
            bordered
            pagination={pagination}
            columns={tableColumn}
            dataSource={data}
            size="medium"
            onChange={handleTableChange}
            showSorterTooltip={true}
          />
        </div>
      </article>
      <Modal
        title="Update Status"
        centered
        width={400}
        visible={visible}
        className="admin-modal"
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <div className="modal-div">
          <Row justify="space-around">
            <Col span={24}>
              <Row className="modal-cafe-name">
                {selectedUser?.businessName
                  ? selectedUser?.businessName
                  : selectedUser.name}
              </Row>
            </Col>
            <Col span={24}>
              <Row className="modal-cafe-name">{selectedUser?.email}</Row>
            </Col>
          </Row>
          <DropdownComponent
            style={{ minWidth: "100%" }}
            placeholder="Current Status"
            options={currentStatus}
            value={selectedStatus}
            onChange={(value) => {
              setAccountStatus(value);
              setSelectedStatus(value);
            }}
          />
          <div className="cust-dashboard-modal">
            <Button
              loading={loading}
              disabled={loading}
              type="primary"
              onClick={() => {
                UpdateStatusFunction((value) => {
                  setModalVisible(false);
                });
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title="Update Business Name"
        centered
        width={400}
        visible={businessUpdate}
        className="admin-modal"
        footer={null}
        onCancel={() => setBusinessUpdate(false)}
      >
        <div className="modal-div">


          <Input
            placeholder="Business Name"

            style={{ height: "36px" }}
            value={businessName}
            onChange={
              (e) => {

                setBusinessName(e.target.value)
              }
            }
          />
          <div className="cust-dashboard-modal">
            <Button
              loading={loading}
              disabled={loading}
              type="primary"
              onClick={() => {
                // UpdateStatusFunction((value) => {
                //   setModalVisible(false);
                // });
                updateBusinessNameApi()
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title="Update Email Address"
        centered
        width={400}
        visible={emailUpdate}
        className="admin-modal"
        footer={null}
        onCancel={() => {
          setEmailUpdate(false);
          setIsValidEmail(true);
          setVerified(true);
          setToken("")
        }}
      >
        <div className="modal-div">
          <Input
            type="email"
            placeholder="Email Address"
            style={{ height: "36px" }}
            value={newEmail}
            onChange={
              (value) => {
                handleEmailChange(value)
              }
            }
          />
          {!isValidEmail && (
            <p style={{ color: 'red', marginTop: '8px' }}>Please enter a valid email address.</p>
          )}
          {!verified &&
            <Row className="verifymobilemodal_input" style={{ width: "100%", padding: "0px" }} justify={"space-between"}>
              <Col span={timer !== "00:00" ? 10 : 14}>
                <Input
                  maxLength={6}
                  type="text"
                  value={token}
                  onChange={(e) => {
                    setToken(e.target.value);
                  }}
                  placeholder="Enter Verification Code"
                  style={{ height: "36px", width: "100%" }}
                />
              </Col>
              <Col>
                <Button
                  type="primary"
                  onClick={() => {
                    handleChangeToken();
                  }}
                  style={{ height: "36px" }}
                >
                  Ok
                </Button>
              </Col>
              <Col>
                <Button
                  style={{ height: "36px" }}
                  disabled={timer === "00:00" ? false : true}
                  type="primary"
                  onClick={() => timer === "00:00" && resendCode()}
                >
                  {timer !== "00:00" ? `Resend (${timer})` : `Resend`}{" "}
                </Button>
              </Col>
            </Row>
          }
          <div className="cust-dashboard-modal">
            <Button
              disabled={!isValidEmail || !verified}
              type="primary"
              onClick={() => {
                resendCode()
              }}
            >
              Update
            </Button>
          </div>
        </div>
      </Modal>
      <CustomizedModal
        title="Stripe Status"
        centered
        visible={stripeVisible}
        className="admin-modal"
        footer={null}
        onCancel={() => {
          setStripeVisible(false);
          setErrors()
        }}
        content={<StripeUI errors={errors} onResetClick={() => resetStripeStatus(objId)} />}
      />
    </div>
  );
}